import React, { useEffect, useState } from 'react';
import './SettingsPage.scss';
import TextInput from '../TextInput/TextInput';
import Button, { ButtonVariant } from '../Button/Button';
import { putProjectDelimiter } from '../../services/project/putProjectDelimiter';
import { toast } from 'react-toastify';
import { AppContext } from '../../types/appContextTypes';
import TabBar from '../TabBar/TabBar';
import { FundingSource } from '../../types/fundingSourceTypes';
import { postNewFundingSource } from '../../services/fundingSource/postNewFundingSource';
import { createTomorrowdate } from '../../utils/createTomorrowDate';
import { formatDateToCustomString } from '../../utils/formatDateForBackend';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../../utils/currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { CostCode } from '../../types/sharedTypes';
import FundingSources from './FundingSources/FundingSources';
import { deleteFundingSource } from '../../services/fundingSource/deleteFundingSource';
import { formatNewExclusionsFromCostCodes } from '../../utils/misc/formatNewExclusionsFromCostCodes';
import { removeDuplicateExclusions } from '../../utils/removeDuplicateExclusions';
import { putFundingSource } from '../../services/fundingSource/putFundingSource';
import cloneDeep from 'lodash/cloneDeep';
import { BudgetLineItem } from '../../types/budgetTypes';
import { calculateTotalApprovedChangeOrders } from '../../utils/changeOrders/calculations/calculateTotalApprovedChangeOrders';
import { isValidEmail } from '../../utils/misc/isValidEmail';
import { ProjectFundingSource } from '../../types/projectTypes';
import { sortCostCodesByCode } from '../../utils/costCodes/sortCostCodesByCode';

interface CostCodeDeliminterProps {
  handleCostCodeDelimiterChanged: (newValue: string) => void;
  delimiter: string;
  onSaveSettingsClicked: () => void;
}

const CostCodeDeliminter: React.FC<CostCodeDeliminterProps> = ({
  handleCostCodeDelimiterChanged,
  onSaveSettingsClicked,
  delimiter,
}) => {
  return (
    <div className="settings-section">
      <h4 className="settings-section-header">Cost Code Delimiter</h4>
      <hr />
      <div className="delimiter-input">
        <TextInput
          placeholder="Delimiter"
          onChange={handleCostCodeDelimiterChanged}
          value={delimiter}
        />
      </div>
      <br />
      <Button
        variant={ButtonVariant.PrimaryThin}
        onClick={onSaveSettingsClicked}
        label="Save Settings"
      />
    </div>
  );
};

interface Props {
  appContext: AppContext;
}
// TODO: Add Cost codes back in
// const tabOptions = ['Cost codes', 'Funding sources'];
const tabOptions = ['Funding sources'];

const SettingsPage: React.FC<Props> = ({ appContext }) => {
  const [activeTabindex, setActiveTabIndex] = useState<number>(0);
  const [delimiter, setDelimiter] = useState<string>('');
  const [fundingSources, setFundingSources] = useState<
    ProjectFundingSource[][]
  >([]);
  // Create funding source modal state
  const [isCFSModalOpen, setIsCFSModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [poc, setPoc] = useState('');
  const [fundingSourceType, setFundingSourceType] = useState('');
  const [amount, setAmount] = useState('0');
  const [paymentOrder, setPaymentOrder] = useState('');
  const [overageResponsibility, setOverageResponsibility] = useState('0');
  const [closeDate, setCloseDate] = useState<Date>(createTomorrowdate());
  const [maturityDate, setMaturityDate] = useState<Date>(createTomorrowdate());
  const [excludeLineItemsFromSource, setExcludeLineItemsFromSource] =
    useState(false);
  const [excludedCostCodes, setExcludedCostCodes] = useState<CostCode[]>([]);
  /////////////////
  const [selectedFundingSource, setSelectedFundingSource] =
    useState<ProjectFundingSource | null>(null);
  const [selectedFundingSourceCopy, setSelectedFundingSourceCopy] =
    useState<ProjectFundingSource | null>(null);

  useEffect(() => {
    setDelimiter(appContext.currentProject.cost_code_delimiter ?? '');
    setFundingSources(appContext.currentProject.funding_sources);
  }, [appContext]);

  const resetModalState = () => {
    setActiveStep(0);
    setName('');
    setPoc('');
    setFundingSourceType('');
    setOverageResponsibility('0');
    setAmount('0');
    setPaymentOrder('');
    setCloseDate(createTomorrowdate());
    setMaturityDate(createTomorrowdate());
    setExcludeLineItemsFromSource(false);
    setExcludedCostCodes([]);
  };

  const handleCloseCreateFundingSourceModal = () => {
    setIsCFSModalOpen(false);
    resetModalState();
  };

  const handleViewTabClicked = (newView: number) => {
    setActiveTabIndex(newView);
  };

  const handleCostCodeDelimiterChanged = (newValue: string) => {
    setDelimiter(newValue);
  };

  const saveSettings = () => {
    (async () => {
      const updatedProject = await putProjectDelimiter(
        appContext.currentProject.id,
        delimiter
      );
      if (updatedProject.error_message) {
        toast(updatedProject.error_message, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: 'error',
        });
      } else {
        appContext.currentProject.cost_code_delimiter = delimiter;
        appContext.refetchProjectData();
        toast('Successfully updated settings', {
          position: toast.POSITION.BOTTOM_CENTER,
          type: 'success',
        });
      }
    })();
  };

  const handleNewFundingSourceSubmit = async (fundingSource: FundingSource) => {
    const newFundingSource = await postNewFundingSource(fundingSource);

    if (newFundingSource.error_message) {
      toast(newFundingSource.error_message, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
    } else {
      toast('Successfully created new funding source', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'success',
      });
      setIsCFSModalOpen(false);
      resetModalState();
      appContext.refetchProjectData();
    }
  };

  const handleCFSSecondaryButtonClicked = () => {
    if (activeStep === 0) {
      setIsCFSModalOpen(false);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleCFSPrimaryButtonClicked = () => {
    console.log(activeStep, 'activeStep');
    // Show errors for general details page
    if (name === '' && activeStep === 0) {
      toast('Name is required', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
      return;
    }
    if (fundingSourceType === '' && activeStep === 0) {
      toast('Type is required', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
      return;
    }
    if (!isValidEmail(poc) && activeStep === 0 && poc !== '') {
      toast('Invalid email', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
      return;
    }

    // Show errors for financial details page
    if (paymentOrder === '' && activeStep === 1) {
      toast('Payment order is required', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
      return;
    }

    if (activeStep === 1) {
      handleNewFundingSourceSubmit({
        name: name,
        project_id: appContext.currentProject.id,
        funding_source_type: fundingSourceType,
        overage_responsibility: parseFloat(overageResponsibility),
        original_amount: parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(amount)
        ),
        order_index: parseInt(paymentOrder),
        primary_contact: poc,
        close_date_utc: formatDateToCustomString(closeDate),
        maturity_date_utc: formatDateToCustomString(maturityDate),
        cost_code_exclusions: excludedCostCodes
          .map((costCode: CostCode) => costCode.id)
          .join(','),
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleFundingSourceCardClicked = (
    fundingSource: ProjectFundingSource
  ) => {
    setSelectedFundingSource(fundingSource);
  };

  const handleFundingSourceEdited = (
    newValue: any,
    keyToChange: keyof ProjectFundingSource
  ) => {
    if (!selectedFundingSource) return;

    if (keyToChange === 'exclusions') {
      const formattedNewExclusions = formatNewExclusionsFromCostCodes(
        newValue,
        selectedFundingSource.id
      );

      const result = removeDuplicateExclusions([...formattedNewExclusions]);

      const fundingSource: any = {
        ...selectedFundingSource,
        exclusions: result,
      };

      setSelectedFundingSource(fundingSource);
    } else {
      const fundingSource: any = { ...selectedFundingSource };
      fundingSource[keyToChange] = newValue;
      setSelectedFundingSource(fundingSource);
    }
  };

  const handleDeleteFundingSourceClicked = async () => {
    if (!selectedFundingSource) return;

    const res = await deleteFundingSource(selectedFundingSource?.id);

    if (res.error_message) {
      toast(res.error_message, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
    } else {
      toast('Successfully deleted funding source', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'success',
      });
      setSelectedFundingSource(null);
      appContext.refetchProjectData();
    }
  };

  const handleConfirmSaveChangesToFundingSource = async () => {
    if (!selectedFundingSource) return;

    const fundingSourceCopy = selectedFundingSource ?? {};
    const formattedFundingSource = {
      ...fundingSourceCopy,
      funding_source_id: selectedFundingSource.id,
    };

    const res = await putFundingSource(formattedFundingSource);

    if (res.error_message) {
      toast(res.error_message, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
    } else {
      toast('Successfully updated funding source', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'success',
      });
      appContext.refetchProjectData();
    }
  };

  const handleCancelChangesButtonClicked = () => {
    setSelectedFundingSource(selectedFundingSourceCopy);
    setSelectedFundingSourceCopy(null);
  };

  const handleEditSourceButtonClicked = () => {
    const deepCopyOfSelectedFundingSource = cloneDeep(selectedFundingSource);
    setSelectedFundingSourceCopy(deepCopyOfSelectedFundingSource);
  };

  const handleFundingSourceBladeClosed = () => {
    setSelectedFundingSource(null);
  };

  const sumBudgetPropertyIncludingChildren = (
    parentLineItem: BudgetLineItem,
    propName: string
  ): number => {
    let total =
      (parentLineItem[propName as keyof typeof parentLineItem] as number) ??
      0.0;
    if (parentLineItem.children) {
      for (const child of parentLineItem.children) {
        total += sumBudgetPropertyIncludingChildren(child, propName);
      }
    }
    return Math.round(total * 100) / 100;
  };

  const budgetOriginalTotal = appContext.budget.reduce((acc, curr) => {
    return acc + sumBudgetPropertyIncludingChildren(curr, 'total_amount');
  }, 0);

  const budgetModificationTotal = appContext.budget.reduce((acc, curr) => {
    return (
      acc + sumBudgetPropertyIncludingChildren(curr, 'modification_amount')
    );
  }, 0);

  const totalApprovedCOs = calculateTotalApprovedChangeOrders(
    appContext.changeOrders
  );

  const approvedCOsBudgetModTotalSum =
    budgetModificationTotal + totalApprovedCOs;

  const budgetOverages =
    approvedCOsBudgetModTotalSum > 0 ? approvedCOsBudgetModTotalSum : 0;

  // TODO: Add Cost code settings back in
  const tabComponents = [
    // <CostCodeDeliminter
    //   handleCostCodeDelimiterChanged={handleCostCodeDelimiterChanged}
    //   delimiter={delimiter}
    //   onSaveSettingsClicked={saveSettings}
    //   key="CostCodeDeliminter"
    // />,
    <FundingSources
      key="FundingSources"
      isCFSModalOpen={isCFSModalOpen}
      activeStep={activeStep}
      name={name}
      poc={poc}
      excludedCostCodes={excludedCostCodes}
      all_cost_codes={sortCostCodesByCode(appContext.currentProject.cost_codes)}
      fundingSourceType={fundingSourceType}
      fundingSources={fundingSources}
      amount={amount}
      paymentOrder={paymentOrder}
      overageResponsibility={overageResponsibility}
      closeDate={closeDate}
      maturityDate={maturityDate}
      excludeLineItemsFromSource={excludeLineItemsFromSource}
      selectedFundingSource={selectedFundingSource}
      isFundingSourceBladeOpen={Boolean(selectedFundingSource)}
      budgetOriginalTotal={budgetOriginalTotal}
      budgetOverages={budgetOverages}
      isLoading={appContext.isRefetching}
      handleFundingSourceEdited={handleFundingSourceEdited}
      onClose={handleCloseCreateFundingSourceModal}
      setExcludedCostCodes={setExcludedCostCodes}
      setName={setName}
      setPoc={setPoc}
      setFundingSourceType={setFundingSourceType}
      setAmount={setAmount}
      setPaymentOrder={setPaymentOrder}
      setOverageResponsibility={setOverageResponsibility}
      setCloseDate={setCloseDate}
      setMaturityDate={setMaturityDate}
      setExcludeLineItemsFromSource={setExcludeLineItemsFromSource}
      onPrimaryButtonClicked={handleCFSPrimaryButtonClicked}
      onSecondaryButtonClicked={handleCFSSecondaryButtonClicked}
      openCFSModal={() => setIsCFSModalOpen(true)}
      onFundingSourceCardClicked={handleFundingSourceCardClicked}
      onFundingSourceBladeClosed={handleFundingSourceBladeClosed}
      handleDeleteFundingSourceClicked={handleDeleteFundingSourceClicked}
      handleConfirmSaveChanges={handleConfirmSaveChangesToFundingSource}
      handleCancelChangesButtonClicked={handleCancelChangesButtonClicked}
      handleEditSourceButtonClicked={handleEditSourceButtonClicked}
    />,
  ];

  const renderActiveTab = () => {
    return tabComponents[activeTabindex];
  };

  window.document.title = 'BidSight – Settings';
  return (
    <section className="SettingsPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Settings</h4>
          <h5>Manage your project settings here</h5>
        </div>
      </div>
      <div className="tab-bar-container">
        <TabBar
          tabOptions={tabOptions}
          activeTabOption={tabOptions[activeTabindex]}
          onTabClick={handleViewTabClicked}
        />
      </div>
      <div className="settings-container">{renderActiveTab()}</div>
    </section>
  );
};

export default SettingsPage;
