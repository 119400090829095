import React from 'react';
import './StateDropdown.scss';
import Select from '../Select/Select';
import { usStates } from '../../types/usStates';

interface Props {
  id?: string;
  onChange: (value: string) => void;
  value: string;
  isDisabled?: boolean;
}

const StateDropdown: React.FC<Props> = ({
  onChange,
  id = 'us-state-selector',
  value,
  isDisabled,
}) => {
  return (
    <Select
      onChange={onChange}
      id={id}
      value={value}
      isDisabled={isDisabled === true}
    >
      {usStates.map((usState: string) => {
        return (
          <option id={usState} key={usState}>
            {usState}
          </option>
        );
      })}
    </Select>
  );
};

export default StateDropdown;
