import { CostCode } from '../../types/sharedTypes';
import { getCostCodeByCode } from '../getCostCodeByCode';
import { getFirstCharactersBeforePeriod } from './getFirstCharactersBeforePeriod';

export const getCostCodeParentByChildID = (
  allCostCodes: CostCode[],
  childCostCode: CostCode | null
): CostCode | null => {
  let result: CostCode | null = null;
  if (!childCostCode) {
    console.warn('No child cost code provided');
    return result;
  }

  // If it doesn't have a . then it's a parent
  if (!childCostCode?.code.includes('.')) {
    const parentLevelCostCode = getCostCodeByCode(
      allCostCodes,
      getFirstCharactersBeforePeriod(childCostCode.code)
    );
    return parentLevelCostCode;
  }

  const findParentCostCode = (
    costCodes: CostCode[],
    childCostCode: CostCode
  ): void => {
    for (const costCode of costCodes) {
      const hasChild = costCode.children.some(
        (child) => child.id === childCostCode.id
      );

      if (hasChild) {
        // Update the result with the current cost code (highest level parent)
        const parentLevelCostCode = getCostCodeByCode(
          allCostCodes,
          getFirstCharactersBeforePeriod(costCode.code)
        );
        result = parentLevelCostCode;

        // No need to continue searching in this branch
        return;
      }

      // Recursively search in the children of the current cost code
      findParentCostCode(costCode.children, childCostCode);

      // If the result has been found, break out of the loop
      if (result !== null) {
        break;
      }
    }
  };

  // Start the search from the top-level cost codes
  findParentCostCode(allCostCodes, childCostCode);

  return result;
};
