// https://gomakethings.com/how-to-get-the-relative-time-between-two-dates-with-vanilla-js
export const getHumanTimeAgo = (startDateTime: Date): string => {
  const currentDateTime = new Date();
  if (startDateTime > currentDateTime) return 'Just now';
  const time = Math.abs(startDateTime.getTime() - currentDateTime.getTime());

  // Define humanTime and units
  let humanTime: string | number, units;

  // If there are years
  if (time > 1000 * 60 * 60 * 24 * 365) {
    humanTime = parseInt((time / (1000 * 60 * 60 * 24 * 365)).toString(), 10);
    units = 'year';
  }

  // If there are months
  else if (time > 1000 * 60 * 60 * 24 * 30) {
    humanTime = parseInt((time / (1000 * 60 * 60 * 24 * 30)).toString(), 10);
    units = 'month';
  }

  // If there are weeks
  else if (time > 1000 * 60 * 60 * 24 * 7) {
    humanTime = parseInt((time / (1000 * 60 * 60 * 24 * 7)).toString(), 10);
    units = 'week';
  }

  // If there are days
  else if (time > 1000 * 60 * 60 * 24) {
    humanTime = parseInt((time / (1000 * 60 * 60 * 24)).toString(), 10);
    units = 'day';
  }

  // If there are hours
  else if (time > 1000 * 60 * 60) {
    humanTime = parseInt((time / (1000 * 60 * 60)).toString(), 10);
    units = 'hour';
  }

  // If there are minutes
  else if (time > 1000 * 60) {
    humanTime = parseInt((time / (1000 * 60)).toString(), 10);
    units = 'minute';
  }

  // Otherwise, use seconds
  else {
    humanTime = parseInt((time / 1000).toString(), 10);
    units = 'second';

    // Use 'Just now' for under 30 seconds ago
    if (humanTime < 30) return 'Just now';
  }

  if (humanTime !== 1) units += 's';
  return humanTime + ' ' + units + ' ago';
};
