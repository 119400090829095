import { Vendor } from '../../types/sharedTypes';
import { getAPIBase, standardErrorHandler, standardRequestObject } from '../shared';

export const postVendor = async (projectId: string, vendor: Vendor) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + 'vendor';

    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({ ...vendor, project_id: projectId })
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};