import React, { useState, useEffect, useRef } from 'react';
import '../CostCodeSelectorInput.scss';
import { AiOutlineSearch as MagnifyingGlassIcon } from 'react-icons/ai';
import TextInput from '../../TextInput/TextInput';
import { CostCode } from '../../../types/sharedTypes';
import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { isDescendant } from '../../../utils/misc/isDescendant';
import Checkbox from '../../CheckBox/Checkbox';
import { getParentCostCodes } from '../../../utils/costCodes/getParentCostCodes';
import { getChildCostCodes } from '../../../utils/costCodes/getChildCostCodes';

interface CostCodeLineItemProps {
  depth: number;
  onClick: (e: React.MouseEvent, selectedCostCode: CostCode) => void;
  costCode: CostCode;
  value: CostCode[];
}

const CostCodeLineItem: React.FC<CostCodeLineItemProps> = ({
  depth,
  costCode,
  value,
  onClick,
}) => {
  const [showChildren, setShowChildren] = useState(false);
  const isSelected = value.includes(costCode);
  const hasChildren = costCode.children.length > 0;

  return (
    <>
      <button
        className="cost-code-list-item"
        style={{ paddingLeft: `${depth * 0.5 + 1}rem` }}
        key={costCode.id}
      >
        <span>
          <ChevronDownIcon
            onClick={() => setShowChildren(!showChildren)}
            className={`chevron-icon ${showChildren ? '' : 'rotate-90-deg'} ${
              hasChildren ? '' : 'hide-chevron'
            } `}
          />
          {costCode.code} - {costCode.description}
        </span>
        <span className="checkbox-container">
          <Checkbox
            isChecked={isSelected}
            onClicked={(e: React.MouseEvent) => onClick(e, costCode)}
          />
        </span>
      </button>
      {hasChildren &&
        showChildren &&
        costCode.children.map((childCostCode: CostCode) => {
          return (
            <CostCodeLineItem
              key={childCostCode.id}
              costCode={childCostCode}
              depth={depth + 1}
              value={value}
              onClick={onClick}
            />
          );
        })}
    </>
  );
};

interface Props {
  all_cost_codes?: CostCode[];
  onCostCodeSelect: (selectedCostCodes: CostCode[]) => void;
  value: CostCode[];
  isFullWidth?: boolean;
  isDisabled?: boolean;
  openFromBottom?: boolean;
  openToLeft?: boolean;
}

const CostCodeSelectorMultiSelectInput: React.FC<Props> = ({
  all_cost_codes = [],
  onCostCodeSelect,
  value,
  isFullWidth,
  isDisabled = false,
  openFromBottom = false,
  openToLeft = false,
}) => {
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [isContentAreaVisible, setIsContentAreaVisible] = useState(false);
  const [valueDisplayID, setValueDisplayID] = useState(
    `cost-code-input-button-${uuidv4()}`
  );
  const [asideID, setAsideID] = useState(`cost-code-aside-${uuidv4()}`);
  const [valueDisplayHeight, setValueDisplayHeight] = useState('');
  const [valueDisplayWidth, setValueDisplayWidth] = useState('');
  const componentRef = useRef(null);

  // Always make sure the content area is positioned just above and to the right of
  // the cost code display
  useEffect(() => {
    const valueDisplayArea = document.getElementById(valueDisplayID);
    const aside = document.getElementById(asideID);

    const handleMouseClick = (event: any) => {
      if (!valueDisplayArea || !aside) return;

      const asideWidth = aside.getBoundingClientRect().width;

      const valueDisplayRect = valueDisplayArea.getBoundingClientRect();
      const valueDisplayWidth = valueDisplayRect.width;

      if (openToLeft) {
        setValueDisplayWidth(`${asideWidth + 3}px`);
      } else {
        setValueDisplayWidth(`${valueDisplayWidth + 3}px`);
      }

      setValueDisplayHeight(`${valueDisplayRect.height}px`);
    };

    // Keep firing this useEffect anytime someone clicks to keep the position updated
    document.addEventListener('click', handleMouseClick);
    return () => {
      document.removeEventListener('click', handleMouseClick);
    };
  }, [openFromBottom, valueDisplayID, isContentAreaVisible, searchboxValue]);

  // Close content area if you click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        event.target &&
        !isDescendant(componentRef.current, event.target as Node)
      ) {
        setSearchboxValue('');
        setIsContentAreaVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const renderCostCodes = (costCodes: CostCode[], depth: number) => {
    return costCodes.map((costCode) => (
      <div key={costCode.code}>
        <CostCodeLineItem
          costCode={costCode}
          depth={depth}
          value={value}
          onClick={handleCostCodeSelected}
        />
      </div>
    ));
  };

  const shouldCostCodeDisplay = (costCode: CostCode) => {
    const doesMatch =
      costCode.code.includes(searchboxValue.toLowerCase()) ||
      costCode.description.toLowerCase().includes(searchboxValue.toLowerCase());

    if (doesMatch) {
      return true;
    }

    // Check recursively for children
    if (costCode.children && costCode.children.length > 0) {
      return costCode.children.some(shouldCostCodeDisplay);
    }

    return false;
  };

  const handleCostCodeSelected = (
    event: React.MouseEvent,
    costCode: CostCode
  ) => {
    event.stopPropagation();

    if (value.includes(costCode)) {
      // Remove the selected cost code and its children recursively if it's already in the list
      const costCodesToRemove = getChildCostCodes(costCode);
      costCodesToRemove.push(costCode);

      const newValue = value.filter(
        (currentCostCode: CostCode) =>
          !costCodesToRemove.some(
            (codeToRemove) => codeToRemove.id === currentCostCode.id
          )
      );

      onCostCodeSelect(newValue);
    } else {
      // Get unique parent and child cost codes
      const parentsLeadingToCostCode = getParentCostCodes(
        all_cost_codes,
        costCode
      ).filter((parentCode) => !value.includes(parentCode));

      const childrenAfterCostCode = getChildCostCodes(costCode).filter(
        (childCode) => !value.includes(childCode)
      );

      // Add the cost code and its unique parents and children to the selection
      onCostCodeSelect([
        ...value,
        ...parentsLeadingToCostCode,
        costCode,
        ...childrenAfterCostCode,
      ]);
    }
  };

  const filteredCostCodes = all_cost_codes.filter(shouldCostCodeDisplay);

  return (
    <div
      className="CostCodeSelectorInput"
      style={{
        position: `${openFromBottom ? 'relative' : 'static'}`,
      }}
      ref={componentRef}
    >
      <button
        id={valueDisplayID}
        disabled={isDisabled}
        className={`value-display-area ${
          isContentAreaVisible ? 'is-open' : ''
        }`}
        onClick={() => setIsContentAreaVisible(!isContentAreaVisible)}
        style={{
          width: `${isFullWidth ? '100%' : ''}`,
        }}
      >
        {value.length > 0 ? (
          <p>{value.length} exclusion(s) selected</p>
        ) : (
          <p>Select cost code</p>
        )}
        <ChevronDownIcon
          onClick={() => setIsContentAreaVisible(!isContentAreaVisible)}
          className={`chevron-down-icon ${
            isContentAreaVisible ? 'rotate-up' : ''
          }`}
        />
      </button>
      <aside
        id={asideID}
        className={`cost-code-selector-content ${
          isContentAreaVisible ? '' : 'hide-content-area'
        }`}
        style={{
          transform: `translateY(${openFromBottom ? '' : '-100%'}) translateX(${
            openFromBottom
              ? ''
              : openToLeft
              ? `-${valueDisplayWidth}`
              : `${valueDisplayWidth}`
          })`,
          top: `${openFromBottom ? valueDisplayHeight : ''}`,
          position: `${openFromBottom ? 'absolute' : 'fixed'}`,
        }}
      >
        <div className="search-container">
          <TextInput
            placeholder="Search"
            icon={<MagnifyingGlassIcon />}
            onChange={(newValue: string) => setSearchboxValue(newValue)}
            value={searchboxValue}
          />
        </div>
        {searchboxValue.length > 0 && filteredCostCodes.length > 0 ? (
          <div className="cost-code-list">
            {renderCostCodes(filteredCostCodes, 0)}
          </div>
        ) : searchboxValue.length === 0 ? (
          <div className="cost-code-list">
            {renderCostCodes(all_cost_codes, 0)}
          </div>
        ) : (
          <p>No cost codes found</p>
        )}
      </aside>
    </div>
  );
};

export default CostCodeSelectorMultiSelectInput;
