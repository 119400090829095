import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import './CurrencyInput.scss';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  allowNegative: true,
  allowLeadingZeroes: false,
};

interface MaskOptions {
  prefix: string;
  suffix: string;
  includeThousandsSeparator: boolean;
  thousandsSeparatorSymbol: string;
  allowDecimal: boolean;
  decimalSymbol: string;
  decimalLimit: string;
  requireDecimal: boolean;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
  integerLimit: number;
}

interface Props {
  maskOptions?: MaskOptions;
  inputprops?: any;
  showBorderOnDisabled?: boolean;
}

const CurrencyInput: React.FC<Props> = ({
  showBorderOnDisabled = false,
  maskOptions = {
    inputMode: 'numeric',
    maskOptions: {},
  },
  ...inputprops
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      className={`CurrencyInput ${
        showBorderOnDisabled ? 'show-currency-input-border' : ''
      }`}
      mask={currencyMask}
      {...inputprops.inputprops}
    />
  );
};

export default CurrencyInput;
