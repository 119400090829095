import React, { useState } from 'react';
import './BudgetBlade.scss';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { AiOutlineArrowUp as ArrowUpIcon } from 'react-icons/ai';
import TabBar from '../TabBar/TabBar';
import EditableTableV2, {
  Column,
  Row,
  CellType,
} from '../EditableTable/EditableTableV2';
import { BudgetLineItemEntity, BudgetLineItem } from '../../types/budgetTypes';
import { formatBudgetLineItemEntityToEditableTableRows } from '../../utils/budget/formatBudgetLineItemEntityToEditableTableRows';
import { useNavigate } from 'react-router-dom';
import Blade from '../Blade/Blade';
import { formatNumberWithCommas } from '../../utils/currency/formatNumberWithCommas';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import { sortBudgetBladeRows } from '../../utils/budget/sortBudgetBladeRows';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  budgetLineItemEntity: BudgetLineItemEntity | null;
  budgetIsFinalized: boolean;
  selectedBudgetLineItem: BudgetLineItem | BudgetLineItem | null;
  onDeleteClicked: (id?: string) => void;
}

const COLORS = ['#7F56D9', '#EBECF0']; // Purple and Gray
const tabOptions = ['All', 'Invoices', "CO's", 'Contracts'];
const tabKeys = ['all', 'invoices', 'change_orders', 'contracts'];

const bladeTableColumns: Column[] = [
  {
    value: 'Name',
  },
  {
    value: 'Amount',
  },
  {
    value: 'Status',
  },
];

interface DataItem {
  name: string;
  value: number;
}

interface RingChartProps {
  data: DataItem[];
}

const RingChart: React.FC<RingChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={45}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const calculateTotalAmountWithRollup = (
  parentLineItem: BudgetLineItem,
  fieldName: string
): number => {
  if (!parentLineItem.children || parentLineItem.children.length === 0) {
    return parentLineItem[fieldName as keyof typeof parentLineItem] as number;
  }

  return parentLineItem.children.reduce(
    (sum, child) => sum + calculateTotalAmountWithRollup(child, fieldName),
    0
  );
};

const BudgetBlade: React.FC<Props> = ({
  isOpen,
  onClose,
  budgetLineItemEntity,
  budgetIsFinalized,
  selectedBudgetLineItem,
  onDeleteClicked,
}) => {
  const [activeTabindex, setActiveTabIndex] = useState<number>(0);
  const navigate = useNavigate();
  const totalAmount = selectedBudgetLineItem
    ? budgetIsFinalized
      ? calculateTotalAmountWithRollup(
          selectedBudgetLineItem,
          'total_approved_budget'
        )
      : calculateTotalAmountWithRollup(selectedBudgetLineItem, 'total_amount')
    : 0;

  const handleViewTabClicked = (newView: number) => {
    setActiveTabIndex(newView);
  };

  const handleRowSelected = (rowIndex: number, row: Row) => {
    const idToUrlMap: any = {
      change_orders: 'changeorders',
      contracts: 'contracts',
      invoices: 'invoices',
    };
    const route = idToUrlMap[row.id ?? ''];
    navigate(`/${route}/${row.rowData.id}`);
  };

  const formattedTableRows: Row[] = sortBudgetBladeRows(
    formatBudgetLineItemEntityToEditableTableRows(budgetLineItemEntity)
  );

  const filteredTableRows: Row[] = formattedTableRows.filter(
    (tableRow: Row) => {
      const activeFilter = tabKeys[activeTabindex];
      if (activeFilter === 'all') return true;
      else if (activeFilter === tableRow.rowData.key) {
        return true;
      } else {
        return false;
      }
    }
  );

  const headerText = selectedBudgetLineItem
    ? `${selectedBudgetLineItem?.cost_code?.code} - ${selectedBudgetLineItem?.cost_code?.description}`
    : '';
  return (
    <Blade onClose={onClose} isOpen={isOpen}>
      {budgetLineItemEntity ? (
        <div className="budget-blade-content">
          <div className="budget-blade-top">
            <h4 className="primary-blade-header">{headerText}</h4>
            <p className="secondary-blade-header">
              {budgetIsFinalized ? 'Approved' : 'Total'} amount: $
              {formatNumberWithCommas(totalAmount)}
            </p>
            <h5 className="related-entities-header">Related entities</h5>
            {formattedTableRows.length > 0 && (
              <TabBar
                tabOptions={tabOptions}
                activeTabOption={tabOptions[activeTabindex]}
                onTabClick={handleViewTabClicked}
              />
            )}
          </div>
          {filteredTableRows.length > 0 ? (
            <div className="blade-table-container">
              <div className="budget-blade-scroll-table">
                <EditableTableV2
                  columns={bladeTableColumns}
                  rows={filteredTableRows}
                  onTablerowClicked={(rowIndex: number, row: Row) =>
                    handleRowSelected(rowIndex, row)
                  }
                  isReadOnly
                />
              </div>
            </div>
          ) : (
            <p className="no-entities-related">
              No entities related to this line item
            </p>
          )}
          {!budgetIsFinalized && (
            <div className="delete-button-container">
              <IconButton
                icon={<TrashCanIcon />}
                variant={IconButtonVariants.error}
                label="Delete"
                isFullWidth
                onClick={() => onDeleteClicked(selectedBudgetLineItem?.id)}
              />
            </div>
          )}
        </div>
      ) : (
        <h4>Loading...</h4>
      )}
    </Blade>
  );
};

export default BudgetBlade;
