export function formatDateMMDDYYYY(utcDateString?: string | null): string {
  if (!utcDateString) return '';
  const utcDate = new Date(utcDateString);
  const formattedDate = utcDate.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
}
