import { AppContext } from '../../types/appContextTypes';
import { Notification } from '../../types/notificationTypes';
import { createNotificationsFromAppContext } from './createNotificationsFromAppContext';

export function formatNotificationFilters(appContext: AppContext): {
  Name: string[];
  Type: string[];
  'Action Requested': string[];
  Vendor: string[];
  Status: string[];
} {
  const nameSet: Set<string> = new Set();
  const typeSet: Set<string> = new Set();
  const actionRequestedSet: Set<string> = new Set();
  const vendorSet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();

  const notifications: Notification[] =
    createNotificationsFromAppContext(appContext);

  notifications.forEach((notification: Notification) => {
    let name = notification?.entity_name;
    if (!name) {
      name = 'None';
    }
    nameSet.add(name);

    let type = notification?.entity_type;
    if (!type) {
      type = 'None';
    }
    typeSet.add(type);

    let actionRequested = notification?.action_requested;
    if (!actionRequested) {
      actionRequested = 'None';
    }
    actionRequestedSet.add(actionRequested);

    let vendor = notification?.vendor_name;
    if (!vendor) {
      vendor = 'None';
    }
    vendorSet.add(vendor);

    const status = notification.status;
    statusSet.add(status);
  });

  const nameArray: string[] = Array.from(nameSet);
  const typeArray: string[] = Array.from(typeSet);
  const actionRequestedArray: string[] = Array.from(actionRequestedSet);
  const vendorArray: string[] = Array.from(vendorSet);
  const statusArray: string[] = Array.from(statusSet);

  return {
    Name: nameArray,
    Type: typeArray,
    'Action Requested': actionRequestedArray,
    Vendor: vendorArray,
    Status: statusArray,
  };
}
