import { ChangeOrderLineItem } from '../../types/changeOrderTypes';

export const getBudgetLineItemsLabel = (
  changeOrderLineItems: ChangeOrderLineItem[]
) => {
  let result: string = changeOrderLineItems
    .filter((item: ChangeOrderLineItem) =>
      item.requested_amount ? item.requested_amount > 0 : false
    )
    .map((item: ChangeOrderLineItem) => item.cost_code.description)
    .join(', ');

  if (!result) {
    result = '-';
  }

  return result;
};
