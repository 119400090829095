import { BudgetLineItem } from '../../types/budgetTypes';

export const addNewLineItemToChildren = (
  items: BudgetLineItem[] | undefined,
  newBudgetLineItem: BudgetLineItem,
  targetCostCodeID: string
): boolean => {
  if (!items) {
    return false;
  }

  for (let i = 0; i < items.length; i++) {
    const currentItem = items[i];

    if (currentItem.cost_code?.id === targetCostCodeID) {
      // Found the target item, add the newBudgetLineItem to its children
      currentItem.children = [newBudgetLineItem, ...currentItem.children];
      return true;
    }

    // Recursively search in the children of the current item
    if (
      addNewLineItemToChildren(
        currentItem.children,
        newBudgetLineItem,
        targetCostCodeID
      )
    ) {
      return true;
    }
  }

  return false;
};
