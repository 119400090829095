import React, { ReactNode } from 'react';
import './FundingSourceColumns.scss';
import { getOrdinalSuffix } from '../../utils/misc/getOrdinalSuffix';
import { ProjectFundingSource } from '../../types/projectTypes';
import { formatToUSD } from '../../utils/currency/formatToUSD';

interface FSColumnProps {
  children?: ReactNode;
  payOrder: number;
}

const FSColumn: React.FC<FSColumnProps> = ({ children, payOrder }) => {
  return (
    <section className="FSColumn">
      <label className="column-header">
        Pays {`${payOrder}${getOrdinalSuffix(payOrder)}`}
      </label>
      {children}
    </section>
  );
};

interface Props {
  onCardClicked: (fundingSource: ProjectFundingSource) => void;
  fundingSources: ProjectFundingSource[][];
  selectedFundingSource: ProjectFundingSource | null;
  originalBudgetAmount: number;
  budgetOveragesAmount: number;
}

const FundingSourceColumns: React.FC<Props> = ({
  onCardClicked,
  fundingSources,
  selectedFundingSource,
  originalBudgetAmount,
  budgetOveragesAmount,
}) => {
  return (
    <div className="FundingSourceColumns">
      {fundingSources.map(
        (fundingSourceColumn: ProjectFundingSource[], i: number) => {
          return (
            <FSColumn payOrder={i + 1} key={i}>
              {fundingSourceColumn.map((fundingSource: ProjectFundingSource) => {
                let fundedToDatePercentage = Math.round(
                  (fundingSource.funded_to_date /
                    (fundingSource.original_amount +
                      budgetOveragesAmount *
                        (fundingSource.overage_responsibility / 100))) *
                    100
                );

                let originalAmountPercentage = Math.round(
                  (fundingSource.original_amount / originalBudgetAmount) * 100
                );

                if (isNaN(fundedToDatePercentage)) {
                  fundedToDatePercentage = 0;
                }
                if (isNaN(originalAmountPercentage)) {
                  originalAmountPercentage = 0;
                }

                return (
                  <div
                    className={`FSCard ${
                      selectedFundingSource?.id === fundingSource.id
                        ? 'active-funding-source-card'
                        : ''
                    }`}
                    onClick={() => onCardClicked(fundingSource)}
                    key={fundingSource.id}
                  >
                    <h6>{fundingSource.name}</h6>
                    <div className="tag-container">
                      <span className="tag purple">{fundingSource.type}</span>
                      <span className="tag">
                        {fundingSource.overage_responsibility}% overage cover
                      </span>
                    </div>
                    <div className="label-data-container">
                      <label>Original amount</label>
                      <p>
                        {formatToUSD(fundingSource.original_amount.toString())}{' '}
                        ({originalAmountPercentage}%)
                      </p>
                    </div>
                    <div className="label-data-container">
                      <label>Funded to date</label>
                      <p>
                        {formatToUSD(fundingSource.funded_to_date.toString())} (
                        {fundedToDatePercentage}%)
                      </p>
                    </div>
                  </div>
                );
              })}
            </FSColumn>
          );
        }
      )}
    </div>
  );
};

export default FundingSourceColumns;
