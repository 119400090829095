import { BudgetLineItem } from '../../types/budgetTypes';

export const doesParentBudgetLineItemHaveNewlyCreatedChild = (
  parent: BudgetLineItem
): boolean => {
  if (parent.children && parent.children.length > 0) {
    // Check if any child has isNewlyCreated set to true
    return parent.children.some((child) => child.isNewlyCreated === true);
  }
  return false;
};
