import { BudgetLineItem } from '../../types/budgetTypes';

const splitAndConvert = (code: string): number[] => {
  return code.split('.').map((part) => parseInt(part, 10));
};

const compareCostCodes = (a: BudgetLineItem, b: BudgetLineItem): number => {
  const codeA = a?.cost_code?.code ?? '';
  const codeB = b?.cost_code?.code ?? '';

  const partsA = splitAndConvert(codeA);
  const partsB = splitAndConvert(codeB);

  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const partA = partsA[i] || 0;
    const partB = partsB[i] || 0;

    if (partA !== partB) {
      return partA - partB;
    }
  }

  return 0; // Both cost codes are equal
};

export const sortBudgetLineItemsByCostCodeCode = (
  items: BudgetLineItem[]
): BudgetLineItem[] => {
  if (!items) {
    return [];
  }

  return items.sort(compareCostCodes).map((item) => ({
    ...item,
    children: sortBudgetLineItemsByCostCodeCode(item.children || []),
  }));
};
