export const formatNumberWithCommas = (number?: number, stripEmptyDecimals = false): string => {
  if (!number && number !== 0) return '';
  const parts = number.toFixed(2).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedNumber = parts.join('.');
  if (stripEmptyDecimals) {
    return formattedNumber.replace(/\.00$/, '');
  }
  return formattedNumber;
};
