import { v4 as uuidv4 } from 'uuid';
import { CostCode } from '../../types/sharedTypes';
import { BudgetLineItem } from '../../types/budgetTypes';

export const createMockBudgetLineItem = (
  parentCostCode: CostCode | null,
  id?: string,
  isNewlyCreated?: boolean,
  projectID?: string,
  isDisabled?: boolean,
  totalAmount?: number,
  costCode?: CostCode | null
): BudgetLineItem => {
  return {
    approved_budget_percent_complete: 0,
    approved_budget_remaining_to_spend: 0,
    approved_changes: 0,
    buyout_bought: 0,
    buyout_unbought: 0,
    buyout_vendor: [],
    children: [],
    contract_id: null,
    cost_code: costCode ?? null,
    cost_code_id: costCode?.id ?? '',
    current_budget: 0,
    current_draw: 0,
    id: id ?? uuidv4(),
    is_deleted: false,
    isDisabled: isDisabled ?? false,
    isNewlyCreated: isNewlyCreated ?? false,
    is_pending: false,
    modification_amount: 0,
    pending_changes: 0,
    parentCostCode: parentCostCode,
    project_id: projectID ?? '',
    projected_budget_percent_complete: 0,
    projected_budget_remaining_to_spend: 0,
    projected_variance_to_approved_budget: 0,
    projected_variance_to_base_budget: 0,
    quantity: 1,
    requested_to_date: 0,
    total_amount: totalAmount ?? 0,
    total_approved_budget: 0,
    total_projected_budget: 0,
    unit_cost: totalAmount ?? 0,
    unit_of_measurement: '',
  };
};
