import React from 'react';
import './FilterButton.scss';
import { FilterItem } from '../../types/sharedTypes';
import { AiOutlineCloseCircle as CloseIcon } from 'react-icons/ai';

interface Props {
  filter: FilterItem;
  onClick: (filter: FilterItem) => void;
}

const FilterButton: React.FC<Props> = ({ filter, onClick }) => {
  return (
    <button
      className="FilterButton"
      key={`${filter.label}_${filter.value}`}
      onClick={() => onClick(filter)}
    >
      <div className="value-container">
        <span>{filter.label} is </span>
        <span className="value ellipsis-overflow">{filter.value}</span>
      </div>
      <CloseIcon />
    </button>
  );
};

export default FilterButton;
