import { InvoiceLineItem } from '../../types/invoiceTypes';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';

export const formatInvoiceLineItemsForBackend = (
  unformattedInvoiceLineItems: any
): InvoiceLineItem[] => {
  const result: InvoiceLineItem[] = [];

  for (let i = 0; i < unformattedInvoiceLineItems.length; i++) {
    const currentUnformattedInvoiceLineItem = unformattedInvoiceLineItems[i];

    // currentUnformattedInvoiceLineItem.balance_to_finish =
    //   removeCharactersExceptForPeriodAndNumbersFromFloatString(
    //     currentUnformattedInvoiceLineItem.balance_to_finish.toString()
    //   );

    // currentUnformattedInvoiceLineItem.from_previous_applications =
    //   removeCharactersExceptForPeriodAndNumbersFromFloatString(
    //     currentUnformattedInvoiceLineItem.from_previous_applications.toString()
    //   );

    // currentUnformattedInvoiceLineItem.previous_retainage =
    //   removeCharactersExceptForPeriodAndNumbersFromFloatString(
    //     currentUnformattedInvoiceLineItem.previous_retainage.toString()
    //   );

    // currentUnformattedInvoiceLineItem.scheduled_value =
    //   removeCharactersExceptForPeriodAndNumbersFromFloatString(
    //     currentUnformattedInvoiceLineItem.scheduled_value.toString()
    //   );
    // currentUnformattedInvoiceLineItem.total_completed_and_stored_to_date =
    //   removeCharactersExceptForPeriodAndNumbersFromFloatString(
    //     currentUnformattedInvoiceLineItem.total_completed_and_stored_to_date.toString()
    //   );

    // These are all editable by user. Need to git rid of $ and , characters first
    currentUnformattedInvoiceLineItem.this_period =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.this_period.toString()
      );

    currentUnformattedInvoiceLineItem.current_billing =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.current_billing.toString()
      );

    currentUnformattedInvoiceLineItem.current_work_and_materials =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.current_work_and_materials.toString()
      );

    currentUnformattedInvoiceLineItem.current_retainage =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.current_retainage.toString()
      );
    currentUnformattedInvoiceLineItem.retainage =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.retainage.toString()
      );
    currentUnformattedInvoiceLineItem.materials_presently_stored =
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentUnformattedInvoiceLineItem.materials_presently_stored.toString()
      );

    result.push(currentUnformattedInvoiceLineItem);
  }

  return result;
};
