import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import './CostCodeSelectorInput.scss';
import { AiOutlineSearch as MagnifyingGlassIcon } from 'react-icons/ai';
import TextInput from '../TextInput/TextInput';
import { AiOutlineArrowLeft as ArrowLeft } from 'react-icons/ai';
import { CostCode } from '../../types/sharedTypes';
import {
  FiChevronDown as ChevronDownIcon,
  FiChevronRight as ChevronRightIcon,
} from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { isDescendant } from '../../utils/misc/isDescendant';
import { isCostCodeIDInArrayOfCostCodes } from '../../utils/costCodes/isCostCodeIDInArrayOfCostCodes';

interface CostCodeLineItemProps {
  depth: number;
  onClick: (e: React.MouseEvent, selectedCostCode: CostCode) => void;
  costCode: CostCode;
  isCostCodeDisabled: boolean;
  renderCostCodes: (costCodes: CostCode[], depth: number) => React.ReactNode;
  renderChildren: boolean;
  filteredCostCodes: CostCode[];
}

const CostCodeLineItem: React.FC<CostCodeLineItemProps> = ({
  depth,
  onClick,
  costCode,
  isCostCodeDisabled,
  renderCostCodes,
  renderChildren,
  filteredCostCodes,
}) => {
  const [areChildrenVisible, setAreChildrenVisible] = useState(depth === 0);
  const hasChidren = costCode.children.length > 0;

  const handleClick = (e: React.MouseEvent) => {
    if (isCostCodeDisabled) return;
    onClick(e, costCode);
  };

  return (
    <div className="cost-code-button-chevron-container" onClick={handleClick}>
      <div className="parent-button-container">
        {renderChildren && (
          <button
            style={{
              marginLeft: `${depth * 1.2 + 1}rem`,
              visibility: `${hasChidren ? 'visible' : 'hidden'}`,
            }}
            className="cost-code-button-chevron-button"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setAreChildrenVisible(!areChildrenVisible);
            }}
          >
            {areChildrenVisible ? (
              <ChevronDownIcon className="cost-code-button-chevron" />
            ) : (
              <ChevronRightIcon className="cost-code-button-chevron" />
            )}
          </button>
        )}
        <button
          className="cost-code-list-item-button"
          onClick={handleClick}
          disabled={isCostCodeDisabled}
        >
          {costCode.code} - {costCode.description}
        </button>
      </div>
      {renderChildren && areChildrenVisible && filteredCostCodes.length > 0 && (
        <>
          <div
            className="line"
            style={{
              left: `${depth * 1.2 + 1.4}rem`,
            }}
          />
          <div
            className="line-curve"
            style={{
              left: `${depth * 1.2 + 1.4}rem`,
            }}
          />
        </>
      )}
      {hasChidren &&
        areChildrenVisible &&
        renderChildren &&
        renderCostCodes(costCode.children, depth + 1)}
    </div>
  );
};

interface Props {
  all_cost_codes?: CostCode[];
  usedCostCodes?: CostCode[];
  onCostCodeSelect: (childCostCode: CostCode) => void;
  value: CostCode | null;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  openFromBottom?: boolean;
  onlyShowAvailableCostCodes?: boolean;
  defaultParent?: CostCode | null;
  disableParentSelection?: boolean;
  openFromBottomInTable?: boolean;
  useFixedPosition?: boolean;
  tableID?: string;
}

const CostCodeSelectorInput: React.FC<Props> = ({
  all_cost_codes = [],
  usedCostCodes = [],
  onCostCodeSelect,
  value,
  isFullWidth,
  isDisabled = false,
  openFromBottom = false,
  onlyShowAvailableCostCodes = false,
  defaultParent = null,
  disableParentSelection = false,
  openFromBottomInTable = false,
  useFixedPosition = false,
  tableID = '',
}) => {
  const [selectedParent, setSelectedParent] = useState<CostCode | null>(
    defaultParent
  );
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [isContentAreaVisible, setIsContentAreaVisible] = useState(false);
  const [valueDisplayID, setValueDisplayID] = useState(
    `cost-code-input-button-${uuidv4()}`
  );
  const [valueDisplayHeight, setValueDisplayHeight] = useState('');
  const [valueDisplayWidth, setValueDisplayWidth] = useState('');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const contentAreaRef = useRef<HTMLDivElement | null>(null);
  const [hasContentAreaBeenAboveTable, setHasContentAreaBeenAboveTable] =
    useState(false);

  useLayoutEffect(() => {
    const valueDisplayArea = document.getElementById(valueDisplayID);
    if (!valueDisplayArea) return;

    const valueDisplayRect = valueDisplayArea.getBoundingClientRect();
    const valueDisplayWidth = valueDisplayRect.width;
    setValueDisplayWidth(`${valueDisplayWidth}`);
    setValueDisplayHeight(`${valueDisplayRect.height}`);
  }, [
    openFromBottom,
    valueDisplayID,
    isContentAreaVisible,
    searchboxValue,
    selectedParent,
    openFromBottomInTable,
  ]);

  useEffect(() => {
    // Close content area if you click outside
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        event.target &&
        !isDescendant(componentRef.current, event.target as Node)
      ) {
        if (!disableParentSelection) {
          setSelectedParent(null);
        }
        setSearchboxValue('');
        setIsContentAreaVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const positionContentArea = () => {
    if (!openFromBottomInTable) return;

    if (!contentAreaRef.current) {
      return console.warn('Unable to find content area element');
    }

    const contentAreaRect = contentAreaRef.current?.getBoundingClientRect();

    if (!contentAreaRect) {
      console.warn('Unable to find content area');
    }

    const valueDisplayArea = document.getElementById(valueDisplayID);
    if (!valueDisplayArea) return;
    const valueDisplayRect = valueDisplayArea.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const isInTop70Percent = valueDisplayRect.top < windowHeight * 0.7;

    if (isInTop70Percent) {
      const styles = `translateX(${valueDisplayWidth}px) translateY(-${valueDisplayHeight}px)`;
      if (contentAreaRef.current !== null) {
        contentAreaRef.current.style.transform = styles;
      }
    } else {
      const styles = `translateX(${valueDisplayWidth}px) translateY(-100%)`;
      if (contentAreaRef.current !== null) {
        contentAreaRef.current.style.transform = styles;
      }
    }
  };

  const shouldCostCodeBeDisabled = (costCode: CostCode) => {
    return onlyShowAvailableCostCodes
      ? isCostCodeIDInArrayOfCostCodes(usedCostCodes, costCode.id) &&
          Boolean(selectedParent)
      : false;
  };

  const renderCostCodes = (costCodes: CostCode[], depth: number) => {
    if (!costCodes) return null;

    return costCodes.map((costCode) => {
      const isCostCodeDisabled = shouldCostCodeBeDisabled(costCode);
      return (
        <div key={costCode.code}>
          {shouldCostCodeDisplay(costCode) && (
            <CostCodeLineItem
              costCode={costCode}
              depth={depth}
              onClick={handleCostCodeSelected}
              isCostCodeDisabled={isCostCodeDisabled}
              renderCostCodes={renderCostCodes}
              renderChildren
              filteredCostCodes={filteredCostCodes}
            />
          )}
        </div>
      );
    });
  };

  const shouldCostCodeDisplay = (costCode: CostCode) => {
    if (!costCode) return false;

    const searchValue = searchboxValue.toLowerCase();
    const codeParts = costCode.code.split('.');

    let partialCode = '';

    for (let i = 0; i < codeParts.length; i++) {
      partialCode += (i > 0 ? '.' : '') + codeParts[i];
      if (partialCode === searchValue) {
        return true;
      }
      if (
        searchValue.endsWith('.') &&
        searchValue.endsWith('0') &&
        i < codeParts.length - 1
      ) {
        // If search value ends with '0' and '.', it might match next code part
        const nextPart = codeParts[i + 1];
        if (nextPart.startsWith(searchValue.slice(0, -1))) {
          return true;
        }
      }
      if (partialCode.startsWith(searchValue)) {
        return true;
      }
    }

    const descriptionMatches = costCode.description
      .toLowerCase()
      .includes(searchValue);

    if (descriptionMatches) {
      return true;
    }

    // Recursively check children
    if (costCode.children.length > 0) {
      for (const child of costCode.children) {
        if (shouldCostCodeDisplay(child)) {
          return true;
        }
      }
    }

    return false;
  };

  const handleCostCodeSelected = (
    event: React.MouseEvent,
    costCode: CostCode
  ) => {
    event.stopPropagation();
    onCostCodeSelect(costCode);
    setSearchboxValue('');
    setIsContentAreaVisible(false);

    value = costCode;
  };

  const handleBackToParentClicked = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedParent(null);
  };

  const handleParentSelected = (
    event: React.MouseEvent,
    costCode: CostCode | null
  ) => {
    event.stopPropagation();
    setSelectedParent(costCode);
  };

  const handleValueDisplayButtonClicked = () => {
    const valueDisplayButton = document
      .getElementById(valueDisplayID)
      ?.getBoundingClientRect();

    if (!valueDisplayButton) {
      return console.warn('Value display button not found in DOM');
    }

    const newValueDisplayWidth = valueDisplayButton.width;
    const newValueDisplayHeight = valueDisplayButton.height;
    setValueDisplayWidth(newValueDisplayWidth.toString());
    setValueDisplayHeight(newValueDisplayHeight.toString());
    setIsContentAreaVisible(!isContentAreaVisible);
  };

  useLayoutEffect(() => {
    positionContentArea();
  }, [isContentAreaVisible]);

  const filteredCostCodes = all_cost_codes.filter(shouldCostCodeDisplay);

  return (
    <div
      className="CostCodeSelectorInput"
      style={{
        position: `${openFromBottom ? 'relative' : 'static'}`,
        width: isFullWidth ? '100%' : '',
      }}
      ref={componentRef}
    >
      <button
        id={valueDisplayID}
        disabled={isDisabled}
        className={`value-display-area ${
          isContentAreaVisible ? 'is-open' : ''
        }`}
        onClick={handleValueDisplayButtonClicked}
        style={{
          width: `${isFullWidth ? '100%' : ''}`,
        }}
      >
        <p>
          {value
            ? `${value?.code} - ${value?.description}`
            : 'Select cost code'}
        </p>
        <ChevronDownIcon
          onClick={() => setIsContentAreaVisible(!isContentAreaVisible)}
          className={`chevron-down-icon ${
            isContentAreaVisible ? 'rotate-up' : ''
          }`}
        />
      </button>
      <aside
        ref={contentAreaRef}
        className={`cost-code-selector-content ${
          isContentAreaVisible ? '' : 'hide-content-area'
        }`}
        style={{
          transform: `translateY(${openFromBottom ? '' : '-100%'}) translateX(${
            openFromBottom ? '' : `${valueDisplayWidth}`
          })`,
          top: `${openFromBottom ? valueDisplayHeight : ''}`,
          position: `${useFixedPosition ? 'fixed' : 'absolute'}`,
        }}
      >
        <div className="search-container">
          <TextInput
            placeholder="Search"
            icon={<MagnifyingGlassIcon />}
            onChange={(newValue: string) => setSearchboxValue(newValue)}
            value={searchboxValue}
          />
        </div>
        {selectedParent && !disableParentSelection && (
          <button
            className="back-to-parent-button"
            onClick={(event: React.MouseEvent) => {
              handleBackToParentClicked(event);
            }}
          >
            <ArrowLeft />
            <span>Back to parent line items</span>
          </button>
        )}
        {searchboxValue.length > 0 && !selectedParent ? (
          <div className="cost-code-list">
            {renderCostCodes(all_cost_codes, 0)}
          </div>
        ) : selectedParent ? (
          <div className="cost-code-list">
            <CostCodeLineItem
              isCostCodeDisabled={shouldCostCodeBeDisabled(selectedParent)}
              costCode={selectedParent}
              depth={0}
              onClick={handleCostCodeSelected}
              renderCostCodes={renderCostCodes}
              renderChildren
              filteredCostCodes={filteredCostCodes}
            />
          </div>
        ) : (
          <div className="cost-code-list">
            {filteredCostCodes.length > 0 ? (
              filteredCostCodes.map((costCode: CostCode) => {
                return (
                  <CostCodeLineItem
                    key={costCode.id}
                    isCostCodeDisabled={shouldCostCodeBeDisabled(costCode)}
                    costCode={costCode}
                    depth={0}
                    onClick={(e: React.MouseEvent) =>
                      handleParentSelected(e, costCode)
                    }
                    renderCostCodes={renderCostCodes}
                    renderChildren={false}
                    filteredCostCodes={filteredCostCodes}
                  />
                );
              })
            ) : (
              <p>No cost codes found</p>
            )}
          </div>
        )}
      </aside>
    </div>
  );
};

export default CostCodeSelectorInput;
