import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { budgetRoute } from './getBudgets';

export interface FormattedForBackendLineItemModification {
  line_item_id: string;
  amount: string;
  cost_code_id: string;
}

export const postLineItemModifications = async (
  lineItems: FormattedForBackendLineItemModification[],
  project_id: string
) => {
  const apiBase = getAPIBase();
  const endpoint =
    apiBase + budgetRoute + '/line/modify' + `?project_id=${project_id}&`;
  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      modification_json: JSON.stringify(lineItems),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
