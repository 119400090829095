import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { Invoice } from '../../types/invoiceTypes';
import { formatDateMMDDYYYY } from '.././formatDateMMDDYYYY';

export const formatInvoicesForEditableTable = (
  invoices: Invoice[],
  selectedInvoices: string[]
): Row[] => {
  const result: Row[] = [];

  if (!invoices || invoices.length === 0) return result;

  for (let i = 0; i < invoices.length; i++) {
    const currentInvoice = invoices[i];
    const currentRow: Row = {
      cells: [],
      rowData: currentInvoice,
      id: currentInvoice.id,
    };

    // Checkbox select
    const isCheckboxSelected = selectedInvoices.includes(currentRow.id ?? '');
    currentRow.cells.push({
      value: String(isCheckboxSelected),
      type: CellType.checkboxSelect,
    });

    // ID
    currentRow.cells.push({
      value: currentInvoice.number,
      type: CellType.number,
      isCellDisabled: true,
    });

    // Invoice Name
    currentRow.cells.push({
      value: currentInvoice.name,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Invoice Number
    currentRow.cells.push({
      value: currentInvoice.number_from_vendor ?? '-',
      type: CellType.number,
      isCellDisabled: true,
    });

    // Vendor
    currentRow.cells.push({
      value: currentInvoice.vendor?.name ?? '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    // Total Amount
    currentRow.cells.push({
      value: currentInvoice.total_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Status
    currentRow.cells.push({
      value: currentInvoice.status,
      type: CellType.status,
      isCellDisabled: true,
    });

    // Invoice Date
    currentRow.cells.push({
      value: currentInvoice.date_utc
        ? formatDateMMDDYYYY(currentInvoice.date_utc)
        : '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    // Draw Package
    currentRow.cells.push({
      value: currentInvoice.draw_package?.title ?? '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    // Draw Status
    currentRow.cells.push({
      value: currentInvoice.draw_package?.status ?? '-',
      type: CellType.status,
      isCellDisabled: true,
    });

    // Contract
    currentRow.cells.push({
      value: currentInvoice.contract?.title ?? '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    // Contract Status
    currentRow.cells.push({
      value: currentInvoice.contract?.status ?? '-',
      type: CellType.status,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
