import React from 'react';
import './StatusChip.scss';
import { Statuses } from '../../types/sharedTypes';

interface Props {
  status: Statuses;
}

const StatusChip: React.FC<Props> = ({ status }) => {
  return <span className={`StatusChip status-${status}`}>{status}</span>;
};

export default StatusChip;
