import { Row } from '../../components/EditableTable/EditableTableV2';
import { convertUTCDateStringToDate } from '../convertUTCDateStringToDate';

export const sortNotifications = (rows: Row[]): Row[] => {
  rows.sort((a, b) => {
    const dateA = new Date(a.rowData?.created_on);
    const dateB = new Date(b.rowData?.created_on);

    return dateB.getTime() - dateA.getTime();
  });
  return rows;
};
