import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { ChangeOrderLineItem } from '../../types/changeOrderTypes';

export const formatChangeOrderLineItemsForEditableTable = (
  changeOrderLineItems?: ChangeOrderLineItem[] | null
): Row[] => {
  const result: Row[] = [];

  if (!changeOrderLineItems || changeOrderLineItems?.length === 0) {
    return result;
  }

  for (let i = 0; i < changeOrderLineItems.length; i++) {
    const currentChangeOrderLineItem: ChangeOrderLineItem =
      changeOrderLineItems[i];
    const currentRow: Row = {
      cells: [],
      id: currentChangeOrderLineItem.id,
      rowData: currentChangeOrderLineItem,
    };

    // Cost Code
    currentRow.cells.push({
      value: currentChangeOrderLineItem.cost_code.code,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Description
    currentRow.cells.push({
      value: currentChangeOrderLineItem.cost_code.description,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Original Contract Amount
    currentRow.cells.push({
      value: currentChangeOrderLineItem.current_contract_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Requested Changes
    currentRow.cells.push({
      value: currentChangeOrderLineItem.requested_amount
        ? currentChangeOrderLineItem.requested_amount.toString()
        : '0',
      type: CellType.dollars,
    });

    // Budgeted Changes
    currentRow.cells.push({
      value: currentChangeOrderLineItem.budgeted_amount
        ? currentChangeOrderLineItem.budgeted_amount.toString()
        : '0',
      type: CellType.dollars,
    });

    // Revised Contract Amount
    currentRow.cells.push({
      value: currentChangeOrderLineItem.revised_contract_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Variance from Contract
    const varianceFromContractValue =
      currentChangeOrderLineItem.revised_contract_amount -
      currentChangeOrderLineItem.current_contract_amount;
    currentRow.cells.push({
      value: varianceFromContractValue.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
