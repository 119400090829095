import { Contract } from '../../types/contractTypes';
import { Invoice, InvoiceLineItem, NewInvoiceLineItem } from '../../types/invoiceTypes';
import { CostCode } from '../../types/sharedTypes';
import { convertUTCDateStringToDate } from '../convertUTCDateStringToDate';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { getEntireCostCodeObjectByCostCodeId } from '../getEntireCostCodeObjectByCostCodeId';
import { v4 as uuidv4 } from 'uuid';
import { getInitialInvoiceLineItemWithCalculations } from './getInitialInvoiceLineItemWithCalculations';

const fallbackCostCode: CostCode = {
  code: '12.345',
  description: 'Fallback costcode',
  id: 'aaaaaaa-bbbb-ccccc-ddd-eeeeeeeeeee',
  organization_id: null,
  project_id: null,
  type: 'Testing',
  is_parent: false,
  children: [],
};

export const formatNewLineItemsIntoBackendLineItems = (
  invoice: Invoice | null,
  newLineItems: NewInvoiceLineItem[],
  allCostCodes: CostCode[],
  allContracts: Contract[],
  allInvoices: Invoice[],
  contractID: string
): InvoiceLineItem[] => {
  const result: InvoiceLineItem[] = [];

  const selectedContract: Contract | undefined = allContracts.find(
    (contract: Contract) => contract.id === contractID
  );

  for (let i = 0; i < newLineItems.length; i++) {
    const currentNewLineItem = newLineItems[i];

    const costCode =
      getEntireCostCodeObjectByCostCodeId(
        currentNewLineItem.cost_code,
        allCostCodes
      ) ?? fallbackCostCode;

    const currentBilling = parseFloat(
      removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentNewLineItem.amount
      )
    );

    const matchingContractLineItem = selectedContract ? allContracts.find(
      (contract) => contract.id == selectedContract.id)?.line_items.find(
        (cli) => cli.cost_code_id == currentNewLineItem.cost_code) : null;
    const scheduledValue = matchingContractLineItem?.total_amount ?? 0.0;

    const invoiceLineItem = getInitialInvoiceLineItemWithCalculations(
      invoice,
      contractID,
      costCode,
      allInvoices,
      currentBilling,
      scheduledValue);
    invoiceLineItem.quoted_line_item = matchingContractLineItem?.quoted_line_item;

    result.push(invoiceLineItem);
  }

  return result;
};
