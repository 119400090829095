import { ProjectTeamMember } from '../types/projectTypes';
import { FilterItem, Statuses } from '../types/sharedTypes';

export const filtersToTeamMemberDataValueMap: Record<
  string,
  keyof ProjectTeamMember
> = {
  Title: 'organization_title',
  Projects: 'project_count',
  Teams: 'teams',
  Status: 'is_deactivated',
};

export function applyTeamFilters(
  filters: FilterItem[],
  teamMembers: ProjectTeamMember[],
  textSearchValue: string
): ProjectTeamMember[] {
  if (filters.length === 0 && textSearchValue.length === 0) return teamMembers;

  // Initialize a map to store filter values for each label
  const labelValueMap: Record<string, Set<string>> = {};

  // Apply filters selected by list
  const result: ProjectTeamMember[] = teamMembers.filter(
    (currentTeamMember) => {
      let shouldInclude = true;

      filters.forEach((filter: FilterItem) => {
        const { label, value } = filter;
        const dataValueForFilter = filtersToTeamMemberDataValueMap[label];

        const memberValue = currentTeamMember[dataValueForFilter];

        // Initialize the set for the label if it doesn't exist
        labelValueMap[label] = labelValueMap[label] || new Set();

        // Add the current filter value to the set
        labelValueMap[label].add(value);

        // Status is different from other fields since it is derived from boolean
        if (label === 'Status') {
          const memberStatusValue = currentTeamMember.is_pending
            ? Statuses.Pending
            : currentTeamMember.is_deactivated
            ? Statuses.Deactivated
            : Statuses.Active;

          // Row's value matches filter
          if (!labelValueMap[label].has(String(memberStatusValue))) {
            shouldInclude = false;
          }
        } else {
          // Row's value matches filter
          if (!labelValueMap[label].has(String(memberValue))) {
            shouldInclude = false;
          }
        }
      });

      return shouldInclude;
    }
  );

  // If no filters are applied, include all team members
  if (filters.length === 0) {
    return [...teamMembers];
  }

  // Apply search filter
  const filteredResult: ProjectTeamMember[] = result.filter((member) => {
    const { email, first_name, last_name, organization_title, teams } = member;

    // Check fields email, first_name, last_name, organization_title
    if (
      email.toLocaleLowerCase().includes(textSearchValue) ||
      first_name.toLocaleLowerCase().includes(textSearchValue) ||
      last_name.toLocaleLowerCase().includes(textSearchValue) ||
      organization_title.toLocaleLowerCase().includes(textSearchValue)
    ) {
      return true;
    }

    // Check the teams array
    return teams.some((team) => team.includes(textSearchValue));
  });

  return filteredResult;
}
