import { CostCode } from '../../types/sharedTypes';

export const sortCostCodesByCode = (costCodes: CostCode[]): CostCode[] => {
  const compareCodes = (a: CostCode, b: CostCode): number => {
    const codeA = a.code.split('.').map(Number);
    const codeB = b.code.split('.').map(Number);

    for (let i = 0; i < Math.min(codeA.length, codeB.length); i++) {
      if (codeA[i] !== codeB[i]) {
        return codeA[i] - codeB[i];
      }
    }

    return codeA.length - codeB.length;
  };

  const recursiveSort = (codes: CostCode[]): CostCode[] => {
    return codes
      .map((code) => ({
        ...code,
        children: recursiveSort(code.children),
      }))
      .sort(compareCodes);
  };

  return recursiveSort(costCodes);
};
