import React from 'react';
import './TabBar.scss';

interface Props {
  tabOptions: string[];
  activeTabOption: string;
  onTabClick: (newViewIndex: number) => void;
}

const TabBar: React.FC<Props> = ({
  tabOptions,
  activeTabOption,
  onTabClick,
}) => {
  const handleOptionClick = (optionValue: number) => {
    onTabClick(optionValue);
  };

  return (
    <section className="TabBar">
      {tabOptions.map((tabOption, i) => {
        const isActive = tabOption === activeTabOption;
        return (
          <button
            key={tabOption}
            className={`taboption ${isActive ? 'active-tab' : ''}`}
            onClick={() => handleOptionClick(i)}
          >
            {tabOption}
          </button>
        );
      })}
    </section>
  );
};

export default TabBar;
