import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { budgetRoute } from './getBudgets';

export const getBudgetLineItemEntity = async (budgetLineItemID: string) => {
  const apiBase = getAPIBase();
  const endpoint =
    apiBase +
    budgetRoute +
    '/line/entities' +
    `?budget_line_item_id=${budgetLineItemID}&`;

  try {
    const request = new Request(endpoint, standardRequestObject);
    const res = await fetch(request);
    const data = await res.json();
    return data; 
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
