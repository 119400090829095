import { BudgetLineItem } from '../../types/budgetTypes';

export const getBudgetLineItemByCostCodeID = (
  budgetLineItems: BudgetLineItem[] | undefined,
  targetCostCode: string | null
): BudgetLineItem | null => {
  if (!targetCostCode || !budgetLineItems) {
    return null;
  }

  for (const budgetLineItem of budgetLineItems) {
    if (budgetLineItem.cost_code?.id === targetCostCode) {
      return budgetLineItem;
    }

    if (budgetLineItem.children?.length > 0) {
      const foundInChild = getBudgetLineItemByCostCodeID(
        budgetLineItem.children,
        targetCostCode
      );

      if (foundInChild) {
        return foundInChild;
      }
    }
  }

  return null;
};
