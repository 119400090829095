import React from 'react';
import './PDFViewer.scss';

interface Props {
  fileUrl: string | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PdfViewer: React.FC<Props> = ({
  fileUrl,
  handleFileChange,
}) => {
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      handleFileChange(event);
    }
  };

  return (
    <div className="PDFViewer">
      <label htmlFor="pdf-upload">Switch file</label>
      <input
        type="file"
        accept=".pdf"
        style={{ display: 'none' }}
        onChange={onFileChange}
        id="pdf-upload"
      />
      {fileUrl && (
        <object type="application/pdf" data={fileUrl} width="100%" height="100%" />
      )}
    </div>
  );
};

export default PdfViewer;
