import { BudgetLineItem } from '../../types/budgetTypes';

export const getParentLevelBudgetLineItem = (
  allBudgetLineItems: BudgetLineItem[],
  targetBudgetLineItem: BudgetLineItem
): BudgetLineItem | null => {
  const findParent = (
    current: BudgetLineItem,
    targetId: string,
    path: BudgetLineItem[] = []
  ): BudgetLineItem[] => {
    const currentPath = [...path, current];

    if (current.children.some((child) => child.id === targetId)) {
      return currentPath;
    }

    for (const child of current.children) {
      const newPath = findParent(child, targetId, currentPath);

      if (newPath.length > 0) {
        return newPath;
      }
    }

    return [];
  };

  for (const item of allBudgetLineItems) {
    const pathToTarget = findParent(item, targetBudgetLineItem.id);
    if (pathToTarget.length > 0) {
      // Return the highest parent in the path
      return pathToTarget[0] || null;
    }
  }

  // If no path is found, return null
  return null;
};
