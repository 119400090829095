import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

export const bidBotRoute = 'bidbot';

export const getBidBotResponse = async (projectId: string, prompt: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + bidBotRoute + `?project_id=${projectId}&prompt=${prompt}`;

    try {
        const request = new Request(endpoint, standardRequestObject);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};
