import { Row } from '../../components/EditableTable/EditableTableV2';
import { FilterItem } from '../../types/sharedTypes';
import { BudgetLineItem } from '../../types/budgetTypes';

const hasDescriptionValue = (
  item: BudgetLineItem,
  targetValue: string
): boolean => {
  if (item?.cost_code?.description === targetValue) {
    return true;
  }

  for (const child of item.children) {
    if (hasDescriptionValue(child, targetValue)) {
      return true;
    }
  }

  return false;
};

const hasCostCode = (item: BudgetLineItem, targetValue: string): boolean => {
  if (item?.cost_code?.code === targetValue) {
    return true;
  }

  for (const child of item.children) {
    if (hasCostCode(child, targetValue)) {
      return true;
    }
  }

  return false;
};

const hasVendor = (item: BudgetLineItem, targetValue: string): boolean => {
  if (item.buyout_vendor.length === 0 && targetValue === 'None') {
    return true;
  }

  if (
    item.buyout_vendor.includes(targetValue) &&
    item.buyout_vendor.length > 0
  ) {
    return true;
  }

  for (const child of item.children) {
    if (hasVendor(child, targetValue)) {
      return true;
    }
  }

  return false;
};

const applyBudgetFiltersRecursively = (
  row: Row,
  activeFilters: FilterItem[]
): Row | null => {
  const { rowData } = row;
  let rowMeetsFilterConditions = false;

  activeFilters.forEach((filter: FilterItem) => {
    const { label, value } = filter;

    if (label === 'Vendor') {
      if (hasVendor(rowData as BudgetLineItem, value)) {
        rowMeetsFilterConditions = true;
      }
    }

    if (label === 'Cost Code') {
      if (hasCostCode(rowData as BudgetLineItem, value)) {
        rowMeetsFilterConditions = true;
      }
    }

    if (label === 'Line Item') {
      if (hasDescriptionValue(rowData as BudgetLineItem, value)) {
        rowMeetsFilterConditions = true;
      }
    }
  });

  if (!rowMeetsFilterConditions) {
    return null; // Exclude this row and its children
  }

  // Apply filters recursively to children
  if (row.children) {
    const filteredChildren: Row[] = [];
    for (const child of row.children) {
      const filteredChild = applyBudgetFiltersRecursively(child, activeFilters);
      if (filteredChild) {
        filteredChildren.push(filteredChild);
      }
    }
    if (filteredChildren.length > 0) {
      row.children = filteredChildren;
    } else {
      row.children = undefined; // No children meet the filter conditions, remove children
    }
  }

  return row; // Include this row and its filtered children
};

const doesBudgetLineItemContainValue = (
  item: BudgetLineItem,
  value: string
): boolean => {
  if (!item) {
    return false;
  }

  if (
    (item.cost_code?.code && item.cost_code.code.includes(value)) ||
    (item.cost_code?.description &&
      item.cost_code.description.toLowerCase().includes(value)) ||
    (item.buyout_vendor &&
      item.buyout_vendor.some((buyout_vendor) =>
        buyout_vendor.toLowerCase().includes(value)
      ))
  ) {
    return true;
  }

  for (const child of item.children || []) {
    if (doesBudgetLineItemContainValue(child, value)) {
      return true;
    }
  }

  return false;
};

const applySearchFiltersRecursively = (
  row: Row,
  lowercaseSearchValue: string
): Row | null => {
  const { rowData } = row;

  const rowMeetsSearchConditions = doesBudgetLineItemContainValue(
    rowData as BudgetLineItem,
    lowercaseSearchValue
  );

  if (!rowMeetsSearchConditions) {
    return null; // Exclude this row and its children
  }

  // Apply search filters recursively to children
  if (row.children) {
    const filteredChildren: Row[] = [];
    for (const child of row.children) {
      const filteredChild = applySearchFiltersRecursively(
        child,
        lowercaseSearchValue
      );
      if (filteredChild) {
        filteredChildren.push(filteredChild);
      }
    }
    if (filteredChildren.length > 0) {
      row.children = filteredChildren;
    } else {
      row.children = undefined; // No children meet the search conditions, remove children
    }
  }

  return row; // Include this row and its filtered children
};

export const applyBudgetFilters = (
  rows: Row[],
  searchboxValue: string,
  activeFilters: FilterItem[]
): Row[] => {
  if (searchboxValue.length === 0 && activeFilters.length === 0) return rows;

  const lowercaseSearchValue = searchboxValue.toLowerCase();

  const rowsWithSearchApplied: Row[] = [];
  for (const row of rows) {
    const filteredRow = applySearchFiltersRecursively(
      row,
      lowercaseSearchValue
    );
    if (filteredRow) {
      rowsWithSearchApplied.push(filteredRow);
    }
  }

  if (activeFilters.length === 0) return rowsWithSearchApplied;

  const rowsWithFiltersApplied: Row[] = [];

  rowsWithSearchApplied.forEach((row: Row) => {
    const filteredRow = applyBudgetFiltersRecursively(row, activeFilters);
    if (filteredRow) {
      rowsWithFiltersApplied.push(filteredRow);
    }
  });

  return rowsWithFiltersApplied;
};
