import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { invoiceRoute } from './getInvoices';
import { FormattedForBackendInvoice } from '../../types/invoiceTypes';

export const postInvoice = async (
  formattedForBackendInvoice: FormattedForBackendInvoice
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + invoiceRoute;

  const formattedApprovedUserIds =
    formattedForBackendInvoice.approver_user_ids.length === 0
      ? ''
      : formattedForBackendInvoice.approver_user_ids.join(',');

  const formattedReviewerUserIds =
    formattedForBackendInvoice.reviewer_user_ids.length === 0
      ? ''
      : formattedForBackendInvoice.reviewer_user_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      ...formattedForBackendInvoice,
      approver_user_ids: formattedApprovedUserIds,
      reviewer_user_ids: formattedReviewerUserIds,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
