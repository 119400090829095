import { BudgetLineItem } from '../../types/budgetTypes';

export const getBudgetLineItemByID = (
  id: string,
  budgetLineItems: BudgetLineItem[]
): BudgetLineItem | null => {
  for (const item of budgetLineItems) {
    if (item.id === id) {
      return item;
    }

    if (item.children && item.children.length > 0) {
      const childResult = getBudgetLineItemByID(id, item.children);
      if (childResult) {
        return childResult;
      }
    }
  }

  return null;
};
