import { AppContext } from '../../types/appContextTypes';
import { Notification } from '../../types/notificationTypes';
import { convertUTCDateStringToDate } from '../../utils/convertUTCDateStringToDate';

export const createNotificationsFromAppContext = (
  appContext: AppContext
): Notification[] => {
  const notifications: Notification[] = [];

  // Append contract notifications
  for (const contract of appContext.contracts) {
    const approval = contract.approvers.find(
      (appr) =>
        appr.id == appContext.currentUser.id && appr.is_approved !== true
    );
    if (approval?.added_as_approver_on_utc) {
      notifications.push({
        entity_name: contract.title,
        entity_url: `/contracts/${contract.id}?from=inbox`,
        entity_type: 'Contract',
        action_requested: 'Approval',
        vendor_name: contract.vendor?.name,
        status: contract.status,
        total_amount: contract.scheduled_value_total,
        created_on: convertUTCDateStringToDate(
          approval.added_as_approver_on_utc
        ),
      });
    }
  }

  // Append change order notifications
  for (const changeOrder of appContext.changeOrders) {
    const approval = changeOrder.approvers.find(
      (appr) =>
        appr.id == appContext.currentUser.id && appr.is_approved !== true
    );
    if (approval?.added_as_approver_on_utc) {
      notifications.push({
        entity_name: changeOrder.title,
        entity_url: `/changeorders/${changeOrder.id}?from=inbox`,
        entity_type: 'Change Order',
        action_requested: 'Approval',
        vendor_name: changeOrder.vendor?.name,
        status: changeOrder.status,
        total_amount: changeOrder.budgeted_amount,
        created_on: convertUTCDateStringToDate(
          approval.added_as_approver_on_utc
        ),
      });
    }
  }

  // Append invoice notifications
  for (const invoice of appContext.invoices) {
    const review = invoice.reviewers.find(
      (rev) => rev.id == appContext.currentUser.id && rev.is_reviewed !== true
    );
    if (review?.added_as_reviewer_on_utc) {
      notifications.push({
        entity_name: invoice.name,
        entity_url: `/invoices/${invoice.id}?from=inbox`,
        entity_type: 'Invoice',
        action_requested: 'Review',
        vendor_name: invoice.vendor?.name,
        status: invoice.status,
        total_amount: invoice.total_amount,
        created_on: convertUTCDateStringToDate(review.added_as_reviewer_on_utc),
      });
    }
    const approval = invoice.approvers.find(
      (appr) =>
        appr.id == appContext.currentUser.id && appr.is_approved !== true
    );
    if (approval?.added_as_approver_on_utc) {
      notifications.push({
        entity_name: invoice.name,
        entity_url: `/invoices/${invoice.id}?from=inbox`,
        entity_type: 'Invoice',
        action_requested: 'Approval',
        vendor_name: invoice.vendor?.name,
        status: invoice.status,
        total_amount: invoice.total_amount,
        created_on: convertUTCDateStringToDate(
          approval.added_as_approver_on_utc
        ),
      });
    }
  }

  // Append draw package notifications
  for (const drawPackage of appContext.drawPackages) {
    const approval = drawPackage.approvers.find(
      (appr) =>
        appr.id == appContext.currentUser.id && appr.is_approved !== true
    );
    if (approval?.added_as_approver_on_utc) {
      notifications.push({
        entity_name: drawPackage.title,
        entity_url: `/drawpackages/${drawPackage.id}?from=inbox`,
        entity_type: 'Draw Package',
        action_requested: 'Approval',
        status: drawPackage.status,
        total_amount: drawPackage.total_amount,
        created_on: convertUTCDateStringToDate(
          approval.added_as_approver_on_utc
        ),
      });
    }
  }

  return notifications;
};
