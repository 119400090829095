import { BudgetLineItem } from '../../types/budgetTypes';

export interface ValueAndIDOfBudgetLineItem {
  line_item_id: string;
  value: string;
}

const getValueOfBudgetLineItemFromIDFromChildren = (
  parentLineItem: BudgetLineItem,
  targetLineItemID: string
): ValueAndIDOfBudgetLineItem => {
  let foundResult: ValueAndIDOfBudgetLineItem = { line_item_id: '', value: '' };
  if (parentLineItem.cost_code_id === targetLineItemID) {
    foundResult.value = parentLineItem.total_approved_budget.toString();
    foundResult.line_item_id = parentLineItem.id;
    return foundResult;
  }

  if (!parentLineItem.children || parentLineItem.children.length === 0) return foundResult;
  for (const lineItem of parentLineItem.children) {
    foundResult = getValueOfBudgetLineItemFromIDFromChildren(lineItem, targetLineItemID);
    if (foundResult.value !== '') return foundResult;
  }

  return foundResult;
};

export const getValueOfBudgetLineItemFromID = (
  budgetLineItems: BudgetLineItem[] | undefined | null,
  targetLineItemID: string
): ValueAndIDOfBudgetLineItem => {
  let foundResult: ValueAndIDOfBudgetLineItem = { line_item_id: '', value: '' };
  if (!budgetLineItems) return foundResult;

  for (const lineItem of budgetLineItems) {
    foundResult = getValueOfBudgetLineItemFromIDFromChildren(lineItem, targetLineItemID);
    if (foundResult.value !== '') return foundResult;
  }

  return foundResult;
};
