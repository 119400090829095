import React, { useState, useEffect } from 'react';
import './VendorBlade.scss';
import Blade from '../Blade/Blade';
import StatusChip from '../StatusChip/StatusChip';
import { Statuses, Vendor } from '../../types/sharedTypes';
import TextInput from '../TextInput/TextInput';
import StateDropdown from '../StateDropdown/StateDropdown';
import { BiLinkExternal as LinkIcon } from 'react-icons/bi';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import { GoPlus as PlusIcon } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { putVendorInfo } from '../../services/project/putVendorInfo';
import { toast } from 'react-toastify';
import { useDebounce } from 'usehooks-ts';
import { AppContext } from '../../types/appContextTypes';

const fakeProjects = [
  { label: 'Project 1' },
  { label: 'Project 2' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
  { label: 'Project 3' },
];

interface LinkAndLabelProps {
  label: string;
  onLinkIconClicked: (id: string) => void;
  id: string;
}
const LinkAndLabel: React.FC<LinkAndLabelProps> = ({
  label,
  onLinkIconClicked,
  id,
}) => {
  return (
    <div className="LinkAndLabel">
      {label}
      <LinkIcon className="link-icon" onClick={() => onLinkIconClicked(id)} />
    </div>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  vendor: Vendor | null;
  onChange: (newValue: string, fieldToUpdate: string) => void;
  onChangeSubmitted: () => void;
  appContext: AppContext;
}

const VendorBlade: React.FC<Props> = ({
  isOpen,
  onClose,
  vendor,
  onChange,
  onChangeSubmitted,
  appContext,
}) => {
  const navigate = useNavigate();
  const [vendorName, setVendorName] = useState<string>('');
  const [vendorPoc, setVendorPoc] = useState<string>('');
  const [vendorID, setVendorID] = useState<string>('');
  const [vendorTaxID, setVendorTaxID] = useState<string>('');
  const [vendorDescription, setVendorDescription] = useState<string>('');
  const [vendorAddrLine1, setVendorAddrLine1] = useState<string>('');
  const [vendorAddrLine2, setVendorAddrLine2] = useState<string>('');
  const [vendorCity, setVendorCity] = useState<string>('');
  const [vendorState, setVendorState] = useState<string>('');
  const [vendorZip, setVendorZip] = useState<string>('');
  const [vendorIsDeactivated, setVendorIsDeactivated] =
    useState<boolean>(false);
  const [initialStateSetForID, setInitialStateSetForID] = useState<string>('');
  const [hideSuccessToast, setHideSuccessToast] = useState<boolean>(false);

  // Debounced state variables for realtime updates
  const debouncedVendorName = useDebounce<string>(vendorName, 500);
  const debouncedVendorPoc = useDebounce<string>(vendorPoc, 500);
  const debouncedVendorID = useDebounce<string>(vendorID, 500);
  const debouncedVendorTaxID = useDebounce<string>(vendorTaxID, 500);
  const debouncedVendorDescription = useDebounce<string>(
    vendorDescription,
    500
  );
  const debouncedVendorAddrLine1 = useDebounce<string>(vendorAddrLine1, 500);
  const debouncedVendorAddrLine2 = useDebounce<string>(vendorAddrLine2, 500);
  const debouncedVendorCity = useDebounce<string>(vendorCity, 500);
  const debouncedVendorState = useDebounce<string>(vendorState, 500);
  const debouncedVendorZip = useDebounce<string>(vendorZip, 500);

  useEffect(() => {
    if (!vendor) return;

    setVendorName(vendor.name);
    setVendorPoc(vendor.point_of_contact);
    setVendorID(vendor.vendor_number ?? '');
    setVendorTaxID(vendor.tax_id ?? '');
    setVendorDescription(vendor.description ?? '');
    setVendorAddrLine1(vendor.address_line_1 ?? '');
    setVendorAddrLine2(vendor.address_line_2 ?? '');
    setVendorCity(vendor.city ?? '');
    setVendorState(vendor.state ?? '');
    setVendorZip(vendor.zip_code ?? '');
    setVendorIsDeactivated(vendor.is_deactivated);
    setTimeout(() => setInitialStateSetForID(vendor.id), 600);
  }, [vendor]);

  useEffect(() => {
    if (!vendor || initialStateSetForID != vendor.id) return;
    (async () => {
      const vendorFromState: Vendor = {
        id: vendor?.id ?? '',
        name: vendorName,
        point_of_contact: vendorPoc,
        vendor_number: vendorID,
        tax_id: vendorTaxID,
        description: vendorDescription,
        address_line_1: vendorAddrLine1,
        address_line_2: vendorAddrLine2,
        city: vendorCity,
        state: vendorState,
        zip_code: vendorZip,
        is_deactivated: vendorIsDeactivated,
      };
      const updatedVendor = await putVendorInfo(
        appContext.currentProject.id,
        vendorFromState
      );
      if (updatedVendor.error_message) {
        showErrorMessageToast(updatedVendor.error_message);
        if (
          updatedVendor.error_message.includes('cannot deactivate a vendor')
        ) {
          setHideSuccessToast(true);
          onChange('false', 'is_deactivated');
        }
      } else {
        if (!hideSuccessToast)
          showSuccessToastMessage('Successfully updated vendor');
        onChangeSubmitted();
        setHideSuccessToast(false);
      }
    })();
  }, [
    debouncedVendorName,
    debouncedVendorPoc,
    debouncedVendorID,
    debouncedVendorTaxID,
    debouncedVendorDescription,
    debouncedVendorAddrLine1,
    debouncedVendorAddrLine2,
    debouncedVendorCity,
    debouncedVendorState,
    debouncedVendorZip,
    vendorIsDeactivated,
  ]);

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const isActive = !vendorIsDeactivated;
  const actionButtonIcon = isActive ? <TrashCanIcon /> : <PlusIcon />;
  const actionButtonLabel = `${isActive ? 'Deactivate' : 'Reactivate'} vendor`;
  const actionButtonVariant = isActive
    ? IconButtonVariants.error
    : IconButtonVariants.primaryActionThin;

  const vendorStatus = vendor?.is_deactivated
    ? Statuses.Deactivated
    : Statuses.Active;

  const contractsWithVendor = appContext.contracts.filter(
    (contract) => contract.vendor_id === vendor?.id
  );

  const handleLinkIconClicked = (id: string, location: string) => {
    const idToUrlMap: any = {
      contracts: 'contracts',
    };
    const route = idToUrlMap[location ?? ''];
    navigate(`/${route}/${id}`);
  };

  return (
    <Blade
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setInitialStateSetForID('');
      }}
    >
      <div className="VendorBlade">
        <div className="vendor-blade-top">
          <h2>{vendorName}</h2>
          <div className="status-chip-container">
            <StatusChip status={vendorStatus} />
          </div>
        </div>
        <div className="vendor-blade-primary-content">
          <h3>Details</h3>
          <div className="inputs-container">
            <div className="label-input-container">
              <label htmlFor="name-input">Name</label>
              <TextInput
                id="name-input"
                value={vendorName}
                isDisabled={vendorIsDeactivated === true}
                onChange={(newValue: string) => {
                  setVendorName(newValue);
                  onChange(newValue, 'name');
                }}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="point-of-contact-input">Point of contact</label>
              <TextInput
                id="point-of-contact-input"
                value={vendorPoc}
                isDisabled={vendorIsDeactivated === true}
                onChange={(newValue: string) => {
                  setVendorPoc(newValue);
                  onChange(newValue, 'point_of_contact');
                }}
              />
            </div>
            <div className="two-item-row">
              <div className="label-input-container">
                <label htmlFor="vendor-input">Vendor ID</label>
                <TextInput
                  id="vendor-input"
                  value={vendorID}
                  isDisabled={vendorIsDeactivated === true}
                  onChange={(newValue) => {
                    setVendorID(newValue);
                    onChange(newValue, 'vendor_number');
                  }}
                />
              </div>
              <div className="label-input-container">
                <label htmlFor="tax-id-input">Tax ID</label>
                <TextInput
                  id="tax-id-input"
                  value={vendorTaxID}
                  isDisabled={vendorIsDeactivated === true}
                  onChange={(newValue) => {
                    setVendorTaxID(newValue);
                    onChange(newValue, 'tax_id');
                  }}
                />
              </div>
            </div>
            <div className="label-input-container">
              <label htmlFor="description-id-input">Description</label>
              <div className="text-area-container">
                <textarea
                  placeholder="Enter a description..."
                  id="description-id-input"
                  value={vendorDescription}
                  disabled={vendorIsDeactivated === true}
                  onChange={(event) => {
                    setVendorDescription(event.target.value);
                    onChange(event.target.value, 'description');
                  }}
                />
              </div>
            </div>
          </div>
          <h3>Location</h3>
          <div className="inputs-container">
            <div className="label-input-container">
              <label htmlFor="address-line-1-input">Address line 1</label>
              <TextInput
                id="address-line-1-input"
                value={vendorAddrLine1}
                isDisabled={vendorIsDeactivated === true}
                onChange={(newValue: string) => {
                  setVendorAddrLine1(newValue);
                  onChange(newValue, 'address_line_1');
                }}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="address-line-2-input">Address line 2</label>
              <TextInput
                id="address-line-2-input"
                value={vendorAddrLine2}
                isDisabled={vendorIsDeactivated === true}
                onChange={(newValue: string) => {
                  setVendorAddrLine2(newValue);
                  onChange(newValue, 'address_line_2');
                }}
              />
            </div>
            <div className="three-item-row">
              <div className="label-input-container">
                <label htmlFor="city-input">City</label>
                <TextInput
                  id="city-input"
                  value={vendorCity}
                  isDisabled={vendorIsDeactivated === true}
                  onChange={(newValue: string) => {
                    setVendorCity(newValue);
                    onChange(newValue, 'city');
                  }}
                />
              </div>
              <div className="label-input-container">
                <label htmlFor="state-input">State</label>
                <StateDropdown
                  onChange={(newValue: string) => {
                    setVendorState(newValue);
                    onChange(newValue, 'state');
                  }}
                  isDisabled={vendorIsDeactivated === true}
                  value={vendorState}
                />
              </div>
              <div className="label-input-container">
                <label htmlFor="zipcode-input">Zip</label>
                <TextInput
                  id="zipcode-input"
                  value={vendorZip}
                  isDisabled={vendorIsDeactivated === true}
                  onlyAllowNumbers
                  onChange={(newValue: string) => {
                    setVendorZip(newValue);
                    onChange(newValue, 'zip_code');
                  }}
                />
              </div>
            </div>
          </div>
          {/* <h3>Projects (3)</h3>
          <div className="links-container">
            {fakeProjects.map((fakeProject: any) => {
              return (
                <LinkAndLabel
                  label={fakeProject.label}
                  // TODO: Add project ID
                  id={}
                  onLinkIconClicked={() =>
                    console.log(fakeProject.label, ' was just clicked')
                  }
                />
              );
            })}
          </div> */}
          <h3>Contracts ({contractsWithVendor.length})</h3>
          <div className="links-container">
            {contractsWithVendor.map((contract) => {
              return (
                <LinkAndLabel
                  key={contract.id}
                  label={contract.title}
                  id={contract.id}
                  onLinkIconClicked={(id: string) =>
                    handleLinkIconClicked(id, 'contracts')
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="action-button-container">
          <IconButton
            variant={actionButtonVariant}
            icon={actionButtonIcon}
            label={actionButtonLabel}
            onClick={() => {
              const newIsDeactivated = !vendorIsDeactivated;
              onChange(
                newIsDeactivated === true ? 'true' : 'false',
                'is_deactivated'
              );
              setVendorIsDeactivated(newIsDeactivated);
            }}
            isFullWidth
          />
        </div>
      </div>
    </Blade>
  );
};

export default VendorBlade;
