import { BudgetLineItem } from '../../types/budgetTypes';
import { CostCode } from '../../types/sharedTypes';

export const convertBudgetLineItemsIntoCostCodes = (
  budgetLineItems: BudgetLineItem[]
): CostCode[] => {
  const traverseBudgetLineItems = (items: BudgetLineItem[]): CostCode[] => {
    const result: CostCode[] = [];

    for (const item of items) {
      const costCode = item.cost_code;
      const children = traverseBudgetLineItems(item.children);

      // Catch incase no cost code is found
      if (!costCode) {
        continue;
      }

      result.push({
        ...costCode,
        children,
      });
    }

    return result;
  };

  return traverseBudgetLineItems(budgetLineItems);
};
