export const getFirstCharactersBeforePeriod = (inputString: string): string => {
  const indexOfPeriod = inputString.indexOf('.');

  if (indexOfPeriod === -1) {
    // If there's no period, return the entire string
    return inputString;
  } else {
    // If there's a period, return the substring before it
    return inputString.substring(0, indexOfPeriod);
  }
};
