import { Row, CellType } from '../../components/EditableTable/EditableTableV2';
import { DrawPackage } from '../../types/drawPackageTypes';
import { formatDateMMDDYYYY } from '../formatDateMMDDYYYY';

export const formatDrawPackagesForEditableTable = (
  drawPackages: DrawPackage[]
): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < drawPackages.length; i++) {
    const currentDrawPackage: DrawPackage = drawPackages[i];
    const currentRow: Row = {
      cells: [],
      rowData: currentDrawPackage,
      id: currentDrawPackage.id,
    };

    // ID
    currentRow.cells.push({
      value: currentDrawPackage.number,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Title
    currentRow.cells.push({
      value: currentDrawPackage.title,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Status
    currentRow.cells.push({
      value: currentDrawPackage.status,
      type: CellType.status,
      isCellDisabled: true,
    });

    // Current Request Amount
    currentRow.cells.push({
      value: currentDrawPackage.total_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Created On
    currentRow.cells.push({
      value: formatDateMMDDYYYY(currentDrawPackage.date_utc),
      type: CellType.text,
      isCellDisabled: true,
    });

    // Sent On
    currentRow.cells.push({
      value: currentDrawPackage.billing_start_utc
        ? formatDateMMDDYYYY(currentDrawPackage.billing_start_utc)
        : '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    // Funded On
    currentRow.cells.push({
      value: currentDrawPackage.billing_end_utc
        ? formatDateMMDDYYYY(currentDrawPackage.billing_end_utc)
        : '-',
      type: CellType.text,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
