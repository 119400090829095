import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { contractRoute } from './getContract';
import axios from 'axios';

const upload_type = 'Contract Attachments';

export const postContractAttachment = async (
  file: any,
  uploadType?: string
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + 'upload';

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      upload_type: uploadType ?? upload_type,
      content_type: file.type,
      filename: file.name,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
