import { Row } from '../../components/EditableTable/EditableTableV2';

export const sortBudgetBladeRows = (rows: Row[]) => {
  rows.sort((a, b) => {
    const valueA = a.rowData?.name || a.rowData?.title || '';
    const valueB = b.rowData?.name || b.rowData?.title || '';

    return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
  });
  return rows;
};
