import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

const route = 'auth';

export const signIn = async (email: string, password: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + route;

    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({ email, password }),
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};