import React, { ReactNode } from 'react';
import './Select.scss';

interface Props {
  id?: string;
  children?: ReactNode[];
  onChange: (value: string) => void;
  value: string | string[];
  isDisabled?: boolean;
  emptyPlaceholder?: string;
}

const Select: React.FC<Props> = ({
  id,
  children,
  onChange,
  value,
  isDisabled = false,
  emptyPlaceholder = 'No options available',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };
  const noOptionsAvailable = !children || children.length === 0;

  return (
    <select
      className="Select"
      onChange={handleChange}
      id={id}
      value={value}
      disabled={isDisabled || noOptionsAvailable}
    >
      <option value="">None</option>
      {noOptionsAvailable ? <option>{emptyPlaceholder}</option> : children}
    </select>
  );
};

export default Select;
