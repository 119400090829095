/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import './EditableTableLoadingSkeleton.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {}

const EditableTableLoadingSkeleton: React.FC<Props> = () => {
  return (
    <div>
      <Skeleton
        duration={1}
        height={36}
        count={5}
        style={{ marginBottom: '0.25rem' }}
      />
    </div>
  );
};

export default EditableTableLoadingSkeleton;
