import { DrawPackage } from '../../types/drawPackageTypes';

export function formatDrawPackageFilters(drawpackages: DrawPackage[]): {
  ID: string[];
  Title: string[];
  Status: string[];
} {
  const idSet: Set<string> = new Set();
  const titleSet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();

  drawpackages.forEach((item: DrawPackage) => {
    // Extracting ID
    let id = item.number;
    if (!id) {
      id = 'None';
    }
    idSet.add(id);

    // Extracting title
    let title = item.title;
    if (!title) {
      title = 'None';
    }
    titleSet.add(title);

    // Extracting status
    const status = item.status;
    statusSet.add(status);
  });

  const idArray: string[] = Array.from(idSet);
  const titleArray: string[] = Array.from(titleSet);
  const statusArray: string[] = Array.from(statusSet);

  return {
    ID: idArray,
    Title: titleArray,
    Status: statusArray,
  };
}
