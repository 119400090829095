import React, { useEffect, useState } from 'react';
import './OverViewPage.scss';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { HiOutlineArrowTrendingUp as ArrowTrendingUpIcon } from 'react-icons/hi2';
import Card from '../Card/Card';
import { BsThreeDotsVertical as ThreeDotsVerticalIcon } from 'react-icons/bs';
import { FiTrendingUp as ArrowsTrendingUpIcon } from 'react-icons/fi';
import { AreaChart, Area, ResponsiveContainer, Line } from 'recharts';
import { PiWarningCircleBold as WarningCircleIcon } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { BudgetLineItem } from '../../types/budgetTypes';
import { ChangeOrder } from '../../types/changeOrderTypes';
import { DrawPackage } from '../../types/drawPackageTypes';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDateMMDDYYYY } from '../../utils/formatDateMMDDYYYY';
import { AppContext } from '../../types/appContextTypes';
import { formatToUSD } from '../../utils/currency/formatToUSD';
import { convertUTCDateStringToDate } from '../../utils/convertUTCDateStringToDate';

const data: any = [
  { x: 0, y: 50 },
  { x: 1, y: 45 },
  { x: 2, y: 43 },
  { x: 3, y: 48 },
  { x: 4, y: 45 },
  { x: 5, y: 39 },
  { x: 6, y: 36 },
  { x: 7, y: 32 },
  { x: 8, y: 36 },
  { x: 9, y: 39 },
  { x: 10, y: 34 },
  { x: 11, y: 29 },
  { x: 12, y: 25 },
  { x: 13, y: 27 },
  { x: 14, y: 33 },
  { x: 15, y: 34 },
  { x: 16, y: 30 },
  { x: 17, y: 25 },
  { x: 18, y: 20 },
  { x: 19, y: 22 },
  { x: 20, y: 23 },
  { x: 21, y: 19 },
  { x: 22, y: 34 },
  { x: 23, y: 32 },
  { x: 24, y: 18 },
  { x: 25, y: 15 },
  { x: 26, y: 17 },
  { x: 27, y: 12 },
  { x: 28, y: 9 },
  { x: 29, y: 5 },
  { x: 30, y: 0 },
];

enum PendingOrdersRowStatuses {
  urgent = 'urgent',
  warn = 'warn',
  good = 'good',
}

interface PendingOrdersRow {
  status: PendingOrdersRowStatuses;
  id: string;
  description: string;
  amount: string;
}

const pendingChangeOrdersTableData: PendingOrdersRow[] = [
  {
    status: PendingOrdersRowStatuses.urgent,
    id: 'CO #34',
    description: 'Best Glass & Windows',
    amount: '750,000',
  },
  {
    status: PendingOrdersRowStatuses.urgent,
    id: 'CO #59',
    description: 'Steel Beam Installation',
    amount: '85,000',
  },
  {
    status: PendingOrdersRowStatuses.urgent,
    id: 'CO #45',
    description: 'Emergency Roof Repair',
    amount: '47,000',
  },
  {
    status: PendingOrdersRowStatuses.urgent,
    id: 'CO #23',
    description: 'Foundation Reinforcement',
    amount: '92,000',
  },
  {
    status: PendingOrdersRowStatuses.warn,
    id: 'CO #38',
    description: 'Toughest Concrete',
    amount: '500,000',
  },
  {
    status: PendingOrdersRowStatuses.warn,
    id: 'CO #62',
    description: 'Concrete Flooring',
    amount: '62,000',
  },
  {
    status: PendingOrdersRowStatuses.warn,
    id: 'CO #52',
    description: 'Scaffolding Setup',
    amount: '37,000',
  },
  {
    status: PendingOrdersRowStatuses.warn,
    id: 'CO #29',
    description: 'Crane Rental',
    amount: '24,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #31',
    description: 'The Demo Team',
    amount: '300,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #65',
    description: 'HVAC Ductwork',
    amount: '32,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #68',
    description: 'Exterior Painting',
    amount: '21,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #13',
    description: 'Electrical Rewiring',
    amount: '15,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #19',
    description: 'Window Replacement',
    amount: '19,000',
  },
  {
    status: PendingOrdersRowStatuses.good,
    id: 'CO #2',
    description: 'Flooring Installation',
    amount: '25,000',
  },
];

const gradientColor = 'red'; // You can change this to 'green' if needed

const MyAreaChart = () => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={gradientColor} stopOpacity={0.4} />
            <stop offset="95%" stopColor={gradientColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="y"
          stroke={gradientColor}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#colorGradient)`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

interface LabelWithLargeTextProps {
  labelText: string;
  valueText: string;
}

const LabelWithLargeText: React.FC<LabelWithLargeTextProps> = ({
  labelText,
  valueText,
}) => {
  return (
    <div className="LabelWithLargeText">
      <h5>{labelText}</h5>
      <h6>{valueText}</h6>
    </div>
  );
};

interface Props {
  appContext: AppContext;
}

const OverViewPage: React.FC<Props> = ({ appContext }) => {
  const navigate = useNavigate();

  // Project budget card
  const [budgetOriginalTotal, setBudgetOriginalTotal] = useState<string>('');
  const [approvedChanges, setApprovedChanges] = useState<string>('');
  const [approvedChangesPercentage, setApprovedChangesPercentage] =
    useState<string>('');
  const [projectedChanges, setProjectedChanges] = useState<string>('');
  const [projectedChangesPercentage, setProjectedChangesPercentage] =
    useState<string>('');
  const [currentBudgetProjected, setCurrentBudgetProjection] =
    useState<string>('');

  // Contracts card
  const [contractsTotalApproved, setContractsTotalApproved] =
    useState<string>('');
  const [contractsTotalBoughtOut, setContractsTotalBoughtOut] =
    useState<string>('');

  // Invoices card
  const [invoicesPendingReview, setInvoicesPendingReview] =
    useState<string>('');

  // Draw packages card
  const [currentDrawPercentComplete, setCurrentDrawPercentComplete] =
    useState<string>('');
  const [currentDrawRemainingSpend, setCurrentDrawRemainingSpend] =
    useState<string>('');

  const currentDraw =
    appContext.drawPackages.find(
      (dp: DrawPackage) =>
        dp.status !== 'Sent' &&
        dp.status !== 'Funded' &&
        dp.status !== 'Rejected'
    ) ?? null;

  const handleCreateReportClicked = () => {
    console.log('handleCreateReportClicked fired');
  };

  const handleViewBudgetClicked = () => {
    navigate('/budget');
  };

  const sumBudgetPropertyIncludingChildren = (
    parentLineItem: BudgetLineItem,
    propName: string
  ): number => {
    let total =
      (parentLineItem[propName as keyof typeof parentLineItem] as number) ??
      0.0;
    if (parentLineItem.children) {
      for (const child of parentLineItem.children) {
        total += sumBudgetPropertyIncludingChildren(child, propName);
      }
    }
    return Math.round(total * 100) / 100;
  };

  useEffect(() => {
    // Calculate budget original total
    const _budgetOriginalTotal = appContext.budget.reduce((acc, curr) => {
      return acc + sumBudgetPropertyIncludingChildren(curr, 'total_amount');
    }, 0);
    setBudgetOriginalTotal(`${formatToUSD(_budgetOriginalTotal.toString())}`);

    // Calculate budget approved amount
    const _budgetApprovedAmount = appContext.budget.reduce((acc, curr) => {
      return (
        acc + sumBudgetPropertyIncludingChildren(curr, 'total_approved_budget')
      );
    }, 0);
    setApprovedChanges(
      `${formatToUSD(
        (_budgetApprovedAmount - _budgetOriginalTotal).toString()
      )}`
    );
    if (_budgetOriginalTotal === 0) {
      setApprovedChangesPercentage('0%');
    } else {
      setApprovedChangesPercentage(
        `${
          Math.round(
            ((_budgetApprovedAmount - _budgetOriginalTotal) /
              _budgetOriginalTotal) *
              1000
          ) / 10
        }%`
      );
    }

    // Calculate budget projected changes
    const _budgetPendingChanges = appContext.budget.reduce((acc, curr) => {
      return acc + sumBudgetPropertyIncludingChildren(curr, 'pending_changes');
    }, 0);
    setProjectedChanges(`${formatToUSD(_budgetPendingChanges.toString())}`);
    if (_budgetApprovedAmount === 0) {
      setProjectedChangesPercentage('0%');
    } else {
      setProjectedChangesPercentage(
        `${
          Math.round((_budgetPendingChanges / _budgetApprovedAmount) * 1000) /
          10
        }%`
      );
    }

    // Calculate current budget projection
    const _budgetTotalProjected = appContext.budget.reduce((acc, curr) => {
      return (
        acc + sumBudgetPropertyIncludingChildren(curr, 'total_projected_budget')
      );
    }, 0);
    setCurrentBudgetProjection(
      `${formatToUSD(_budgetTotalProjected.toString())}`
    );

    // Calulate contracts total approved and boughtout
    const _approvedContracts = appContext.contracts.reduce((acc, curr) => {
      if (curr.status === 'Approved' || curr.status === 'Completed') {
        return acc + 1;
      }
      return acc;
    }, 0);
    setContractsTotalApproved(_approvedContracts.toString());
    const _contractsSOVTotal = appContext.contracts.reduce((acc, curr) => {
      return acc + curr.scheduled_value_total;
    }, 0);
    if (_budgetApprovedAmount === 0) {
      setContractsTotalBoughtOut('0%');
    } else {
      setContractsTotalBoughtOut(
        `${
          Math.round((_contractsSOVTotal / _budgetApprovedAmount) * 1000) / 10
        }%`
      );
    }

    // Calculate invoices in review
    setInvoicesPendingReview(
      appContext.invoices
        .filter(
          (invoice) =>
            invoice.status === 'Draft' || invoice.status === 'In Review'
        )
        .length.toString()
    );

    // Calculate current draw percent complete and remaining spend
    if (currentDraw) {
      setCurrentDrawPercentComplete(
        `${Math.round(
          (1 -
            (currentDraw.remaining_spend - currentDraw.total_amount) /
              _budgetApprovedAmount) *
            100
        )}% complete`
      );
      setCurrentDrawRemainingSpend(
        formatToUSD(
          (currentDraw.remaining_spend - currentDraw.total_amount).toString()
        )
      );
    }
  }, [appContext]);

  window.document.title = 'BidSight – Overview';
  return (
    <section className="OverViewPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Overview</h4>
          <h5>Project overview dashboard</h5>
        </div>
        <div className="header-action-button-containers">
          <div className="standard-button-container">
            {/* <IconButton
              label="Create report"
              onClick={handleCreateReportClicked}
              variant={IconButtonVariants.secondaryAction}
              icon={<PlusIcon />}
            /> */}
          </div>
          <IconButton
            label="View budget"
            onClick={handleViewBudgetClicked}
            variant={IconButtonVariants.primaryAction}
            icon={<ArrowTrendingUpIcon />}
          />
        </div>
      </div>
      <div className="bottom">
        {appContext.isRefetching === true ? (
          <>
            <div className="margin-bottom-skeleton">
              <Skeleton
                duration={1}
                height={40}
                count={3}
                style={{ marginBottom: '0.25rem' }}
              />
            </div>
            <div className="two-column-grid margin-bottom-skeleton">
              <div className="left">
                <div className="full-width-row margin-bottom">
                  <Skeleton
                    duration={1}
                    height={40}
                    count={3}
                    style={{ marginBottom: '0.25rem' }}
                  />
                  <Skeleton
                    duration={1}
                    height={40}
                    count={3}
                    style={{ marginBottom: '0.25rem' }}
                  />
                </div>
              </div>
              <div className="right">
                <Skeleton
                  duration={1}
                  height={40}
                  count={3}
                  style={{ marginBottom: '0.25rem' }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="margin-bottom">
              <Card>
                <div className="header-options-container">
                  <h4 className="card-header">Project Budget</h4>
                  <button className="options-button">
                    <ThreeDotsVerticalIcon className="three-dots-icon" />
                  </button>
                </div>
                <div className="row">
                  <LabelWithLargeText
                    labelText="Original total"
                    valueText={budgetOriginalTotal}
                  />
                  <LabelWithLargeText
                    labelText={`Approved changes (${approvedChangesPercentage})`}
                    valueText={approvedChanges}
                  />
                  <LabelWithLargeText
                    labelText={`Projected changes (${projectedChangesPercentage})`}
                    valueText={projectedChanges}
                  />
                  <LabelWithLargeText
                    labelText={`Current projection`}
                    valueText={currentBudgetProjected}
                  />
                </div>
              </Card>
            </div>
            <div className="two-column-grid margin-bottom">
              <div className="left">
                <div className="full-width-row margin-bottom">
                  <Card>
                    <div className="header-options-container">
                      <h4 className="card-header">Contracts</h4>
                      <button className="options-button">
                        <ThreeDotsVerticalIcon className="three-dots-icon" />
                      </button>
                    </div>
                    <div className="row">
                      <LabelWithLargeText
                        labelText="Total approved"
                        valueText={contractsTotalApproved}
                      />
                      <LabelWithLargeText
                        labelText="Bought out"
                        valueText={contractsTotalBoughtOut}
                      />
                    </div>
                  </Card>
                  <Card>
                    <div className="header-options-container">
                      <h4 className="card-header">Invoices</h4>
                      <button className="options-button">
                        <ThreeDotsVerticalIcon className="three-dots-icon" />
                      </button>
                    </div>
                    <div className="row">
                      <LabelWithLargeText
                        labelText="Pending review"
                        valueText={invoicesPendingReview}
                      />
                    </div>
                  </Card>
                </div>
                {!!currentDraw && (
                  <div
                    className="margin-bottom current-draw-card"
                    onClick={() => navigate(`/drawpackages/${currentDraw.id}`)}
                  >
                    <Card className="bg-on-hover">
                      <div className="header-options-container">
                        <h4 className="card-header">Current Draw</h4>
                        <button className="options-button">
                          <ThreeDotsVerticalIcon className="three-dots-icon" />
                        </button>
                      </div>
                      <div className="draw-info-container">
                        <div className="info-container">
                          <h4>{currentDraw.title}</h4>
                          <h5>{`${formatDateMMDDYYYY(
                            currentDraw.billing_start_utc
                          )} - ${formatDateMMDDYYYY(
                            currentDraw.billing_end_utc
                          )}`}</h5>
                          <p
                            className={`status-chip status-chip-${currentDraw.status.replace(
                              ' ',
                              '-'
                            )}`}
                          >
                            {currentDraw.status}
                          </p>
                        </div>
                        <div className="info-container">
                          <h6>{`${formatToUSD(
                            currentDraw.total_amount.toString()
                          )}`}</h6>
                          <h5>
                            <ArrowsTrendingUpIcon className="arrow-up-icon" />
                            {currentDrawPercentComplete}
                          </h5>
                          <h5
                            style={{ marginTop: '-0.6rem' }}
                          >{`${currentDrawRemainingSpend} remaining`}</h5>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
              {appContext.changeOrders.filter(
                (co) => co.status === 'Draft' || co.status === 'In Review'
              ).length > 0 && (
                <div className="right">
                  <Card>
                    <div className="header-options-container">
                      <h4 className="card-header">Pending Change Orders</h4>
                      <button className="options-button">
                        <ThreeDotsVerticalIcon className="three-dots-icon" />
                      </button>
                    </div>
                    <table className="pending-change-orders-table">
                      {appContext.changeOrders
                        .filter(
                          (co) =>
                            co.status === 'Draft' || co.status === 'In Review'
                        )
                        .sort((a, b) => {
                          const dateA = convertUTCDateStringToDate(
                            a.created_on_utc
                          );
                          const dateB = convertUTCDateStringToDate(
                            b.created_on_utc
                          );

                          return dateB.getTime() - dateA.getTime();
                        })
                        .map((changeOrder: ChangeOrder) => {
                          return (
                            <tr
                              key={changeOrder.id}
                              className="change-order-row"
                              onClick={() =>
                                navigate(`/changeorders/${changeOrder.id}`)
                              }
                            >
                              <td className="status-id-container">
                                <WarningCircleIcon className="warning-circle-icon" />
                                {`CO #${changeOrder.number}`}
                              </td>
                              <td className="table-item-description">
                                {(changeOrder.title.length ?? 0) > 50
                                  ? changeOrder.title.slice(0, 50) + '...'
                                  : changeOrder.title}
                              </td>
                              <td className="table-item-amount">
                                {formatToUSD(
                                  changeOrder.budgeted_amount.toString()
                                )}
                              </td>
                            </tr>
                          );
                        }) ?? null}
                    </table>
                  </Card>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default OverViewPage;
