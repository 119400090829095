import { Vendor } from '../../types/sharedTypes';
import { getAPIBase, standardErrorHandler, standardRequestObject } from '../shared';

export const putVendorInfo = async (projectId: string, vendor: Vendor) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + 'vendor';

    const req = {
        ...standardRequestObject,
        method: 'PUT',
        body: JSON.stringify({
            ...vendor,
            vendor_id: vendor.id,
            project_id: projectId,
            is_active: vendor.is_deactivated === true ? 'false' : 'true'
        })
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};