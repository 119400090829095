import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';

const route = 'invite';

export const inviteTeamMember = async (projectId: string, emailsToInvite: string, role: string) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + route;
  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({ project_id: projectId, emails: emailsToInvite, role }),
  };

  try {
    const res = await fetch(endpoint, req);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
