import React from 'react';
import './IconButton.scss';

export enum IconButtonVariants {
  primary = 'primary',
  primaryAction = 'primaryAction',
  primaryActionThin = 'primaryActionThin',
  secondaryAction = 'secondaryAction',
  dotted = 'dotted',
  error = 'iconButtonError',
  errorSecondary = 'iconButtonErrorSecondary'
}

interface Props {
  icon: React.ReactNode;
  label?: string;
  variant?: IconButtonVariants;
  onClick?: () => void;
  isFullWidth?: boolean;
}

const IconButton: React.FC<Props> = ({
  icon,
  label,
  variant = IconButtonVariants.primary,
  onClick,
  isFullWidth,
}) => {
  const hasLabel = Boolean(label);
  const computedLabelClass = hasLabel ? '' : 'no-label';
  return (
    <button
      className={`IconButton ${variant}`}
      onClick={onClick}
      style={{ width: isFullWidth ? '100%' : '' }}
    >
      <span className={`icon ${computedLabelClass}`}>{icon}</span>
      {label && <span>{label}</span>}
    </button>
  );
};

export default IconButton;
