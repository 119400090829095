import { BudgetLineItem } from '../../types/budgetTypes';

export const removeDuplicateChildren = (
  budgetLineItems: BudgetLineItem[]
): BudgetLineItem[] => {
  if (!budgetLineItems) {
    return [];
  }

  const seenCostCodeIds = new Set<string>();
  const deduplicatedChildren: BudgetLineItem[] = [];

  for (const child of budgetLineItems) {
    const costCodeId = child.cost_code?.id;

    if (costCodeId && !seenCostCodeIds.has(costCodeId)) {
      seenCostCodeIds.add(costCodeId);
      const deduplicatedChild: BudgetLineItem = {
        ...child,
        children: removeDuplicateChildren(child.children ?? []),
      };
      deduplicatedChildren.push(deduplicatedChild);
    }
  }

  return deduplicatedChildren.length > 0 ? deduplicatedChildren : [];
};
