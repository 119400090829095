const apiVersion = 'v2';

export const getAPIBase = () => {
  // TODO: Get the API base based on the env
  return `${process.env.REACT_APP_API_URL}/${apiVersion}/`;
};

const getStandardHeaders = () => {
  const authToken = localStorage.getItem('active_user_auth_token');
  return new Headers({
    'Content-Type': 'application/json',
    Authentication: authToken ?? '',
  });
};

// TODO: Get the Authentication field based on the currently signed in user
export const standardRequestObject = {
  method: 'GET',
  headers: getStandardHeaders(),
};

export const standardErrorHandler = (error: Error) => {
  console.error(error.message);
};
