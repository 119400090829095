export const getCurrentUTCDateTime = (): string => {
  const currentUTCDate = new Date();
  const year = currentUTCDate.getUTCFullYear();
  const month = (currentUTCDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = currentUTCDate.getUTCDate().toString().padStart(2, '0');
  const hours = currentUTCDate.getUTCHours().toString().padStart(2, '0');
  const minutes = currentUTCDate.getUTCMinutes().toString().padStart(2, '0');
  const seconds = currentUTCDate.getUTCSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
