import { CostCode } from '../../types/sharedTypes';

export const getParentCostCodes = (
  all_cost_codes: CostCode[],
  targetCostCode: CostCode
): CostCode[] => {
  const parents: CostCode[] = [];

  const findParent = (currentCode: string, costCodes: CostCode[]): void => {
    for (const costCode of costCodes) {
      if (costCode.children.length > 0) {
        if (costCode.children.some((child) => child.code === currentCode)) {
          parents.push(costCode);
          findParent(costCode.code, all_cost_codes);
        } else {
          findParent(currentCode, costCode.children);
        }
      }
    }
  };

  findParent(targetCostCode.code, all_cost_codes);

  return parents;
};
