/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, memo, useCallback } from 'react';
import './EditableTableV2.scss';
import DropdownRow from './DropdownRow/DropdownRow';
import { CostCode } from '../../types/sharedTypes';
import { postExportTable } from '../../services/exports/postExportTable';
import { toast } from 'react-toastify';
import { createGrandTotalRow } from '../../utils/misc/createGrandTotalRow';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '../CheckBox/Checkbox';

export enum CellType {
  text = 'text',
  editableText = 'editableText',
  dropdown = 'dropdown',
  dollars = 'dollars',
  number = 'number',
  status = 'status',
  percentage = 'percentage',
  costCodeDropDown = 'costCodeDropDown',
  indent = 'indent',
  displayText = 'displayText',
  checkboxSelect = 'checkboxSelect',
}

export enum RowType {
  parent = 'parent',
  child = 'child',
  default = 'default',
  grandTotal = 'grandTotal',
  new = 'new',
}

export interface DropdownOption {
  value: string;
  displayValue?: string;
}

export interface TableCell {
  value: string;
  type: CellType;
  isCellDisabled?: boolean;
  dropdownOptions?: CostCode[];
  id?: string;
  columnDisplayName?: string;
  className?: string;
  parentCostCode?: CostCode | null;
  usedCostCodes?: CostCode[];
}

export interface Row {
  cells: TableCell[];
  type?: RowType;
  children?: Row[];
  id?: string;
  rowData?: any;
  indent?: number;
  isClickingDisabled?: boolean;
  isAddRowButtonDisabled?: boolean;
}

export interface Column {
  value: string;
  className?: string;
  dataKey?: string;
}

interface Props {
  rows: Row[];
  columns: Column[];
  isReadOnly?: boolean;
  onTableCellChanged?: (
    newValue: string,
    rowIndex: number,
    columnIndex: number,
    columnName?: string,
    row?: Row,
    parentRowIndex?: number
  ) => void;
  onTablerowClicked?: (rowIndex: number, row: Row) => void;
  onTableCellClicked?: (
    event: React.MouseEvent,
    cellID?: string,
    row?: Row
  ) => void;
  onTableRowDeleteClicked?: (event: React.MouseEvent, row: Row) => void;
  tableName?: string;
  isExporting?: boolean;
  setIsExporting?: (newValue: boolean) => void;
  showSummationRow?: boolean;
  handleAddNewLineItemInLine?: (newLineItem: any, row: Row) => void;
  onAddRowClicked?: (row: Row) => void;
  useFixedPositionOnCostCodeSelector?: boolean;
  showCheckboxSelectColumn?: boolean;
  onCheckboxSelected?: (row: Row) => void;
  onCheckboxColumnSelected?: () => void;
  isCheckboxHeaderSelected?: boolean;
}

const EditableTableV2: React.FC<Props> = memo(
  ({
    rows = [],
    columns = [],
    isReadOnly = false,
    onTableCellChanged = () => {},
    onTablerowClicked = () => {},
    onTableCellClicked = () => null,
    onTableRowDeleteClicked,
    tableName = '',
    isExporting = false,
    setIsExporting = () => {},
    showSummationRow = false,
    handleAddNewLineItemInLine,
    onAddRowClicked,
    useFixedPositionOnCostCodeSelector = false,
    showCheckboxSelectColumn,
    onCheckboxSelected = () => null,
    onCheckboxColumnSelected = () => null,
    isCheckboxHeaderSelected = false,
  }: Props) => {
    const handleTableCellChanged = useCallback(
      (
        newValue: string,
        rowIndex: number,
        columnIndex: number,
        columnName?: string,
        row?: Row,
        parentRowIndex?: number
      ) => {
        onTableCellChanged(
          newValue,
          rowIndex,
          columnIndex,
          columnName,
          row,
          parentRowIndex
        );
      },
      [onTableCellChanged]
    );

    const handleTableRowClicked = useCallback((rowIndex: number, row: Row) => {
      onTablerowClicked(rowIndex, row);
    }, []);

    const onNewLineItemInLine = useCallback(
      (newLineItem: any, row: Row) => {
        if (!handleAddNewLineItemInLine) return;
        handleAddNewLineItemInLine(newLineItem, row);
      },
      [handleAddNewLineItemInLine]
    );

    useEffect(() => {
      if (!isExporting) return;

      (async () => {
        const tableJson = JSON.stringify({
          name: tableName,
          headers: columns.map((column) => column.value),
          rows: flattenRows(rows).map((row) =>
            row.cells.map((cell) => cell.value)
          ),
        });

        const exportResponse = await postExportTable(tableJson);
        if (exportResponse?.error_message) {
          toast(exportResponse?.error_message, {
            position: toast.POSITION.BOTTOM_CENTER,
            type: 'error',
          });
        } else {
          window.open(exportResponse.export_url, '_blank');
          toast('Successfully exported table', {
            position: toast.POSITION.BOTTOM_CENTER,
            type: 'success',
          });
        }

        setIsExporting(false);
      })();
    }, [isExporting]);

    const flattenRows = (rows: Row[]): Row[] => {
      let flattenedRows: Row[] = [];
      rows.forEach((row) => {
        flattenedRows.push(row);
        if (row.children) {
          flattenedRows = flattenedRows.concat(flattenRows(row.children));
        }
      });
      return flattenedRows;
    };

    const grandTotalRow = createGrandTotalRow(rows);
    const tableID = `editable-table-${uuidv4()}`;

    return (
      <table className="EditableTableV2" id={tableID}>
        <tr>
          {showCheckboxSelectColumn && (
            <th
              key={`column-checkbox-select`}
              id={`column-checkbox-select`}
              className={`column-header checkbox-select-column`}
            >
              <Checkbox
                isChecked={isCheckboxHeaderSelected}
                onClicked={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  onCheckboxColumnSelected();
                }}
              />
            </th>
          )}
          {columns.map((column: Column, i: number) => {
            return (
              <th
                key={`column-${i}`}
                className={`column-header ${column.className ?? ''}`}
              >
                {column.value}
              </th>
            );
          })}
        </tr>
        <tbody>
          {rows.map((row: Row, rowIndex: number) => {
            return (
              <DropdownRow
                key={row.id}
                onTablerowClicked={handleTableRowClicked}
                onTableCellClicked={onTableCellClicked}
                onTableRowDeleteClicked={onTableRowDeleteClicked}
                row={row}
                rowIndex={rowIndex}
                handleTableCellChanged={handleTableCellChanged}
                columns={columns}
                isReadOnly={isReadOnly}
                handleAddNewLineItemInLine={onNewLineItemInLine}
                onAddRowClicked={onAddRowClicked}
                useFixedPositionOnCostCodeSelector={
                  useFixedPositionOnCostCodeSelector
                }
                tableID={tableID}
                onCheckboxSelected={onCheckboxSelected}
              />
            );
          })}
          {showSummationRow && (
            <DropdownRow
              key={grandTotalRow.id}
              onTablerowClicked={handleTableRowClicked}
              onTableCellClicked={onTableCellClicked}
              onTableRowDeleteClicked={onTableRowDeleteClicked}
              row={grandTotalRow}
              rowIndex={rows.length - 1}
              handleTableCellChanged={handleTableCellChanged}
              columns={columns}
              isReadOnly
              className="grand-total-row"
              handleAddNewLineItemInLine={onNewLineItemInLine}
              useFixedPositionOnCostCodeSelector={
                useFixedPositionOnCostCodeSelector
              }
              tableID={tableID}
              onCheckboxSelected={onCheckboxSelected}
            />
          )}
        </tbody>
      </table>
    );
  }
);

EditableTableV2.displayName = 'EditableTableV2';

export default EditableTableV2;
