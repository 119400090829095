import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';
import { drawPackageRoute } from './getDrawPackages';

export const postDrawPackageApproval = async (
    drawPackageId: string, budgetJson: string, fundingSourceJson: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + drawPackageRoute + '/approve';

    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({
            draw_package_id: drawPackageId,
            budget_json: budgetJson,
            funding_source_json: fundingSourceJson
        }),
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};