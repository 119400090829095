import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

const route = 'user';

export const getUser = async () => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + route;

    try {
        const request = new Request(endpoint, standardRequestObject);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};
