import { BudgetLineItem } from '../../types/budgetTypes';
import { findPathToChildCostCode } from '../costCodes/findPathToCostCodeChild';
import { getBudgetLineItemByCostCodeID } from './getBudgetLineItemByCostCodeID';
import { getParentLevelBudgetLineItem } from './getParentLevelBudgetLineItem';
import { makeNewBudgetLineItemsFromCostCodes } from './makeNewBudgetLineItemsFromCostCodes';

export const createPathToOrphanBudgetLineItem = (
  allBudgetLineItems: BudgetLineItem[],
  newBudgetLineItem: BudgetLineItem
): BudgetLineItem[] => {
  const result: BudgetLineItem[] = [];

  if (!newBudgetLineItem?.cost_code || !newBudgetLineItem.parentCostCode) {
    console.warn(
      'New budget line item is missing cost code or parent cost code'
    );
    return result;
  }

  const pathToChildInCostCodes = findPathToChildCostCode(
    newBudgetLineItem.parentCostCode,
    newBudgetLineItem.cost_code.id
  );

  const parentLevelBudgetLineItem = getParentLevelBudgetLineItem(
    allBudgetLineItems,
    newBudgetLineItem
  );

  if (!parentLevelBudgetLineItem) {
    console.warn('Unable to find parent level budget line item');
    return result;
  }

  const pathToChildInBudgetLineItems = makeNewBudgetLineItemsFromCostCodes(
    pathToChildInCostCodes,
    allBudgetLineItems
  );

  pathToChildInBudgetLineItems.forEach(
    (budgetLineItem: BudgetLineItem, i: number) => {
      const newBudgetLineItem = getBudgetLineItemByCostCodeID(
        allBudgetLineItems,
        budgetLineItem?.cost_code?.id ?? ''
      );

      if (!newBudgetLineItem) {
        result.push(pathToChildInBudgetLineItems[i]);
      } else {
        result.push(newBudgetLineItem);
      }
    }
  );

  return result;
};
