import { BudgetLineItem } from '../../types/budgetTypes';

export function removeBudgetLineItemById(
  idToRemove: string | undefined,
  budgetItems: BudgetLineItem[] | undefined
): BudgetLineItem[] {
  if (!idToRemove || !budgetItems) return [];
  // Create a copy of the original array to avoid mutating it
  const updatedBudgetItems: BudgetLineItem[] = [...budgetItems];

  // Find the index of the item with the matching id
  const indexToRemove = updatedBudgetItems.findIndex(
    (item) => item.id === idToRemove
  );

  // If the item with the specified id was found, remove it
  if (indexToRemove !== -1) {
    updatedBudgetItems.splice(indexToRemove, 1);
  }

  // Recursively remove the item from children, if present
  for (const budgetItem of updatedBudgetItems) {
    if (budgetItem.children) {
      budgetItem.children = removeBudgetLineItemById(
        idToRemove,
        budgetItem.children
      );
    }
  }

  return updatedBudgetItems;
}
