/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignInPage.scss';
import TextInput from '../TextInput/TextInput';
import Button, { ButtonVariant } from '../Button/Button';
import { signIn } from '../../services/auth/signIn';
import { toast } from 'react-toastify';

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleContinueButtonClicked = () => {
    // eslint-disable-next-line no-useless-escape
    if (!/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) {
      showErrorMessageToast('invalid email format');
      return;
    }

    setIsLoading(true);
    (async () => {
      const authenticatedUser = await signIn(email, password);

      if (authenticatedUser.error_type) {
        if (authenticatedUser.error_type == 'IncorrectPassword') {
          showErrorMessageToast('Incorrect password');
          setIsLoading(false);
          return;
        }
        if (authenticatedUser.error_type == 'EmailNotFound') {
          showErrorMessageToast('User not found');
          setIsLoading(false);
          return;
        }

        showErrorMessageToast(authenticatedUser.error_message);
        setIsLoading(false);
        return;
      }

      localStorage.setItem(
        'active_user_auth_token',
        authenticatedUser.auth_token
      );
      window.location.href = '/overview';
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleContinueButtonClicked();
    }
  };

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  window.document.title = 'BidSight – Sign In';
  return (
    <div className="SignInPage">
      <div className="header-container">
        <img src="https://storage.googleapis.com/public-v1.bidsight.io/BidSightTextLogo.png" />
      </div>
      <div className="body-container">
        <div className="login-form-container">
          <h1>Log in</h1>
          <div className="login-form-input">
            <TextInput
              placeholder="Email address"
              onChange={(newEmail: string) => setEmail(newEmail)}
              value={email}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="login-form-input">
            <TextInput
              placeholder="Password"
              onChange={(newPassword) => setPassword(newPassword)}
              value={password}
              onKeyDown={handleKeyDown}
              isPassword
            />
          </div>
          <div className="login-form-submit">
            <Button
              label={isLoading ? 'Loading...' : 'Continue'}
              variant={ButtonVariant.GrayThin}
              onClick={handleContinueButtonClicked}
              isDisabled={!email || !password || isLoading}
            />
          </div>
          <p className="forgot-password">
            <span onClick={() => navigate('/reset')}>Forgot password?</span>
          </p>
          <p className="terms-and-privacy">
            By clicking "Continue" above, you acknowledge that you have read and
            understood, and agree to BidSight's{' '}
            <a
              href="https://storage.googleapis.com/public-v1.bidsight.io/BidSight%20-%20Web%20User%20Terms%20of%20Service.pdf"
              target="_blank"
            >
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a
              href="https://storage.googleapis.com/public-v1.bidsight.io/BidSight%20-%20Web%20User%20Privacy%20Policy.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
