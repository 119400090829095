import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { FundingSource } from '../../types/fundingSourceTypes';

export const fundingSourceRoute = 'funding_source';

export const postNewFundingSource = async (fundingSource: any) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + fundingSourceRoute;

  const overageResponsibility = !fundingSource.overage_responsibility
    ? '0'
    : fundingSource.overage_responsibility;

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      ...fundingSource,
      overage_responsibility: overageResponsibility,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
