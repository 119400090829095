import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import routes from './routes';
import { getUser } from '../../services/user/getUser';
import { ReactComponent as LoadingSpinner } from '../../assets/loading-spinner.svg';
import BidSightLogo from '../../assets/BidSightLogoRounded.png';
import './AppRouter.scss';
import SignInPage from '../SignInPage/SignInPage';
import { Project } from '../../types/projectTypes';
import { User } from '../../types/userTypes';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import { AppContext } from '../../types/appContextTypes';
import { getProjectInfo } from '../../services/project/getProjectInfo';
import JoinPage from '../JoinPage/JoinPage';
import ChatBot from '../ChatBot/ChatBot';

// eslint-disable-next-line no-var
declare var posthog: any;

const HIDE_NAV_BAR_PATHS = [
  routes.Login.path,
  routes.Join.path,
  routes.ResetPassword.path
];

interface PosthogUserInfo {
  id: string;
  name: string;
  email: string;
  organization?: string;
}

interface PosthogOrgInfo {
  id: string;
  name: string;
  address: string;
  website?: string;
}

interface Props {
  appIsLoaded: boolean;
  setAppIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  appContext: AppContext | null;
  setAppContext: React.Dispatch<React.SetStateAction<AppContext | null>>;
  setHideNavBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppRouter: React.FC<Props> = ({
  appIsLoaded,
  setAppIsLoaded,
  appContext,
  setAppContext,
  setHideNavBar,
}) => {
  const location = useLocation();
  const [missingAuthToken, setMissingAuthToken] = useState<boolean>(false);
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    const hideNavBarFrompath = !!HIDE_NAV_BAR_PATHS.find(
      (path) => window.location.pathname.includes(path));
    setHideNavBar(hideNavBarFrompath || !appIsLoaded);
  }, [location.pathname, appIsLoaded]);

  useEffect(() => {
    if (location.pathname === routes.Join.path) return; // avoid unnecessary loading of user data

    const localUserAuthToken = localStorage.getItem('active_user_auth_token');
    if (!localUserAuthToken) {
      setMissingAuthToken(true);
      return;
    }

    // Get current project ID from local storage
    const localActiveProjectID = localStorage.getItem('active_project_id');

    (async () => {
      // Load current user data
      const currentUser = await getUser();
      if (!currentUser) {
        window.alert(
          'Unable to load webpage. Please contact support@bidsight.io.'
        );
        return;
      }
      if (currentUser.error_message) {
        if (currentUser.error_message == 'User session expired') {
          localStorage.removeItem('active_user_auth_token');
          setMissingAuthToken(true);
          return;
        }

        console.error(currentUser.error_message);
        window.alert(
          'Unable to load current user. Please contact support@bidsight.io.'
        );
        return;
      }

      // Record user and org for PostHog analytics
      if (typeof posthog !== 'undefined') {
        posthog.identify(`b-${currentUser.id}`);

        const userInfo: PosthogUserInfo = {
          id: currentUser.id,
          name: `${currentUser.first_name} ${currentUser.last_name}`,
          email: currentUser.email,
        };
        const hasOrg = !!currentUser.organization;
        if (hasOrg) {
          userInfo.organization = currentUser.organization.name;

          let orgAddress = currentUser.organization.address_line_1 ?? '';
          orgAddress += currentUser.organization.address_line_2
            ? ' ' + currentUser.organization.address_line_2 + ','
            : ',';
          orgAddress += currentUser.organization.city
            ? ' ' + currentUser.organization.city + ','
            : '';
          orgAddress += currentUser.organization.state
            ? ' ' + currentUser.organization.state + ','
            : '';
          orgAddress += currentUser.organization.zip_code
            ? ' ' + currentUser.organization.zip_code
            : '';
          const orgInfo: PosthogOrgInfo = {
            id: currentUser.organization_id,
            name: currentUser.organization.name,
            address: orgAddress,
          };
          // eslint-disable-next-line
          if (!!currentUser.organization.website_url) {
            orgInfo.website = currentUser.organization.website_url;
          }
          posthog.group('organizations', currentUser.organization_id, orgInfo);
        }
        posthog.group('users', currentUser.id, userInfo);
      }

      if (currentUser.projects.length > 0) {
        const currentProjectFromLocalStorage = currentUser.projects.find(
          (proj: Project) => proj.id == localActiveProjectID
        );
        const currentProject =
          currentProjectFromLocalStorage ?? currentUser.projects[0];
        await loadProject(
          null,
          currentUser,
          currentUser.projects,
          currentProject,
          false
        );
      } else {
        // No active projects is a deal breaker :/
        window.alert(
          'No active projects for this account. Please contact support@bidsight.io.'
        );
        return;
      }

      setAppIsLoaded(true);
    })();
  }, []);

  const loadProject = async (
    prevContext: AppContext | null,
    currentUser: User,
    allProjects: Project[],
    currentProject: Project,
    inBackground?: boolean
  ) => {
    if (!!prevContext && !inBackground) {
      setAppContext({ ...prevContext, isRefetching: true });
    }

    const projectData = await getProjectInfo(currentProject.id);
    if (!projectData) {
      window.alert(
        'Unable to load webpage. Please contact support@bidsight.io.'
      );
      return;
    }
    if (projectData.error_message) {
      console.error(projectData.error_message);
      window.alert(
        'Unable to load project data. Please contact support@bidsight.io.'
      );
      return;
    }

    // Define updated top-level project object
    // Can't do "updatedProject = {...projectData}" because we don't want entity fields
    const updatedProject = {
      address_line_1: projectData.address_line_1,
      address_line_2: projectData.address_line_2,
      auto_allocate_funding: projectData.auto_allocate_funding,
      budget_is_finalized: projectData.budget_is_finalized,
      city: projectData.city,
      cost_code_delimiter: projectData.cost_code_delimiter,
      funding_sources: projectData.funding_sources,
      id: projectData.id,
      inherited_org_defaults: projectData.inherited_org_defaults,
      invoice_pipeline_email: projectData.invoice_pipeline_email,
      is_complete: projectData.is_complete,
      is_deactivated: projectData.is_deactivated,
      is_test_project: projectData.is_test_project,
      name: projectData.name,
      organization_id: projectData.organization_id,
      projected_finish_date_utc: projectData.projected_finish_date_utc,
      square_footage: projectData.square_footage,
      stage: projectData.stage,
      start_date_utc: projectData.start_date_utc,
      state: projectData.state,
      type: projectData.type,
      users: projectData.users,
      vendors: projectData.vendors,
      zip_code: projectData.zip_code,
      cost_codes: projectData.cost_codes,
    };

    // Define new list of all projects
    const updatedAllProjects = allProjects.map((proj) => {
      if (proj.id == updatedProject.id) return updatedProject;
      return proj;
    });

    // Set global app context
    const newContext = {
      currentUser,
      currentProject: updatedProject,
      allProjects: updatedAllProjects,
      budget: projectData.budget_line_items,
      contracts: projectData.contracts,
      changeOrders: projectData.change_orders,
      invoices: projectData.invoices,
      drawPackages: projectData.draw_packages,
      projectTeam: projectData.team,
      isRefetching: false,
      // eslint-disable-next-line
      refetchProjectData: (_?: boolean) => { }, // fake refetch function until we have context defined for real one
    };
    setAppContext({
      ...newContext,
      refetchProjectData: (inBackground?: boolean) =>
        loadProject(
          newContext,
          currentUser,
          allProjects,
          currentProject,
          inBackground
        ),
    });
  };

  if (location.pathname === routes.ResetPassword.path) {
    return <ResetPasswordPage />;
  } else if (location.pathname === routes.Join.path) {
    return <JoinPage />;
  } else if (missingAuthToken) {
    return <SignInPage />;
  } else if (!appIsLoaded || !appContext) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner-inner">
          <img src={BidSightLogo} />
          <LoadingSpinner className="loading-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Routes>
        <Route
          path={routes.Overview.path}
          element={routes.Overview.element(appContext)}
        />
        <Route
          path={routes.Budget.path}
          element={routes.Budget.element(appContext)}
        />
        <Route
          path={routes.ContractsID.path}
          element={routes.ContractsID.element(appContext)}
        />
        <Route
          path={routes.Contracts.path}
          element={routes.Contracts.element(appContext)}
        />
        <Route
          path={routes.ChangeOrdersID.path}
          element={routes.ChangeOrdersID.element(appContext)}
        />
        <Route
          path={routes.ChangeOrders.path}
          element={routes.ChangeOrders.element(appContext)}
        />
        <Route
          path={routes.InvoicesID.path}
          element={routes.InvoicesID.element(appContext)}
        />
        <Route
          path={routes.Invoices.path}
          element={routes.Invoices.element(appContext)}
        />
        <Route
          path={routes.DrawPackagesID.path}
          element={routes.DrawPackagesID.element(appContext)}
        />
        <Route
          path={routes.DrawPackages.path}
          element={routes.DrawPackages.element(appContext)}
        />
        <Route
          path={routes.Users.path}
          element={routes.Users.element(appContext)}
        />
        <Route
          path={routes.Vendors.path}
          element={routes.Vendors.element(appContext)}
        />
        <Route
          path={routes.Settings.path}
          element={routes.Settings.element(appContext)}
        />
        <Route
          path={routes.MyInbox.path}
          element={routes.MyInbox.element(appContext)}
        />
        <Route path="/*" element={<Navigate to={routes.Overview.path} />} />
      </Routes>
      {searchParams.has('bidbot') === true && (
        <ChatBot appContext={appContext} />
      )}
    </div>
  );
};

export default AppRouter;
