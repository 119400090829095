import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { InvoiceLineItem } from '../../types/invoiceTypes';
import { CostCode } from '../../types/sharedTypes';

export const formatInvoiceLineItemsForEditableTable = (
  lineItems: InvoiceLineItem[],
  costCodes: CostCode[],
  isContractSelected: boolean
): Row[] => {
  const result: Row[] = [];

  if (!lineItems || lineItems.length === 0) return result;

  for (let i = 0; i < lineItems.length; i++) {
    const currentLineItem = lineItems[i];
    const currentRow: Row = {
      cells: [],
      id: currentLineItem.id,
      rowData: currentLineItem,
    };

    // Cost Code
    currentRow.cells.push({
      value: currentLineItem.cost_code?.id ?? '',
      type: CellType.dropdown,
      dropdownOptions: costCodes,
      isCellDisabled: false,
    });

    // Quoted Line Item
    currentRow.cells.push({
      value: currentLineItem.quoted_line_item ?? (isContractSelected === true ? '-' : ''),
      type: isContractSelected === true ? CellType.text : CellType.editableText,
      isCellDisabled: isContractSelected === true,
    });

    // Current Billing
    currentRow.cells.push({
      value: currentLineItem.current_billing.toString(),
      type: CellType.dollars,
    });

    let previouslyPaid = '-';
    if (
      currentLineItem?.previously_paid !== null &&
      currentLineItem?.previously_paid !== undefined
    ) {
      previouslyPaid = currentLineItem?.previously_paid.toString();
    }
    // Previously Paid
    currentRow.cells.push({
      value: previouslyPaid,
      type: CellType.dollars,
      isCellDisabled: true,
    });

    let scheduledValue = '-';
    if (currentLineItem.scheduled_value !== null) {
      scheduledValue = currentLineItem.scheduled_value.toString();
    }
    // Scheduled Value
    currentRow.cells.push({
      value: scheduledValue,
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // From Previous Application
    currentRow.cells.push({
      value: currentLineItem?.from_previous_applications.toString(),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    let thisPeriod = '-';
    if (currentLineItem?.this_period !== null) {
      thisPeriod = currentLineItem?.this_period.toString();
    }

    // This Period
    currentRow.cells.push({
      value: thisPeriod,
      type: CellType.dollars,
    });

    let materialPresentlyStored = '-';
    if (currentLineItem?.materials_presently_stored !== null) {
      materialPresentlyStored =
        currentLineItem?.materials_presently_stored.toString();
    }

    // Materials presently stored
    currentRow.cells.push({
      value: materialPresentlyStored,
      type: CellType.dollars,
    });

    // Total Completed and Stored to Date
    currentRow.cells.push({
      value: currentLineItem.total_completed_and_stored_to_date.toString(),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    let percentageCompleteValue = Math.round(
      (currentLineItem.total_completed_and_stored_to_date /
        (currentLineItem?.scheduled_value ?? 1)) *
        100
    ).toFixed(2);

    if (
      isNaN(Number(percentageCompleteValue)) ||
      percentageCompleteValue === 'Infinity'
    ) {
      percentageCompleteValue = '0.00';
    }
    // % Complete
    currentRow.cells.push({
      value: percentageCompleteValue,
      type: CellType.percentage,
      isCellDisabled: true,
    });

    let balanceToFinishValue = '-';
    // Check that both fields aren't null so we can do subtraction
    if (
      currentLineItem.scheduled_value !== null &&
      currentLineItem.total_completed_and_stored_to_date !== null
    ) {
      balanceToFinishValue = (
        currentLineItem.scheduled_value -
        currentLineItem.total_completed_and_stored_to_date
      ).toString();
    }
    // Balance to finish
    currentRow.cells.push({
      value: balanceToFinishValue,
      type: CellType.dollars,
      isCellDisabled: true,
    });

    let totalRetainage = '-';
    if (currentLineItem?.retainage !== null) {
      totalRetainage = currentLineItem?.retainage.toString();
    }

    // Total retainage
    currentRow.cells.push({
      value: totalRetainage,
      type: CellType.dollars,
    });

    // Current work and materials
    currentRow.cells.push({
      value: currentLineItem.current_work_and_materials.toString(),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    let previousRetainage = '-';
    if (
      currentLineItem?.previous_retainage !== null &&
      currentLineItem?.previous_retainage !== undefined
    ) {
      previousRetainage = currentLineItem?.previous_retainage.toString();
    }

    // Previous retainage
    currentRow.cells.push({
      value: previousRetainage,
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Current retainage
    currentRow.cells.push({
      value: currentLineItem?.current_retainage.toString(),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
