import React from 'react';
import './CreateFundingSourceModal.scss';
import Modal, { ModalSizes } from '../Modal/Modal';
import { ButtonVariant } from '../Button/Button';
import ProgressStepTracker, {
  Step,
} from '../ProgressStepTracker/ProgressStepTracker';
import TextInput from '../TextInput/TextInput';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import Select from '../Select/Select';
import { indexesInEnglish } from '../../utils/misc/indexesInEnglish';
import Checkbox from '../CheckBox/Checkbox';
import { FundingSourceTypes } from '../../types/fundingSourceTypes';
import { CostCode } from '../../types/sharedTypes';
import CostCodeSelectorMultiSelectInput from '../CostCodeSelectorInput/MultiSelect/CostCodeSelectorMultiSelectInput';
import { ProjectFundingSource } from '../../types/projectTypes';

export const fundingSourceTypes: string[] = Object.values(
  FundingSourceTypes
).filter((value) => typeof value === 'string');

interface FinancialDetailsProps {
  fundingSources: ProjectFundingSource[][];
  amount: string;
  paymentOrder: string;
  overageResponsibility: string;
  closeDate: Date;
  maturityDate: Date;
  all_cost_codes: CostCode[];
  excludedCostCodes: CostCode[];
  excludeLineItemsFromSource: boolean;
  setAmount: (newValue: string) => void;
  setPaymentOrder: (newValue: string) => void;
  setOverageResponsibility: (newValue: string) => void;
  setCloseDate: (newValue: Date) => void;
  setMaturityDate: (newValue: Date) => void;
  setExcludeLineItemsFromSource: (newValue: boolean) => void;
  setExcludedCostCodes: (selectedCostCodes: CostCode[]) => void;
}

const FinancialDetails: React.FC<FinancialDetailsProps> = ({
  fundingSources,
  amount,
  paymentOrder,
  overageResponsibility,
  closeDate,
  maturityDate,
  excludeLineItemsFromSource,
  all_cost_codes,
  excludedCostCodes,
  setAmount,
  setPaymentOrder,
  setOverageResponsibility,
  setCloseDate,
  setMaturityDate,
  setExcludeLineItemsFromSource,
  setExcludedCostCodes,
}) => {
  return (
    <>
      <div className="label-input-container margin-bottom">
        <label htmlFor="cfs-amount-input">Amount</label>
        <CurrencyInput
          inputprops={{
            id: 'cfs-amount-input',
            placeholder: 'Input an amount in USD',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              setAmount(event.target.value),
            value: amount,
          }}
        />
      </div>
      <div className="two-column-grid">
        <div className="label-input-container margin-bottom">
          <label className="required-label" htmlFor="cfs-payment-order">
            Payment order
          </label>
          <Select
            id="cfs-payment-order"
            onChange={(newValue) => setPaymentOrder(newValue)}
            value={paymentOrder}
          >
            {fundingSources.map((fundingSource, i) => {
              return (
                <option value={(i + 1).toString()} key={indexesInEnglish[i]}>
                  {indexesInEnglish[i]}
                </option>
              );
            })}
            <option value={fundingSources.length + 1}>
              {indexesInEnglish[fundingSources.length]}
            </option>
          </Select>
        </div>
        <div className="label-input-container margin-bottom">
          <label htmlFor="cfs-overage-responsibility">
            Overage responsibility
          </label>
          <TextInput
            id="cfs-overage-responsibility"
            placeholder="Input a percent, if any"
            value={overageResponsibility}
            onChange={(newValue) => setOverageResponsibility(newValue)}
            onlyAllowNumbers
            isPercentage
          />
        </div>
        <div className="label-input-container margin-bottom">
          <label htmlFor="cfs-close-date">Close date</label>
          <CustomDatePicker
            id="cfs-close-date"
            selectedDate={closeDate}
            handleDateChange={(newDate: Date) => setCloseDate(newDate)}
          />
        </div>
        <div className="label-input-container margin-bottom">
          <label htmlFor="cfs-maturity-date">Maturity date</label>
          <CustomDatePicker
            id="cfs-maturity-date"
            selectedDate={maturityDate}
            handleDateChange={(newDate: Date) => setMaturityDate(newDate)}
          />
        </div>
      </div>
      <div className="margin-bottom row">
        <span className="small-margin-right">
          <Checkbox
            isChecked={excludeLineItemsFromSource}
            onClicked={() =>
              setExcludeLineItemsFromSource(!excludeLineItemsFromSource)
            }
          />
        </span>
        <label
          className="block pointer"
          onClick={() =>
            setExcludeLineItemsFromSource(!excludeLineItemsFromSource)
          }
        >
          Exclude line items from this source?
        </label>
      </div>

      {excludeLineItemsFromSource && (
        <div className="cost-code-multiselect-container label-input-container margin-bottom">
          <label>Exclusions</label>
          <CostCodeSelectorMultiSelectInput
            all_cost_codes={all_cost_codes}
            value={excludedCostCodes}
            onCostCodeSelect={setExcludedCostCodes}
          />
        </div>
      )}
    </>
  );
};

interface GeneralDetailsProps {
  name: string;
  poc: string;
  fundingSourceType: string;
  setName: (newValue: string) => void;
  setPoc: (newValue: string) => void;
  setFundingSourceType: (newValue: string) => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({
  name,
  poc,
  fundingSourceType,
  setName,
  setPoc,
  setFundingSourceType,
}) => {
  return (
    <>
      <div className="label-input-container margin-bottom">
        <label className="required-label" htmlFor="cfs-name">
          Name
        </label>
        <TextInput
          id="cfs-name"
          placeholder="Please enter the name of the funding source"
          value={name}
          onChange={(newValue) => setName(newValue)}
        />
      </div>
      <div className="label-input-container margin-bottom">
        <label htmlFor="cfs-poc">Point of contact</label>
        <TextInput
          id="cfs-poc"
          placeholder="Please enter a valid email for the primary point of contact"
          value={poc}
          onChange={(newValue) => setPoc(newValue)}
        />
      </div>
      <div className="label-input-container margin-bottom half-width">
        <label htmlFor="cfs-type" className="required-label">
          Type
        </label>
        <Select
          id="cfs-type"
          onChange={(newValue) => setFundingSourceType(newValue)}
          value={fundingSourceType}
        >
          {fundingSourceTypes.map((typeOfFundingSource) => {
            return (
              <option key={typeOfFundingSource}>{typeOfFundingSource}</option>
            );
          })}
        </Select>
      </div>
    </>
  );
};

interface Props {
  activeStep: number;
  isOpen: boolean;
  fundingSources: ProjectFundingSource[][];
  amount: string;
  paymentOrder: string;
  overageResponsibility: string;
  closeDate: Date;
  maturityDate: Date;
  excludeLineItemsFromSource: boolean;
  name: string;
  poc: string;
  fundingSourceType: string;
  all_cost_codes: CostCode[];
  excludedCostCodes: CostCode[];
  setName: (newValue: string) => void;
  setPoc: (newValue: string) => void;
  setFundingSourceType: (newValue: string) => void;
  setAmount: (newValue: string) => void;
  setPaymentOrder: (newValue: string) => void;
  setOverageResponsibility: (newValue: string) => void;
  setCloseDate: (newValue: Date) => void;
  setMaturityDate: (newValue: Date) => void;
  setExcludeLineItemsFromSource: (newValue: boolean) => void;
  onClose: () => void;
  onPrimaryButtonClicked: () => void;
  onSecondaryButtonClicked: () => void;
  setExcludedCostCodes: (selectedCostCodes: CostCode[]) => void;
}

const CreateFundingSourceModal: React.FC<Props> = ({
  activeStep,
  excludedCostCodes,
  all_cost_codes,
  isOpen,
  name,
  poc,
  fundingSourceType,
  fundingSources,
  amount,
  paymentOrder,
  overageResponsibility,
  closeDate,
  maturityDate,
  excludeLineItemsFromSource,
  onClose,
  onPrimaryButtonClicked,
  onSecondaryButtonClicked,
  setName,
  setPoc,
  setFundingSourceType,
  setAmount,
  setPaymentOrder,
  setOverageResponsibility,
  setCloseDate,
  setMaturityDate,
  setExcludeLineItemsFromSource,
  setExcludedCostCodes,
}) => {
  const steps: Step[] = [
    {
      label: 'General details',
    },
    {
      label: 'Financial details',
    },
  ];

  const componentSteps = [
    <GeneralDetails
      key="GeneralDetails"
      name={name}
      poc={poc}
      fundingSourceType={fundingSourceType}
      setName={setName}
      setPoc={setPoc}
      setFundingSourceType={setFundingSourceType}
    />,
    <FinancialDetails
      key="FinancialDetails"
      all_cost_codes={all_cost_codes}
      excludedCostCodes={excludedCostCodes}
      fundingSources={fundingSources}
      amount={amount}
      paymentOrder={paymentOrder}
      overageResponsibility={overageResponsibility}
      closeDate={closeDate}
      maturityDate={maturityDate}
      excludeLineItemsFromSource={excludeLineItemsFromSource}
      setAmount={setAmount}
      setPaymentOrder={setPaymentOrder}
      setOverageResponsibility={setOverageResponsibility}
      setCloseDate={setCloseDate}
      setMaturityDate={setMaturityDate}
      setExcludeLineItemsFromSource={setExcludeLineItemsFromSource}
      setExcludedCostCodes={setExcludedCostCodes}
    />,
  ];

  return (
    <Modal
      size={ModalSizes.medium}
      isOpen={isOpen}
      onClose={onClose}
      secondaryButtonType={ButtonVariant.GrayThin}
      primaryButtonType={ButtonVariant.PrimaryThin}
      disableScrolling
      primaryHeader="Create funding source"
      primaryButtonLabel={
        activeStep === steps.length - 1 ? 'Create source' : 'Next'
      }
      secondaryButtonLabel={activeStep === 0 ? 'Cancel' : 'Previous'}
      onPrimaryButtonClicked={onPrimaryButtonClicked}
      onSecondaryButtonClicked={onSecondaryButtonClicked}
    >
      <div className="CreateFundingSourceModal">
        <div className="progress-step-container">
          <ProgressStepTracker steps={steps} activeStep={activeStep} />
        </div>
        {componentSteps[activeStep]}
      </div>
    </Modal>
  );
};

export default CreateFundingSourceModal;
