import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { budgetRoute } from './getBudgets';

export const putFinalizedBudget = async (projectId: string) => {
  const apiBase = getAPIBase();
  const endpoint =
    apiBase + budgetRoute + '/finalize' + `?project_id=${projectId}`;

  const req = {
    ...standardRequestObject,
    method: 'PUT',
  };
  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
