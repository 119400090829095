import { Row } from '../components/EditableTable/EditableTableV2';
import { RawContractLineItem } from '../types/contractTypes';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from './currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';

export const formatContractLineItemsForBackend = (
  sovTableRows: Row[],
  originalLineItems: RawContractLineItem[]
) => {
  return sovTableRows.map((tableRow: Row) => {
    const lineItemId =
      originalLineItems?.find((lineItem: RawContractLineItem) => {
        return lineItem.cost_code_id === tableRow.cells[0].value;
      })?.id ?? null;

    return {
      id: lineItemId,
      cost_code_id: tableRow.cells[0].value,
      quoted_line_item: tableRow.cells[1].value,
      total_amount: removeCharactersExceptForPeriodAndNumbersFromFloatString(
        tableRow.cells[2].value
      ),
    };
  });
};
