import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

export const projectRoute = 'project';

export const getProjectInvite = async (inviteCode: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + `invite?invite_code=${inviteCode}`;

    try {
        const request = new Request(endpoint, standardRequestObject);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};
