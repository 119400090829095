import React from 'react';
import './FundingSources.scss';
import { CostCode } from '../../../types/sharedTypes';
import IconButton, { IconButtonVariants } from '../../IconButton/IconButton';
import { GoPlus as PlusIcon } from 'react-icons/go';
import CreateFundingSourceModal from '../../CreateFundingSourceModal/CreateFundingSourceModal';
import { ReactComponent as FileIconWithCircle } from '../../../assets/File-icon-with-circle.svg';
import SwitchButton from '../../SwitchButton/SwitchButton';
import FundingSourceColumns from '../../FundingSourceColumns/FundingSourceColumns';
import FundingSourceBlade from './FundingSourceBlade/FundingSourceBlade';
import { formatToUSD } from '../../../utils/currency/formatToUSD';
import EditableTableLoadingSkeleton from '../../EditableTable/EditableTableLoadingSkeleton';
import { ProjectFundingSource } from '../../../types/projectTypes';

interface NoFundingSourcesProps {
  openCFSModal: () => void;
}

const NoFundingSources: React.FC<NoFundingSourcesProps> = ({
  openCFSModal,
}) => {
  return (
    <div className="no-funding-sources-container">
      <FileIconWithCircle />
      <h5>No funding sources created</h5>
      <p>
        You have not created any funding sources yet for this project. Create
        funding sources to get started.
      </p>
      <div className="create-funding-source-button-container">
        <IconButton
          variant={IconButtonVariants.primaryActionThin}
          label="Create funding source"
          icon={<PlusIcon />}
          onClick={openCFSModal}
        />
      </div>
    </div>
  );
};

interface FundingSourcesProps {
  activeStep: number;
  isCFSModalOpen: boolean;
  fundingSources: ProjectFundingSource[][];
  amount: string;
  paymentOrder: string;
  overageResponsibility: string;
  closeDate: Date;
  maturityDate: Date;
  excludeLineItemsFromSource: boolean;
  isFundingSourceBladeOpen: boolean;
  isLoading: boolean;
  name: string;
  poc: string;
  fundingSourceType: string;
  selectedFundingSource: ProjectFundingSource | null;
  all_cost_codes: CostCode[];
  excludedCostCodes: CostCode[];
  budgetOriginalTotal: number;
  budgetOverages: number;
  setExcludedCostCodes: (selectedCostCodes: CostCode[]) => void;
  setName: (newValue: string) => void;
  setPoc: (newValue: string) => void;
  setFundingSourceType: (newValue: string) => void;
  setAmount: (newValue: string) => void;
  setPaymentOrder: (newValue: string) => void;
  setOverageResponsibility: (newValue: string) => void;
  setCloseDate: (newValue: Date) => void;
  setMaturityDate: (newValue: Date) => void;
  setExcludeLineItemsFromSource: (newValue: boolean) => void;
  onClose: () => void;
  onPrimaryButtonClicked: () => void;
  onSecondaryButtonClicked: () => void;
  openCFSModal: () => void;
  onFundingSourceCardClicked: (fundingSource: ProjectFundingSource) => void;
  onFundingSourceBladeClosed: () => void;
  handleFundingSourceEdited: (
    newValue: any,
    keyToChange: keyof ProjectFundingSource
  ) => void;
  handleDeleteFundingSourceClicked: () => void;
  handleConfirmSaveChanges: () => void;
  handleCancelChangesButtonClicked: () => void;
  handleEditSourceButtonClicked: () => void;
}

const FundingSources: React.FC<FundingSourcesProps> = ({
  activeStep,
  fundingSources,
  all_cost_codes,
  name,
  poc,
  fundingSourceType,
  amount,
  paymentOrder,
  overageResponsibility,
  closeDate,
  maturityDate,
  excludeLineItemsFromSource,
  isCFSModalOpen,
  excludedCostCodes,
  selectedFundingSource,
  isFundingSourceBladeOpen,
  budgetOriginalTotal,
  budgetOverages,
  isLoading,
  setExcludedCostCodes,
  setName,
  setPoc,
  setFundingSourceType,
  setAmount,
  setPaymentOrder,
  setOverageResponsibility,
  setCloseDate,
  setMaturityDate,
  setExcludeLineItemsFromSource,
  onPrimaryButtonClicked,
  onSecondaryButtonClicked,
  onClose,
  openCFSModal,
  onFundingSourceCardClicked,
  onFundingSourceBladeClosed,
  handleFundingSourceEdited,
  handleDeleteFundingSourceClicked,
  handleConfirmSaveChanges,
  handleCancelChangesButtonClicked,
  handleEditSourceButtonClicked,
}) => {
  const totalFundingSources = fundingSources.reduce(
    (fsGroupTotal, fsGroup) =>
      fsGroupTotal +
      fsGroup.reduce((fsTotal, fs) => fsTotal + fs.original_amount, 0),
    0
  );

  return (
    <div className="FundingSources">
      <div>
        <h4 className="sources-header">Sources</h4>
        <div className="budget-values-container">
          <div className="label-value-container">
            <label>Original Budget:</label>
            <p>{formatToUSD(budgetOriginalTotal.toString())}</p>
          </div>
          <div className="label-value-container">
            <label>Budget Overages:</label>
            <p>{formatToUSD(budgetOverages.toString())}</p>
          </div>
          <div className="label-value-container">
            <label>Total Funding Sources:</label>
            <p>{formatToUSD(totalFundingSources.toString())}</p>
          </div>
          <div className='add-source-button-container'>
            <IconButton
              variant={IconButtonVariants.dotted}
              label="Add source"
              icon={<PlusIcon />}
              onClick={openCFSModal}
            />
          </div>
        </div>
      </div>
      {fundingSources.length > 0 ? (
        <div className="funding-sources-content-area">
          {isLoading ? (
            <EditableTableLoadingSkeleton />
          ) : (
            <div className="bottom">
              <div className="funding-source-columns-container">
                <FundingSourceColumns
                  onCardClicked={onFundingSourceCardClicked}
                  fundingSources={fundingSources}
                  selectedFundingSource={selectedFundingSource}
                  originalBudgetAmount={budgetOriginalTotal}
                  budgetOveragesAmount={budgetOverages}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoFundingSources openCFSModal={openCFSModal} />
      )}
      <CreateFundingSourceModal
        excludedCostCodes={excludedCostCodes}
        activeStep={activeStep}
        isOpen={isCFSModalOpen}
        fundingSources={fundingSources}
        all_cost_codes={all_cost_codes}
        name={name}
        poc={poc}
        fundingSourceType={fundingSourceType}
        amount={amount}
        paymentOrder={paymentOrder}
        overageResponsibility={overageResponsibility}
        closeDate={closeDate}
        maturityDate={maturityDate}
        excludeLineItemsFromSource={excludeLineItemsFromSource}
        setExcludedCostCodes={setExcludedCostCodes}
        onClose={onClose}
        setName={setName}
        setPoc={setPoc}
        setFundingSourceType={setFundingSourceType}
        setAmount={setAmount}
        setPaymentOrder={setPaymentOrder}
        setOverageResponsibility={setOverageResponsibility}
        setCloseDate={setCloseDate}
        setMaturityDate={setMaturityDate}
        setExcludeLineItemsFromSource={setExcludeLineItemsFromSource}
        onPrimaryButtonClicked={onPrimaryButtonClicked}
        onSecondaryButtonClicked={onSecondaryButtonClicked}
      />
      <FundingSourceBlade
        selectedFundingSource={selectedFundingSource}
        isOpen={isFundingSourceBladeOpen}
        onClose={onFundingSourceBladeClosed}
        allFundingSources={fundingSources}
        allCostCodes={all_cost_codes}
        handleFundingSourceEdited={handleFundingSourceEdited}
        handleDeleteFundingSourceClicked={handleDeleteFundingSourceClicked}
        handleConfirmSaveChanges={handleConfirmSaveChanges}
        handleCancelChangesButtonClicked={handleCancelChangesButtonClicked}
        handleEditSourceButtonClicked={handleEditSourceButtonClicked}
      />
    </div>
  );
};

export default FundingSources;
