import { Row } from '../../components/EditableTable/EditableTableV2';

export const sortContractTableRows = (rows: Row[]) => {
  rows.sort((a, b) => {
    const titleA = a.rowData?.title || '';
    const titleB = b.rowData?.title || '';

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' });
  });
  return rows;
};
