import { ProjectFundingSourceExclusions } from '../../types/projectTypes';
import { CostCode } from '../../types/sharedTypes';
import { v4 as uuidv4 } from 'uuid';

export const formatNewExclusionsFromCostCodes = (
  costCodes: CostCode[],
  fundingSourceID: string
): ProjectFundingSourceExclusions[] => {
  const result: ProjectFundingSourceExclusions[] = [];

  costCodes.forEach((costCode: CostCode) => {
    result.push({
      cost_code_id: costCode.id,
      id: uuidv4(),
      is_removed: false,
      funding_source_id: fundingSourceID,
    });
  });

  return result;
};
