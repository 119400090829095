export const formatToUSD = (inputValue: string): string => {
  if (inputValue === '') return inputValue;

  // Convert to number and format as dollars
  const dollars = Number(inputValue).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return dollars;
};
