import { CostCode } from '../types/sharedTypes';

export const getCostCodeIdByCostCode = (
  costCodes: CostCode[],
  code: string
): string => {
  for (const costCode of costCodes) {
    if (costCode.code === code) {
      return costCode.id;
    } else if (costCode.children && costCode.children.length > 0) {
      const childId = getCostCodeIdByCostCode(costCode.children, code);
      if (childId) {
        return childId;
      }
    }
  }

  return '';
};
