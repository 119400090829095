import React, { useState, useEffect } from 'react';
import './VendorsPage.scss';
import TabBar from '../TabBar/TabBar';
import TextInput from '../TextInput/TextInput';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { BsFilter as FilterIcon } from 'react-icons/bs';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import { GoPlus as PlusIcon } from 'react-icons/go';
import { BiExport as ExportIcon } from 'react-icons/bi';
import EditableTableV2, { Column, Row } from '../EditableTable/EditableTableV2';
import VendorBlade from '../VendorBlade/VendorBlade';
import { Vendor } from '../../types/sharedTypes';
import { formatVendorsToTableRows } from '../../utils/vendor/formatVendorsToTableRows';
import { AppContext } from '../../types/appContextTypes';
import Modal, { ModalSizes } from '../Modal/Modal';
import Button, { ButtonVariant } from '../Button/Button';
import { getBlankVendor } from '../../utils/vendor/getBlankVendor';
import StateDropdown from '../StateDropdown/StateDropdown';
import { toast } from 'react-toastify';
import { isValidEmail } from '../../utils/misc/isValidEmail';
import { sortVendors } from '../../utils/vendor/sortVendors';
import { applyVendorFilters } from '../../utils/vendor/applyVendorFilters';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import { formatVendorFilters } from '../../utils/vendor/formatVendorFilters';
import FilterButton from '../FilterButton/FilterButton';
import { postVendor } from '../../services/project/postVendor';

const vendorColumns: Column[] = [
  {
    value: 'Name',
  },
  {
    value: 'Status',
  },
  {
    value: 'Point of Contact',
  },
  {
    value: 'Contracted Amount',
  },
  {
    value: 'Contract Count',
  },
];

const tabOptions = ['All Vendors'];

interface Props {
  appContext: AppContext;
}

interface InputError {
  message: string;
  inputId: string;
}

const VendorsPage: React.FC<Props> = ({ appContext }) => {
  const [projectVendors, setProjectVendors] = useState<Vendor[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isVendorBladeOpen, setIsVendorBladeOpen] = useState<boolean>(false);
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] =
    useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [newVendor, setNewVendor] = useState<Vendor>(getBlankVendor());
  const [inputErrors, setInputErrors] = useState<InputError[]>([]);
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;
  const vendorsMap: filtersMap = formatVendorFilters(
    appContext.currentProject.vendors
  );

  useEffect(() => {
    setProjectVendors(appContext.currentProject.vendors);
    if (!selectedVendor) return;
    const newSelectedVendor = appContext.currentProject.vendors.find(
      (vend) => vend.id == selectedVendor.id
    );
    if (newSelectedVendor) setSelectedVendor(newSelectedVendor);
  }, [appContext.currentProject.vendors]);

  const handleAddVendorsButtonClicked = () => {
    setIsAddVendorModalOpen(true);
  };

  const handleViewTabClicked = () => {
    // TODO: Add in tabbar clicking
  };

  const handleRowSelected = (rowIndex: number, row: Row) => {
    setSelectedVendor(row.rowData);
    setIsVendorBladeOpen(true);
  };

  const handleVendorEdit = (newValue: string, fieldToUpdate: string) => {
    const newSelectedVendor: any = { ...selectedVendor };
    if (fieldToUpdate === 'is_deactivated') {
      newSelectedVendor[fieldToUpdate] = newValue === 'true';
    } else {
      newSelectedVendor[fieldToUpdate] = newValue;
    }
    setSelectedVendor(newSelectedVendor);
  };

  const handleVendorUpdated = () => {
    if (!selectedVendor) return;
    const newVendorsList = [...projectVendors];
    setProjectVendors(
      newVendorsList.map((vend) => {
        if (vend.id == selectedVendor.id) return selectedVendor;
        return vend;
      })
    );
  };

  const vendorTableRows = sortVendors(
    applyVendorFilters(
      formatVendorsToTableRows(projectVendors, appContext.contracts),
      searchboxValue,
      activeFilters
    )
  );
  const handleVendorBladeClose = () => {
    setIsVendorBladeOpen(false);
    setSelectedVendor(null);
  };

  const handleAddVendorModalClose = () => {
    setIsAddVendorModalOpen(false);
    setNewVendor(getBlankVendor());
  };

  const handleNewVendorChanged = (newValue: string, fieldToUpdate: string) => {
    const newVendorState: any = { ...newVendor };
    newVendorState[fieldToUpdate] = newValue;
    setNewVendor(newVendorState);
  };

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const checkForInputErrors = () => {
    const newInputErrorsState = [];
    if (!isValidEmail(newVendor.point_of_contact)) {
      newInputErrorsState.push({
        inputId: 'new-vendor-poc-input',
        message: 'Invalid email',
      });
    }
    setInputErrors(newInputErrorsState);

    return newInputErrorsState.length > 0;
  };

  const handleNewVendorSubmitted = () => {
    const hasErrors = checkForInputErrors();
    if (hasErrors) return;

    (async () => {
      const response = await postVendor(
        appContext.currentProject.id,
        newVendor
      );
      if (response?.error_message) {
        showErrorMessageToast(response.error_message);
      } else {
        showSuccessToastMessage('Successfully created new vendor');
        appContext.refetchProjectData();
        // Wait for fade out animation to play on modal
        setTimeout(() => {
          handleAddVendorModalClose();
        }, 200);
      }
    })();
  };

  const getInputErrorMessage = (inputId: string) => {
    return inputErrors.find((inputError) => inputId === inputError.inputId)
      ?.message;
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  window.document.title = 'BidSight – Vendors';
  return (
    <section className="VendorsPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Vendors</h4>
          <h5>Add, view and manage vendors</h5>
        </div>
        <div className="header-action-button-containers">
          <Button
            label="Add vendors"
            onClick={handleAddVendorsButtonClicked}
            variant={ButtonVariant.PrimaryThin}
          />
        </div>
      </div>
      <div className="tab-bar-container">
        <TabBar
          tabOptions={tabOptions}
          activeTabOption={tabOptions[activeTabIndex]}
          onTabClick={handleViewTabClicked}
        />
      </div>
      <div className="search-filter-display-container">
        <div className="search-fitler-container">
          <div className="search-container">
            <TextInput
              placeholder="Search"
              icon={<MagnifyingGlassIcon />}
              onChange={(newValue: string) => setSearchboxValue(newValue)}
            />
          </div>
          <span className="filter-popunder-container">
            <span className="filter-button-container">
              <IconButton
                icon={<FilterIcon />}
                label={'Filters'}
                variant={IconButtonVariants.dotted}
                onClick={() =>
                  setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                }
              />
              <PopUnder
                isOpen={isFilterPopUnderVisible}
                onClose={() => setIsFilterPopUnderVisible(false)}
                optionsMap={vendorsMap}
                handleSecondOptionSelected={handleFilterSelected}
                activeFilters={activeFilters}
              />
            </span>
            {hasActiveFilters && (
              <span className="clear-filters-button-container">
                <IconButton
                  icon={<CloseIcon />}
                  label={'Clear filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() => setActiveFilters([])}
                />
              </span>
            )}
          </span>
        </div>
        <IconButton
          icon={<ExportIcon />}
          label={tableIsExporting ? 'Exporting...' : 'Export'}
          variant={IconButtonVariants.dotted}
          onClick={() => setTableIsExporting(true)}
        />
      </div>
      <div className="active-filters-container">
        {activeFilters.map((activeFilter) => (
          <FilterButton
            key={activeFilter.label}
            filter={activeFilter}
            onClick={handleFilterButtonRemoved}
          />
        ))}
      </div>
      <div className="bottom">
        <div className="table-container">
          <EditableTableV2
            columns={vendorColumns}
            rows={vendorTableRows}
            isReadOnly
            onTablerowClicked={(rowIndex: number, row: Row) =>
              handleRowSelected(rowIndex, row)
            }
            tableName={`${appContext.currentProject.name} Vendors - BidSight`}
            isExporting={tableIsExporting}
            setIsExporting={setTableIsExporting}
          />
        </div>
      </div>
      <VendorBlade
        onClose={handleVendorBladeClose}
        isOpen={isVendorBladeOpen}
        vendor={selectedVendor}
        onChange={handleVendorEdit}
        onChangeSubmitted={handleVendorUpdated}
        appContext={appContext}
      />
      <Modal
        onSecondaryButtonClicked={handleAddVendorModalClose}
        onClose={handleAddVendorModalClose}
        isOpen={isAddVendorModalOpen}
        primaryHeader="Add vendor"
        secondaryHeader="Add vendor details and location information"
        primaryButtonLabel="Add vendor"
        secondaryButtonType={ButtonVariant.GrayThin}
        primaryButtonType={ButtonVariant.PrimaryThin}
        size={ModalSizes.large}
        onPrimaryButtonClicked={handleNewVendorSubmitted}
      >
        <div className="vendors-page-modal-main-content">
          <h3 className="modal-sub-header">Details</h3>
          <div className="two-column-rwo">
            <div className="label-input-container">
              <label htmlFor="new-vendor-name-input">Name *</label>
              <TextInput
                id="new-vendor-name-input"
                value={newVendor.name}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'name')
                }
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="new-vendor-poc-input">Point of contact *</label>
              <TextInput
                id="new-vendor-poc-input"
                value={newVendor.point_of_contact}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'point_of_contact')
                }
                errorMessage={getInputErrorMessage('new-vendor-poc-input')}
              />
            </div>
          </div>
          <div className="two-column-rwo">
            <div className="label-input-container">
              <label htmlFor="new-vendor-vendor-id-input">Vendor ID</label>
              <TextInput
                id="new-vendor-vendor-id-input"
                value={newVendor.vendor_number}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'vendor_number')
                }
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="new-vendor-tax-id-input">Tax ID</label>
              <TextInput
                id="new-vendor-tax-id-input"
                value={newVendor.tax_id}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'tax_id')
                }
              />
            </div>
          </div>
          <div className="label-input-container">
            <label htmlFor="new-vendor-description-input">Description</label>
            <div className="text-area-container">
              <textarea
                id="new-vendor-description-input"
                value={newVendor.description}
                onChange={(event) => {
                  handleNewVendorChanged(event.target.value, 'description');
                }}
              />
            </div>
          </div>
          <h3 className="modal-sub-header">Location</h3>
          <div className="two-column-rwo">
            <div className="label-input-container">
              <label htmlFor="new-vendor-address-line-1-input">
                Address line 1
              </label>
              <TextInput
                id="new-vendor-address-line-1-input"
                value={newVendor.address_line_1}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'address_line_1')
                }
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="new-vendor-address-line-2-input">
                Address line 2
              </label>
              <TextInput
                id="new-vendor-address-line-2-input"
                value={newVendor.address_line_2 ?? ''}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'address_line_2')
                }
              />
            </div>
          </div>
          <div className="three-item-row">
            <div className="label-input-container">
              <label htmlFor="new-vendor-city-input">City</label>
              <TextInput
                id="new-vendor-city-input"
                value={newVendor?.city}
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'city')
                }
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="new-vendor-state-input">State</label>
              <StateDropdown
                id="new-vendor-state-inputs"
                onChange={(newValue: string) =>
                  handleNewVendorChanged(newValue, 'state')
                }
                value={newVendor?.state ?? ''}
              />
            </div>
            <div className="label-input-container">
              <label htmlFor="new-vendor-zipcode-input">Zip</label>
              <TextInput
                id="new-vendor-zipcode-input"
                value={newVendor?.zip_code}
                onlyAllowNumbers
                onChange={(newValue: string) => {
                  if (newValue.length <= 5) {
                    handleNewVendorChanged(newValue, 'zip_code');
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default VendorsPage;
