/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react';
import './TextInput.scss';

interface Props {
  placeholder?: string;
  icon?: React.ReactNode;
  onChange?: (inputString: string) => void;
  id?: string;
  errorMessage?: string;
  value?: string;
  onlyAllowNumbers?: boolean;
  isDisabled?: boolean;
  styledForEditableTable?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isPassword?: boolean;
  isPercentage?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const TextInput: React.FC<Props> = ({
  placeholder,
  icon,
  onChange,
  id,
  errorMessage,
  value,
  onlyAllowNumbers = false,
  isDisabled = false,
  styledForEditableTable = false,
  onKeyDown = (_) => {},
  isPassword = false,
  className = '',
  onClick = () => null,
  isPercentage = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (onlyAllowNumbers) {
      // Remove non-numeric characters if onlyAllowNumbers is true
      inputValue = inputValue.replace(/[^-0-9]/g, '');
    }

    if (onChange) {
      onChange(inputValue);
    }
  };

  if (isPercentage) {
    value = value + '%';
  }

  return (
    <div
      className={`TextInput ${errorMessage ? 'error' : ''}`}
      onClick={onClick}
    >
      <div
        className={`text-input-wrapper ${
          styledForEditableTable ? 'editable-table-input-wrapper' : ''
        }`}
        onClick={onClick}
      >
        {icon && (
          <span className="text-icon" onClick={handleIconClick}>
            {icon}
          </span>
        )}
        <input
          id={id}
          type={isPassword ? 'password' : 'text'}
          className={`text-input ${className}`}
          placeholder={placeholder}
          ref={inputRef}
          onChange={handleInputChange}
          value={value}
          disabled={isDisabled}
          onKeyDown={onKeyDown}
        />
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default TextInput;
