import React, { useState, useCallback, memo } from 'react';
import './DropdownRow.scss';
import { Row, TableCell, Column, CellType, RowType } from '../EditableTableV2';
import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import CostCodeSelectorInput from '../../CostCodeSelectorInput/CostCodeSelectorInput';
import { CostCode } from '../../../types/sharedTypes';
import { getEntireCostCodeObjectByCostCodeId } from '../../../utils/getEntireCostCodeObjectByCostCodeId';
import CurrencyInput from '../../CurrencyInput/CurrencyInput';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import { formatToUSD } from '../../../utils/currency/formatToUSD';
import TextInput from '../../TextInput/TextInput';
import { GoPlus as PlusIcon } from 'react-icons/go';
import Checkbox from '../../CheckBox/Checkbox';

interface Props {
  row: Row;
  columns: Column[];
  rowIndex: number;
  isReadOnly?: boolean;
  onTablerowClicked: (rowIndex: number, row: Row) => void;
  handleTableCellChanged: (
    newValue: string,
    rowIndex: number,
    columnIndex: number,
    columnName?: string,
    row?: Row,
    parentRowIndex?: number
  ) => void;
  onTableCellClicked?: (
    event: React.MouseEvent,
    cellID?: string,
    row?: Row
  ) => void;
  onTableRowDeleteClicked?: (event: React.MouseEvent, row: Row) => void;
  className?: string;
  handleAddNewLineItemInLine?: (newLineItem: any, row: Row) => void;
  onAddRowClicked?: (row: Row) => void;
  useFixedPositionOnCostCodeSelector?: boolean;
  isLastChild?: boolean;
  parentLastChildMap?: boolean[];
  tableID: string;
  onCheckboxSelected?: (row: Row) => void;
}

const DropdownRow: React.FC<Props> = memo(
  ({
    row,
    rowIndex,
    isReadOnly,
    columns,
    onTablerowClicked,
    handleTableCellChanged,
    onTableCellClicked = () => null,
    onTableRowDeleteClicked,
    handleAddNewLineItemInLine,
    onAddRowClicked,
    className,
    useFixedPositionOnCostCodeSelector = false,
    isLastChild = false,
    parentLastChildMap = [],
    tableID,
    onCheckboxSelected = () => null,
  }: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    let showChevron = row.type === RowType.parent;
    if (row.children) {
      showChevron = row.children.length > 0;
    }

    // Add other icons to this for copy, export, etc. for now
    // delete is the only action we need
    const showDeleteIcon = Boolean(onTableRowDeleteClicked);
    // e.g. showActionsColumn && showExportIcon &&....
    const showActionsColumn = showDeleteIcon;

    const isGrandTotalRow = row.type === RowType.grandTotal;
    const isNewRow = row.type === RowType.new;
    const rowHasChildren = row.children && row.children.length > 0;

    const handleNewRowCostCodeSelected = useCallback(
      (selectedCostCode: CostCode, row: Row) => {
        if (!handleAddNewLineItemInLine) {
          return console.warn('handleAddNewLineItemInLine is undeclared');
        }
        handleAddNewLineItemInLine(selectedCostCode, row);
      },
      [handleAddNewLineItemInLine]
    );

    const handleAddRowIconClicked = useCallback(
      (event: React.MouseEvent, row: Row) => {
        event.stopPropagation();
        setIsVisible(true);

        if (onAddRowClicked) {
          onAddRowClicked(row);
        }
      },
      [onAddRowClicked]
    );

    const handleDropdownChevronClicked = useCallback(
      (event: React.MouseEvent) => {
        setIsVisible(!isVisible);
        // event.stopPropagation();
      },
      [showChevron, isVisible]
    );

    const handleRowClicked = useCallback(
      (rowIndex: number, row: Row) => {
        if (isNewRow || row.isClickingDisabled) {
          return;
        } else {
          onTablerowClicked(rowIndex, row);
        }
      },
      [isNewRow, onTablerowClicked]
    );

    const handleTableCellClicked = useCallback(
      (event: React.MouseEvent, tableCellID: string, row: Row) => {
        if (isNewRow || row.isClickingDisabled) {
          return;
        } else {
          onTableCellClicked(event, tableCellID, row);
        }
      },
      [isNewRow, onTableCellClicked]
    );

    return (
      <>
        <tr
          key={`row-${row.id}`}
          onClick={() => handleRowClicked(rowIndex, row)}
          className={className}
        >
          {showActionsColumn && (
            <td
              className={`actions-column-cell ${
                isGrandTotalRow ? 'invisible' : ''
              }`}
            >
              <span
                style={{
                  marginLeft: `${(row?.indent ?? 0) * 2 + 0.3}rem `,
                }}
              >
                {showDeleteIcon && (
                  <button
                    className="action-button trash-can"
                    onClick={(event: React.MouseEvent) =>
                      onTableRowDeleteClicked &&
                      onTableRowDeleteClicked(event, row)
                    }
                  >
                    <TrashCanIcon />
                  </button>
                )}
              </span>
            </td>
          )}
          {row.cells.map((tableCell: TableCell, columnIndex: number) => {
            if (tableCell.type === CellType.dropdown) {
              return (
                <td
                  className="select-container"
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                >
                  <CostCodeSelectorInput
                    isFullWidth
                    isDisabled={isReadOnly}
                    all_cost_codes={tableCell.dropdownOptions ?? []}
                    value={getEntireCostCodeObjectByCostCodeId(
                      String(tableCell.value),
                      tableCell.dropdownOptions ?? []
                    )}
                    onCostCodeSelect={(selectedCostCode: CostCode) =>
                      handleTableCellChanged(
                        selectedCostCode.id,
                        rowIndex,
                        columnIndex,
                        columns[columnIndex].value ?? '',
                        row
                      )
                    }
                    useFixedPosition={useFixedPositionOnCostCodeSelector}
                    tableID={tableID}
                  />
                </td>
              );
            } else if (tableCell.type === CellType.checkboxSelect) {
              return (
                <td
                  className="checkbox-select-container"
                  id={`checkbox-select-${row.id}`}
                  key={`checkbox-select-${row.id}`}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(
                      event,
                      `checkbox-select-${row.id}`,
                      row
                    );
                  }}
                >
                  <Checkbox
                    isChecked={tableCell.value === 'true'}
                    onClicked={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      onCheckboxSelected(row);
                    }}
                  />
                </td>
              );
            } else if (tableCell.type === CellType.status) {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                >
                  <p
                    className={`status-chip-${tableCell.value}`}
                    onClick={(event: React.MouseEvent) => {
                      handleRowClicked(rowIndex, row);
                      handleTableCellClicked(event, tableCell.id ?? '', row);
                    }}
                  >
                    {tableCell.value}
                  </p>
                </td>
              );
            } else if (tableCell.type === CellType.costCodeDropDown) {
              return (
                <td
                  className="cost-code-selector-td"
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  onClick={(event: React.MouseEvent) => {
                    if (isNewRow || !rowHasChildren) {
                      return;
                    } else {
                      // handleRowClicked(rowIndex, row);
                      // handleTableCellClicked(event, tableCell.id ?? '', row);
                    }
                  }}
                  id={tableCell.id}
                >
                  <div className={`cost-code-dropdown-cell`}>
                    <span
                      style={{
                        marginLeft: `${(row?.indent ?? 0) * 1 + 0.5}rem`,
                      }}
                      className="chevron-icon-container"
                    >
                      {isNewRow ? (
                        <div className="blue-circle" />
                      ) : (
                        <ChevronDownIcon
                          onClick={handleDropdownChevronClicked}
                          className={`chevron-icon ${
                            isVisible ? 'rotate-down' : ''
                          } ${showChevron ? '' : 'invisible'}`}
                        />
                      )}
                    </span>
                    {isNewRow ||
                    (!rowHasChildren &&
                      row.type !== RowType.parent &&
                      !isReadOnly) ? (
                      <div className="new-cost-code-selector-container">
                        <CostCodeSelectorInput
                          isFullWidth
                          all_cost_codes={tableCell.dropdownOptions ?? []}
                          value={getEntireCostCodeObjectByCostCodeId(
                            row.rowData?.cost_code?.id ?? '',
                            tableCell.dropdownOptions ?? []
                          )}
                          onCostCodeSelect={(selectedCostCode: CostCode) => {
                            handleNewRowCostCodeSelected(selectedCostCode, row);
                          }}
                          disableParentSelection
                          defaultParent={tableCell.parentCostCode}
                          onlyShowAvailableCostCodes
                          usedCostCodes={tableCell.usedCostCodes}
                          openFromBottomInTable
                          useFixedPosition={useFixedPositionOnCostCodeSelector}
                          tableID={tableID}
                        />
                      </div>
                    ) : (
                      <p
                        onClick={(event: React.MouseEvent) => {
                          handleRowClicked(rowIndex, row);
                          handleTableCellClicked(
                            event,
                            tableCell.id ?? '',
                            row
                          );
                        }}
                      >
                        {tableCell.value}
                      </p>
                    )}
                    {!isNewRow && !isReadOnly && (
                      <button
                        className="add-row-button"
                        onClick={(event: React.MouseEvent) =>
                          handleAddRowIconClicked(event, row)
                        }
                        disabled={Boolean(row.isAddRowButtonDisabled)}
                      >
                        <PlusIcon className="add-row-icon" />
                      </button>
                    )}
                  </div>
                </td>
              );
            } else if (tableCell.type === CellType.percentage) {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                  className={`percentage-table-cell ${tableCell.className}`}
                >
                  <p
                    onClick={(event: React.MouseEvent) => {
                      handleRowClicked(rowIndex, row);
                      handleTableCellClicked(event, tableCell.id ?? '', row);
                    }}
                  >
                    {tableCell.value}%
                  </p>
                </td>
              );
            } else if (tableCell.type === CellType.displayText) {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                  className={`display-text-table-cell ${tableCell.className}`}
                >
                  <p
                    onClick={(event: React.MouseEvent) => {
                      handleRowClicked(rowIndex, row);
                      handleTableCellClicked(event, tableCell.id ?? '', row);
                    }}
                  >
                    {tableCell.value}
                  </p>
                </td>
              );
            } else if (tableCell.type === CellType.dollars) {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    if (tableCell.isCellDisabled || isReadOnly) {
                      handleRowClicked(rowIndex, row);
                      handleTableCellClicked(event, tableCell.id ?? '', row);
                    } else if (isNewRow) {
                      event.stopPropagation();
                    }
                  }}
                  className={`currency-table-cell ${tableCell.className}`}
                >
                  {tableCell.isCellDisabled || isReadOnly ? (
                    <p className="currency-table-cell-value">
                      {formatToUSD(String(tableCell.value))}
                    </p>
                  ) : (
                    <CurrencyInput
                      inputprops={{
                        value: tableCell.value,
                        disabled: tableCell.isCellDisabled || isReadOnly,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleTableCellChanged(
                            event.target.value,
                            rowIndex,
                            columnIndex,
                            columns[columnIndex].value ?? '',
                            row
                          );
                        },
                      }}
                    />
                  )}
                </td>
              );
            } else if (tableCell.type === CellType.editableText) {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                  className={`editable-text-table-cell ${tableCell.className}`}
                >
                  <TextInput
                    onChange={(newValue: string) => {
                      handleTableCellChanged(
                        newValue,
                        rowIndex,
                        columnIndex,
                        columns[columnIndex].value ?? '',
                        row
                      );
                    }}
                    value={String(tableCell.value)}
                    isDisabled={tableCell.isCellDisabled || isReadOnly}
                  />
                </td>
              );
            } else {
              return (
                <td
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  id={tableCell.id}
                  onClick={(event: React.MouseEvent) => {
                    handleRowClicked(rowIndex, row);
                    handleTableCellClicked(event, tableCell.id ?? '', row);
                  }}
                  className={`display-text-table-cell ${tableCell.className}`}
                >
                  <p
                    onClick={(event: React.MouseEvent) => {
                      handleRowClicked(rowIndex, row);
                      handleTableCellClicked(event, tableCell.id ?? '', row);
                    }}
                  >
                    {tableCell.value}
                  </p>
                </td>
              );
            }
          })}
          {row?.indent !== 0 &&
            Array.from({ length: row?.indent ?? 0 }).map((_, index) => {
              const isParentLastChild = parentLastChildMap[index + 1];

              if (isParentLastChild) return null;
              if (isLastChild && row.indent && row.indent - 1 === index) {
                return (
                  <div
                    key={index}
                    className="line-curve"
                    style={{
                      left: `${row?.indent && index + 1.65}rem`,
                    }}
                  />
                );
              } else {
                return (
                  <div
                    key={index}
                    className="line"
                    style={{
                      left: `${
                        row?.indent && row.indent !== 0 ? index + 1.65 : 0
                      }rem`,
                    }}
                  />
                );
              }
            })}
        </tr>
        {isVisible &&
          row?.children?.map((child: Row, childIndex: number) => {
            return (
              <DropdownRow
                key={child.id}
                onTablerowClicked={onTablerowClicked}
                row={child}
                rowIndex={childIndex}
                handleTableCellChanged={handleTableCellChanged}
                columns={columns}
                onTableCellClicked={onTableCellClicked}
                onTableRowDeleteClicked={onTableRowDeleteClicked}
                isReadOnly={isReadOnly}
                handleAddNewLineItemInLine={handleAddNewLineItemInLine}
                onAddRowClicked={onAddRowClicked}
                useFixedPositionOnCostCodeSelector={
                  useFixedPositionOnCostCodeSelector
                }
                isLastChild={
                  (row?.children && row.children.length - 1 === childIndex) ??
                  false
                }
                parentLastChildMap={[...parentLastChildMap, isLastChild]}
                tableID={tableID}
              />
            );
          })}
      </>
    );
  }
);

DropdownRow.displayName = 'DropdownRow';

export default DropdownRow;
