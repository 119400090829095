import { CostCode } from '../types/sharedTypes';

export const getEntireCostCodeObjectByDescription = (
  costCodes: CostCode[],
  targetDescription: string
): CostCode | null => {
  for (const costCode of costCodes) {
    if (costCode.description === targetDescription) {
      return costCode;
    }

    if (costCode.children && costCode.children.length > 0) {
      const foundInChildren = getEntireCostCodeObjectByDescription(
        costCode.children,
        targetDescription
      );
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  return null;
};
