import { Invoice } from '../../types/invoiceTypes';

export const filtersToInvoiceDataMap: Record<string, keyof Invoice> = {
  ID: 'number',
  Name: 'name',
  Vendor: 'vendor',
  Number: 'number_from_vendor',
  Status: 'status',
  'Draw Package': 'draw_package',
  Contract: 'contract',
  'Draw Status': 'draw_package',
  'Contract Status': 'contract',
};
