import { Statuses, Vendor } from '../../types/sharedTypes';
import { Row, CellType } from '../../components/EditableTable/EditableTableV2';
import { getTotalContractAmountForVendor } from '../contract/getTotalContractAmountForVendor';
import { Contract } from '../../types/contractTypes';
import { getTotalNumberOfContractsForVendor } from '../contract/getTotalNumberOfContractsForVendor';

export const formatVendorsToTableRows = (
  vendors: Vendor[],
  contracts: Contract[]
): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < vendors.length; i++) {
    const currentVendor = vendors[i];
    const currentRow: Row = {
      cells: [],
      id: currentVendor.id,
      rowData: currentVendor,
    };

    // Name
    currentRow.cells.push({
      value: currentVendor.name ?? '-',
      type: CellType.displayText,
      isCellDisabled: true,
    });

    // Status
    currentRow.cells.push({
      value: currentVendor.is_deactivated
        ? Statuses.Deactivated
        : Statuses.Active,
      type: CellType.status,
      isCellDisabled: true,
    });

    // Point of Contact
    currentRow.cells.push({
      value: currentVendor?.point_of_contact ?? '-',
      type: CellType.displayText,
      isCellDisabled: true,
    });

    // Contracted Amount
    const contractedAmount = getTotalContractAmountForVendor(
      currentVendor.id,
      contracts
    ).toFixed(2);
    currentRow.cells.push({
      value: contractedAmount,
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Contract Count
    const contractCount = getTotalNumberOfContractsForVendor(
      currentVendor.id,
      contracts
    ).toString();
    currentRow.cells.push({
      value: contractCount,
      type: CellType.number,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
