import React, { ReactNode } from 'react';
import './Card.scss';

interface Props {
  children?: ReactNode;
  isFullHeight?: boolean;
  disableScroll?: boolean;
  showOverflow?: boolean;
  className?: string;
}

const Card: React.FC<Props> = ({
  children,
  isFullHeight = false,
  disableScroll = false,
  showOverflow = false,
  className = '',
}) => {
  return (
    <div
      className={`Card ${className}`}
      style={{
        height: `${isFullHeight ? '100%' : ''}`,
        overflow: `${
          disableScroll ? 'hidden' : showOverflow ? 'visible' : 'auto'
        }`,
      }}
    >
      {children}
    </div>
  );
};

export default Card;
