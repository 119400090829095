import { Invoice, InvoiceLineItem } from '../../types/invoiceTypes';
import { v4 as uuidv4 } from 'uuid';
import { CostCode } from '../../types/sharedTypes';
import { convertUTCDateStringToDate } from '../convertUTCDateStringToDate';

export const getInitialInvoiceLineItemWithCalculations = (
  invoice: Invoice | null,
  contractID: string | null,
  costCode: CostCode,
  allInvoices: Invoice[],
  currentBilling: number,
  scheduled_value: number
): InvoiceLineItem => {
  const prevLineItems: InvoiceLineItem[] = [];
  const allInvoicesSorted = allInvoices.sort((a, b) => {
    return convertUTCDateStringToDate(a.date_utc) <
      convertUTCDateStringToDate(b.date_utc)
      ? -1
      : 1;
  });

  for (const prevInvoice of allInvoicesSorted) {
    for (const prevLineItem of prevInvoice.line_items) {
      const prevLineItemInvoiceDate = convertUTCDateStringToDate(
        prevInvoice.date_utc
      );
      const invoiceDate = invoice?.date_utc
        ? convertUTCDateStringToDate(invoice.date_utc)
        : new Date();

      if (
        prevLineItem.invoice_id != invoice?.id &&
        prevLineItem.cost_code?.id == costCode.id &&
        prevLineItemInvoiceDate < invoiceDate &&
        !!contractID &&
        contractID == prevInvoice.contract_id
      ) {
        prevLineItems.push(prevLineItem);
      }
    }
  }

  const previousRetainage =
    prevLineItems.length > 0
      ? prevLineItems[prevLineItems.length - 1].retainage
      : 0.0;
  const fromPreviousApplications = prevLineItems.reduce((acc, li) => {
    return acc + (li.this_period ?? 0) + (li.materials_presently_stored ?? 0);
  }, 0);

  return {
    // These fields are decided by the user
    cost_code: costCode,
    cost_code_id: costCode.id,
    current_billing: currentBilling, // may have preset value
    materials_presently_stored: 0,
    retainage: 0,
    this_period: 0,

    // These fields are calculated
    previous_retainage: previousRetainage,
    current_retainage: prevLineItems.length > 0 ? -1 * previousRetainage : 0.0,
    from_previous_applications: fromPreviousApplications,
    previously_paid: prevLineItems.reduce((acc, li) => {
      const isInvoicePaid =
        allInvoices.find((invoice) => invoice.id === li.invoice_id)?.status ===
        'Paid';
      const currentBilling =
        typeof li.current_billing === 'string'
          ? parseFloat(li.current_billing)
          : li.current_billing;
      return isInvoicePaid ? acc + currentBilling : acc;
    }, 0),
    total_completed_and_stored_to_date: fromPreviousApplications,
    scheduled_value: scheduled_value,

    // These fields are calulcated but start at zero
    current_work_and_materials: 0,

    // These fields are just to mimic an actual line items
    id: uuidv4(),
    created_on_utc: new Date().toDateString(),
    is_deleted: false,
    unit_cost: null,
    unit_of_measurement: '',
    quantity: null,
    is_pending: false,
  };
};
