import React, { useRef } from 'react';
import './CustomDatePicker.scss';
import { createTomorrowdate } from '../../utils/createTomorrowDate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiOutlineCalendar as CalendarIcon } from 'react-icons/ai';

interface Props {
  minDate?: Date;
  selectedDate: Date;
  handleDateChange?: (newDate: Date) => void;
  id?: string;
  isDisabled?: boolean;
}

const CustomDatePicker: React.FC<Props> = ({
  minDate,
  selectedDate,
  handleDateChange = () => null,
  id,
  isDisabled = false,
}) => {
  const datepickerRef = useRef<DatePicker | null>(null);

  const handleCalendarIconClicked = () => {
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(true);
    }
  };

  return (
    <div className="CustomDatePicker">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="Select a date"
        className="date-picker"
        ref={datepickerRef}
        id={id ?? 'react-date-picker'}
        minDate={minDate}
        disabled={isDisabled}
      />
      {isDisabled ? null : (
        <CalendarIcon
          className="calendar-icon"
          onClick={handleCalendarIconClicked}
        />
      )}
    </div>
  );
};

export default CustomDatePicker;
