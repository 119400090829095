import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { invoiceRoute } from './getInvoices';

export const postExportInvoicesForERP = async (invoiceIDs: string[]) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + invoiceRoute + '/export_for_erp';

  const formattedInvoiceIDs = invoiceIDs.join(',');

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      invoice_ids: formattedInvoiceIDs,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
