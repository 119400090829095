import { CostCode } from '../types/sharedTypes';

export const getCostCodeDescriptionByCode = (
  costCodes: CostCode[],
  codeToFind: string
) => {
  const findCostCodeDescription = (codes: CostCode[]): string | null => {
    for (const costCode of codes) {
      if (costCode.id === codeToFind) {
        return costCode.description;
      }
      if (costCode.children.length > 0) {
        const childDescription = findCostCodeDescription(costCode.children);
        if (childDescription) {
          return childDescription;
        }
      }
    }
    return null;
  };

  const matchingDescription = findCostCodeDescription(costCodes);
  return matchingDescription || '-';
};
