import { BudgetLineItem } from '../../types/budgetTypes';
import { sortBudgetLineItemsByCostCodeCode } from './sortBudgetLineItemsByCostCodeCode';

export const findAndReplaceBudgetLineItem = (
  budgetLineItems: BudgetLineItem[],
  newItem: BudgetLineItem
): BudgetLineItem[] => {
  const replaceItem = (items: BudgetLineItem[]): BudgetLineItem[] => {
    if (!items) {
      return [];
    }

    return items.map((item) => {
      if (item.id === newItem.id) {
        // If the item is found, replace it with the new item
        return newItem;
      }

      // Recursively replace the children
      return { ...item, children: replaceItem(item.children || []) };
    });
  };

  const replacedItems = replaceItem([...budgetLineItems]);

  // Sort the entire array after replacement
  return sortBudgetLineItemsByCostCodeCode(replacedItems);
};
