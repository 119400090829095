import { ChangeOrder } from '../../types/changeOrderTypes';

export const filtersToChangeOrdersDataValueMap: Record<
  string,
  keyof ChangeOrder
> = {
  ID: 'number',
  Title: 'title',
  Vendor: 'vendor',
  Priority: 'priority',
  Status: 'status',
  Contract: 'contract_title',
};
