import { Statuses, Vendor } from '../../types/sharedTypes';

export function formatVendorFilters(vendors: Vendor[]): {
  Name: string[];
  Status: string[];
} {
  const nameSet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();

  vendors.forEach((vendor: Vendor) => {
    let name = vendor?.name;
    if (!name) {
      name = 'None';
    }
    nameSet.add(name);

    const status = vendor.is_deactivated
      ? Statuses.Deactivated
      : Statuses.Active;

    statusSet.add(status);
  });

  const nameArray: string[] = Array.from(nameSet);
  const statusArray: string[] = Array.from(statusSet);

  return {
    Name: nameArray,
    Status: statusArray,
  };
}
