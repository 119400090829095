export const getBudgetColumnKeyFromDisplayValue = (displayValue?: string) => {
  if (!displayValue) return;
  const budgetDataToBackendMap: any = {
    'Original Amount': 'total_amount',
    Modifications: 'modification_amount',
    "Approved CO's": 'approved_changes',
    'Approved Budget': 'total_approved_budget',
    'Pending Changes': 'pending_changes',
    'Project Budget': 'total_projected_budget',
    'Spent to Date': 'spent_to_date',
    'Current Draw': 'current_draw',
    'Remaining Spend': 'approved_budget_remaining_to_spend',
    'Percent Complete': 'approved_budget_percent_complete',
    'Line Item': 'description',
    'Modification Amount': 'modification_amount',
    'Approved Amount': 'total_approved_budget',
    'Projected Budget': 'total_projected_budget',
    'Variance to Approved': 'projected_variance_to_approved_budget',
    'Variance to Projected': 'projected_variance_to_base_budget',
    'Requested to Date': 'requested_to_date',
    'Remaining Approved Spend': 'approved_budget_remaining_to_spend',
    'Remaining Projected Spend': 'projected_budget_remaining_to_spend',
    Vendors: 'buyout_vendor',
    'Bought Scope': 'buyout_bought',
    'Unbought Scope': 'buyout_unbought',
    Description: 'description',
    'Total Amount': 'total_amount',
    'Committed Costs': 'buyout_bought',
  };

  return budgetDataToBackendMap[displayValue];
};
