/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './JoinPage.scss';
import TextInput from '../TextInput/TextInput';
import Button, { ButtonVariant } from '../Button/Button';
import { toast } from 'react-toastify';
import { getProjectInvite } from '../../services/project/getProjectInvite';
import { postAcceptInvite } from '../../services/team/postAcceptInvite';

const JoinPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [inviteCode, setInviteCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);

  useEffect(() => {
    const inviteCode = searchParams.get('invite_code');
    if (!inviteCode) {
      window.location.href = '/login';
      return;
    }
    setInviteCode(inviteCode);

    (async () => {
      const invite = await getProjectInvite(inviteCode);
      if (invite.error_message) {
        window.location.href = '/login';
      } else {
        setEmail(invite.email);
      }
    })();
  }, []);

  const handleContinueButtonClicked = () => {
    if (!email || !firstName || !lastName || !password || !confirmPassword)
      return;
    if (password !== confirmPassword) {
      showErrorMessageToast('Passwords do not match');
      return;
    }
    setIsLoading(true);
    (async () => {
      const newUser = await postAcceptInvite(
        email,
        password,
        firstName,
        lastName,
        inviteCode
      );

      if (newUser.error_type) {
        showErrorMessageToast(newUser.error_message);
        setIsLoading(false);
        return;
      }

      localStorage.setItem('active_user_auth_token', newUser.auth_token);
      window.location.href = '/overview';
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleContinueButtonClicked();
    }
  };

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  window.document.title = 'BidSight – Join';
  return (
    <div className="JoinPage">
      <div className="header-container">
        <img src="https://storage.googleapis.com/public-v1.bidsight.io/BidSightTextLogo.png" />
      </div>
      <div className="body-container">
        <div className="join-form-container">
          <h1>Join</h1>
          <div className="join-form-input">
            <TextInput
              placeholder="Email address"
              onChange={(newEmail: string) => setEmail(newEmail)}
              value={email}
              onKeyDown={handleKeyDown}
              isDisabled={true}
            />
          </div>
          <div className="join-form-input">
            <TextInput
              placeholder="First name"
              onChange={(newFirstName: string) => setFirstName(newFirstName)}
              value={firstName}
              onKeyDown={handleKeyDown}
              isDisabled={disableAll}
            />
          </div>
          <div className="join-form-input">
            <TextInput
              placeholder="Last name"
              onChange={(newLastName: string) => setLastName(newLastName)}
              value={lastName}
              onKeyDown={handleKeyDown}
              isDisabled={disableAll}
            />
          </div>
          <div className="join-form-input">
            <TextInput
              placeholder="Password"
              onChange={(newPassword) => setPassword(newPassword)}
              value={password}
              onKeyDown={handleKeyDown}
              isDisabled={disableAll}
              isPassword
            />
          </div>
          <div className="join-form-input">
            <TextInput
              placeholder="Confirm password"
              onChange={(newConfirmPassword) =>
                setConfirmPassword(newConfirmPassword)
              }
              value={confirmPassword}
              onKeyDown={handleKeyDown}
              isDisabled={disableAll}
              isPassword
            />
          </div>
          <div className="join-form-submit">
            <Button
              label={isLoading ? 'Loading...' : 'Continue'}
              variant={ButtonVariant.GrayThin}
              onClick={handleContinueButtonClicked}
              isDisabled={
                !email ||
                !firstName ||
                !lastName ||
                !password ||
                !confirmPassword ||
                isLoading ||
                disableAll
              }
            />
          </div>
          <p className="terms-and-privacy">
            By clicking "Continue" above, you acknowledge that you have read and
            understood, and agree to BidSight's{' '}
            <a
              href="https://storage.googleapis.com/public-v1.bidsight.io/BidSight%20-%20Web%20User%20Terms%20of%20Service.pdf"
              target="_blank"
            >
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a
              href="https://storage.googleapis.com/public-v1.bidsight.io/BidSight%20-%20Web%20User%20Privacy%20Policy.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinPage;
