import { CellType, Row } from '../components/EditableTable/EditableTableV2';
import { ChangeOrder } from '../types/contractTypes';
import { formatDateMMDDYYYY } from './formatDateMMDDYYYY';

export const formatContractChangeOrdersForEditableTable = (
  changeOrders: ChangeOrder[]
): Row[] => {
  const result: Row[] = [];

  if (!changeOrders || changeOrders.length === 0) return result;

  for (let i = 0; i < changeOrders.length; i++) {
    const currentChangeOrder = changeOrders[i];
    const currentRow: Row = {
      cells: [],
      rowData: currentChangeOrder,
      id: currentChangeOrder.id,
    };

    // ID
    currentRow.cells.push({
      value: currentChangeOrder.number,
      type: CellType.number,
    });

    // Title
    currentRow.cells.push({
      value: currentChangeOrder.title,
      type: CellType.text,
    });

    // Total amount
    currentRow.cells.push({
      value: currentChangeOrder.total_amount.toFixed(2),
      type: CellType.dollars,
    });

    // Status
    currentRow.cells.push({
      value: currentChangeOrder.status,
      type: CellType.status,
    });

    // Date
    currentRow.cells.push({
      value: formatDateMMDDYYYY(currentChangeOrder.date_utc),
      type: CellType.text,
    });

    result.push(currentRow);
  }

  return result;
};
