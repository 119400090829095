import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { RawContractLineItem } from '../../types/contractTypes';

export const formatContractLineItemsForChangeOrderEditableTable = (
  contractLineItems: RawContractLineItem[]
): Row[] => {
  const result: Row[] = [];

  if (!contractLineItems || contractLineItems?.length === 0) {
    return result;
  }

  for (let i = 0; i < contractLineItems.length; i++) {
    const currentContractLineItem: RawContractLineItem = contractLineItems[i];
    const currentRow: Row = {
      cells: [],
      id: currentContractLineItem.id,
      rowData: currentContractLineItem,
    };

    // Cost Code
    currentRow.cells.push({
      value: currentContractLineItem.cost_code.code,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Description
    currentRow.cells.push({
      value: currentContractLineItem.cost_code.description,
      type: CellType.text,
      isCellDisabled: true,
    });

    // Original Contract Amount
    currentRow.cells.push({
      value: currentContractLineItem.total_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Requested Changes
    currentRow.cells.push({
      value: '0.00',
      type: CellType.dollars,
    });

    // Budgeted Changes
    currentRow.cells.push({
      value: '0.00',
      type: CellType.dollars,
    });

    // Revised Contract Amount
    currentRow.cells.push({
      value: currentContractLineItem.total_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Variance from Contract
    currentRow.cells.push({
      value: '0.00',
      type: CellType.dollars,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
