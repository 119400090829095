export const removeCharactersExceptForPeriodAndNumbersFromFloatString = (
  usdString: string
): string => {
  // Remove all non-numeric characters except for the period (.) and minus sign (-)
  const numericString = usdString.replace(/[^0-9.-]/g, '');

  // Check if the string starts with a minus sign
  if (numericString.startsWith('-')) {
    // If it does, remove any additional minus signs
    const withoutExtraMinus = numericString.replace(/-/g, '');

    // Add a minus sign in front of the formatted number
    const formattedFloat = `-${Number(withoutExtraMinus).toFixed(2)}`;

    return formattedFloat;
  } else {
    // If there is no minus sign, simply format the number with two decimal places
    const float = Number(numericString).toFixed(2);

    return float;
  }
};
