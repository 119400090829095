import React from 'react';
import './DrawerNav.scss';
import routes from '../AppRouter/routes';
import DrawerNavLink from './DrawerNavLink/DrawerNavLink';
import {
  TbArrowsRight as ArrowsIcon,
  TbDoorExit as ExitIcon,
} from 'react-icons/tb';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { Project } from '../../types/projectTypes';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppContext } from '../../types/appContextTypes';
import { FaRegUserCircle as UserOutline } from 'react-icons/fa';

interface Props {
  isOpen: boolean;
  onDrawerToggleClick: () => void;
  hidden: boolean;
  appContext: AppContext | null;
}

const DrawerNav: React.FC<Props> = ({
  isOpen,
  onDrawerToggleClick,
  hidden,
  appContext,
}) => {
  const handleProjectChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!event.target.value) return;
    localStorage.setItem('active_project_id', event.target.value);
    window.location.reload();
  };

  const handleSignOut = () => {
    localStorage.removeItem('active_user_auth_token');
    localStorage.removeItem('active_project_id');
    window.location.href = routes.Login.path;
  };

  if (hidden) return null;

  return (
    <nav className={`DrawerNav ${isOpen ? 'open' : 'hide-text'}`}>
      <div className="top-part-nav">
        <div className="logo-bidsight-container">
          <Logo className="logo" />
          <h1 className={`${isOpen ? '' : 'hide-text'}`}>BidSight</h1>
        </div>
        <div className="project-selector-container">
          {appContext ? (
            <select
              className={`${isOpen ? '' : 'no-display'}`}
              onChange={handleProjectChanged}
            >
              {appContext.allProjects.map((project: Project) => {
                return (
                  <option
                    value={project.id}
                    key={project.id}
                    selected={project.id === appContext.currentProject.id}
                  >
                    {project.name}
                  </option>
                );
              })}
            </select>
          ) : (
            <Skeleton height={36} />
          )}
        </div>
        <div className="links-container">
          <DrawerNavLink
            to={routes.Overview.linkTo()}
            icon={routes.Overview.icon}
            displayText={routes.Overview.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.MyInbox.linkTo()}
            icon={routes.MyInbox.icon}
            displayText={routes.MyInbox.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.Budget.linkTo()}
            icon={routes.Budget.icon}
            displayText={routes.Budget.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.Contracts.linkTo()}
            icon={routes.Contracts.icon}
            displayText={routes.Contracts.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.ChangeOrders.linkTo()}
            icon={routes.ChangeOrders.icon}
            displayText={routes.ChangeOrders.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.Invoices.linkTo()}
            icon={routes.Invoices.icon}
            displayText={routes.Invoices.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.DrawPackages.linkTo()}
            icon={routes.DrawPackages.icon}
            displayText={routes.DrawPackages.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.Users.linkTo()}
            icon={routes.Users.icon}
            displayText={routes.Users.displayText}
            isOpen={isOpen}
          />
          <DrawerNavLink
            to={routes.Vendors.linkTo()}
            icon={routes.Vendors.icon}
            displayText={routes.Vendors.displayText}
            isOpen={isOpen}
          />
        </div>
      </div>
      <div className="bottom">
        <DrawerNavLink
          to={routes.Settings.linkTo()}
          icon={routes.Settings.icon}
          displayText={routes.Settings.displayText}
          isOpen={isOpen}
        />
        <button onClick={onDrawerToggleClick} className="toggle-drawer-button">
          <ArrowsIcon className="arrows-icon" />
          <span className={`${isOpen ? '' : 'hide-text'}`}>Close</span>
        </button>
        <button onClick={handleSignOut} className="sign-out-button">
          <span>
            <ExitIcon className="sign-out-icon" />
          </span>
          <span className={`${isOpen ? '' : 'hide-text'}`}>Sign out</span>
        </button>
        <hr />
        <div className="user-info-container">
          {/* <img
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&w=1000&q=80"
            alt="Avatar"
            className="avatar"
          /> */}
          <UserOutline className="default-user-icon" />
          <div className="name-email-container">
            <p className={`main-name ${isOpen ? '' : 'hide-text'}`}>
              {`${appContext?.currentUser.first_name ?? ''} ${appContext?.currentUser.last_name ?? ''
                }`}
            </p>
            <p className={`email ${isOpen ? '' : 'hide-text'}`}>
              {appContext?.currentUser.email}
            </p>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DrawerNav;
