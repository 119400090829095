import { Row } from '../../components/EditableTable/EditableTableV2';
import { CostCode } from '../../types/sharedTypes';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { getCostCodeIdByCostCode } from '../getCostCodeIdByCostCode';

export const formatChangeOrdersForBackend = (
  contractChangesRows: Row[],
  costCodes: CostCode[]
) => {
  return contractChangesRows.map((row: Row) => {
    return {
      cost_code_id: getCostCodeIdByCostCode(costCodes, row.cells[0].value),
      budgeted_amount: removeCharactersExceptForPeriodAndNumbersFromFloatString(
        row.cells[4].value
      ),
      requested_amount: removeCharactersExceptForPeriodAndNumbersFromFloatString(
        row.cells[3].value
      ),
    };
  });
};
