import { ModificationToLineItem } from '../../components/BudgetPage/BudgetPage';

export const modificationToLineItemHasDuplicate = (
  modificationsToLineItems: ModificationToLineItem[]
): boolean => {
  const encounteredCostCodes = new Set<string>();

  for (const lineItem of modificationsToLineItems) {
    if (lineItem.cost_code && lineItem.cost_code.id) {
      const costCodeId = lineItem.cost_code.id.toString();
      if (encounteredCostCodes.has(costCodeId)) {
        return true; // Found a duplicate cost_code.id
      }
      encounteredCostCodes.add(costCodeId);
    }
  }

  return false; // No duplicates found
};
