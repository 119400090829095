import { Contract } from '../../types/contractTypes';

export const getTotalNumberOfContractsForVendor = (
  vendorId: string,
  contracts: Contract[]
): number => {
  const contractsWithVendor = contracts.filter(
    (contract) => contract.vendor_id === vendorId
  );
  return contractsWithVendor.length;
};
