import React, { useState, useEffect } from 'react';
import './DrawPackagesPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TabBar from '../TabBar/TabBar';
import TextInput from '../TextInput/TextInput';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import { BsFilter as FilterIcon } from 'react-icons/bs';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import EditableTableV2, { Column, Row } from '../EditableTable/EditableTableV2';
import {
  DrawPackage,
  DrawPackageFundingSource,
} from '../../types/drawPackageTypes';
import { formatDrawPackagesForEditableTable } from '../../utils/drawPackages/formatDrawPackagesForEditableTable';
import Card from '../Card/Card';
import Modal, { ModalSizes } from '../Modal/Modal';
import { formatDrawPackageInvoicesForTableRow } from '../../utils/drawPackages/formatDrawPackageInvoicesForTableRow';
import Select from '../Select/Select';
import { createTomorrowdate } from '../../utils/createTomorrowDate';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { User } from '../../types/userTypes';
import MultiSelect, { OptionType } from '../MultiSelect/MultiSelect';
import { formatDrawPackageBudgetForEditableTable } from '../../utils/drawPackages/formatDrawPackageBudgetForEditableTable';
import Checkbox from '../CheckBox/Checkbox';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { postDrawPackage } from '../../services/drawPackages/postDrawPackage';
import { toast } from 'react-toastify';
import { formatDateToCustomString } from '../../utils/formatDateForBackend';
import { putDrawPackage } from '../../services/drawPackages/putDrawPackage';
import { BiExport as ExportIcon } from 'react-icons/bi';
import { exportDrawPackage } from '../../services/drawPackages/exportDrawPackage';
import { getBudgetColumnKeyFromDisplayValue } from '../../utils/budget/getBudgetColumnKeyFromDisplayValue';
import {
  budgetColumns,
  notFinalizedBudgetColumns,
} from '../BudgetPage/BudgetPage';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../../utils/currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { formatNumberWithCommas } from '../../utils/currency/formatNumberWithCommas';
import '../../styles/global.scss';
import { AppContext } from '../../types/appContextTypes';
import { postDrawPackageApproval } from '../../services/drawPackages/postDrawPackageApproval';
import { FilterItem, Statuses } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import { formatDrawPackageFilters } from '../../utils/drawPackages/formatDrawPackageFilters';
import FilterButton from '../FilterButton/FilterButton';
import { applyDrawPackageFilters } from '../../utils/drawPackages/applyDrawPackageFilters';
import { sortDrawPackages } from '../../utils/drawPackages/sortDrawPackages';
import { sortRowsByCostCode } from '../../utils/misc/sortRowsByCostCode';
import { BudgetLineItem } from '../../types/budgetTypes';
import { convertUTCDateStringToDate } from '../../utils/convertUTCDateStringToDate';
import { getNewDrawPackageFundingSources } from '../../utils/drawPackages/getNewDrawPackageFundingSources';
import { formatToUSD } from '../../utils/currency/formatToUSD';
import cloneDeep from 'lodash/cloneDeep';
import SwitchButton from '../SwitchButton/SwitchButton';
import { sortDrawPackageFundingSourceByType } from '../../utils/misc/sortDrawPackageFundingSourceByType';
import { isLastCharADecimal } from '../../utils/misc/isLastCharADecimal';
import { endsWithDotZero } from '../../utils/misc/endsWithDotZero';

const tabOptions = ['All draws'];

const drawPackgeColumns: Column[] = [
  { value: 'ID' },
  { value: 'Title' },
  { value: 'Status' },
  { value: 'Current Request Amount', className: 'text-align-right' },
  { value: 'Created On' },
  { value: 'Billing Begins' },
  { value: 'Billing Ends' },
];

const drawPackageInvoiceColumns: Column[] = [
  { value: 'Invoice #' },
  { value: 'Cost Code' },
  { value: 'Line Item' },
  { value: 'Invoice Title' },
  { value: 'Vendor' },
  { value: 'Current Billing' },
  { value: 'Status' },
  { value: 'Date' },
];

interface SelectableColumn {
  displayValue: string;
  backendKey: string;
}

const drawPackageSelectableColumnsForFinalizedBudget: SelectableColumn[] = [
  { displayValue: 'Original Amount', backendKey: 'total_amount' },
  { displayValue: 'Modification Amount', backendKey: 'modification_amount' },
  { displayValue: "Approved CO's", backendKey: 'approved_changes' },
  { displayValue: 'Approved Amount', backendKey: 'total_approved_budget' },
  { displayValue: 'Pending Changes', backendKey: 'pending_changes' },
  { displayValue: 'Project Budget', backendKey: 'total_projected_budget' },
  {
    displayValue: 'Variance to Approved',
    backendKey: 'projected_variance_to_approved_budget',
  },
  {
    displayValue: 'Variance to Projected',
    backendKey: 'projected_variance_to_base_budget',
  },
  {
    displayValue: 'Requested to Date',
    backendKey: 'requested_to_date',
  },

  { displayValue: 'Current Draw', backendKey: 'current_draw' },
  {
    displayValue: 'Remaining Spend',
    backendKey: 'approved_budget_remaining_to_spend',
  },
  {
    displayValue: 'Remaining Projected Spend',
    backendKey: 'projected_budget_remaining_to_spend',
  },
  {
    displayValue: 'Vendors',
    backendKey: 'buyout_vendor',
  },
  {
    displayValue: 'Bought Scope',
    backendKey: 'buyout_bought',
  },
  {
    displayValue: 'Unbought Scope',
    backendKey: 'buyout_unbought',
  },
];

const drawPackageSelectableColumnsForNonFinalizedBudget: SelectableColumn[] = [
  { displayValue: 'Total Amount', backendKey: 'total_amount' },
  { displayValue: 'Requested to Date', backendKey: 'requested_to_date' },
  { displayValue: 'Current Draw', backendKey: 'current_draw' },
  {
    displayValue: 'Remaining to Spend',
    backendKey: 'approved_budget_remaining_to_spend',
  },
  {
    displayValue: 'Committed Costs',
    backendKey: 'buyout_bought',
  },
];

interface Props {
  appContext: AppContext;
}

const DrawPackagesPage: React.FC<Props> = ({ appContext }) => {
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedDrawPackage, setSelectedDrawPackage] =
    useState<DrawPackage | null>(null);
  const [isDrawPackagesModalVisible, setIsDrawPackagesModalVisible] =
    useState<boolean>(false);
  const [drawPackageID, setDrawPackageID] = useState<string>('');
  const [title, setTitle] = useState<string>('New Draw Package');
  const [status, setStatus] = useState<string>('Draft');
  const [startOfBillingPeriod, setStartOfBillingPeriod] = useState<Date>(
    createTomorrowdate()
  );
  const [endOfBillingPeriod, setEndOfBillingPeriod] = useState<Date>(
    createTomorrowdate()
  );
  const [approverActiveOptions, setActiveApproverOptions] = useState<
    OptionType[]
  >([]);
  const [approversValue, setApproversValue] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const [isBudgetFilterPopUnderVisible, setIsBudgetFilterPopUnderVisible] =
    useState<boolean>(false);
  const [
    budgetFilterPopUnderIsCheckedValues,
    setBudgetFilterPopUnderIsCheckedValues,
  ] = useState<string[]>([]);
  const navigate = useNavigate();
  const [activeFundingSources, setActiveFundingSources] = useState<any[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const [isAutoAllocateFundsToggled, setIsAutoAllocateFundsToggled] =
    useState<boolean>(true);
  const [useBudgetSnapshot, setUseBudgetSnapshot] = useState<boolean>(false);
  const [useFundingSourceSnapshot, setUseFundingSourceSnapshot] =
    useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;

  const drawPackagesMap: filtersMap = formatDrawPackageFilters(
    appContext.drawPackages
  );
  const { id } = useParams();

  const defaultBudgetTableColumnVisiblilties =
    selectedDrawPackage?.budget_is_finalized
      ? drawPackageSelectableColumnsForFinalizedBudget
          .map(
            (selectableColumn: SelectableColumn) => selectableColumn.backendKey
          )
          .join(',')
      : drawPackageSelectableColumnsForNonFinalizedBudget
          .map(
            (selectableColumn: SelectableColumn) => selectableColumn.backendKey
          )
          .join(',');

  useEffect(() => {
    if (appContext.drawPackages?.length === 0) return;
    let indexOfDrawPackage = null;
    const drawPackageSelectedByIDInURL = appContext.drawPackages.find(
      (drawPackage: DrawPackage, index: number) => {
        if (drawPackage.id === id) {
          // Set the indexOfDrawPackage when the draw with the specified 'id' is found
          indexOfDrawPackage = index;
          return true;
        }
        return false;
      }
    );

    // Zero is falsey in JavaScript so make sure we check if the number is 0
    if (
      drawPackageSelectedByIDInURL &&
      (indexOfDrawPackage || indexOfDrawPackage === 0)
    ) {
      setSelectedDrawPackage(drawPackageSelectedByIDInURL);
      handleTableRowClicked(indexOfDrawPackage, { id } as Row);
    }
  }, [id]);

  useEffect(() => {
    if (selectedDrawPackage) {
      return;
    }

    if (!appContext.currentProject?.funding_sources) {
      setActiveFundingSources([]);
      return;
    }
  }, [selectedDrawPackage]);

  useEffect(() => {
    // If the user came from my inbox, update browser history so back button navigates back to it
    if (searchParams.get('from') === 'inbox') {
      window.history.pushState(null, '', '/inbox');
      window.history.pushState(null, '', location.pathname);
    }
  }, []);

  useEffect(() => {
    setUseBudgetSnapshot(
      !!selectedDrawPackage?.budget_snapshot &&
        status !== 'Draft' &&
        status !== 'In Review' &&
        !!selectedDrawPackage?.status &&
        selectedDrawPackage.status !== 'Draft' &&
        selectedDrawPackage.status !== 'In Review'
    );
    setUseFundingSourceSnapshot(
      !!selectedDrawPackage?.funding_sources_snapshot &&
        status !== 'Draft' &&
        status !== 'In Review' &&
        !!selectedDrawPackage?.status &&
        selectedDrawPackage.status !== 'Draft' &&
        selectedDrawPackage.status !== 'In Review'
    );
  }, [selectedDrawPackage, status]);

  useEffect(() => {
    if (!selectedDrawPackage) {
      return;
    }

    const _fundingSources =
      useFundingSourceSnapshot === true &&
      !!selectedDrawPackage?.funding_sources_snapshot
        ? selectedDrawPackage.funding_sources_snapshot
        : cloneDeep(selectedDrawPackage.funding_sources);
    setActiveFundingSources(
      sortDrawPackageFundingSourceByType(_fundingSources)
    );
  }, [useFundingSourceSnapshot]);

  const handleCreateDrawClicked = () => {
    const formattedNewFundingSources = sortDrawPackageFundingSourceByType(
      appContext.currentProject.funding_sources.flat().map((fs) => {
        return {
          ...fs,
          amount: 0.0,
          is_active: true,
          draw_package_id: 'new-dp',
          auto_calculated_funding: 0,
        };
      })
    );

    setActiveFundingSources(formattedNewFundingSources);
    setSelectedDrawPackage(null);
    setIsDrawPackagesModalVisible(true);
    setIsAutoAllocateFundsToggled(true);
  };

  const handleViewTabClicked = (newTabIndex: number) => {
    setActiveTabIndex(newTabIndex);
  };

  const handleBudgetPopUnderValueCheckedChanged = async (
    backendKey: string
  ) => {
    if (!selectedDrawPackage) return;
    let newColumnVisibilityValues = null;
    // Remove if already this checkbox is already checked
    if (budgetFilterPopUnderIsCheckedValues.includes(backendKey)) {
      const newState = budgetFilterPopUnderIsCheckedValues.filter(
        (currentBackendKey: string) => currentBackendKey !== backendKey
      );
      setBudgetFilterPopUnderIsCheckedValues(newState);
      newColumnVisibilityValues = newState;
    }
    // Otherwise add the this backend key into the array so that it's checked
    else {
      const newState = [...budgetFilterPopUnderIsCheckedValues, backendKey];
      setBudgetFilterPopUnderIsCheckedValues(newState);
      newColumnVisibilityValues = newState;
    }

    const updatedDrawPackage = await putDrawPackage(
      {
        project_id: appContext.currentProject?.id,
        draw_package_id: selectedDrawPackage.id,
        title: title,
        status: status,
        number: drawPackageID,
        description: description,
        // TODO: How do I set the funding type?
        funding_type: '',
        approver_user_ids: approversValue,
        billing_start_utc: formatDateToCustomString(startOfBillingPeriod),
        billing_end_utc: formatDateToCustomString(endOfBillingPeriod),
        visible_budget_columns: newColumnVisibilityValues.join(','),
        funding_source_json: activeFundingSources,
        auto_calculate_funding: String(isAutoAllocateFundsToggled),
      },
      JSON.stringify(drawPackageBudget)
    );

    if (updatedDrawPackage.error_message) {
      showErrorMessageToast(updatedDrawPackage.error_message);
    } else {
      const newDrawPackageState: DrawPackage[] = appContext.drawPackages.map(
        (drawPackage: DrawPackage) =>
          drawPackage.id === updatedDrawPackage.id
            ? updatedDrawPackage
            : drawPackage
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();
    }
  };

  const handleTableRowClicked = async (rowindex: number, row: Row) => {
    const _selectedDrawPackage = appContext.drawPackages.find(
      (drawPackage: DrawPackage) => drawPackage.id === row.id
    );

    if (!_selectedDrawPackage) {
      console.warn(`No draw package found in state with ID of ${row.id}`);
      return;
    }

    const approversOptions: OptionType[] = _selectedDrawPackage.approvers.map(
      (user: User) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
          isComplete: user.is_approved === true,
        };
      }
    );

    const formattedApproverIDs: string[] = _selectedDrawPackage.approvers.map(
      (approver: User) => approver.id
    );
    // Populate state with existing values
    setSelectedDrawPackage(_selectedDrawPackage);
    setDrawPackageID(_selectedDrawPackage.number);
    setTitle(_selectedDrawPackage.title);
    setStatus(_selectedDrawPackage.status);
    setApproversValue(formattedApproverIDs);
    setActiveApproverOptions(approversOptions);
    setStartOfBillingPeriod(new Date(_selectedDrawPackage.billing_start_utc));
    setEndOfBillingPeriod(new Date(_selectedDrawPackage.billing_end_utc));
    setDescription(_selectedDrawPackage.description);
    setBudgetFilterPopUnderIsCheckedValues(
      _selectedDrawPackage.visible_budget_columns
    );
    setIsAutoAllocateFundsToggled(_selectedDrawPackage.auto_calculate_funding);

    // TODO(josh-bidsight): Remove duplicate code below to determine if the snapshot should be used.
    const _useFundingSourceSnapshot =
      !!_selectedDrawPackage?.funding_sources_snapshot &&
      _selectedDrawPackage.status !== 'Draft' &&
      _selectedDrawPackage.status !== 'In Review' &&
      !!_selectedDrawPackage?.status &&
      _selectedDrawPackage.status !== 'Draft' &&
      _selectedDrawPackage.status !== 'In Review';
    const _fundingSources =
      _useFundingSourceSnapshot === true &&
      !!_selectedDrawPackage?.funding_sources_snapshot
        ? _selectedDrawPackage.funding_sources_snapshot
        : cloneDeep(_selectedDrawPackage.funding_sources);
    setActiveFundingSources(
      sortDrawPackageFundingSourceByType(_fundingSources)
    );

    setIsDrawPackagesModalVisible(true);
  };

  const handleInvoiceTableRowClicked = async (rowindex: number, row: Row) => {
    const invoiceID: string = row.cells[0]?.id ? row.cells[0]?.id : '';
    navigate(`/invoices/${invoiceID}`);
  };

  const handleCloseDrawPackagesModal = () => {
    setActiveFundingSources([]);
    setIsDrawPackagesModalVisible(false);
    setSelectedDrawPackage(null);
    setIsBudgetFilterPopUnderVisible(false);
    resetModalState();
  };

  const handleFilterBudgetIconClicked = () => {
    setIsBudgetFilterPopUnderVisible(!isBudgetFilterPopUnderVisible);
  };

  const handleExportIconClicked = async () => {
    if (!selectedDrawPackage) return;

    const exportedDrawPackageDowloadLink = await exportDrawPackage(
      selectedDrawPackage.id
    );

    if (exportedDrawPackageDowloadLink.export_url) {
      window.open(exportedDrawPackageDowloadLink.export_url, '_blank');
      showSuccessToastMessage('Successfully exported draw package');
    } else {
      showErrorMessageToast('Unable to export draw package');
    }
  };

  const handleSubmitDrawPackage = async () => {
    // Editing an existing draw package
    if (selectedDrawPackage) {
      const updatedDrawPackage = await putDrawPackage(
        {
          project_id: appContext.currentProject?.id,
          draw_package_id: selectedDrawPackage.id,
          title: title,
          status: status,
          number: drawPackageID,
          description: description,
          // TODO: How do I set the funding type?
          funding_type: '',
          approver_user_ids: approversValue,
          billing_start_utc: formatDateToCustomString(startOfBillingPeriod),
          billing_end_utc: formatDateToCustomString(endOfBillingPeriod),
          visible_budget_columns: budgetFilterPopUnderIsCheckedValues.join(','),
          funding_source_json: activeFundingSources,
          auto_calculate_funding: `${isAutoAllocateFundsToggled}`,
        },
        JSON.stringify(drawPackageBudget)
      );

      if (updatedDrawPackage.error_message) {
        showErrorMessageToast(updatedDrawPackage.error_message);
      } else {
        showSuccessToastMessage('Successfully updated draw package');
        const newDrawPackageState: DrawPackage[] = appContext.drawPackages.map(
          (drawPackage: DrawPackage) =>
            drawPackage.id === updatedDrawPackage.id
              ? updatedDrawPackage
              : drawPackage
        );
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        setIsDrawPackagesModalVisible(false);
        resetModalState();
      }
    }
    // Create a new draw package
    else {
      const newDrawPackage = await postDrawPackage({
        project_id: appContext.currentProject?.id,
        title: title,
        status: status,
        number: drawPackageID,
        description: description,
        // TODO: How do I set the funding type?
        funding_type: '',
        approver_user_ids: approversValue,
        billing_start_utc: formatDateToCustomString(startOfBillingPeriod),
        billing_end_utc: formatDateToCustomString(endOfBillingPeriod),
        visible_budget_columns: defaultBudgetTableColumnVisiblilties,
        funding_source_json: activeFundingSources,
        auto_calculate_funding: `${isAutoAllocateFundsToggled}`,
      });

      if (newDrawPackage.error_message) {
        showErrorMessageToast(newDrawPackage.error_message);
      } else {
        showSuccessToastMessage('Successfully created new draw package');
        const newDrawPackageState = [
          ...appContext.drawPackages,
          newDrawPackage,
        ];
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        setIsDrawPackagesModalVisible(false);
        resetModalState();
      }
    }
  };

  const resetModalState = () => {
    setDrawPackageID('');
    setTitle('');
    setStatus('Draft');
    setApproversValue([]);
    setActiveApproverOptions([]);
    setStartOfBillingPeriod(createTomorrowdate());
    setEndOfBillingPeriod(createTomorrowdate());
    setDescription('');
    setBudgetFilterPopUnderIsCheckedValues(
      defaultBudgetTableColumnVisiblilties.split(',')
    );
    setIsAutoAllocateFundsToggled(true);
  };

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const handleApproverOptionValueClicked = (newValues: string[]) => {
    setApproversValue(newValues);
  };

  const handleApproveDrawPackage = async () => {
    if (!selectedDrawPackage) return;
    const drawPackageApproval = await postDrawPackageApproval(
      selectedDrawPackage.id,
      JSON.stringify(drawPackageBudget),
      JSON.stringify(activeFundingSources)
    );

    if (drawPackageApproval.error_message) {
      showErrorMessageToast(drawPackageApproval.error_message);
    } else {
      const newApproval = drawPackageApproval.is_approved;
      const newStatus = drawPackageApproval.status;
      showSuccessToastMessage(
        `Successfully ${newApproval === true ? '' : 'un'}approved draw package`
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();

      const currentDrawPackageApprovers = selectedDrawPackage.approvers;
      const updatedDrawPackageApprovers = currentDrawPackageApprovers.map(
        (appr) => {
          if (appr.id == appContext.currentUser.id) {
            appr.is_approved = newApproval;
          }
          return appr;
        }
      );
      setSelectedDrawPackage({
        ...selectedDrawPackage,
        status: newStatus,
        approvers: updatedDrawPackageApprovers,
        budget_snapshot: drawPackageApproval.budget_snapshot_json
          ? JSON.parse(drawPackageApproval.budget_snapshot_json)
          : null,
        budget_snapshotted_on_utc:
          drawPackageApproval.budget_snapshotted_on_utc,
        funding_sources_snapshot:
          drawPackageApproval.funding_sources_snapshot_json
            ? JSON.parse(drawPackageApproval.funding_sources_snapshot_json)
            : null,
        funding_sources_snapshotted_on_utc:
          drawPackageApproval.funding_sources_snapshotted_on_utc,
      });

      setStatus(newStatus);
      setActiveApproverOptions(
        approverActiveOptions.map((option: OptionType) => {
          if (option.value == appContext.currentUser.id) {
            option.isComplete = newApproval;
          }
          return option;
        })
      );
    }
  };

  const drawPackageTableRows: Row[] =
    appContext.drawPackages.length > 0
      ? sortDrawPackages(
          applyDrawPackageFilters(
            formatDrawPackagesForEditableTable(appContext.drawPackages),
            searchboxValue,
            activeFilters
          )
        )
      : [];

  const drawPackageInvoiceRows: Row[] = selectedDrawPackage?.invoices
    ? sortRowsByCostCode(
        formatDrawPackageInvoicesForTableRow(selectedDrawPackage?.invoices)
      )
    : [];

  const drawPackageBudget: BudgetLineItem[] =
    (useBudgetSnapshot
      ? selectedDrawPackage?.budget_snapshot
      : selectedDrawPackage?.budget) || [];
  const drawPackageBudgetRows: Row[] = formatDrawPackageBudgetForEditableTable(
    drawPackageBudget,
    selectedDrawPackage?.budget_is_finalized ?? false
  );

  const approversOptions = appContext.currentProject
    ? appContext.currentProject?.users.map((drawPackageUsers: User) => {
        return {
          value: drawPackageUsers.id,
          label: `${drawPackageUsers.first_name} ${drawPackageUsers.last_name}`,
          isComplete: selectedDrawPackage
            ? selectedDrawPackage.approvers.find(
                (appr) => appr.id == drawPackageUsers.id
              )?.is_approved === true
            : false,
        };
      })
    : [];

  const budgetColumnsByBudgetStatus = selectedDrawPackage?.budget_is_finalized
    ? budgetColumns
    : notFinalizedBudgetColumns;

  const filteredBudgetTableColumns: Column[] =
    budgetColumnsByBudgetStatus.filter((column: Column) =>
      budgetFilterPopUnderIsCheckedValues.includes(
        getBudgetColumnKeyFromDisplayValue(column.value)
      )
    );

  // Hacky way to make sure these are always the first two items in the table
  if (selectedDrawPackage?.budget_is_finalized) {
    filteredBudgetTableColumns.unshift({ value: 'Line Item' });
  } else {
    filteredBudgetTableColumns.unshift({ value: 'Description' });
  }
  filteredBudgetTableColumns.unshift({
    value: 'Cost Code',
    className: 'budget-cost-code-column',
  });

  const handleFundingSourceChanged = (
    fundingSource: DrawPackageFundingSource,
    newAmount: string
  ) => {
    if (isLastCharADecimal(newAmount) || endsWithDotZero(newAmount)) {
      return;
    }

    const parsedFloatAmount = parseFloat(
      removeCharactersExceptForPeriodAndNumbersFromFloatString(newAmount)
    );

    if (Number.isNaN(parsedFloatAmount)) {
      fundingSource.amount = 0;
    } else {
      fundingSource.amount = parsedFloatAmount;
    }

    const clonedActiveFundingSources = cloneDeep(activeFundingSources);

    const updatedFundingSources = clonedActiveFundingSources.map(
      (fs: DrawPackageFundingSource) =>
        fs.id === fundingSource.id ? fundingSource : fs
    );

    setActiveFundingSources(updatedFundingSources);
  };

  const handleFundingSourceCheckboxClicked = (
    newFundingSource: DrawPackageFundingSource
  ) => {
    newFundingSource.is_active = !newFundingSource.is_active;
    const updatedFundingSources = activeFundingSources.map(
      (fundingSource: DrawPackageFundingSource) =>
        fundingSource.id === newFundingSource.id
          ? newFundingSource
          : fundingSource
    );

    setActiveFundingSources(updatedFundingSources);
  };

  const filterCellsRecursively = (row: Row): Row => {
    return {
      ...row,
      cells: row.cells.filter(
        (currentCell) =>
          budgetFilterPopUnderIsCheckedValues.includes(
            getBudgetColumnKeyFromDisplayValue(currentCell.columnDisplayName)
          ) ||
          currentCell.columnDisplayName === 'Cost Code' ||
          currentCell.columnDisplayName === 'Description' ||
          currentCell.columnDisplayName === 'Line Item'
      ),
      children: row?.children ? row.children.map(filterCellsRecursively) : [],
    };
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const handleToggleAutoAllocateFunds = () => {
    const updatedFundingSources = activeFundingSources.map((fs) => {
      return {
        ...fs,
        amount: fs.auto_calculated_funding,
      };
    });

    setActiveFundingSources(updatedFundingSources);

    setIsAutoAllocateFundsToggled(!isAutoAllocateFundsToggled);
  };

  const filteredBudgetTableRows = drawPackageBudgetRows.map(
    filterCellsRecursively
  );

  let totalAllocatedFunding = isAutoAllocateFundsToggled
    ? (activeFundingSources ?? [])
        .filter((fs) => fs.is_active === true)
        .reduce((acc, source) => acc + source.auto_calculated_funding, 0)
    : (activeFundingSources ?? [])
        .filter((fs) => fs.is_active === true)
        .reduce((acc, source) => acc + source.amount, 0);
  if (isNaN(totalAllocatedFunding)) {
    totalAllocatedFunding = 0;
  }

  const totalDrawPackageAmount = !selectedDrawPackage
    ? 0.0
    : selectedDrawPackage.total_amount;

  const popUnderOptions = selectedDrawPackage?.budget_is_finalized
    ? drawPackageSelectableColumnsForFinalizedBudget
    : drawPackageSelectableColumnsForNonFinalizedBudget;

  const drawPackageIsEditable = status === 'Draft' || status === 'In Review';

  window.document.title = 'BidSight – Draw Packages';
  return (
    <section className="DrawPackagesPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Draw Packages</h4>
          <h5>Create and view your draws here</h5>
        </div>
        <div>
          {!appContext.isRefetching && drawPackageTableRows.length == 0 ? (
            <div /> // wierd bug where "isRefetching || drawPackageTableRows.length == 0" condition isn't trigger a render
          ) : (
            <Button
              label={'Create draw'}
              isDisabled={appContext.isRefetching}
              onClick={handleCreateDrawClicked}
              variant={ButtonVariant.PrimaryThin}
            />
          )}
        </div>
      </div>
      <div className="tab-bar-container">
        <TabBar
          tabOptions={tabOptions}
          activeTabOption={tabOptions[activeTabIndex]}
          onTabClick={handleViewTabClicked}
        />
      </div>
      <div className="search-filter-display-container">
        <div className="search-fitler-container">
          <div className="search-container">
            <TextInput
              placeholder="Search"
              icon={<MagnifyingGlassIcon />}
              onChange={(newValue: string) => setSearchboxValue(newValue)}
            />
          </div>
          <span className="filter-popunder-container">
            <span className="filter-button-container">
              <IconButton
                icon={<FilterIcon />}
                label={'Filters'}
                variant={IconButtonVariants.dotted}
                onClick={() =>
                  setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                }
              />
              <PopUnder
                isOpen={isFilterPopUnderVisible}
                onClose={() => setIsFilterPopUnderVisible(false)}
                optionsMap={drawPackagesMap}
                handleSecondOptionSelected={handleFilterSelected}
                activeFilters={activeFilters}
              />
            </span>
            {hasActiveFilters && (
              <span className="clear-filters-button-container">
                <IconButton
                  icon={<CloseIcon />}
                  label={'Clear filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() => setActiveFilters([])}
                />
              </span>
            )}
          </span>
        </div>
        <IconButton
          icon={<ExportIcon />}
          label={tableIsExporting ? 'Exporting...' : 'Export'}
          variant={IconButtonVariants.dotted}
          onClick={() => setTableIsExporting(true)}
        />
      </div>
      <div className="active-filters-container">
        {activeFilters.map((activeFilter) => (
          <FilterButton
            key={activeFilter.label}
            filter={activeFilter}
            onClick={handleFilterButtonRemoved}
          />
        ))}
      </div>
      <div className="bottom">
        {appContext.isRefetching ? (
          <EditableTableLoadingSkeleton />
        ) : drawPackageTableRows.length > 0 ? (
          <div className="draw-table-container">
            <EditableTableV2
              isReadOnly
              columns={drawPackgeColumns}
              rows={drawPackageTableRows}
              onTablerowClicked={handleTableRowClicked}
              tableName={`${appContext.currentProject.name} Draw Packages - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
            />
          </div>
        ) : appContext.drawPackages.length > 0 &&
          drawPackageTableRows.length === 0 ? (
          <div>No draw packages found</div>
        ) : (
          <div className="no-draws-container">
            <FileIconWithCircle />
            <p className="no-draws-added">No draw packages</p>
            <p>Create a draw package to get started</p>
            <div className="no-draws-button-container">
              <Button
                label="Create draw"
                onClick={handleCreateDrawClicked}
                variant={ButtonVariant.PrimaryThin}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isDrawPackagesModalVisible}
        onClose={handleCloseDrawPackagesModal}
        size={ModalSizes.fullScreen}
        hideXButton={true}
        hideButtons={true}
        primaryHeader={selectedDrawPackage ? 'Update Draw' : 'Create Draw'}
      >
        <div className="modal-change-order-content">
          <div className="top-right-buttons-container">
            <Button
              label="Cancel"
              variant={ButtonVariant.GrayThin}
              onClick={handleCloseDrawPackagesModal}
            />
            {!!selectedDrawPackage?.approvers?.find(
              (appr) => appr.id == appContext.currentUser.id
            ) &&
              (() => {
                const approval = selectedDrawPackage.approvers.find(
                  (appr) => appr.id == appContext.currentUser.id
                )!;
                return (
                  <span className="margin-left">
                    <Button
                      label={`${
                        approval.is_approved === true ? 'Unapprove' : 'Approve'
                      }`}
                      variant={ButtonVariant.SecondaryThin}
                      onClick={handleApproveDrawPackage}
                    />
                  </span>
                );
              })()}
            <span className="margin-left">
              <Button
                label={selectedDrawPackage ? 'Update Draw' : 'Create Draw'}
                isDisabled={appContext.isRefetching}
                variant={ButtonVariant.PrimaryThin}
                onClick={handleSubmitDrawPackage}
              />
            </span>
          </div>
        </div>
        <div className="modal-primary-content-container">
          <div className="left">
            <Card isFullHeight>
              <p className="content-subheader">Draw Package Details</p>
              <div>
                <div className="two-column-grid">
                  <div className="label-input-container">
                    <label htmlFor="draw-package-id">Draw Package ID</label>
                    <TextInput
                      id="draw-package-id"
                      value={drawPackageID}
                      placeholder="Draw package ID"
                      onChange={(newValue: string) =>
                        setDrawPackageID(newValue)
                      }
                    />
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="title">Draw Package Title</label>
                    <TextInput
                      id="title"
                      value={title}
                      placeholder="Draw package title"
                      onChange={(newValue: string) => setTitle(newValue)}
                    />
                  </div>
                </div>
                <div className="two-column-grid">
                  <div className="label-input-container">
                    <label htmlFor="status-input">Status</label>
                    <Select
                      id="status-input"
                      onChange={(newValue: string) => setStatus(newValue)}
                      value={status}
                    >
                      <option>Draft</option>
                      <option>In Review</option>
                      <option>Approved</option>
                      <option>Sent</option>
                      <option>Funded</option>
                      <option>Rejected</option>
                    </Select>
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="approvers-input">Approvers</label>
                    <MultiSelect
                      id="approvers-input"
                      options={approversOptions}
                      selectedOptions={approverActiveOptions}
                      setSelectOptions={(newOptions: OptionType[]) =>
                        setActiveApproverOptions(newOptions)
                      }
                      onChange={(newValue: string[]) =>
                        handleApproverOptionValueClicked(newValue)
                      }
                    />
                    {approverActiveOptions.length > 0 && (
                      <span className="approval-status">
                        {`${
                          approverActiveOptions.filter(
                            (opt) => opt.isComplete === true
                          ).length
                        }/${
                          approverActiveOptions.length
                        } approvers have approved the draw package`}
                      </span>
                    )}
                  </div>
                </div>
                <div className="two-column-grid">
                  <div className="label-input-container">
                    <label htmlFor="start-of-billing-period">
                      Start of Billing Period
                    </label>
                    <CustomDatePicker
                      id="start-of-billing-period"
                      selectedDate={startOfBillingPeriod}
                      handleDateChange={(newDate: Date) =>
                        setStartOfBillingPeriod(newDate)
                      }
                    />
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="end-of-billing-period">
                      End of Billing Period
                    </label>
                    <CustomDatePicker
                      id="end-of-billing-period"
                      selectedDate={endOfBillingPeriod}
                      handleDateChange={(newDate: Date) =>
                        setEndOfBillingPeriod(newDate)
                      }
                      minDate={startOfBillingPeriod}
                    />
                  </div>
                </div>
              </div>
              <div className="label-input-container">
                <label htmlFor="description">Description</label>
                <div className="text-area-container">
                  <textarea
                    placeholder="Enter a description..."
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setDescription(e.target.value)
                    }
                    value={description}
                    id="description"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="right">
            <Card isFullHeight>
              <p className="funding-sources-subheader">Funding Sources</p>
              {useFundingSourceSnapshot && (
                <h4 className="snapshot-timestamp">
                  {selectedDrawPackage?.funding_sources_snapshotted_on_utc
                    ? `Snapshotted on ${convertUTCDateStringToDate(
                        selectedDrawPackage.funding_sources_snapshotted_on_utc
                      ).toLocaleString('en-US', { timeZoneName: 'short' })}`
                    : `Snapshot`}
                </h4>
              )}
              <br />
              <div className="switch-auto-allocate-label-container">
                <label>Auto-allocate funds on draw</label>
                <SwitchButton
                  checked={isAutoAllocateFundsToggled}
                  onChange={() => {
                    if (drawPackageIsEditable === true) {
                      handleToggleAutoAllocateFunds();
                    }
                  }}
                />
              </div>
              <hr />
              <div className="funding-sources-inputs-container">
                <div className="funding-sources-row">
                  {/* First empty label is so we don't go above the checkbox */}
                  <p className="funding-label"></p>
                  <p className="funding-label">Funding Source</p>
                  <p className="funding-label">Funded to Date</p>
                  <p className="funding-label">Current Draw Amount</p>
                  <p className="funding-label">Draw %</p>
                  <p className="funding-label">Remaining to Fund</p>
                </div>
                {activeFundingSources.length > 0 ? (
                  activeFundingSources.map(
                    (fundingSource: DrawPackageFundingSource) => {
                      let remainingToFund = fundingSource.original_amount
                        ? fundingSource.funded_to_date
                          ? fundingSource.original_amount -
                            fundingSource.funded_to_date
                          : fundingSource.original_amount
                        : 0;
                      remainingToFund = Math.max(remainingToFund, 0);
                      remainingToFund -= isAutoAllocateFundsToggled
                        ? fundingSource.auto_calculated_funding
                        : fundingSource.amount;

                      const drawPercentage =
                        totalDrawPackageAmount > 0
                          ? fundingSource.is_active === true
                            ? `${Math.round(
                                (fundingSource.amount /
                                  totalDrawPackageAmount) *
                                  100
                              )}%`
                            : `0%`
                          : `0%`;

                      return (
                        <div
                          className="funding-sources-row"
                          key={fundingSource.id}
                        >
                          <Checkbox
                            isChecked={fundingSource.is_active}
                            onClicked={() => {
                              if (drawPackageIsEditable === true) {
                                handleFundingSourceCheckboxClicked(
                                  fundingSource
                                );
                              }
                            }}
                          />
                          <p className="funding-label stack funding-source-name">
                            <span>{fundingSource.name}</span>
                            <span className="funding-source-type">
                              {fundingSource.type}
                            </span>
                          </p>
                          <p className="funding-label">
                            {formatToUSD(
                              fundingSource.funded_to_date?.toString() ?? ''
                            )}
                          </p>
                          <span>
                            {isAutoAllocateFundsToggled ? (
                              <p className="auto-calculated-value">
                                {formatToUSD(
                                  fundingSource.auto_calculated_funding.toString()
                                )}
                              </p>
                            ) : (
                              <CurrencyInput
                                inputprops={{
                                  value: fundingSource.amount.toString(),
                                  onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (drawPackageIsEditable === true) {
                                      handleFundingSourceChanged(
                                        fundingSource,
                                        event.target.value
                                      );
                                    }
                                  },
                                }}
                              />
                            )}
                          </span>
                          <p className="funding-label">{drawPercentage}</p>
                          <p className="funding-label">
                            {formatToUSD(remainingToFund.toString())}
                          </p>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="no-funding-sources-warning">
                    {`No funding sources available. Add them in `}
                    <span onClick={() => navigate(`/settings`)}>settings</span>.
                  </div>
                )}
              </div>
              <hr />
              <div className="totals-row">
                {/* Blank div for checkbox column */}
                <div></div>
                <p className="funding-label allocated-funding-total">
                  Allocated funding
                </p>
                {/* Blank div for the  Funded to Date column */}
                <div></div>
                <p className="total-row-value">
                  ${formatNumberWithCommas(totalAllocatedFunding)}
                </p>
                <p>
                  {totalDrawPackageAmount > 0
                    ? `${Math.round(
                        (totalAllocatedFunding / totalDrawPackageAmount) * 100
                      )}%`
                    : ``}
                </p>
              </div>
              {selectedDrawPackage && (
                <>
                  <hr />
                  <div className="totals-row">
                    {/* Blank div for checkbox column */}
                    <div></div>
                    <p className="funding-label allocated-funding-total">
                      Draw Total
                    </p>
                    {/* Blank div for the Funded to Date column */}
                    <div></div>
                    <p className="total-row-value">
                      ${formatNumberWithCommas(totalDrawPackageAmount)}
                    </p>
                    {/* Blank div for the Draw % column Amount column */}
                    <div></div>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
        {filteredBudgetTableRows.length > 0 && (
          <div className="table-header-container">
            <Card isFullHeight>
              <div className="table-label-buttons-container">
                <p className="standard-table-header">Budget</p>
                <div className="icons-container">
                  <ExportIcon
                    className="icon"
                    onClick={handleExportIconClicked}
                  />
                  <DisplayIcon
                    className="icon"
                    onClick={handleFilterBudgetIconClicked}
                  />
                  {isBudgetFilterPopUnderVisible && (
                    <div
                      className="budget-filter-popunder"
                      style={{
                        width: `${
                          selectedDrawPackage?.budget_is_finalized
                            ? '36rem'
                            : '30rem'
                        }`,
                        height: `${
                          selectedDrawPackage?.budget_is_finalized
                            ? '14rem'
                            : '7rem'
                        }`,
                        left: `${
                          selectedDrawPackage?.budget_is_finalized
                            ? '-31.5rem'
                            : '-26.5rem'
                        }`,
                      }}
                    >
                      {popUnderOptions.map((budgetColumn: SelectableColumn) => {
                        return (
                          <span
                            className="budget-checkbox"
                            key={budgetColumn.backendKey}
                          >
                            <Checkbox
                              isChecked={budgetFilterPopUnderIsCheckedValues.includes(
                                budgetColumn.backendKey
                              )}
                              onClicked={() =>
                                handleBudgetPopUnderValueCheckedChanged(
                                  budgetColumn.backendKey
                                )
                              }
                            />
                            <p
                              onClick={() =>
                                handleBudgetPopUnderValueCheckedChanged(
                                  budgetColumn.backendKey
                                )
                              }
                            >
                              {budgetColumn.displayValue}
                            </p>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="standard-table-container">
                <EditableTableV2
                  isReadOnly
                  columns={filteredBudgetTableColumns}
                  rows={filteredBudgetTableRows}
                />
              </div>
              {useBudgetSnapshot && (
                <h4 className="snapshot-timestamp">
                  {selectedDrawPackage?.budget_snapshotted_on_utc
                    ? `Snapshotted on ${convertUTCDateStringToDate(
                        selectedDrawPackage.budget_snapshotted_on_utc
                      ).toLocaleString('en-US', { timeZoneName: 'short' })}`
                    : `Snapshot`}
                </h4>
              )}
            </Card>
          </div>
        )}
        {drawPackageInvoiceRows.length > 0 && (
          <div className="table-header-container">
            <Card isFullHeight>
              <p className="standard-table-header">Invoiced Line Items</p>
              <div className="standard-table-container">
                <EditableTableV2
                  isReadOnly
                  columns={drawPackageInvoiceColumns}
                  rows={drawPackageInvoiceRows}
                  onTablerowClicked={handleInvoiceTableRowClicked}
                />
              </div>
            </Card>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default DrawPackagesPage;
