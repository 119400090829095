import React, { useState } from 'react';
import './FundingSourceBlade.scss';
import Blade from '../../../Blade/Blade';
import TextInput from '../../../TextInput/TextInput';
import CustomDatePicker from '../../../CustomDatePicker/CustomDatePicker';
import IconButton, { IconButtonVariants } from '../../../IconButton/IconButton';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import Button, { ButtonVariant } from '../../../Button/Button';
import CurrencyInput from '../../../CurrencyInput/CurrencyInput';
import Select from '../../../Select/Select';
import { indexesInEnglish } from '../../../../utils/misc/indexesInEnglish';
import { fundingSourceTypes } from '../../../CreateFundingSourceModal/CreateFundingSourceModal';
import CostCodeSelectorMultiSelectInput from '../../../CostCodeSelectorInput/MultiSelect/CostCodeSelectorMultiSelectInput';
import { CostCode } from '../../../../types/sharedTypes';
import { populateCostCodesFromExclusions } from '../../../../utils/costCodes/populateCostCodesFromExclusions';
import { ProjectFundingSource } from '../../../../types/projectTypes';

interface Props {
  allCostCodes: CostCode[];
  allFundingSources: ProjectFundingSource[][];
  selectedFundingSource: ProjectFundingSource | null;
  isOpen: boolean;
  onClose: () => void;
  handleFundingSourceEdited: (
    newValue: any,
    keyToChange: keyof ProjectFundingSource
  ) => void;
  handleDeleteFundingSourceClicked: () => void;
  handleConfirmSaveChanges: () => void;
  handleCancelChangesButtonClicked: () => void;
  handleEditSourceButtonClicked: () => void;
}

const FundingSourceBlade: React.FC<Props> = ({
  allCostCodes,
  allFundingSources,
  selectedFundingSource,
  isOpen,
  onClose,
  handleFundingSourceEdited,
  handleDeleteFundingSourceClicked,
  handleConfirmSaveChanges,
  handleCancelChangesButtonClicked,
  handleEditSourceButtonClicked,
}) => {
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const handleClose = () => {
    setIsEditModeOn(false);
    onClose();
  };

  if (!selectedFundingSource) {
    return <Blade isOpen={isOpen} onClose={handleClose}></Blade>;
  }

  const closeDate = selectedFundingSource.close_date_utc
    ? new Date(selectedFundingSource.close_date_utc)
    : null;

  const maturityDate = selectedFundingSource.maturity_date_utc
    ? new Date(selectedFundingSource.maturity_date_utc)
    : null;

  const fundingSourceExclusionCostCodes = populateCostCodesFromExclusions(
    allCostCodes,
    selectedFundingSource.exclusions
  );

  const handleCancelButtonClicked = () => {
    if (isEditModeOn) {
      setIsEditModeOn(false);
      handleCancelChangesButtonClicked();
    } else {
      handleDeleteFundingSourceClicked();
    }
  };

  const handleSaveChangesClicked = () => {
    if (isEditModeOn) {
      handleConfirmSaveChanges();
      setIsEditModeOn(false);
    } else {
      handleEditSourceButtonClicked();
      setIsEditModeOn(true);
    }
  };

  return (
    <Blade isOpen={isOpen} onClose={handleClose}>
      <div className="FundingSourceBlade">
        <div className="top">
          <h5 className="primary-header">{selectedFundingSource.name}</h5>
          <div className="payment-type">{selectedFundingSource.type}</div>
        </div>
        <div className="middle">
          <h6 className="section-header">General details</h6>
          <div className="blade-inputs-container">
            <div className="label-input-container">
              <label>Name</label>
              <TextInput
                value={selectedFundingSource.name}
                isDisabled={!isEditModeOn}
                onChange={(newValue) =>
                  handleFundingSourceEdited(newValue, 'name')
                }
              />
            </div>
            <div className="label-input-container">
              <label>Point of contact</label>
              <TextInput
                value={selectedFundingSource.primary_contact ?? ''}
                isDisabled={!isEditModeOn}
                onChange={(newValue) =>
                  handleFundingSourceEdited(newValue, 'primary_contact')
                }
              />
            </div>
            <div className="label-input-container">
              <label>Type</label>
              {isEditModeOn ? (
                <Select
                  id="cfs-type"
                  onChange={(newValue) =>
                    handleFundingSourceEdited(newValue, 'type')
                  }
                  value={selectedFundingSource.type}
                >
                  {fundingSourceTypes.map((typeOfFundingSource, i) => {
                    return (
                      <option key={typeOfFundingSource}>
                        {typeOfFundingSource}
                      </option>
                    );
                  })}
                </Select>
              ) : (
                <TextInput value={selectedFundingSource.type} isDisabled />
              )}
            </div>
            <div className="label-input-container">
              <label>Payment order</label>
              {isEditModeOn ? (
                <Select
                  value={selectedFundingSource.payment_order_index.toString()}
                  onChange={(newValue) =>
                    handleFundingSourceEdited(newValue, 'payment_order_index')
                  }
                >
                  {allFundingSources.map((fundingSource, i) => {
                    return (
                      <option value={(i + 1).toString()} key={i}>
                        {indexesInEnglish[i]}
                      </option>
                    );
                  })}
                  <option value={(allFundingSources.length + 1).toString()}>
                    {indexesInEnglish[allFundingSources.length]}
                  </option>
                </Select>
              ) : (
                <TextInput
                  value={selectedFundingSource.payment_order_index.toString()}
                  isDisabled
                />
              )}
            </div>
          </div>
          <h6 className="section-header">Financial details</h6>
          <div className="blade-inputs-container">
            <div className="label-input-container">
              <label>Amount</label>
              <CurrencyInput
                showBorderOnDisabled
                inputprops={{
                  placeholder: 'Amount',
                  value: selectedFundingSource.original_amount,
                  disabled: !isEditModeOn,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleFundingSourceEdited(
                      event.target.value,
                      'original_amount'
                    );
                  },
                }}
              />
            </div>
            <div className="label-input-container">
              <label>Overage Responsibility</label>
              <TextInput
                value={selectedFundingSource.overage_responsibility.toString()}
                isDisabled={!isEditModeOn}
                onlyAllowNumbers
                isPercentage
                onChange={(newValue) =>
                  handleFundingSourceEdited(newValue, 'overage_responsibility')
                }
              />
            </div>
            <div className="label-input-container two-columns">
              <div>
                <label>Close date</label>
                {closeDate ? (
                  <CustomDatePicker
                    isDisabled={!isEditModeOn}
                    selectedDate={closeDate}
                    handleDateChange={(newDate: Date) =>
                      handleFundingSourceEdited(newDate, 'close_date_utc')
                    }
                  />
                ) : (
                  <TextInput value={'No close date'} isDisabled />
                )}
              </div>
              <div>
                <label>Maturity date</label>
                {maturityDate ? (
                  <CustomDatePicker
                    isDisabled={!isEditModeOn}
                    selectedDate={maturityDate}
                    handleDateChange={(newDate: Date) =>
                      handleFundingSourceEdited(newDate, 'maturity_date_utc')
                    }
                  />
                ) : (
                  <TextInput value={'No close date'} isDisabled />
                )}
              </div>
            </div>
            <div className="label-input-container">
              <label>Exclusions</label>
              {isEditModeOn ? (
                <CostCodeSelectorMultiSelectInput
                  all_cost_codes={allCostCodes}
                  value={fundingSourceExclusionCostCodes}
                  onCostCodeSelect={(newValue) =>
                    handleFundingSourceEdited(newValue, 'exclusions')
                  }
                  isFullWidth
                  openToLeft
                />
              ) : (
                <TextInput
                  value={`${selectedFundingSource.exclusions.length} exclusion(s) selected`}
                  isDisabled
                />
              )}
            </div>
          </div>
        </div>
        <div className="action-buttons-container">
          <span className="margin-right">
            {isEditModeOn ? (
              <Button
                variant={ButtonVariant.GrayThin}
                label="Cancel"
                onClick={handleCancelButtonClicked}
              />
            ) : (
              <IconButton
                label={'Delete'}
                onClick={handleCancelButtonClicked}
                icon={<TrashCanIcon />}
                variant={IconButtonVariants.errorSecondary}
              />
            )}
          </span>
          <Button
            label={isEditModeOn ? 'Save changes' : 'Edit source'}
            variant={
              isEditModeOn ? ButtonVariant.PrimaryThin : ButtonVariant.GrayThin
            }
            onClick={handleSaveChangesClicked}
          />
        </div>
      </div>
    </Blade>
  );
};

export default FundingSourceBlade;
