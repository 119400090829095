import React from 'react';
import './SwitchButton.scss';

interface SwitchButtonProps {
  checked: boolean;
  onChange: () => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ checked, onChange }) => {
  return (
    <div
      className={`SwitchButton ${checked ? 'checked' : ''}`}
      onClick={onChange}
    >
      <div className={`slider ${checked ? 'slider-checked' : ''}`} />
    </div>
  );
};

export default SwitchButton;
