import React from 'react';
import { BsCheckLg as CheckIcon } from 'react-icons/bs';
import './Checkbox.scss';

interface CheckboxProps {
  isChecked: boolean;
  onClicked?: (e: React.MouseEvent) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ isChecked, onClicked }) => {
  return (
    <div
      className={`CheckBox ${isChecked ? 'checked' : ''}`}
      onClick={(e: React.MouseEvent) => onClicked && onClicked(e)}
    >
      {isChecked && <CheckIcon className="check-icon" />}
    </div>
  );
};

export default Checkbox;
