import { FormattedContract, Contract } from '../types/contractTypes';

export const mapContractToTableData = (
  contracts: Contract[]
): FormattedContract[] => {
  const result: FormattedContract[] = [];
  if (contracts.length === 0) return result;

  for (const contract of contracts) {
    const formattedContract: FormattedContract = {
      contractId: contract.number ?? '-',
      title: contract.title ?? '-',
      status: contract.status ?? '-',
      vendor: contract.vendor?.name ?? '-',
      totalAmount: contract.scheduled_value_total?.toString() ?? '-',
      changeOrderTotal: contract.change_order_total?.toString() ?? '-',
      revisedTotal: contract.revised_total?.toString() ?? '-',
      paidToDate: contract.paid_to_date?.toString() ?? '-',
      currentAmountDue: contract.current_amount_due?.toString() ?? '-',
      remainingPercentage: contract.remaining_percentage?.toString() ?? '-',
    };

    result.push(formattedContract);
  }

  return result;
};
