import { Notification } from '../../types/notificationTypes';

export const filtersToNotificationDataValueMap: Record<
  string,
  keyof Notification
> = {
  Name: 'entity_name',
  Type: 'entity_type',
  'Action Requested': 'action_requested',
  Vendor: 'vendor_name',
  Status: 'status',
};
