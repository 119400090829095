import { ProjectFundingSourceExclusions } from '../types/projectTypes';

export function removeDuplicateExclusions(arr: ProjectFundingSourceExclusions[]) {
  const uniqueMap = new Map<string, boolean>();

  const uniqueArray = arr.reduce(
    (result: ProjectFundingSourceExclusions[], item) => {
      if (!uniqueMap.has(item.cost_code_id)) {
        uniqueMap.set(item.cost_code_id, true);
        result.push(item);
      }
      return result;
    },
    []
  );

  return uniqueArray;
}
