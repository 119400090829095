import React, { useState, useEffect } from 'react';
import './App.scss';
import DrawerNav from '../DrawerNav/DrawerNav';
import AppRouter from '../AppRouter/AppRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from '../../types/appContextTypes';

const App: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [appIsLoaded, setAppIsLoaded] = useState<boolean>(false);
  const [hideNavBar, setHideNavBar] = useState<boolean>(true);
  const computedClasses = isDrawerOpen ? 'App' : 'App closed-drawer';

  // Global application state
  const [appContext, setAppContext] = useState<AppContext | null>(null);

  return (
    <Router>
      <div className={computedClasses}>
        <DrawerNav
          isOpen={isDrawerOpen}
          onDrawerToggleClick={() => setIsDrawerOpen(!isDrawerOpen)}
          hidden={hideNavBar}
          appContext={appContext}
        />
        <main className={`${isDrawerOpen ? 'nav-open' : ''}`}>
          <AppRouter
            appIsLoaded={appIsLoaded}
            setAppIsLoaded={setAppIsLoaded}
            appContext={appContext}
            setAppContext={setAppContext}
            setHideNavBar={setHideNavBar}
          />
        </main>
        <ToastContainer pauseOnFocusLoss={false} style={{ zIndex: 999999 }} />
      </div>
    </Router>
  );
};

export default App;
