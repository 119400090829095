import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { fundingSourceRoute } from './postNewFundingSource';

export const deleteFundingSource = async (id: string) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + fundingSourceRoute;

  const req = {
    ...standardRequestObject,
    method: 'DELETE',
    body: JSON.stringify({
      funding_source_id: id,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
