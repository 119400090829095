import React from 'react';
import { NavLink } from 'react-router-dom';
import './DrawerNavLink.scss';

interface Props {
  to: string;
  displayText: string;
  icon: React.ReactNode;
  isOpen: boolean;
  disabled?: boolean;
}

const DrawerNavLink: React.FC<Props> = ({ to, icon, displayText, isOpen, disabled = false }) => {
  if (disabled) {
    return (
      <div className="disabled-nav-link">
        <span className="icon-container">{icon}</span>{' '}
        <span className={isOpen ? '' : 'hide-text'}>{displayText}</span>
      </div>
    );
  }

  return (
    <NavLink className="DrawerNavLink" to={to}>
      <span className="icon-container">{icon}</span>{' '}
      <span className={isOpen ? '' : 'hide-text'}>{displayText}</span>
    </NavLink>
  );
};

export default DrawerNavLink;
