import { Invoice } from '../../types/invoiceTypes';

export function formatInvoiceFilters(invoices: Invoice[]): {
  ID: string[];
  Name: string[];
  Number: string[];
  Vendor: string[];
  'Draw Package': string[];
  'Draw Package Status': string[];
  Contract: string[];
  'Contract Status': string[];
  Status: string[];
} {
  const idSet: Set<string> = new Set();
  const nameSet: Set<string> = new Set();
  const numberSet: Set<string> = new Set();
  const vendorSet: Set<string> = new Set();
  const drawPackageNameSet: Set<string> = new Set();
  const drawPackageStatusSet: Set<string> = new Set();
  const contractNameSet: Set<string> = new Set();
  const contractStatusSet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();

  invoices.forEach((invoice: Invoice) => {
    let id = invoice?.number;
    if (!id) {
      id = 'None';
    }
    idSet.add(id);

    let name = invoice?.name;
    if (!name) {
      name = 'None';
    }
    nameSet.add(name);

    let number = invoice?.number_from_vendor;
    if (!number) {
      number = 'None';
    }
    numberSet.add(number);

    let vendor = invoice?.vendor?.name;
    if (!vendor) {
      vendor = 'None';
    }
    vendorSet.add(vendor);

    let drawPackageName = invoice?.draw_package?.title;
    if (!drawPackageName) {
      drawPackageName = 'None';
    }
    drawPackageNameSet.add(drawPackageName);

    let drawPackageStatus = invoice?.draw_package?.status;
    if (!drawPackageStatus) {
      drawPackageStatus = 'None';
    }
    drawPackageStatusSet.add(drawPackageStatus);

    let contractName = invoice?.contract?.title;
    if (!contractName) {
      contractName = 'None';
    }
    contractNameSet.add(contractName);

    let contractStatus = invoice?.contract?.status;
    if (!contractStatus) {
      contractStatus = 'None';
    }
    contractStatusSet.add(contractStatus);

    const status = invoice.status;
    statusSet.add(status);
  });

  const idArray: string[] = Array.from(idSet);
  const nameArray: string[] = Array.from(nameSet);
  const numberArray: string[] = Array.from(numberSet);
  const vendorArray: string[] = Array.from(vendorSet);
  const drawPackageArray: string[] = Array.from(drawPackageNameSet);
  const drawPackageStatusArray: string[] = Array.from(drawPackageStatusSet);
  const contractNameArray: string[] = Array.from(contractNameSet);
  const contractStatusArray: string[] = Array.from(contractStatusSet);
  const statusArray: string[] = Array.from(statusSet);

  return {
    ID: idArray,
    Name: nameArray,
    Number: numberArray,
    Vendor: vendorArray,
    'Draw Package': drawPackageArray,
    'Draw Package Status': drawPackageStatusArray,
    Contract: contractNameArray,
    'Contract Status': contractStatusArray,
    Status: statusArray,
  };
}
