import { AiOutlineHome as HomeIcon } from 'react-icons/ai';
import { MdOutlineGroups2 as GroupIcon } from 'react-icons/md';
import {
  BsReceiptCutoff as ReceiptIcon,
  BsDatabase as DatabaseIcon,
  BsTag as TagIcon,
} from 'react-icons/bs';
import { BiCog as CogIcon } from 'react-icons/bi';
import {
  HiOutlineArrowTrendingUp as ArrowTrendingUpIcon,
  HiOutlineInbox as InboxIcon,
  HiOutlineSquare3Stack3D as SquareStackIcon,
} from 'react-icons/hi2';
import { GoMail as MailIcon } from 'react-icons/go';
import UsersPage from '../UsersPage/UsersPage';
import BudgetPage from '../BudgetPage/BudgetPage';
import ContractPage from '../ContractPage/ContractPage';
import ChangeOrdersPage from '../ChangeOrdersPage/ChangeOrdersPage';
import InvoicesPage from '../InvoicesPage/InvoicesPage';
import DrawPackagesPage from '../DrawPackagesPage/DrawPackagesPage';
import OverViewPage from '../OverviewPage/OverViewPage';
import SignInPage from '../SignInPage/SignInPage';
import SettingsPage from '../SettingsPage/SettingsPage';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import VendorsPage from '../VendorsPage/VendorsPage';
import { AppContext } from '../../types/appContextTypes';
import MyInboxPage from '../MyInboxPage/MyInboxPage';
import JoinPage from '../JoinPage/JoinPage';
import React from 'react';

const routes = {
  Overview: {
    path: '/overview',
    element: (context: AppContext) => <OverViewPage appContext={context} />,
    linkTo: () => '/overview',
    displayText: 'Overview',
    icon: <HomeIcon />,
  },
  Budget: {
    path: '/budget',
    element: (context: AppContext) => <BudgetPage appContext={context} />,
    linkTo: () => '/budget',
    displayText: 'Budget',
    icon: <ArrowTrendingUpIcon />,
  },
  Contracts: {
    path: '/contracts',
    element: (context: AppContext) => <ContractPage appContext={context} />,
    linkTo: () => '/contracts',
    displayText: 'Contracts',
    icon: <InboxIcon />,
  },
  ContractsID: {
    path: '/contracts/:id',
    element: (context: AppContext) => <ContractPage appContext={context} />,
    linkTo: () => '/contracts',
    displayText: 'Contracts',
    icon: <InboxIcon />,
  },
  ChangeOrders: {
    path: '/changeorders',
    element: (context: AppContext) => <ChangeOrdersPage appContext={context} />,
    linkTo: () => '/changeorders',
    displayText: 'Change orders',
    icon: <TagIcon />,
  },
  ChangeOrdersID: {
    path: '/changeorders/:id',
    element: (context: AppContext) => <ChangeOrdersPage appContext={context} />,
    linkTo: () => '/changeorders',
    displayText: 'Change orders',
    icon: <TagIcon />,
  },
  InvoicesID: {
    path: '/invoices/:id',
    element: (context: AppContext) => <InvoicesPage appContext={context} />,
    linkTo: () => '/invoices',
    displayText: 'Invoices',
    icon: <ReceiptIcon />,
  },
  Invoices: {
    path: '/invoices',
    element: (context: AppContext) => <InvoicesPage appContext={context} />,
    linkTo: () => '/invoices',
    displayText: 'Invoices',
    icon: <ReceiptIcon />,
  },
  DrawPackagesID: {
    path: '/drawpackages/:id',
    element: (context: AppContext) => <DrawPackagesPage appContext={context} />,
    linkTo: () => '/drawpackages',
    displayText: 'Draw packages',
    icon: <SquareStackIcon />,
  },
  DrawPackages: {
    path: '/drawpackages',
    element: (context: AppContext) => <DrawPackagesPage appContext={context} />,
    linkTo: () => '/drawpackages',
    displayText: 'Draw packages',
    icon: <SquareStackIcon />,
  },
  Users: {
    path: '/users',
    element: (context: AppContext) => <UsersPage appContext={context} />,
    linkTo: () => '/users',
    displayText: 'Users',
    icon: <GroupIcon />,
  },
  Vendors: {
    path: '/vendors',
    element: (context: AppContext) => <VendorsPage appContext={context} />,
    linkTo: () => '/vendors',
    displayText: 'Vendors',
    icon: <DatabaseIcon />,
  },
  Settings: {
    path: '/settings',
    element: (context: AppContext) => <SettingsPage appContext={context} />,
    linkTo: () => '/settings',
    displayText: 'Settings',
    icon: <CogIcon />,
  },
  MyInbox: {
    path: '/inbox',
    element: (context: AppContext) => <MyInboxPage appContext={context} />,
    linkTo: () => '/inbox',
    displayText: 'My Inbox',
    icon: <MailIcon />,
  },
  Login: {
    path: '/login',
    element: () => <SignInPage />,
    linkTo: () => '/login',
    displayText: null,
    icon: null,
  },
  ResetPassword: {
    path: '/reset',
    element: () => <ResetPasswordPage />,
    linkTo: () => '/reset',
    displayText: null,
    icon: null,
  },
  Join: {
    path: '/join',
    element: () => <JoinPage />,
    linkTo: () => '/join',
    displayText: null,
    icon: null,
  },
};

export default routes;
