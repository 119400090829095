import { Row } from '../../components/EditableTable/EditableTableV2';
import { FilterItem, Statuses } from '../../types/sharedTypes';
import { filtersToVendorsMap } from './filtersToVendorsMap';

export const applyVendorFilters = (
  rows: Row[],
  searchboxValue: string,
  activeFilters: FilterItem[]
): Row[] => {
  if (searchboxValue.length === 0 && activeFilters.length === 0) return rows;

  // First apply search
  const lowercaseSearchValue = searchboxValue.toLowerCase();
  const rowsWithSearchApplied = rows.filter(
    (row: Row) =>
      row.rowData.name &&
      row.rowData.name.toLowerCase().includes(lowercaseSearchValue)
  );

  if (activeFilters.length === 0) return rowsWithSearchApplied;

  // Then apply filters
  const rowsWithFiltersApplied: Row[] = [];
  const labelValueMap: Record<string, Set<string>> = {};

  // Filters with the same label should show rows with different values
  // e.g., "Status" could be "Terminated" or "Approved," and we show rows with
  // either of those statuses. If a label is of a different value, then rows
  // must have BOTH of the values. E.g., Status is "Approved" AND Title is "Test title"

  rowsWithSearchApplied.forEach((row: Row) => {
    let shouldRowBeAdded = true;

    activeFilters.forEach((filter: FilterItem) => {
      const { label, value } = filter;
      const dataValueForFilter = filtersToVendorsMap[label];
      let vendorValue = row.rowData[dataValueForFilter];

      if (label === 'Status') {
        vendorValue = row.rowData.is_deactivated
          ? Statuses.Deactivated
          : Statuses.Active;
      }

      if (vendorValue === null || vendorValue === undefined) {
        vendorValue = 'None';
      }

      // Initialize the set for the label if it doesn't exist
      labelValueMap[label] = labelValueMap[label] || new Set();

      // Add the current filter value to the set
      labelValueMap[label].add(value);

      // Row hasn't been added already && row's value matches filter
      if (
        !(
          labelValueMap[label].has(String(vendorValue)) &&
          !rowsWithFiltersApplied.includes(row)
        )
      ) {
        shouldRowBeAdded = false;
      }
    });

    if (shouldRowBeAdded) rowsWithFiltersApplied.push(row);
  });

  return rowsWithFiltersApplied;
};
