export const indexesInEnglish = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Thirteenth',
  'Fourteenth',
  'Fifteenth',
  'Sixteenth',
  'Seventeenth',
  'Eighteenth',
  'Nineteenth',
  'Twentieth',
  'Twenty-First',
  'Twenty-Second',
  'Twenty-Third',
  'Twenty-Fourth',
  'Twenty-Fifth',
  'Twenty-Sixth',
  'Twenty-Seventh',
  'Twenty-Eighth',
  'Twenty-Ninth',
  'Thirtieth',
  'Thirty-First',
  'Thirty-Second',
  'Thirty-Third',
  'Thirty-Fourth',
  'Thirty-Fifth',
  'Thirty-Sixth',
  'Thirty-Seventh',
  'Thirty-Eighth',
  'Thirty-Ninth',
  'Fortieth',
  'Forty-First',
  'Forty-Second',
  'Forty-Third',
  'Forty-Fourth',
  'Forty-Fifth',
  'Forty-Sixth',
  'Forty-Seventh',
  'Forty-Eighth',
  'Forty-Ninth',
  'Fiftieth',
];
