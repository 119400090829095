/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from 'react';
import './ChangeOrdersPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TabBar from '../TabBar/TabBar';
import TextInput from '../TextInput/TextInput';
import { BsFilter as FilterIcon, BsUpload as UploadIcon } from 'react-icons/bs';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import EditableTableV2, { Column, Row } from '../EditableTable/EditableTableV2';
import { formatChangeOrdersForEditableTable } from '../../utils/changeOrders/formatChangeOrdersForEditableTable';
import { ChangeOrder } from '../../types/changeOrderTypes';
import Modal, { ModalSizes } from '../Modal/Modal';
import Card from '../Card/Card';
import Select from '../Select/Select';
import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import { User } from '../../types/userTypes';
import MultiSelect, { OptionType } from '../MultiSelect/MultiSelect';
import axios from 'axios';
import { getAPIBase, standardRequestObject } from '../../services/shared';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import { Attachment } from '../ContractPage/ContractPage';
import { postContractAttachment } from '../../services/contract/postContractAttachment';
import { Contract } from '../../types/contractTypes';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiOutlineCalendar as CalendarIcon } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { postChangeOrder } from '../../services/changeOrders/postChangeOrder';
import { postChangeOrderApproval } from '../../services/changeOrders/postChangeOrderApproval';
import { formatChangeOrdersForBackend } from '../../utils/changeOrders/formatChangeOrdersForBackend';
import { formatDateToCustomString } from '../../utils/formatDateForBackend';
import { createTomorrowdate } from '../../utils/createTomorrowDate';
import { formatChangeOrderLineItemsForEditableTable } from '../../utils/changeOrders/formatChangeOrderLineItemsForEditableTable';
import { putChangeOrder } from '../../services/changeOrders/putChangeOrder';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import { isLastCharADecimal } from '../../utils/misc/isLastCharADecimal';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../../utils/currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { formatContractLineItemsForChangeOrderEditableTable } from '../../utils/changeOrders/formatContractLineItemsForChangeOrderEditableTable';
import { AppContext } from '../../types/appContextTypes';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import { applyChangeOrderFilters } from '../../utils/changeOrders/applyChangeOrderFilters';
import { formatChangeOrderFilters } from '../../utils/changeOrders/formatChangeOrderFilters';
import { sortChangeOrders } from '../../utils/changeOrders/sortChangeOrders';
import { sortRowsByCostCode } from '../../utils/misc/sortRowsByCostCode';
import { BiExport as ExportIcon } from 'react-icons/bi';
import { sortCostCodesByCode } from '../../utils/costCodes/sortCostCodesByCode';

const tabOptions = ['All COs'];

const changeOrdersColumns: Column[] = [
  {
    value: 'ID',
  },
  {
    value: 'Title',
  },
  {
    value: 'Priority',
  },
  {
    value: 'Vendor',
  },
  {
    value: 'Status',
  },
  {
    value: 'Contract',
  },
  {
    value: 'Budget Line Item',
  },
  {
    value: 'Requested Amount',
    className: 'text-align-right',
  },
  {
    value: 'Budgeted Amount',
    className: 'text-align-right',
  },
  {
    value: 'Created On',
  },
  {
    value: 'Approved On',
  },
];

const changeOrdersContractChangesColumns: Column[] = [
  {
    value: 'Cost Code',
  },
  {
    value: 'Description',
  },
  {
    value: 'Original Contract Amount',
  },
  {
    value: 'Requested Changes',
  },
  {
    value: 'Budgeted Changes',
  },
  {
    value: 'Revised Contract Amount',
  },
  {
    value: 'Variance from Contract',
  },
];

const defaultStatus = 'Draft';
const defaultPriority = 'Low';

interface Props {
  appContext: AppContext;
}

const ChangeOrdersPage: React.FC<Props> = ({ appContext }) => {
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const [selectedChangeOrder, setSelectedChangeOrder] =
    useState<ChangeOrder | null>(null);
  const [isChangeOrderModalOpen, setIsChangeOrderModalOpen] =
    useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [changeOrdersModalStatusValue, setChangeOrdersModalStatusValue] =
    useState<string>(defaultStatus);
  const [changeOrdersModalContractValue, setChangeOrdersModalContractValue] =
    useState<string>('');
  const [changeOrdersModalPriorityValue, setChangeOrdersModalPriorityValue] =
    useState<string>(defaultPriority);
  const [changeOrdersModalApproversValue, setChangeOrdersModalApproversValue] =
    useState<string[]>([]);
  const [changeOrdersModalID, setChangeOrdersModalID] = useState<string>('');
  const [changeOrdersModalTitle, setChangeOrdersModalTitle] =
    useState<string>('');
  const [
    changeOrdersModalContractDescription,
    setContractModalContractDescription,
  ] = useState<string>('');
  const [
    changeOrdersModalMultiselectActiveOptions,
    setChangeOrderModalMultiselectActiveOptions,
  ] = useState<OptionType[]>([]);
  const [changeOrdersModalDateValue, setChangeOrdersDateValue] = useState<Date>(
    createTomorrowdate()
  );
  const datepickerRef = useRef<DatePicker | null>(null);
  const [contractChangesRows, setContractChangesRows] = useState<Row[]>([]);
  const { id } = useParams();
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;
  const changeOrdersMap: filtersMap = formatChangeOrderFilters(
    appContext.changeOrders
  );

  useEffect(() => {
    setContractChangesRows(formattedChangeOrderModalTableRows);
  }, [changeOrdersModalContractValue]);

  useEffect(() => {
    // Auto select if there's an ID in the URL
    if (appContext.changeOrders.length === 0) return;
    if (id) {
      const foundChangeOrder = appContext.changeOrders.find(
        (changeOrder: ChangeOrder) => {
          return changeOrder.id === id;
        }
      );
      if (foundChangeOrder) {
        const changeOrderWithSameIDAsURLIndex =
          appContext.changeOrders.findIndex((changeOrder: ChangeOrder) => {
            return changeOrder.id === id;
          });
        handleRowClicked(changeOrderWithSameIDAsURLIndex, { id } as Row);
      } else {
        console.warn(`No change order found with id: ${id}`);
      }
    }
  }, [appContext.changeOrders, id]);

  useEffect(() => {
    // If the user came from my inbox, update browser history so back button navigates back to it
    if (searchParams.get('from') === 'inbox') {
      window.history.pushState(null, '', '/inbox');
      window.history.pushState(null, '', location.pathname);
    }
  }, []);

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const handleDateChange = (date: Date) => {
    setChangeOrdersDateValue(date);
  };

  const handleSubmitChangeOrder = async () => {
    const formattedAttachmentIds = attachments.map(
      (attachment: Attachment) => attachment.id
    );

    const formattedForBackendLineItems = formatChangeOrdersForBackend(
      contractChangesRows,
      changeOrderModalCostCodeOptions
    );

    // If we're updating an existing change order
    if (selectedChangeOrder) {
      const updatedChangeOrder = await putChangeOrder({
        change_order_id: selectedChangeOrder.id,
        title: changeOrdersModalTitle,
        status: changeOrdersModalStatusValue,
        approver_user_ids: changeOrdersModalApproversValue,
        priority: changeOrdersModalPriorityValue,
        number: changeOrdersModalID,
        due_date_utc: formatDateToCustomString(changeOrdersModalDateValue),
        attachment_upload_ids: formattedAttachmentIds,
        line_item_json: formattedForBackendLineItems,
        description: changeOrdersModalContractDescription,
        contract_id: changeOrdersModalContractValue,
      });

      if (updatedChangeOrder.error_message) {
        showErrorMessageToast(updatedChangeOrder.error_message);
      } else {
        showSuccessToastMessage('Successfully updated change order');

        const newChangeOrdersState = appContext.changeOrders.map(
          (changeOrder: ChangeOrder) => {
            if (changeOrder.id === updatedChangeOrder.id) {
              return updatedChangeOrder;
            } else {
              return changeOrder;
            }
          }
        );

        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        setIsChangeOrderModalOpen(false);
        resetModalState();
      }
    }
    // Otherwise create a new change order
    else {
      const newChangeOrder = await postChangeOrder({
        contract_id: changeOrdersModalContractValue,
        title: changeOrdersModalTitle,
        status: changeOrdersModalStatusValue,
        approver_user_ids: changeOrdersModalApproversValue,
        priority: changeOrdersModalPriorityValue,
        number: changeOrdersModalID,
        due_date_utc: formatDateToCustomString(changeOrdersModalDateValue),
        attachment_upload_ids: formattedAttachmentIds,
        line_item_json: formattedForBackendLineItems,
        description: changeOrdersModalContractDescription,
      });

      if (newChangeOrder.error_message) {
        showErrorMessageToast(newChangeOrder.error_message);
      } else {
        showSuccessToastMessage('Successfully created new change order');
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        setIsChangeOrderModalOpen(false);
        resetModalState();
      }
    }
  };

  const handleContractChangesRowChanged = (
    newValue: string,
    rowIndex: number,
    columnIndex: number,
    columnName?: string,
    row?: Row
  ) => {
    const newSOVTableState: Row[] = [...contractChangesRows];
    newSOVTableState[rowIndex].cells[columnIndex].value = newValue;

    if (isLastCharADecimal(newValue)) return;

    if (columnName === 'Requested Changes') {
      newSOVTableState[rowIndex].cells[3].value = newValue;
    } else if (columnName === 'Budgeted Changes') {
      // Recalculate "Revised Contract Amount" with the new "Budgeted Change" value
      const currentContractAmount = newSOVTableState[rowIndex].cells[2].value;

      const newRevisedContractAmount =
        parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(
            currentContractAmount
          )
        ) +
        parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue)
        );

      // Safety check to make sure we don't have NaN
      if (Number.isNaN(newRevisedContractAmount)) {
        newSOVTableState[rowIndex].cells[5].value =
          currentContractAmount.toString();
      } else {
        newSOVTableState[rowIndex].cells[4].value = newValue;
        newSOVTableState[rowIndex].cells[5].value =
          newRevisedContractAmount.toString();
      }
    }

    const revisedContractAmount = newSOVTableState[rowIndex].cells[5].value;
    const currentContractAmount = newSOVTableState[rowIndex].cells[2].value;
    const newVariancefromContractValue =
      parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          revisedContractAmount
        )
      ) -
      parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          currentContractAmount
        )
      );

    newSOVTableState[rowIndex].cells[6].value =
      newVariancefromContractValue.toFixed(2);

    setContractChangesRows(newSOVTableState);
  };

  const handleViewTabClicked = (newView: number) => {
    // handle tab change here
  };

  const handleRowClicked = (rowIndex: number, row: Row) => {
    const newSelectedChangeOrder = appContext.changeOrders.find(
      (changeOrder: ChangeOrder) => changeOrder.id === row.id
    );

    if (!newSelectedChangeOrder) {
      console.warn(`No change order found in state with id of ${row.id}`);
      return;
    }
    setSelectedChangeOrder(newSelectedChangeOrder);

    const formattedAttachments = newSelectedChangeOrder.attachments.map(
      (attachment: any) => ({
        id: attachment.id,
        title: attachment.upload.filename,
        // TODO: Have backend send an interger for filesize
        size: null,
        download_url: attachment.upload.download_url,
      })
    );

    const formattedApprovers = newSelectedChangeOrder.approvers.map(
      (approver: User) => {
        return {
          label: `${approver.first_name} ${approver.last_name}`,
          value: approver.id,
          isComplete: approver.is_approved === true,
        };
      }
    );

    const approverIds = newSelectedChangeOrder.approvers.map(
      (approver: User) => approver.id
    );

    const formattedLineItems = formatChangeOrderLineItemsForEditableTable(
      newSelectedChangeOrder.line_items
    );

    setContractChangesRows(formattedLineItems);
    setChangeOrdersModalApproversValue(approverIds);
    setChangeOrderModalMultiselectActiveOptions(formattedApprovers);
    setAttachments(formattedAttachments);
    setChangeOrdersModalStatusValue(newSelectedChangeOrder.status);
    setChangeOrdersModalContractValue(newSelectedChangeOrder.contract_id);
    setChangeOrdersModalPriorityValue(newSelectedChangeOrder.priority);
    setChangeOrdersModalID(newSelectedChangeOrder.number ?? '');
    setChangeOrdersModalTitle(newSelectedChangeOrder.title);
    setContractModalContractDescription(
      newSelectedChangeOrder.description ?? ''
    );
    setChangeOrdersDateValue(new Date(newSelectedChangeOrder.due_date_utc));

    setIsChangeOrderModalOpen(true);
  };

  const handleCreateChangeOrderButtonClicked = () => {
    setIsChangeOrderModalOpen(true);
  };

  const handleCloseChangeOrderModal = () => {
    setIsChangeOrderModalOpen(false);
    setSelectedChangeOrder(null);
    resetModalState();
  };

  const handleCalendarIconClicked = () => {
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(true);
    }
  };

  const handleContractValueChanged = (newValue: string) => {
    setChangeOrdersModalContractValue(newValue);
  };

  const handleDeleteAttachmentClicked = (id: string) => {
    const newAttachmentsState: Attachment[] = attachments.filter(
      (attachment: Attachment) => attachment.id !== id
    );
    setAttachments(newAttachmentsState);
  };

  const handleUploadAttachment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const uploadResponse = await postContractAttachment(file);
    const uploadUrl = uploadResponse.upload_url;
    const closeUploadUrl = getAPIBase() + 'upload/process';
    const uploadId = uploadResponse.id;

    // Upload to bucket
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file?.type,
      },
    });

    // Close connection to bucket
    const closeUrlRequest = {
      ...standardRequestObject,
      method: 'PUT',
      body: JSON.stringify({
        upload_id: uploadId,
      }),
    };
    const formattedCloseRequest = new Request(closeUploadUrl, closeUrlRequest);
    const res = await fetch(formattedCloseRequest);
    const data = await res.json();

    const newAttachmentsState = [...attachments];
    const newAttachment: Attachment = {
      id: uploadId,
      title: file.name,
      size: file.size,
      download_url: data.download_url,
    };
    newAttachmentsState.push(newAttachment);

    setAttachments(newAttachmentsState);
  };

  const resetModalState = () => {
    setAttachments([]);
    setChangeOrdersModalStatusValue(defaultStatus);
    setChangeOrdersModalContractValue('');
    setChangeOrdersModalPriorityValue(defaultPriority);
    setChangeOrdersModalApproversValue([]);
    setChangeOrdersModalID('');
    setChangeOrdersModalTitle('');
    setContractModalContractDescription('');
    setChangeOrderModalMultiselectActiveOptions([]);
    setChangeOrdersDateValue(createTomorrowdate());
    setContractChangesRows([]);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContractModalContractDescription(event.target.value);
  };

  const handleApproverOptionValueClicked = (newValues: string[]) => {
    setChangeOrdersModalApproversValue(newValues);
  };

  const handleApproveChangeOrder = async () => {
    if (!selectedChangeOrder) return;
    const changeOrderApproval = await postChangeOrderApproval(
      selectedChangeOrder.id
    );

    if (changeOrderApproval.error_message) {
      showErrorMessageToast(changeOrderApproval.error_message);
    } else {
      const newApproval = changeOrderApproval.is_approved;
      const newStatus = changeOrderApproval.status;
      showSuccessToastMessage(
        `Successfully ${newApproval === true ? '' : 'un'}approved change order`
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();

      const currentChangeOrderApprovers = selectedChangeOrder.approvers;
      const updatedChangeOrderApprovers = currentChangeOrderApprovers.map(
        (appr) => {
          if (appr.id == appContext.currentUser.id) {
            appr.is_approved = newApproval;
          }
          return appr;
        }
      );
      setSelectedChangeOrder({
        ...selectedChangeOrder,
        status: newStatus,
        approvers: updatedChangeOrderApprovers,
      });
      setChangeOrdersModalStatusValue(newStatus);
      setChangeOrderModalMultiselectActiveOptions(
        changeOrdersModalMultiselectActiveOptions.map((option: OptionType) => {
          if (option.value == appContext.currentUser.id) {
            option.isComplete = newApproval;
          }
          return option;
        })
      );
    }
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const formattedChangeOrders = sortChangeOrders(
    applyChangeOrderFilters(
      formatChangeOrdersForEditableTable(appContext.changeOrders),
      searchboxValue,
      activeFilters
    )
  );

  const changeOrderModalCostCodeOptions = appContext.currentProject
    ? sortCostCodesByCode(appContext.currentProject.cost_codes)
    : [];
  const changeOrdersApproversOptions = appContext.currentProject
    ? appContext.currentProject.users.map((contractUser: User) => {
        return {
          value: contractUser.id,
          label: `${contractUser.first_name} ${contractUser.last_name}`,
          isComplete: selectedChangeOrder
            ? selectedChangeOrder.approvers.find(
                (appr) => appr.id == contractUser.id
              )?.is_approved === true
            : false,
        };
      })
    : [];
  const hasAttachments = attachments?.length > 0;
  const contractDropDownOptions =
    appContext.contracts.length > 0
      ? appContext.contracts.map((contract: Contract) => {
          return {
            label: contract.title,
            value: contract.id,
          };
        })
      : [];

  const contractLineItems =
    appContext.contracts.find(
      (contract: Contract) => contract.id === changeOrdersModalContractValue
    )?.line_items ?? [];

  // If a change order has line items then we want to use those, otherwise
  // take the line items off a the contract with the selected id
  const formattedChangeOrderModalTableRows =
    selectedChangeOrder?.line_items &&
    selectedChangeOrder?.line_items.length > 0
      ? sortRowsByCostCode(
          formatChangeOrderLineItemsForEditableTable(
            selectedChangeOrder?.line_items
          )
        )
      : sortRowsByCostCode(
          formatContractLineItemsForChangeOrderEditableTable(contractLineItems)
        );

  window.document.title = 'BidSight – Change Orders';
  return (
    <section className="ChangeOrdersPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Change Orders</h4>
          <h5>Create and view your change orders here</h5>
        </div>
        <div>
          {!appContext.isRefetching && appContext.changeOrders.length == 0 ? (
            <div /> // wierd bug where "isRefetching || changeOrders.length == 0" condition isn't trigger a render
          ) : (
            <Button
              label={'Create CO'}
              onClick={handleCreateChangeOrderButtonClicked}
              variant={ButtonVariant.PrimaryThin}
              isDisabled={appContext.isRefetching}
            />
          )}
        </div>
      </div>
      <div className="tab-bar-container">
        <TabBar
          tabOptions={tabOptions}
          activeTabOption={tabOptions[0]}
          onTabClick={handleViewTabClicked}
        />
      </div>
      <div className="search-filter-display-container">
        <div className="search-fitler-container">
          <div className="search-container">
            <TextInput
              placeholder="Search"
              icon={<MagnifyingGlassIcon />}
              onChange={(newValue: string) => setSearchboxValue(newValue)}
            />
          </div>
          <span className="filter-popunder-container">
            <span className="filter-button-container">
              <IconButton
                icon={<FilterIcon />}
                label={'Filters'}
                variant={IconButtonVariants.dotted}
                onClick={() =>
                  setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                }
              />
              <PopUnder
                isOpen={isFilterPopUnderVisible}
                onClose={() => setIsFilterPopUnderVisible(false)}
                optionsMap={changeOrdersMap}
                handleSecondOptionSelected={handleFilterSelected}
                activeFilters={activeFilters}
              />
            </span>
            {hasActiveFilters && (
              <span className="clear-filters-button-container">
                <IconButton
                  icon={<CloseIcon />}
                  label={'Clear filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() => setActiveFilters([])}
                />
              </span>
            )}
          </span>
        </div>
        <IconButton
          icon={<ExportIcon />}
          label={tableIsExporting ? 'Exporting...' : 'Export'}
          variant={IconButtonVariants.dotted}
          onClick={() => setTableIsExporting(true)}
        />
      </div>
      <div className="active-filters-container">
        {activeFilters.map((activeFilter) => (
          <FilterButton
            key={activeFilter.label}
            filter={activeFilter}
            onClick={handleFilterButtonRemoved}
          />
        ))}
      </div>
      <div className="bottom">
        {appContext.isRefetching ? (
          <EditableTableLoadingSkeleton />
        ) : formattedChangeOrders?.length > 0 ? (
          <div className="table-container">
            <EditableTableV2
              columns={changeOrdersColumns}
              rows={formattedChangeOrders}
              isReadOnly
              onTablerowClicked={handleRowClicked}
              tableName={`${appContext.currentProject.name} Change Orders - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
            />
          </div>
        ) : formattedChangeOrders?.length === 0 &&
          appContext.changeOrders.length > 0 ? (
          <div>No change orders found</div>
        ) : (
          <div className="no-change-orders-container">
            <FileIconWithCircle />
            <p className="no-change-orders-added">No change orders</p>
            <p>Create a change order to get started</p>
            <div className="no-change-orders-button-container">
              <Button
                label="Create CO"
                onClick={handleCreateChangeOrderButtonClicked}
                variant={ButtonVariant.PrimaryThin}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isChangeOrderModalOpen}
        primaryHeader={`${
          selectedChangeOrder
            ? `${
                selectedChangeOrder.number
                  ? `CO #${selectedChangeOrder.number} - `
                  : ``
              }${selectedChangeOrder.title}`
            : 'Create a change order'
        }`}
        onClose={handleCloseChangeOrderModal}
        size={ModalSizes.fullScreen}
        hideXButton={true}
        hideButtons={true}
      >
        <div className="modal-change-order-content">
          <div className="top-right-buttons-container">
            <Button
              label="Cancel"
              variant={ButtonVariant.GrayThin}
              onClick={handleCloseChangeOrderModal}
            />
            {!!selectedChangeOrder?.approvers?.find(
              (appr) => appr.id == appContext.currentUser.id
            ) &&
              (() => {
                const approval = selectedChangeOrder.approvers.find(
                  (appr) => appr.id == appContext.currentUser.id
                )!;
                return (
                  <span className="margin-left">
                    <Button
                      label={`${
                        approval.is_approved === true ? 'Unapprove' : 'Approve'
                      }`}
                      variant={ButtonVariant.SecondaryThin}
                      onClick={handleApproveChangeOrder}
                    />
                  </span>
                );
              })()}
            <span className="margin-left">
              <Button
                label={`${selectedChangeOrder ? 'Save' : 'Create'}`}
                variant={ButtonVariant.PrimaryThin}
                onClick={handleSubmitChangeOrder}
              />
            </span>
          </div>

          <div className="margin-bottom">
            <Card>
              <div className="card-header">
                <ChevronDownIcon className="chevron-icon" />
                <span className="label-container">
                  <h6>Change Order Details</h6>
                  <p>Input change order details here</p>
                </span>
              </div>
              <div className="contract-inputs-container">
                <div className="left-half">
                  <div className="row">
                    <span className="label-input-container">
                      <label htmlFor="id-input">Change Order ID</label>
                      <div className="text-input-container">
                        <TextInput
                          id="id-input"
                          placeholder="Sample title"
                          value={changeOrdersModalID}
                          onChange={(newValue: string) =>
                            setChangeOrdersModalID(newValue)
                          }
                        />
                      </div>
                    </span>
                    <span className="label-input-container">
                      <label htmlFor="title-input">Change Order Title</label>
                      <div className="text-input-container">
                        <TextInput
                          id="title-input"
                          placeholder="Sample title"
                          value={changeOrdersModalTitle}
                          onChange={(newValue: string) =>
                            setChangeOrdersModalTitle(newValue)
                          }
                        />
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <span className="label-input-container">
                      <label htmlFor="due-date-input">Due Date</label>
                      <div className="text-input-container">
                        <DatePicker
                          selected={changeOrdersModalDateValue}
                          onChange={handleDateChange}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select a date"
                          className="date-picker"
                          ref={datepickerRef}
                          id="due-date-input"
                        />
                      </div>
                      <CalendarIcon
                        className="calendar-icon"
                        onClick={handleCalendarIconClicked}
                      />
                    </span>
                    <span className="label-input-container">
                      <label htmlFor="status-input">Status</label>
                      <div className="text-input-container">
                        <Select
                          id="status-input"
                          onChange={(newValue: string) =>
                            setChangeOrdersModalStatusValue(newValue)
                          }
                          value={changeOrdersModalStatusValue}
                        >
                          <option>Draft</option>
                          <option>In Review</option>
                          <option>Approved</option>
                          <option>Terminated</option>
                          <option>Disputed</option>
                        </Select>
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <span className="label-input-container">
                      <label htmlFor="priority-input">Priority</label>
                      <div className="text-input-container">
                        <Select
                          id="priority-input"
                          onChange={(newValue: string) =>
                            setChangeOrdersModalPriorityValue(newValue)
                          }
                          value={changeOrdersModalPriorityValue}
                        >
                          <option>Low</option>
                          <option>Medium</option>
                          <option>High</option>
                          <option>Urgent</option>
                        </Select>
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <div className="label-input-container full-width">
                      <label htmlFor="contract-input">Contract</label>
                      <div className="text-input-container">
                        <Select
                          id="contract-input"
                          onChange={(newValue: string) =>
                            handleContractValueChanged(newValue)
                          }
                          value={changeOrdersModalContractValue}
                        >
                          {contractDropDownOptions.map(
                            (dropDownOption: any) => {
                              return (
                                <option
                                  value={dropDownOption.value}
                                  key={dropDownOption.label}
                                >
                                  {dropDownOption.label}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="label-input-container full-width">
                      <label htmlFor="approvers-input">Approvers</label>
                      <div className="text-input-container">
                        <MultiSelect
                          id="approvers-input"
                          options={changeOrdersApproversOptions}
                          selectedOptions={
                            changeOrdersModalMultiselectActiveOptions
                          }
                          setSelectOptions={(newOptions: OptionType[]) =>
                            setChangeOrderModalMultiselectActiveOptions(
                              newOptions
                            )
                          }
                          onChange={(newValue: string[]) =>
                            handleApproverOptionValueClicked(newValue)
                          }
                        />
                      </div>
                      {changeOrdersModalMultiselectActiveOptions.length > 0 && (
                        <span className="approval-status">
                          {`${
                            changeOrdersModalMultiselectActiveOptions.filter(
                              (opt) => opt.isComplete === true
                            ).length
                          }/${
                            changeOrdersModalMultiselectActiveOptions.length
                          } approvers have approved the change order`}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="label-input-container full-width">
                      <label htmlFor="description-input">Description</label>
                      <div className="text-area-container">
                        <textarea
                          placeholder="Enter a description..."
                          onChange={handleDescriptionChange}
                          value={changeOrdersModalContractDescription}
                          id="description-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-half">
                  <div className="area-header">Attachments</div>
                  <div className="drag-and-drop-area">
                    {hasAttachments ? (
                      <>
                        {attachments.map((attachment: Attachment) => {
                          return (
                            <div
                              className="uploaded-attachment"
                              key={attachment.id}
                            >
                              <div>
                                <a
                                  href={
                                    attachment.download_url ||
                                    'https://www.bidsight.io/'
                                  }
                                  target="_blank"
                                  className="attachment-link"
                                >
                                  {attachment.title}
                                </a>
                              </div>
                              <button
                                onClick={() =>
                                  handleDeleteAttachmentClicked(attachment.id)
                                }
                              >
                                <TrashCanIcon className="trash-can-icon" />
                              </button>
                            </div>
                          );
                        })}
                        <div className="upload-container">
                          <label htmlFor="file-upload" className="upload-label">
                            <span className="click-text">Attach more</span>
                          </label>
                          <div>
                            PDF only {'('}max 5gb{')'}
                          </div>
                          <input
                            type="file"
                            id="file-upload"
                            accept=".pdf"
                            className="file-input"
                            max={5000000}
                            onChange={handleUploadAttachment}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="empty-attachments-container">
                        <IconButton icon={<UploadIcon />} />
                        <div className="upload-container">
                          <label htmlFor="file-upload" className="upload-label">
                            <span className="click-text">Click to upload</span>
                          </label>
                          <div>
                            PDF only {'('}max 5gb{')'}
                          </div>
                          <input
                            type="file"
                            id="file-upload"
                            accept=".pdf"
                            className="file-input"
                            max={5000000}
                            onChange={handleUploadAttachment}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {contractChangesRows.length > 0 && (
            <div className="margin-top">
              <Card>
                <div className="card-header">
                  <ChevronDownIcon className="chevron-icon" />
                  <span className="label-container">
                    <h6>Contract Changes</h6>
                    <p>Input change order impact on the contract here</p>
                  </span>
                </div>
                <div className="contract-changes-table-container auto-overflow-x">
                  <EditableTableV2
                    columns={changeOrdersContractChangesColumns}
                    rows={contractChangesRows}
                    onTableCellChanged={handleContractChangesRowChanged}
                    showSummationRow
                  />
                </div>
              </Card>
            </div>
          )}
        </div>
      </Modal>
    </section>
  );
};

export default ChangeOrdersPage;
