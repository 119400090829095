import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { drawPackageRoute } from './getDrawPackages';
import { FormattedForBackendDrawPackage } from '../../types/drawPackageTypes';

export const putDrawPackage = async (
  formattedForBackendInvoice: FormattedForBackendDrawPackage,
  budgetJson: string
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + drawPackageRoute;

  const formattedApprovedUserIds =
    formattedForBackendInvoice.approver_user_ids.length === 0
      ? ''
      : formattedForBackendInvoice.approver_user_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'PUT',
    body: JSON.stringify({
      ...formattedForBackendInvoice,
      funding_source_json: JSON.stringify(
        formattedForBackendInvoice.funding_source_json
      ),
      approver_user_ids: formattedApprovedUserIds,
      budget_json: budgetJson,
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
