import { BudgetLineItem } from '../../../types/budgetTypes';

export const calculatePendingChanges = (
  budgetLineItem: BudgetLineItem
): number => {
  if (!budgetLineItem.children || budgetLineItem.children.length === 0) {
    return budgetLineItem.pending_changes;
  }

  const childrenTotal = budgetLineItem.children.reduce(
    (sum, child) => sum + calculatePendingChanges(child),
    0
  );

  return childrenTotal;
};
