import React from 'react';
import './ProgressStepTracker.scss';
import {
  BsRecordCircleFill as InProgressIcon,
  BsCheckCircleFill as CheckCircleIcon,
} from 'react-icons/bs';

export interface Step {
  label: string;
}

interface Props {
  steps: Step[];
  activeStep: number;
}

const ProgressStepTracker: React.FC<Props> = ({ steps, activeStep }) => {
  return (
    <div
      className="ProgressStepTracker"
      style={{
        gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
      }}
    >
      {steps.map((step: Step, i: number) => {
        return (
          <div className="step-line-container" key={i}>
            <div
              className={`progress-tracker-step ${
                activeStep === i ? 'active' : ''
              }`}
            >
              {activeStep > i ? (
                <CheckCircleIcon className="icon complete" />
              ) : activeStep === i ? (
                <InProgressIcon className="icon active" />
              ) : (
                <InProgressIcon className="icon inactive" />
              )}
              <p>{step.label}</p>
            </div>
            {steps.length - 1 !== i && <div className="line-connector" />}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressStepTracker;
