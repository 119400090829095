import {
  CellType,
  Row,
  RowType,
} from '../../components/EditableTable/EditableTableV2';
import { v4 as uuidv4 } from 'uuid';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';

export const createGrandTotalRow = (rows: Row[]): Row => {
  const grandTotal: Row = {
    type: RowType.grandTotal,
    cells: [],
    id: `dropdown-row-${uuidv4()}`,
  };

  if (!rows || rows.length === 0) {
    return grandTotal;
  }

  const numRows = rows.length;
  const numCells = rows[0].cells.length;

  for (let i = 0; i < numCells; i++) {
    let cellSum = '0';
    let cellType: CellType = CellType.displayText;

    for (let j = 0; j < numRows; j++) {
      let cellValue = rows[j].cells[i].value;

      // Safety catch if there's no cellValue
      if (!cellValue) {
        cellValue = '-';
      }

      // Set the default value to make TypeScript happy
      if (cellType === CellType.displayText) {
        cellType = rows[j].cells[i].type;
      }

      // If it's X type and it's the first cell
      if (
        (cellType === CellType.dropdown && i === 0) ||
        (cellType === CellType.costCodeDropDown && i === 0) ||
        (cellType === CellType.text && i === 0)
      ) {
        cellType = CellType.displayText;
        cellSum = 'Grand Total';
        break;
      }

      // Cell types where we add them all up
      if (cellType === CellType.dollars || cellType === CellType.number) {
        const numericValue = parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(cellValue)
        );
        if (!isNaN(numericValue)) {
          cellSum = (parseFloat(cellSum) + numericValue).toString();
        }
      }

      // Cell types where we write '-' as their value
      if (
        cellType === CellType.percentage ||
        cellType === CellType.text ||
        cellType === CellType.displayText ||
        cellType === CellType.editableText
      ) {
        cellType = CellType.displayText;
        cellSum = '-';
        break;
      }
      // Add more conditions for other cell types as needed
    }

    grandTotal.cells.push({
      value: cellSum,
      type: cellType,
    });
  }

  return grandTotal;
};
