import { Row } from '../../components/EditableTable/EditableTableV2';
import { BudgetLineItem } from '../../types/budgetTypes';
import { formatFinalizedBudgetRow } from '../budget/formatBudgetToEditableTableRows';
import { formatNonFinalizedBudgetRow } from '../budget/formatNonFinalizedBudgetToEditableTable';

const formatBudgetRow = (
  budgetLineItem: BudgetLineItem,
  depth: number,
  isBudgetFinalized: boolean
): Row => {
  const increasedDepth = depth + 1;

  if (isBudgetFinalized) {
    const parentRow = formatFinalizedBudgetRow(budgetLineItem, depth, true);
    if (budgetLineItem.children) {
      parentRow.children = budgetLineItem.children.map((childBudgetLineItem) =>
        formatBudgetRow(childBudgetLineItem, increasedDepth, isBudgetFinalized)
      );
    }
    return parentRow;
  } else {
    const parentRow = formatNonFinalizedBudgetRow(
      budgetLineItem,
      depth,
      [],
      [],
      []
    );
    if (budgetLineItem.children) {
      parentRow.children = budgetLineItem.children.map((childBudgetLineItem) =>
        formatBudgetRow(childBudgetLineItem, increasedDepth, isBudgetFinalized)
      );
    }
    return parentRow;
  }
};

export const formatDrawPackageBudgetForEditableTable = (
  drawPackageBudgets: BudgetLineItem[],
  isBudgetFinalized: boolean
): Row[] => {
  if (!drawPackageBudgets) return [];

  return drawPackageBudgets.map((budgetLineItem) =>
    formatBudgetRow(budgetLineItem, 0, isBudgetFinalized)
  );
};
