import { Contract } from '../../types/contractTypes';

export const getTotalContractAmountForVendor = (
  vendorId: string,
  contracts: Contract[]
): number => {
  return contracts.reduce((totalAmount, contract) => {
    if (contract.vendor_id === vendorId) {
      totalAmount += contract.scheduled_value_total;
    }
    return totalAmount; 
  }, 0); 
};
