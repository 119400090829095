function formatTeamFilters(arr: any[]): {
  Status: string[];
  Title: string[];
  Projects: string[];
  Teams: string[];
} {
  const titleSet: Set<string> = new Set();
  const projectSet: Set<string> = new Set();
  const teamSet: Set<string> = new Set();

  arr.forEach((item) => {
    // Extracting title
    const title = item.organization_title;
    titleSet.add(title);

    // Extracting project count
    const projectCount = item.project_count;
    projectSet.add(projectCount.toString());

    // Extracting teams
    const teams = item.teams;
    teams.forEach((team: string) => {
      teamSet.add(team);
    });
  });

  const titleArray: string[] = Array.from(titleSet);
  const projectArray: string[] = Array.from(projectSet);
  const teamArray: string[] = Array.from(teamSet);

  return {
    Status: ['Active', 'Deactivated', 'Pending'],
    Title: titleArray,
    Projects: projectArray,
    Teams: teamArray,
  };
}

export default formatTeamFilters;
