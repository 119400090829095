import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

export const postPasswordReset = async (email: string, newPassword: string, resetCode: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + 'user/reset';
    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({
            email,
            password: newPassword,
            reset_code: resetCode
        }),
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};
