import React from 'react';
import './Button.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  GrayThin = 'gray-thin',
  PrimaryThin = 'primary-thin',
  SecondaryThin = 'secondary-thin',
  ErrorThin = 'error-thin',
}

interface Props {
  label: string;
  variant?: ButtonVariant;
  onClick: () => void;
  isDisabled?: boolean;
}

const Button: React.FC<Props> = ({
  label,
  variant = ButtonVariant.Primary,
  onClick,
  isDisabled,
}) => {
  const buttonClass = `button ${variant}`;

  return (
    <button onClick={onClick} disabled={isDisabled} className={buttonClass}>
      {label}
    </button>
  );
};

export default Button;
