import { Row } from '../../components/EditableTable/EditableTableV2';
import { convertUTCDateStringToDate } from '../convertUTCDateStringToDate';

export const sortChangeOrders = (rows: Row[]): Row[] => {
  rows.sort((a, b) => {
    const dateA = convertUTCDateStringToDate(a.rowData?.date_utc);
    const dateB = convertUTCDateStringToDate(b.rowData?.date_utc);

    return dateB.getTime() - dateA.getTime();
  });
  return rows;
};
