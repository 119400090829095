import { BudgetLineItem } from '../../types/budgetTypes';
import { CostCode } from '../../types/sharedTypes';
import { createMockBudgetLineItem } from './createMockBudgetLineItem';
import { getBudgetLineItemByCostCodeID } from './getBudgetLineItemByCostCodeID';
import { v4 as uuidv4 } from 'uuid';

export const makeNewBudgetLineItemsFromCostCodes = (
  costCodes: CostCode[],
  allBudgetLineItems: BudgetLineItem[]
): BudgetLineItem[] => {
  const result: BudgetLineItem[] = [];

  costCodes.forEach((costCode: CostCode) => {
    const budgetLineItem = getBudgetLineItemByCostCodeID(
      allBudgetLineItems,
      costCode.id
    );

    result.push(
      createMockBudgetLineItem(
        costCode,
        budgetLineItem ? budgetLineItem.id : uuidv4(),
        true,
        '',
        false,
        0,
        costCode
      )
    );
  });

  return result;
};
