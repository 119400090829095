/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResetPasswordPage.scss';
import TextInput from '../TextInput/TextInput';
import Button, { ButtonVariant } from '../Button/Button';
import { signIn } from '../../services/auth/signIn';
import { toast } from 'react-toastify';
import { postPasswordResetCode } from '../../services/user/postPasswordResetCode';
import { postPasswordReset } from '../../services/user/postPasswordReset';

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [resetCode, setResetCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [sendCodeIsLoading, setSendCodeIsLoading] = useState<boolean>(false);
  const [resetIsLoading, setResetIsLoading] = useState<boolean>(false);
  const [resetCodeSent, setResetCodeSent] = useState<boolean>(false);

  const handleResetButtonClicked = () => {
    if (!email || !resetCode || !newPassword) return;
    if (newPassword != confirmNewPassword) {
      toast(`Passwords do not match`, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
      return;
    }

    setResetIsLoading(true);
    (async () => {
      const updatedUser = await postPasswordReset(
        email,
        newPassword,
        resetCode
      );
      if (updatedUser.error_message) {
        toast(updatedUser.error_message, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: 'error',
        });
        setResetIsLoading(false);
        return;
      }

      localStorage.setItem('active_user_auth_token', updatedUser.auth_token);
      window.location.href = '/budget';
    })();
  };

  const handleSendResetCodeButtonClicked = () => {
    if (!email) return;
    setSendCodeIsLoading(true);
    (async () => {
      await postPasswordResetCode(email);
      setSendCodeIsLoading(false);
      setResetCodeSent(true);

      toast(`A password reset code has been sent to ${email}`, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'success',
      });
    })();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    isReset: boolean
  ) => {
    if (event.key === 'Enter') {
      if (isReset === true) {
        handleResetButtonClicked();
      } else if (isReset === false) {
        handleSendResetCodeButtonClicked();
      }
    }
  };

  const resetPageState = () => {
    setEmail('');
    setResetCode('');
    setNewPassword('');
    setConfirmNewPassword('');
    setSendCodeIsLoading(false);
    setResetIsLoading(false);
    setResetCodeSent(false);
  };

  window.document.title = 'BidSight – Reset Password';
  return (
    <div className="ResetPasswordPage">
      <div className="header-container">
        <img src="https://storage.googleapis.com/public-v1.bidsight.io/BidSightTextLogo.png" />
      </div>
      <div className="body-container">
        <div className="reset-form-container">
          <h1>Reset password</h1>
          {resetCodeSent === false && (
            <>
              <div className="reset-form-input">
                <TextInput
                  placeholder="Email address"
                  onChange={(newEmail: string) => setEmail(newEmail)}
                  value={email}
                  onKeyDown={(event) => handleKeyDown(event, false)}
                />
              </div>
              <div className="reset-form-send-code">
                <Button
                  label={sendCodeIsLoading ? 'Loading...' : 'Continue'}
                  variant={ButtonVariant.GrayThin}
                  onClick={handleSendResetCodeButtonClicked}
                  isDisabled={!email || sendCodeIsLoading}
                />
              </div>
            </>
          )}
          {resetCodeSent === true && (
            <>
              <div className="reset-form-input">
                <TextInput
                  placeholder="Reset code from email"
                  onChange={(code) => setResetCode(code)}
                  value={resetCode}
                  onKeyDown={(event) => handleKeyDown(event, true)}
                />
              </div>
              <div className="reset-form-input">
                <TextInput
                  placeholder="New password"
                  onChange={(password) => setNewPassword(password)}
                  value={newPassword}
                  onKeyDown={(event) => handleKeyDown(event, true)}
                  isPassword
                />
              </div>
              <div className="reset-form-input">
                <TextInput
                  placeholder="Confirm new password"
                  onChange={(password) => setConfirmNewPassword(password)}
                  value={confirmNewPassword}
                  onKeyDown={(event) => handleKeyDown(event, true)}
                  isPassword
                />
              </div>
              <div className="reset-form-submit">
                <Button
                  label={resetIsLoading ? 'Loading...' : 'Continue'}
                  variant={ButtonVariant.GrayThin}
                  onClick={handleResetButtonClicked}
                  isDisabled={
                    !email || !resetCode || !newPassword || resetIsLoading
                  }
                />
              </div>
              <p className="try-again">
                Didn't receive an email?{' '}
                <span onClick={() => resetPageState()}>Try again</span>.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
