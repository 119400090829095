import { CostCode } from '../../types/sharedTypes';

export const getChildCostCodes = (parentCostCode: CostCode): CostCode[] => {
  const allChildren: CostCode[] = [];

  const traverseChildren = (currentCostCode: CostCode): void => {
    allChildren.push(currentCostCode);

    for (const child of currentCostCode.children) {
      traverseChildren(child);
    }
  };

  traverseChildren(parentCostCode);

  return allChildren;
};
