import React, { useState, useEffect } from 'react';
import './MyInboxPage.scss';
import TabBar from '../TabBar/TabBar';
import { AppContext } from '../../types/appContextTypes';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import TextInput from '../TextInput/TextInput';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import { BsFilter as FilterIcon } from 'react-icons/bs';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import EditableTableV2, { Column, Row } from '../EditableTable/EditableTableV2';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import { formatNotificationsForEditableTable } from '../../utils/notifications/formatNotificationsForEditableTable';
import { Notification } from '../../types/notificationTypes';
import { useNavigate } from 'react-router-dom';
import { sortNotifications } from '../../utils/notifications/sortNotifications';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import { formatNotificationFilters } from '../../utils/notifications/formatNotificationFilters';
import { applyNotificationFilters } from '../../utils/notifications/applyNotificationFilters';
import { createNotificationsFromAppContext } from '../../utils/notifications/createNotificationsFromAppContext';

const myInboxColumns: Column[] = [
  {
    value: 'Entity Name',
  },
  {
    value: 'Entity Type',
  },
  {
    value: 'Action Requested',
  },
  {
    value: 'Vendor',
  },
  {
    value: 'Current Status',
  },
  {
    value: 'Amount',
  },
];

interface Props {
  appContext: AppContext;
}

const MyInboxPage: React.FC<Props> = ({ appContext }) => {
  const navigate = useNavigate();
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const hasActiveFilters = activeFilters.length > 0;
  const notificationsMap: filtersMap = formatNotificationFilters(appContext);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const handleVisibilityChange = () => {
    if (window.document.hidden === false) {
      appContext.refetchProjectData(true);
    }
  };

  const handleLinkToEntity = (rowIndex: number, row?: Row) => {
    navigate(row?.rowData.entity_url);
  };

  const getMyInboxTableRows = (): Row[] => {
    const notifications: Notification[] =
      createNotificationsFromAppContext(appContext);

    return formatNotificationsForEditableTable(notifications);
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const inboxTableRows = sortNotifications(
    applyNotificationFilters(
      getMyInboxTableRows(),
      searchboxValue,
      activeFilters
    )
  );

  const handleTabBarClicked = () => {
    return null;
  };

  window.document.title = 'BidSight – My Inbox';
  return (
    <section className="MyInboxPage">
      <div>
        <div className="header-button-container">
          <div className="primary-header-subheader-container">
            <h4 className="main-page-header">My Inbox</h4>
            <h5>All of your action items live here</h5>
          </div>
        </div>
        <div className="tab-bar-container">
          <TabBar
            tabOptions={['All notifications']}
            activeTabOption={'All notifications'}
            onTabClick={handleTabBarClicked}
          />
        </div>
        <div className="search-filter-display-container">
          <div className="search-fitler-container">
            <div className="search-container">
              <TextInput
                placeholder="Search"
                icon={<MagnifyingGlassIcon />}
                onChange={(newValue: string) => setSearchboxValue(newValue)}
              />
            </div>
            <span className="filter-popunder-container">
              <span className="filter-button-container">
                <IconButton
                  icon={<FilterIcon />}
                  label={'Filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() =>
                    setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                  }
                />
                <PopUnder
                  isOpen={isFilterPopUnderVisible}
                  onClose={() => setIsFilterPopUnderVisible(false)}
                  optionsMap={notificationsMap}
                  handleSecondOptionSelected={handleFilterSelected}
                  activeFilters={activeFilters}
                />
              </span>
              {hasActiveFilters && (
                <span className="clear-filters-button-container">
                  <IconButton
                    icon={<CloseIcon />}
                    label={'Clear filters'}
                    variant={IconButtonVariants.dotted}
                    onClick={() => setActiveFilters([])}
                  />
                </span>
              )}
            </span>
          </div>
          <IconButton
            icon={<DisplayIcon />}
            label="Display"
            variant={IconButtonVariants.dotted}
          />
        </div>
        <div className="active-filters-container">
          {activeFilters.map((activeFilter) => (
            <FilterButton
              filter={activeFilter}
              onClick={handleFilterButtonRemoved}
              key={activeFilter.label}
            />
          ))}
        </div>
      </div>
      <div className="bottom">
        {appContext.isRefetching ? (
          <EditableTableLoadingSkeleton />
        ) : inboxTableRows.length > 0 ? (
          <div className="inbox-table-container">
            <EditableTableV2
              isReadOnly
              columns={myInboxColumns}
              rows={inboxTableRows}
              onTablerowClicked={handleLinkToEntity}
            />
          </div>
        ) : (
          <div className="no-notifications-container">
            <FileIconWithCircle />
            <p className="no-notifications">No notifications</p>
            <p>No action is required</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default MyInboxPage;
