export interface FundingSource {
  project_id: string;
  name: string;
  funding_source_type: string;
  // Should be a float but TS doesn't have a float
  overage_responsibility: number;
  original_amount: number;
  ///////
  order_index: number;
  // contact email
  primary_contact?: string;
  close_date_utc?: string;
  maturity_date_utc?: string;
  // comma-seperated list of cost code IDs <UUID1>,<UUID2>,<UUID3>
  cost_code_exclusions?: string;
}

export enum FundingSourceTypes {
  'Equity' = 'Equity',
  'Preferred Equity' = 'Preferred Equity',
  'Debt' = 'Debt',
  'Bond' = 'Bond',
  'Cash flow' = 'Cash flow',
  'Letter of credit' = 'Letter of credit',
  'Working capital' = 'Working capital',
}
