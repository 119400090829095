import { BudgetLineItem } from '../../types/budgetTypes';

export const filterBudgetLineItemsForOutOfPlaceCostCodes = (
  budgetLineItems: BudgetLineItem[],
  parentCode = '',
  parentLevel = 0
): BudgetLineItem[] => {
  return budgetLineItems
    .filter((item) => {
      const codeParts = item.cost_code?.code.split('.');
      const numPeriods = codeParts ? codeParts.length - 1 : 0;
      const targetPeriods = parentLevel + 1;

      if (
        !item.cost_code?.code.startsWith(parentCode) ||
        (numPeriods !== targetPeriods && numPeriods !== 0)
      ) {
        // Remove items with incorrect parent code or exceeding the expected level
        return false;
      }

      if (item.children && item.children.length > 0) {
        // Recursively filter children
        item.children = filterBudgetLineItemsForOutOfPlaceCostCodes(
          item.children,
          item.cost_code.code,
          numPeriods
        );
      }

      return true;
    })
    .sort((a, b) => a.cost_code!.code.localeCompare(b.cost_code!.code)); // Sort by cost code
};
