import { BudgetLineItem } from '../../types/budgetTypes';

export const createHeirarchyOfBudgetLineItemsFromFlatList = (
  flatBudgetLineItems: BudgetLineItem[]
) => {
  if (flatBudgetLineItems.length === 0) {
    return null;
  }

  for (let i = 0; i < flatBudgetLineItems.length; i++) {
    const currentBudgetLineItem = flatBudgetLineItems[i];
    const nextBudgetLineItem = flatBudgetLineItems[i + 1];
    currentBudgetLineItem.isNewlyCreated = false;
    currentBudgetLineItem.isDisabled = true;

    if (nextBudgetLineItem) {
      currentBudgetLineItem.children = [
        ...currentBudgetLineItem.children,
        nextBudgetLineItem,
      ];
    }
  }

  return flatBudgetLineItems;
};
