import { CostCode } from '../../types/sharedTypes';
import { ProjectFundingSourceExclusions } from '../../types/projectTypes';

function findCostCodeById(costCodes: CostCode[], id: string): CostCode | null {
  for (const costCode of costCodes) {
    if (costCode.id === id) {
      return costCode; // Found the costCode
    }

    // Recursively search in children
    const foundInChild = findCostCodeById(costCode.children, id);
    if (foundInChild !== null) {
      return foundInChild; // Found in a child
    }
  }

  return null; // Not found in this subtree
}

export const populateCostCodesFromExclusions = (
  costCodes: CostCode[],
  exclusions: ProjectFundingSourceExclusions[]
): CostCode[] => {
  const result: CostCode[] = [];

  exclusions.forEach((exclusion: ProjectFundingSourceExclusions) => {
    const foundCostCode = findCostCodeById(costCodes, exclusion.cost_code_id);
    if (foundCostCode) {
      result.push(foundCostCode);
    }
  });

  return result;
};
