import React, { ReactNode, useEffect, useRef } from 'react';
import './Modal.scss';
import Button, { ButtonVariant } from '../Button/Button';

export enum ModalSizes {
  large = 'large',
  medium = 'medium',
  fullScreen = 'fullScreen',
  mediumLarge = 'medium-large',
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClicked?: () => void;
  onSecondaryButtonClicked?: () => void;
  primaryButtonLabel?: string;
  primaryHeader?: string;
  secondaryHeader?: string;
  children?: ReactNode;
  secondaryButtonLabel?: string;
  size?: ModalSizes;
  hideXButton?: boolean;
  hideButtons?: boolean;
  primaryButtonType?: ButtonVariant;
  secondaryButtonType?: ButtonVariant;
  disableScrolling?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  primaryButtonLabel = 'Confirm',
  primaryHeader,
  secondaryHeader,
  children,
  secondaryButtonLabel = 'Cancel',
  onPrimaryButtonClicked = () => null,
  onSecondaryButtonClicked = () => null,
  size = ModalSizes.medium,
  hideXButton = false,
  hideButtons = false,
  primaryButtonType = ButtonVariant.Primary,
  secondaryButtonType = ButtonVariant.Secondary,
  disableScrolling,
  isSecondaryButtonDisabled,
  isPrimaryButtonDisabled,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resetScrollPosition = (
      element: HTMLElement | null,
      isVertical: boolean
    ) => {
      if (!element) return;

      // Reset scroll position of the current element
      if (isVertical) {
        element.scrollTop = 0;
      } else {
        element.scrollLeft = 0;
      }

      // Recursively reset scroll position for each child element
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i] as HTMLElement;
        resetScrollPosition(child, isVertical);
      }
    };

    if (isOpen && modalContentRef.current) {
      // Reset both vertical and horizontal scroll positions of the modal content and its children when the modal is opened
      resetScrollPosition(modalContentRef.current, true); // Vertical scroll
      resetScrollPosition(modalContentRef.current, false); // Horizontal scroll
    }
  }, [isOpen]);

  return (
    <div className={`Modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-overlay" onClick={onClose}></div>
      <div
        className={`modal-content ${size}`}
        ref={modalContentRef} // Set the ref here
        style={{
          overflow: `${disableScrolling ? '' : 'auto'}`,
        }}
      >
        <h2 className={`modal-header ${size}`}>{primaryHeader}</h2>
        <p className={`modal-subheaer ${size}`}>{secondaryHeader}</p>
        {!hideXButton && (
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        )}
        <div className="modal-body">{children}</div>
        {!hideButtons && (
          <div className="modal-footer">
            <span className="secondary-button-container">
              <Button
                label={secondaryButtonLabel}
                onClick={onSecondaryButtonClicked}
                variant={secondaryButtonType}
                isDisabled={isSecondaryButtonDisabled}
              />
            </span>
            <Button
              label={primaryButtonLabel}
              onClick={onPrimaryButtonClicked}
              variant={primaryButtonType}
              isDisabled={isPrimaryButtonDisabled}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
