import { BudgetLineItem } from '../../../types/budgetTypes';

export const calculateTotalAmount = (
  budgetLineItem: BudgetLineItem
): number => {
  if (!budgetLineItem.children || budgetLineItem.children.length === 0) {
    return budgetLineItem.total_amount;
  }

  const childrenTotal = budgetLineItem.children.reduce(
    (sum, child) => sum + calculateTotalAmount(child), // Recursively call calculateTotalAmount on each child
    0
  );

  return childrenTotal;
};
