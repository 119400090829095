export const findDuplicateRows = (
  arr1: Array<{ cells: Array<{ value: string }> }>,
  arr2: Array<{ cells: Array<{ value: string }> }>
): Array<{ cells: Array<{ value: string }> }> => {
  const seen: { [key: string]: boolean } = {};
  const duplicates: Array<{ cells: Array<{ value: string }> }> = [];

  arr1.forEach((obj) => {
    const value = obj.cells[0].value;
    seen[value] = true;
  });

  arr2.forEach((obj) => {
    const value = obj.cells[0].value;
    if (seen[value]) {
      duplicates.push(obj);
    }
  });

  return duplicates;
};
