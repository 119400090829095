import { Contract } from '../../types/contractTypes';

export function formatContractFilters(contracts: Contract[]): {
  ID: string[];
  Title: string[];
  Vendor: string[];
  Status: string[];
} {
  const idSet: Set<string> = new Set();
  const titleSet: Set<string> = new Set();
  const vendorSet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();

  contracts.forEach((contract: Contract) => {
    let id = contract?.number;
    if (!id) {
      id = 'None';
    }
    idSet.add(id);

    let title = contract?.title;
    if (!title) {
      title = 'None';
    }
    titleSet.add(title);

    let vendor = contract?.vendor?.name;
    if (!vendor) {
      vendor = 'None';
    }
    vendorSet.add(vendor);

    const status = contract.status;
    statusSet.add(status);
  });

  const idArray: string[] = Array.from(idSet);
  const titleArray: string[] = Array.from(titleSet);
  const vendorArray: string[] = Array.from(vendorSet);
  const statusArray: string[] = Array.from(statusSet);

  return {
    ID: idArray,
    Title: titleArray,
    Vendor: vendorArray,
    Status: statusArray,
  };
}
