import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';

export const contractRoute = 'contract';

export const getContracts = async (projectId: string) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + contractRoute + `?project_id=${projectId}`;

  try {
    const request = new Request(endpoint, standardRequestObject);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
