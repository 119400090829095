import React, { useEffect, useState, useCallback } from 'react';
import './PopUnder.scss';
import { FilterItem } from '../../types/sharedTypes';
import { AiOutlineSearch as MagnifyingGlassIcon } from 'react-icons/ai';
import TextInput from '../TextInput/TextInput';
import Checkbox from '../CheckBox/Checkbox';

export interface filtersMap {
  [key: string]: string[];
}

interface FilterOptionProps {
  isOpen: boolean;
  label: string;
  onClick: (page: string) => void;
}

const FilterOption: React.FC<FilterOptionProps> = ({
  isOpen,
  label,
  onClick,
}) => {
  return (
    <button
      className="row-option"
      tabIndex={isOpen ? 0 : -1}
      onClick={() => onClick(label)}
      key={label}
    >
      {label}
    </button>
  );
};

interface PageTwoFilterOptionsContainerProps {
  filterOptions: string[];
  isOpen: boolean;
  onClick: (page: string) => void;
  activeFilters: FilterItem[];
  pageOneFilter: string | null;
}

const PageTwoFilterOptionsContainer: React.FC<
  PageTwoFilterOptionsContainerProps
> = ({ isOpen, filterOptions, onClick, activeFilters, pageOneFilter }) => {
  const [activeSearch, setActiveSearch] = useState<string>('');

  return (
    <div className="PageTwoFilterOptionsContainer">
      <div className="popunder-search-container">
        <TextInput
          placeholder="Search"
          icon={<MagnifyingGlassIcon />}
          onChange={setActiveSearch}
        />
      </div>
      {filterOptions.map((filterOption) => {
        const isOptionVisibleFromSearch = filterOption
          .toLowerCase()
          .includes(activeSearch);

        if (!isOptionVisibleFromSearch) return null;

        const activeOptionsInCategory = activeFilters.filter(
          (item) => item.label === pageOneFilter
        );
        const isChecked = activeOptionsInCategory.some(
          (item) => item.value === filterOption
        );

        return (
          <div className="checkbox-filter-container" key={filterOption}>
            <span className="checkbox-container">
              <Checkbox
                isChecked={isChecked}
                onClicked={() => onClick(filterOption)}
              />
            </span>
            <div className="filter-option-container">
              <FilterOption
                isOpen={isOpen}
                label={filterOption}
                onClick={onClick}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  optionsMap: filtersMap;
  handleSecondOptionSelected: (filter: FilterItem) => void;
  activeFilters: FilterItem[];
}

const PopUnder: React.FC<Props> = ({
  isOpen,
  onClose,
  optionsMap,
  handleSecondOptionSelected,
  activeFilters,
}) => {
  const [pageOneFilter, setPageOneFilter] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    onClose();
    // Delay this to match the disappearing animation
    setTimeout(() => {
      setPageOneFilter(null);
    }, 200);
  }, [onClose]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleClose]);

  const handlePageOneSelect = (page: string) => {
    setPageOneFilter(page);
  };

  const handlePageTwoSelect = (page: string) => {
    // Throw this in an if statement to protect against case where pageOneFilter is null
    if (pageOneFilter) {
      handleSecondOptionSelected({ label: pageOneFilter, value: page });
    }
  };

  const pageOneFilters = Object.keys(optionsMap).map((filter) => (
    <FilterOption
      key={filter}
      label={filter}
      isOpen={isOpen}
      onClick={handlePageOneSelect}
    />
  ));

  const pageTwoFilters = pageOneFilter && (
    <PageTwoFilterOptionsContainer
      filterOptions={optionsMap[pageOneFilter]}
      pageOneFilter={pageOneFilter}
      isOpen={isOpen}
      onClick={handlePageTwoSelect}
      activeFilters={activeFilters}
    />
  );

  return (
    <>
      {isOpen && <div className="PopUnder-overlay" onClick={handleClose} />}
      <aside className={`PopUnder ${isOpen ? 'open' : ''}`}>
        {pageOneFilter ? pageTwoFilters : pageOneFilters}
      </aside>
    </>
  );
};

export default PopUnder;
