import { CostCode } from '../../types/sharedTypes';

export const isCostCodeIDInArrayOfCostCodes = (
  allCostCodes: CostCode[],
  targetCostCodeId: string
): boolean => {
  const traverseCostCodes = (costCodes: CostCode[]): boolean => {
    for (const costCode of costCodes) {
      if (costCode.id === targetCostCodeId) {
        return true;
      }

      if (costCode.children.length > 0) {
        if (traverseCostCodes(costCode.children)) {
          return true;
        }
      }
    }

    return false;
  };

  return traverseCostCodes(allCostCodes);
};
