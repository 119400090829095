import { Vendor } from '../../types/sharedTypes';

export const getBlankVendor = (): Vendor => {
  return {
    address_line_1: '',
    address_line_2: '',
    city: '',
    id: '',
    is_deactivated: false,
    name: '',
    point_of_contact: '',
    state: '',
    zip_code: '',
    vendor_number: '',
    description: '',
    tax_id: ''
  };
};
