import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { changeOrdersRoute } from './getChangeOrders';
import { FormattedForBackendPUTChangeOrder } from '../../types/changeOrderTypes';

export const putChangeOrder = async (
  formattedForBackendChangeOrder: FormattedForBackendPUTChangeOrder
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + changeOrdersRoute;

  const formattedApprovedUserIds =
    formattedForBackendChangeOrder.approver_user_ids.length === 0
      ? ''
      : formattedForBackendChangeOrder.approver_user_ids.join(',');

  const formattedAttachmentIds =
    formattedForBackendChangeOrder.attachment_upload_ids.length === 0
      ? ''
      : formattedForBackendChangeOrder.attachment_upload_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'PUT',
    body: JSON.stringify({
      ...formattedForBackendChangeOrder,
      approver_user_ids: formattedApprovedUserIds,
      attachment_upload_ids: formattedAttachmentIds,
      line_item_json: JSON.stringify(
        formattedForBackendChangeOrder.line_item_json
      ),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
