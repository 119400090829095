import { Row } from '../../components/EditableTable/EditableTableV2';
import { FilterItem } from '../../types/sharedTypes';
import { filtersToInvoiceDataMap } from './filtersToInvoiceDataMap';

export const applyInvoiceFilters = (
  rows: Row[],
  searchboxValue: string,
  activeFilters: FilterItem[]
): Row[] => {
  if (searchboxValue.length === 0 && activeFilters.length === 0) return rows;

  // First apply search
  const lowercaseSearchValue = searchboxValue.toLowerCase();
  const rowsWithSearchApplied = rows.filter(
    (row: Row) =>
      (row.rowData?.name &&
        row.rowData.name.toLowerCase().includes(lowercaseSearchValue)) ||
      (row.rowData?.number &&
        row.rowData.number.includes(lowercaseSearchValue)) ||
      (row.rowData?.vendor?.name &&
        row.rowData.vendor.name.toLowerCase().includes(lowercaseSearchValue)) ||
      (row.rowData?.draw_package?.title &&
        row.rowData.draw_package.title
          .toLowerCase()
          .includes(lowercaseSearchValue)) ||
      (row.rowData?.contract?.title &&
        row.rowData.contract.title
          .toLowerCase()
          .includes(lowercaseSearchValue)) ||
      (row.rowData?.number_from_vendor &&
        row.rowData.number_from_vendor.includes(lowercaseSearchValue))
  );

  if (activeFilters.length === 0) return rowsWithSearchApplied;

  // Then apply filters
  const rowsWithFiltersApplied: Row[] = [];
  const labelValueMap: Record<string, Set<string>> = {};

  // Filters with the same label should show rows with different values
  // e.g. "Status" could be "Terminated" or "Approved," and we show rows with
  // either of those statuses. If a label is of a different value, then rows
  // must have BOTH of the values. E.g., Status is "Approved" AND Title is "Test title"
  rowsWithSearchApplied.forEach((row: Row) => {
    activeFilters.forEach((filter: FilterItem) => {
      const { label, value } = filter;
      const dataValueForFilter = filtersToInvoiceDataMap[label];
      let invoiceValue = row.rowData[dataValueForFilter];

      if (label === 'Vendor') {
        invoiceValue = row.rowData?.vendor?.name;
      }

      if (label === 'Draw Package') {
        invoiceValue = row.rowData?.draw_package?.title;
      }

      if (label === 'Draw Package Status') {
        invoiceValue = row.rowData?.draw_package?.status;
      }

      if (label === 'Contract') {
        invoiceValue = row.rowData?.contract?.title;
      }

      if (label === 'Contract Status') {
        invoiceValue = row.rowData?.contract?.status;
      }

      if (invoiceValue === null || invoiceValue === undefined) {
        invoiceValue = 'None';
      }

      // Initialize the set for the label if it doesn't exist
      labelValueMap[label] = labelValueMap[label] || new Set();

      // Add the current filter value to the set
      labelValueMap[label].add(value);
    });

    let shouldRowBeAdded = true;

    Object.entries(labelValueMap).forEach(([label, valueSet]) => {
      const dataValueForFilter = filtersToInvoiceDataMap[label];
      let invoiceValue = row.rowData[dataValueForFilter];

      if (label === 'Vendor') {
        invoiceValue = row.rowData?.vendor?.name;
      }

      if (label === 'Draw Package') {
        invoiceValue = row.rowData?.draw_package?.title;
      }

      if (label === 'Draw Package Status') {
        invoiceValue = row.rowData?.draw_package?.status;
      }

      if (label === 'Contract') {
        invoiceValue = row.rowData?.contract?.title;
      }

      if (label === 'Contract Status') {
        invoiceValue = row.rowData?.contract?.status;
      }

      if (invoiceValue === null || invoiceValue === undefined) {
        invoiceValue = 'None';
      }

      if (
        !(
          valueSet.has(String(invoiceValue)) &&
          !rowsWithFiltersApplied.includes(row)
        )
      ) {
        shouldRowBeAdded = false;
      }
    });

    if (shouldRowBeAdded) rowsWithFiltersApplied.push(row);
  });

  return rowsWithFiltersApplied;
};
