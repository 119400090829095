import { ChangeOrder } from '../../types/changeOrderTypes';
import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { formatDateMMDDYYYY } from '../formatDateMMDDYYYY';
import { getBudgetLineItemsLabel } from './getBudgetLineItemsLabel';

export const formatChangeOrdersForEditableTable = (
  changeOrders: ChangeOrder[]
) => {
  const result: Row[] = [];

  if (!changeOrders || changeOrders.length === 0) return result;

  for (let i = 0; i < changeOrders.length; i++) {
    const currentChangeOrder = changeOrders[i];
    const currentRow: Row = {
      cells: [],
      rowData: currentChangeOrder,
      id: currentChangeOrder.id,
    };

    // ID
    currentRow.cells.push({
      value: currentChangeOrder.number,
      type: CellType.number,
    });

    // Title
    currentRow.cells.push({
      value: currentChangeOrder.title,
      type: CellType.text,
    });

    // Priority
    currentRow.cells.push({
      value: currentChangeOrder.priority,
      type: CellType.status,
    });

    // Vendor
    currentRow.cells.push({
      value: currentChangeOrder.vendor.name,
      type: CellType.text,
    });

    // Status
    currentRow.cells.push({
      value: currentChangeOrder.status,
      type: CellType.status,
    });

    // Contract
    currentRow.cells.push({
      value: currentChangeOrder.contract_title,
      type: CellType.text,
    });

    // Budget line item
    currentRow.cells.push({
      value: getBudgetLineItemsLabel(currentChangeOrder.line_items),
      type: CellType.text,
    });

    // Requested amount
    currentRow.cells.push({
      value: currentChangeOrder.requested_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Budgeted amount
    currentRow.cells.push({
      value: currentChangeOrder.budgeted_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Created on
    currentRow.cells.push({
      value: currentChangeOrder.date_utc
        ? formatDateMMDDYYYY(currentChangeOrder.date_utc)
        : '-',
      type: CellType.text,
    });

    // Approved on
    currentRow.cells.push({
      value: currentChangeOrder.approved_on_utc
        ? formatDateMMDDYYYY(currentChangeOrder.approved_on_utc)
        : '-',
      type: CellType.text,
    });

    result.push(currentRow);
  }

  return result;
};
