import { endsWithDotDoubleZero } from './endsWithDotDoubleZero';
import { endsWithDotZero } from './endsWithDotZero';
import { isLastCharADecimal } from './isLastCharADecimal';

// If the user is in the middle of typing a decimal, we don't want to
// parse the number and lose the decimal e.g. parseFloat(1.) will return 1
// which means you can't ever type in the cents place
export const doesDecimalEndWithZero = (string: string): boolean => {
  return (
    isLastCharADecimal(string) ||
    endsWithDotZero(string) ||
    endsWithDotDoubleZero(string) ||
    string === ''
  );
};
