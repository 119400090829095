import { RawContractLineItem } from '../../types/contractTypes';
import { Invoice, InvoiceLineItem } from '../../types/invoiceTypes';
import { getInitialInvoiceLineItemWithCalculations } from './getInitialInvoiceLineItemWithCalculations';

export const formatContractLineItemsIntoInvoiceLineItems = (
  contractLineItems: RawContractLineItem[],
  allInvoices: Invoice[],
  invoice: Invoice | null,
  contractID: string,
  existingLineItems: InvoiceLineItem[]
): InvoiceLineItem[] => {
  const result: InvoiceLineItem[] = [];

  if (!contractLineItems || contractLineItems.length === 0) return result;

  for (let i = 0; i < contractLineItems.length; i++) {
    const contractLineItem = contractLineItems[i];

    const invoiceLineItemWithCalcs = getInitialInvoiceLineItemWithCalculations(
      invoice,
      contractID,
      contractLineItem.cost_code,
      allInvoices,
      0,  // no preset current billing
      contractLineItem.total_amount)

    const matchingExistingLineItem = existingLineItems.find(
      (lineItem) => lineItem.cost_code_id === contractLineItem.cost_code_id);
    if (matchingExistingLineItem) {
      const updatedMatchingLineItem = {...matchingExistingLineItem};
      updatedMatchingLineItem.previously_paid = invoiceLineItemWithCalcs.previously_paid;
      updatedMatchingLineItem.scheduled_value = invoiceLineItemWithCalcs.scheduled_value;
      updatedMatchingLineItem.from_previous_applications = invoiceLineItemWithCalcs.from_previous_applications;
      updatedMatchingLineItem.total_completed_and_stored_to_date = invoiceLineItemWithCalcs.total_completed_and_stored_to_date;
      updatedMatchingLineItem.current_work_and_materials = invoiceLineItemWithCalcs.current_work_and_materials;
      updatedMatchingLineItem.previous_retainage = invoiceLineItemWithCalcs.previous_retainage;
      updatedMatchingLineItem.quoted_line_item = contractLineItem.quoted_line_item;
      result.push(updatedMatchingLineItem);
    } else {
      invoiceLineItemWithCalcs.quoted_line_item = contractLineItem.quoted_line_item;
      result.push(invoiceLineItemWithCalcs);
    }
  }

  return result;
};
