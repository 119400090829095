import { DrawPackage } from "../../types/drawPackageTypes";

export const filtersToDrawPackageDataValueMap: Record<
  string,
  keyof DrawPackage
> = {
  Title: 'title',
  Status: 'status',
  ID: 'number',
};
