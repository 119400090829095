import { BudgetLineItem } from '../../types/budgetTypes';

export const doBudgetLineItemsHaveANullCostCode = (
  budgetLineItems: BudgetLineItem[]
): boolean => {
  for (const item of budgetLineItems) {
    if (item.cost_code === null) {
      return true;
    }

    if (item.children && item.children.length > 0) {
      if (doBudgetLineItemsHaveANullCostCode(item.children)) {
        return true;
      }
    }
  }

  return false;
};
