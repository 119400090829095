import { CostCode } from '../../types/sharedTypes';

export const findPathToChildCostCode = (
  parent: CostCode,
  childId: string,
  path: CostCode[] = []
): CostCode[] => {
  // Add the current cost code to the path
  const currentPath = [...path, parent];

  // Check if the current cost code is the child we're looking for
  if (parent.id === childId) {
    return currentPath;
  }

  // Recursively traverse through children
  for (const child of parent.children) {
    const newPath = findPathToChildCostCode(child, childId, currentPath);

    // If the child is found in the path, return the path
    if (newPath.length > 0) {
      return newPath;
    }
  }

  // If the child is not found in the current path, return an empty array
  return [];
};
