import { RawContractLineItem } from '../types/contractTypes';
import {
  TableCell,
  Row,
  CellType,
  DropdownOption,
} from '../components/EditableTable/EditableTableV2';
import { CostCode } from '../types/sharedTypes';

export const formatContractLineItemsForEditableTable = (
  rawContractLineItems: RawContractLineItem[],
  costCodes: CostCode[]
): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < rawContractLineItems.length; i++) {
    const currentRawContractLineItem = rawContractLineItems[i];
    const currentRow: Row = {
      cells: [],
      id: currentRawContractLineItem.id,
      rowData: currentRawContractLineItem,
    };

    // Cost Code
    currentRow.cells.push({
      value: currentRawContractLineItem.cost_code.id,
      type: CellType.dropdown,
      dropdownOptions: costCodes,
    });

    // Quoted Line Item
    currentRow.cells.push({
      value: currentRawContractLineItem.quoted_line_item ?? '',
      type: CellType.editableText,
    });

    // Original Amount
    currentRow.cells.push({
      value: currentRawContractLineItem.total_amount.toFixed(2),
      type: CellType.dollars,
    });

    // Approved COs
    currentRow.cells.push({
      value: currentRawContractLineItem.approved_changes.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Total Approved Amount
    currentRow.cells.push({
      value: currentRawContractLineItem.total_approved_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Pending COs
    currentRow.cells.push({
      value: currentRawContractLineItem.pending_changes.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Total Projected Amount
    currentRow.cells.push({
      value: currentRawContractLineItem.total_projected_amount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Paid To Date
    currentRow.cells.push({
      value: currentRawContractLineItem.paid_to_date.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Current Amount Due
    currentRow.cells.push({
      value: currentRawContractLineItem.current_amount_due.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Remaining %
    currentRow.cells.push({
      value: currentRawContractLineItem.remaining_percentage.toFixed(2),
      type: CellType.percentage,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
