import { Row, CellType } from '../../components/EditableTable/EditableTableV2';
import { ProjectTeamMember } from '../../types/projectTypes';

export const formatUsersForEditableTable = (
  users: ProjectTeamMember[]
): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < users.length; i++) {
    const currentUser = users[i];
    const currentRow: Row = {
      cells: [],
      id: currentUser.id,
      rowData: currentUser,
    };

    // Name
    currentRow.cells.push({
      value: `${currentUser.first_name} ${currentUser.last_name}`,
      type: CellType.displayText,
    });

    // Title
    currentRow.cells.push({
      value: currentUser.organization_title,
      type: CellType.displayText,
    });

    // Email
    currentRow.cells.push({
      value: currentUser.email,
      type: CellType.displayText,
    });

    // Teams
    currentRow.cells.push({
      value: currentUser.teams.length > 0 ? currentUser.teams.join(' ') : '-',
      type: CellType.displayText,
    });

    // Status
    currentRow.cells.push({
      value: currentUser.is_pending
        ? 'Pending'
        : currentUser.is_deactivated
        ? 'Deactivated'
        : 'Active',
      type: CellType.status,
    });

    result.push(currentRow);
  }

  return result;
};
