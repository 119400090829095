import { Contract } from '../../types/contractTypes';
import { Row, CellType } from '../../components/EditableTable/EditableTableV2';

export const formatContractsToTableRows = (contracts: Contract[]): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < contracts.length; i++) {
    const currentContract = contracts[i];
    const currentRow: Row = {
      cells: [],
      id: currentContract.id,
      rowData: currentContract,
    };

    // Contract ID
    currentRow.cells.push({
      value: currentContract.number ?? '-',
      type: CellType.displayText,
      isCellDisabled: true,
    });

    // Title
    currentRow.cells.push({
      value: currentContract.title ?? '-',
      type: CellType.displayText,
      isCellDisabled: true,
    });

    // Vendor
    currentRow.cells.push({
      value: currentContract?.vendor?.name ?? '-',
      type: CellType.displayText,
      isCellDisabled: true,
    });

    // Status
    currentRow.cells.push({
      value: currentContract.status ?? '-',
      type: CellType.status,
      isCellDisabled: true,
    });

    // Scheduled Value Total
    currentRow.cells.push({
      value: currentContract.scheduled_value_total.toFixed(2) ?? '-',
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Change Order Total
    currentRow.cells.push({
      value: currentContract.change_order_total.toFixed(2) ?? '-',
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Paid to Date
    currentRow.cells.push({
      value: currentContract.paid_to_date.toFixed(2) ?? '-',
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Current Amount Due
    currentRow.cells.push({
      value: currentContract.current_amount_due.toFixed(2) ?? '-',
      type: CellType.dollars,
      isCellDisabled: true,
    });

    // Remaining %
    currentRow.cells.push({
      value: currentContract.remaining_percentage.toFixed(2) ?? '-',
      type: CellType.percentage,
      isCellDisabled: true,
    });

    result.push(currentRow);
  }

  return result;
};
