import { Row } from '../../components/EditableTable/EditableTableV2';

export const sortRowsByCostCode = (rows: Row[]): Row[] => {
  if (!rows) return [];
  // Create a copy of the input array to avoid modifying the original
  const sortedRows = [...rows];

  // Sort the array using a custom comparator function
  sortedRows.sort((a, b) => {
    // Extract the cost code strings
    const codeA = a.rowData?.cost_code?.code || '';
    const codeB = b.rowData?.cost_code?.code || '';

    // Split the cost codes into arrays for comparison
    const partsA = codeA.split('.').map((part: any) => parseInt(part, 10));
    const partsB = codeB.split('.').map((part: any) => parseInt(part, 10));

    // Iterate over the parts and compare them
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || 0; // Default to 0 if no part exists
      const partB = partsB[i] || 0; // Default to 0 if no part exists

      if (partA < partB) {
        return -1; // a should come before b
      } else if (partA > partB) {
        return 1; // b should come before a
      }
      // If parts are equal, continue to the next part
    }

    // If all parts are equal, maintain the original order
    return 0;
  });

  return sortedRows;
};
