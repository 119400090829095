import { ChangeOrder } from '../../../types/changeOrderTypes';
import { Statuses } from '../../../types/sharedTypes';

export const calculateTotalApprovedChangeOrders = (
  changeOrders: ChangeOrder[]
): number => {
  if (!changeOrders) return 0;

  const totalApprovedAmount = changeOrders.reduce((total, changeOrder) => {
    if (changeOrder.status === Statuses.Approved) {
      total += changeOrder.budgeted_amount || 0;
    }
    return total;
  }, 0);

  return totalApprovedAmount;
};
