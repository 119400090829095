import React, { useEffect, useRef } from 'react';
import './ChatBot.scss';
import { AppContext } from '../../types/appContextTypes';
import BidSightLogo from '../../assets/BidSightLogo.png';
import CloseXImage from '../../assets/CloseX.png';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { getHumanTimeAgo } from '../../utils/getHumanTimeAgo';
import { LuSend } from 'react-icons/lu';
import { getBidBotResponse } from '../../services/bidbot/getBidBotResponse';
import parse from 'html-react-parser';

const initialPrompt = "Hi there 👋 I'm BidBot, your virtual search assistant. Have anything you'd like to search for?";

const sharedImageStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
};

interface ChatMessage {
    contentHTML: string;
    isUser: boolean;
    timestamp: Date;
    isVisible: boolean;
}

interface ChatBotProps {
    appContext: AppContext;
}

interface SearchResult {
    field_name: string;
    entity_name: string;
    result_type: string;
    context: string;
    entity_id: string;
}

const ChatBot: React.FC<ChatBotProps> = ({ appContext }) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [initialTimestamp, _] = React.useState<Date>(new Date());
    const [alertIsOpen, setAlertIsOpen] = React.useState<boolean>(false);
    const [chatIsOpen, setChatIsOpen] = React.useState<boolean>(false);
    const [chatMessages, setChatMessages] = React.useState<ChatMessage[]>([{
        contentHTML: initialPrompt,
        isUser: false,
        timestamp: initialTimestamp,
        isVisible: true
    }]);
    const [newMessageContent, setNewMessageContent] = React.useState<string>('');

    useEffect(() => {
        setTimeout(() => setAlertIsOpen(true), 1000);
    }, []);

    useEffect(() => {
        if (!chatIsOpen) return;
        adjustChatScroll(chatMessages);
    }, [chatIsOpen]);

    const submitNewChatMessage = () => {
        if (newMessageContent === '') return;

        const newChatMessages = [...chatMessages];
        newChatMessages.push({
            contentHTML: newMessageContent,
            isUser: true,
            timestamp: new Date(),
            isVisible: false
        });
        setChatMessages(newChatMessages);
        setNewMessageContent('');
        setTimeout(() => adjustChatScroll(newChatMessages), 100);
        promptBidBotAddResponse([...newChatMessages].map((msg) => {
            return {
                ...msg,
                isVisible: true
            };
        }), newMessageContent);
    };

    const promptBidBotAddResponse = async (updatedChatMessages: ChatMessage[], prompt: string) => {
        const newChatMessages = [...updatedChatMessages];
        newChatMessages.push({
            contentHTML: "Searching...",
            isUser: false,
            timestamp: new Date(),
            isVisible: false
        });
        const indexOfSearchingMessage = newChatMessages.length - 1;
        setTimeout(() => setChatMessages(newChatMessages), 100);
        setTimeout(() => adjustChatScroll(newChatMessages), 200);

        const bidBotResponse = await getBidBotResponse(appContext.currentProject.id, prompt);
        if (bidBotResponse.error_message) {
            newChatMessages.push({
                contentHTML: "Hmm, I'm having trouble searching your project. Please contact support@bidsight.io",
                isUser: false,
                timestamp: new Date(),
                isVisible: false
            });
        } else if (bidBotResponse.results.length === 0) {
            newChatMessages.push({
                contentHTML: "I wasn't able to find anything for that search",
                isUser: false,
                timestamp: new Date(),
                isVisible: false
            });
        } else {
            let resultContentHTML = `<p>Here's what I found.</p><br/>`;
            bidBotResponse.results.map((searchResult: SearchResult) => {
                let url = ``;
                if (searchResult.result_type === 'contract') {
                    url = `/contracts/${searchResult.entity_id}`;
                } else if (searchResult.result_type === 'change order') {
                    url = `/changeorders/${searchResult.entity_id}`;
                } else if (searchResult.result_type === 'invoice') {
                    url = `/invoices/${searchResult.entity_id}`;
                } else if (searchResult.result_type === 'draw package') {
                    url = `/drawpackages/${searchResult.entity_id}`;
                }

                // eslint-disable-next-line no-extra-boolean-cast
                const searchTypeMaybeWithLink = !!url ? `<a href="${url}?bidbot" target="_blank">${searchResult.result_type}</a>` : searchResult.result_type;
                const content = `The ${searchResult.field_name} of ${searchTypeMaybeWithLink} ` + (!searchResult.context ? `"` : `"${searchResult.entity_name}" is `);
                const boldContent = !searchResult.context ? searchResult.entity_name : searchResult.context;
                const afterBoldContent = !searchResult.context ? `."` : `.`;
                resultContentHTML += `<p>- ${content}<span>${boldContent}</span>${afterBoldContent}</p><br/>`;
            });
            resultContentHTML = resultContentHTML.slice(0, -5);
            newChatMessages.push({
                contentHTML: resultContentHTML,
                isUser: false,
                timestamp: new Date(),
                isVisible: false
            });
        }

        newChatMessages[indexOfSearchingMessage].isVisible = true;
        setChatMessages(newChatMessages);
        setTimeout(() => adjustChatScroll(newChatMessages), 100);
    };

    const adjustChatScroll = (updatedChatMessages: ChatMessage[]) => {
        const scrollableElement = scrollRef.current;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!scrollableElement) {
            scrollableElement.scrollTop = scrollableElement.scrollHeight;
            setChatMessages(updatedChatMessages.map((msg) => {
                return {
                    ...msg,
                    isVisible: true
                };
            }));
        }
    };

    const bidBotImageAnchorStyle = {
        ...sharedImageStyle,
        backgroundImage: `url(${chatIsOpen ? CloseXImage : BidSightLogo})`,
    };

    const bidBotImageHeaderStyle = {
        ...sharedImageStyle,
        backgroundImage: `url(${BidSightLogo})`,
    };

    return (
        <section className="ChatBot">
            <div
                className="anchor"
                style={bidBotImageAnchorStyle}
                onClick={() => {
                    setAlertIsOpen(false);
                    setChatIsOpen(!chatIsOpen);
                }}
            />
            {alertIsOpen && !chatIsOpen && (
                <>
                    <div
                        className="alert"
                        onClick={() => {
                            setAlertIsOpen(false);
                            setChatIsOpen(!chatIsOpen);
                        }}
                    >
                        <p>{initialPrompt}</p>
                        <div className="arrow" />
                    </div>
                    <div className="alertCloseButton" onClick={() => setAlertIsOpen(false)}>
                        <IoCloseCircleSharp size={18} />
                    </div>
                </>
            )}
            {chatIsOpen && (
                <div className="chatBox">
                    <div
                        className="header"
                        onClick={() => {
                            setChatIsOpen(false);
                            setAlertIsOpen(false);
                        }}
                    >
                        <div className="logo" style={bidBotImageHeaderStyle} />
                        <div className="status" />
                        <p className="title">BidBot</p>
                    </div>
                    <div className="messages" ref={scrollRef}>
                        <p className="chatStart">Today at {_getTimeStringForChat(initialTimestamp)}</p>
                        {chatMessages.map((chatMessage, index) => {
                            const isSameUserMessageAbove = index > 0 && chatMessages[index - 1].isUser === chatMessage.isUser;
                            const isSameUserMessageBelow = index < chatMessages.length - 1 && chatMessages[index + 1].isUser === chatMessage.isUser;
                            return (
                                <div key={`chatMessage = ${index} `} className={`chatMessage for${chatMessage.isUser ? 'User' : 'Bot'} chatMessageFor${chatMessage.isUser ? 'User' : 'Bot'} ${chatMessage.isVisible ? 'visibleMsg' : 'invisibleMsg'} `}>
                                    {!chatMessage.isUser && !isSameUserMessageBelow && (
                                        <div className="avatar" style={bidBotImageHeaderStyle} />
                                    )}
                                    {!chatMessage.isUser && isSameUserMessageBelow && (
                                        <div className="avatarPlaceholder" />
                                    )}
                                    <div className={`contentAndTimestamp for${chatMessage.isUser ? 'User' : 'Bot'} `}>
                                        {!isSameUserMessageAbove && (
                                            <p className={`avatarName for${chatMessage.isUser ? 'User' : 'Bot'} `}>{chatMessage.isUser ? 'You' : 'BidBot'}</p>
                                        )}
                                        <div className="content">
                                            <p>{parse(chatMessage.contentHTML)}</p>
                                        </div>
                                        {!isSameUserMessageBelow && (
                                            <p className={`timestamp for${chatMessage.isUser ? 'User' : 'Bot'} `}>{getHumanTimeAgo(chatMessage.timestamp)}</p>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="messageBox">
                        <input
                            className="messageInput"
                            type="text"
                            placeholder="Type a search phrase..."
                            value={newMessageContent}
                            onChange={(event) => setNewMessageContent(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    submitNewChatMessage();
                                }
                            }}
                        />
                        <LuSend
                            className="sendIcon"
                            size={23}
                            onClick={() => submitNewChatMessage()}
                        />
                    </div>
                </div>
            )
            }
        </section >
    );
};

const _getTimeStringForChat = (dateObj: Date): string => {
    const timeParts = dateObj.toLocaleTimeString().split(' ');
    return `${timeParts[0].slice(0, -3)} ${timeParts[1]} `;
};

export default ChatBot;