import { Row, CellType } from '../../components/EditableTable/EditableTableV2';
import {
  DrawPackageInvoice,
  DrawPackageInvoiceLineItem,
} from '../../types/drawPackageTypes';
import { formatDateMMDDYYYY } from '../formatDateMMDDYYYY';

export const formatDrawPackageInvoicesForTableRow = (
  drawPackageInvoices: DrawPackageInvoice[]
): Row[] => {
  const result: Row[] = [];

  for (let i = 0; i < drawPackageInvoices.length; i++) {
    const currentDrawPackageInvoice: DrawPackageInvoice =
      drawPackageInvoices[i];

    const currentRow: Row = {
      cells: [],
      id: currentDrawPackageInvoice.id,
      rowData: currentDrawPackageInvoice,
    };

    // We want to create a table row for each line item
    // some fields come from the line item and other fields
    // come from the invoice object itself
    if (currentDrawPackageInvoice.line_items.length > 0) {
      for (let j = 0; j < currentDrawPackageInvoice.line_items.length; j++) {
        const currentDrawPackageLineItem: DrawPackageInvoiceLineItem =
          currentDrawPackageInvoice.line_items[j];

        // Don't show $0 invoice line items because they have no
        // impact and the user doesn't want to see them
        if (currentDrawPackageLineItem?.current_billing === 0) continue;

        const currentLineItemRow: Row = {
          cells: [],
          id: currentDrawPackageLineItem.id,
          rowData: currentDrawPackageLineItem,
        };

        // #
        currentLineItemRow.cells.push({
          value: currentDrawPackageInvoice?.number_from_vendor
            ? currentDrawPackageInvoice.number_from_vendor
            : '-',
          type: CellType.text,
          isCellDisabled: true,
          id: currentDrawPackageInvoice.id,
        });

        // Cost Code
        currentLineItemRow.cells.push({
          value: currentDrawPackageLineItem?.cost_code.code
            ? currentDrawPackageLineItem?.cost_code.code
            : '-',
          type: CellType.text,
          isCellDisabled: true,
        });

        // Line Item
        currentLineItemRow.cells.push({
          value: currentDrawPackageLineItem?.cost_code.description
            ? currentDrawPackageLineItem?.cost_code.description
            : '-',
          type: CellType.text,
          isCellDisabled: true,
        });

        // Title
        currentLineItemRow.cells.push({
          value: currentDrawPackageInvoice?.name
            ? currentDrawPackageInvoice.name
            : '-',
          type: CellType.text,
          isCellDisabled: true,
        });

        // Vendor
        currentLineItemRow.cells.push({
          value: currentDrawPackageInvoice?.vendor?.name
            ? currentDrawPackageInvoice.vendor.name
            : '-',
          type: CellType.text,
          isCellDisabled: true,
        });

        // Current Billing
        currentLineItemRow.cells.push({
          value: currentDrawPackageLineItem?.current_billing
            ? currentDrawPackageLineItem.current_billing.toFixed(2)
            : '0',
          type: CellType.dollars,
          isCellDisabled: true,
        });

        // Status
        currentLineItemRow.cells.push({
          value: currentDrawPackageInvoice.status,
          type: CellType.status,
          isCellDisabled: true,
        });

        // Date
        currentLineItemRow.cells.push({
          value: currentDrawPackageInvoice.date_utc
            ? formatDateMMDDYYYY(currentDrawPackageInvoice.date_utc)
            : '-',
          type: CellType.text,
          isCellDisabled: true,
        });

        result.push(currentLineItemRow);
      }
    }
    // If there's no line items then pull the fields from the invoice object itself
    else {
      // Don't show $0 invoice line items because they have no
      // impact and the user doesn't want to see them
      if (currentDrawPackageInvoice?.total_amount === 0) continue;
      // #
      currentRow.cells.push({
        value: currentDrawPackageInvoice?.number_from_vendor
          ? currentDrawPackageInvoice.number_from_vendor
          : '-',
        type: CellType.text,
        isCellDisabled: true,
        id: currentDrawPackageInvoice.id,
      });

      // Cost Code
      currentRow.cells.push({
        value: currentDrawPackageInvoice?.number
          ? currentDrawPackageInvoice.number
          : '-',
        type: CellType.text,
        isCellDisabled: true,
      });

      // Line Item
      currentRow.cells.push({
        value: '-',
        type: CellType.text,
        isCellDisabled: true,
      });

      // Title
      currentRow.cells.push({
        value: currentDrawPackageInvoice?.name
          ? currentDrawPackageInvoice.name
          : '-',
        type: CellType.text,
        isCellDisabled: true,
      });

      // Vendor
      currentRow.cells.push({
        value: currentDrawPackageInvoice?.vendor?.name
          ? currentDrawPackageInvoice.vendor.name
          : '-',
        type: CellType.text,
        isCellDisabled: true,
      });

      // Current Billing
      currentRow.cells.push({
        value: currentDrawPackageInvoice?.total_amount
          ? currentDrawPackageInvoice.total_amount.toFixed(2)
          : '0',
        type: CellType.dollars,
        isCellDisabled: true,
      });

      // Status
      currentRow.cells.push({
        value: currentDrawPackageInvoice.status,
        type: CellType.status,
        isCellDisabled: true,
      });

      // Date
      currentRow.cells.push({
        value: currentDrawPackageInvoice.date_utc
          ? formatDateMMDDYYYY(currentDrawPackageInvoice.date_utc)
          : '-',
        type: CellType.text,
        isCellDisabled: true,
      });

      result.push(currentRow);
    }
  }

  return result;
};
