import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';
import { invoiceRoute } from './getInvoices';

export const postInvoiceReview = async (invoiceId: string) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + invoiceRoute + '/review';

    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({ invoice_id: invoiceId }),
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};