import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { contractRoute } from './getContract';
import { FormattedForBackendContract } from '../../types/contractTypes';

export const postNewContract = async (
  formattedForBackendContract: FormattedForBackendContract
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + contractRoute;

  const formattedApprovedUserIds =
    formattedForBackendContract.approver_user_ids.length === 0
      ? ''
      : formattedForBackendContract.approver_user_ids.join(',');

  const formattedAttachmentIds =
    formattedForBackendContract.attachment_upload_ids.length === 0
      ? ''
      : formattedForBackendContract.attachment_upload_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      ...formattedForBackendContract,
      approver_user_ids: formattedApprovedUserIds,
      attachment_upload_ids: formattedAttachmentIds,
      line_item_json: JSON.stringify(
        formattedForBackendContract.line_item_json
      ),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
