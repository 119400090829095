import { BudgetLineItem } from '../../types/budgetTypes';

export function formatBudgetFilters(
  budgetLineItems: BudgetLineItem[],
  isBudgetFinalized: boolean
): {
  'Cost Code': string[];
  'Line Item': string[];
  Vendor?: string[];
} {
  const costCodeSet: Set<string> = new Set();
  const lineItemSet: Set<string> = new Set();
  const vendorSet: Set<string> = new Set();

  function processBudgetLineItem(budgetLineItem: BudgetLineItem) {
    const costCode = budgetLineItem?.cost_code?.code || 'None';
    costCodeSet.add(costCode);

    const lineItem = budgetLineItem?.cost_code?.description || 'None';
    lineItemSet.add(lineItem);

    const buyoutVendors = budgetLineItem?.buyout_vendor;
    if (!buyoutVendors || buyoutVendors.length === 0) {
      vendorSet.add('None');
    } else {
      buyoutVendors.forEach((buyoutVendor) => {
        vendorSet.add(buyoutVendor);
      });
    }

    budgetLineItem.children.forEach(processBudgetLineItem);
  }

  budgetLineItems.forEach(processBudgetLineItem);

  const costCodeArray: string[] = Array.from(costCodeSet);
  const lineItemArray: string[] = Array.from(lineItemSet);
  const vendorArray: string[] = Array.from(vendorSet);
  if (isBudgetFinalized) {
    return {
      'Cost Code': costCodeArray,
      'Line Item': lineItemArray,
      Vendor: vendorArray,
    };
  } else {
    return {
      'Cost Code': costCodeArray,
      'Line Item': lineItemArray,
    };
  }
}
