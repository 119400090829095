import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';

export const budgetRoute = 'budget';

export const getBudgets = async (projectId: string) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + budgetRoute + `?project_id=${projectId}`;

  try {
    const request = new Request(endpoint, standardRequestObject);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
