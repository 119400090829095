/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import './ContractPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TabBar from '../TabBar/TabBar';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import TextInput from '../TextInput/TextInput';
import { BsFilter as FilterIcon, BsUpload as UploadIcon } from 'react-icons/bs';
import { mapContractToTableData } from '../../utils/mapContractToTableData';
import Modal, { ModalSizes } from '../Modal/Modal';
import Card from '../Card/Card';
import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import Select from '../Select/Select';
import { GoPlus as PlusIcon } from 'react-icons/go';
import { BiTrashAlt as TrashCanIcon } from 'react-icons/bi';
import { Contract } from '../../types/contractTypes';
import { Vendor } from '../../types/sharedTypes';
import { User } from '../../types/userTypes';
import MultiSelect, { OptionType } from '../MultiSelect/MultiSelect';
import { postNewContract } from '../../services/contract/postNewContract';
import { toast } from 'react-toastify';
import { CostCode } from '../../types/sharedTypes';
import { getCostCodeDescriptionByCode } from '../../utils/getCostCodeDescriptionByCode';
import { formatNewContractLineItemsScheduleOfValuesToTableData } from '../../utils/formatNewContractLineItemsScheduleOfValuesToTableData';
import { postContractAttachment } from '../../services/contract/postContractAttachment';
import axios from 'axios';
import { getAPIBase, standardRequestObject } from '../../services/shared';
import EditableTableV2 from '../EditableTable/EditableTableV2';
import { Row, Column } from '../EditableTable/EditableTableV2';
import { formatContractLineItemsForEditableTable } from '../../utils/formatContractLineItemsForEditableTable';
import { formatContractLineItemsForBackend } from '../../utils/formatContractLineItemsForBackend';
import { putContract } from '../../services/contract/putContract';
import { formatContractChangeOrdersForEditableTable } from '../../utils/formatContractChangeOrdersForEditableTable';
import { formatContractInvoicesForEditableTable } from '../../utils/formatContractInvoicesForEditableTable';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getEntireCostCodeObjectByCostCodeId } from '../../utils/getEntireCostCodeObjectByCostCodeId';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import { formatContractsToTableRows } from '../../utils/contract/formatContractsToTableRows';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { findDuplicateRows } from '../../utils/misc/findDuplicateRows';
import { AppContext } from '../../types/appContextTypes';
import { postContractApproval } from '../../services/contract/postContractApproval';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import { formatContractFilters } from '../../utils/contract/formatContractFilters';
import { applyContractFilters } from '../../utils/contract/applyContractFilters';
import { sortContractTableRows } from '../../utils/contract/sortContractTableRows';
import { sortContractInvoices } from '../../utils/contract/sortContractInvoices';
import { sortContractChangeOrders } from '../../utils/contract/sortContractChangeOrders';
import { getCurrentUTCDateTime } from '../../utils/misc/getCurrentUTCDateTime';
import { sortRowsByCostCode } from '../../utils/misc/sortRowsByCostCode';
import CostCodeSelectorInput from '../CostCodeSelectorInput/CostCodeSelectorInput';
import { BiExport as ExportIcon } from 'react-icons/bi';
import { sortCostCodesByCode } from '../../utils/costCodes/sortCostCodesByCode';
import { convertBudgetLineItemsIntoCostCodes } from '../../utils/costCodes/convertBudgetLineItemsIntoCostCodes';

export interface Attachment {
  id: string;
  size?: number | null;
  title: string;
  download_url: string;
}

export interface ContractLineItem {
  costCode: string;
  created_on_utc: string;
  total_amount: string;
  description?: string;
  approved_changes: string;
  total_approved_amount: string;
  pending_changes: string;
  total_projected_amount: string;
  paid_to_date: string;
  current_amount_due: string;
  remaining_percentage: string;
  quoted_line_item?: string;
}

const contractTableColumns: Column[] = [
  {
    value: 'Contract ID',
  },
  {
    value: 'Title',
  },
  {
    value: 'Vendor',
  },
  {
    value: 'Status',
  },
  {
    value: 'Scheduled Value Total',
    className: 'text-align-right',
  },
  {
    value: 'Change Order Total',
    className: 'text-align-right',
  },
  {
    value: 'Paid to Date',
    className: 'text-align-right',
  },
  {
    value: 'Current Amount Due',
    className: 'text-align-right',
  },
  {
    value: 'Remaining %',
  },
];

const sovEditableColumns: Column[] = [
  // Blank column for actions column
  {
    value: '',
    className: 'actions-column',
  },
  {
    value: 'Cost Code',
    className: 'cost-code-selector-column',
  },
  {
    value: 'Quoted Line Item',
  },
  {
    value: 'Original Amount',
    className: 'text-align-right',
  },
  {
    value: 'Approved COs',
    className: 'text-align-right',
  },
  {
    value: 'Total Approved Amount',
    className: 'text-align-right',
  },
  {
    value: 'Pending COs',
    className: 'text-align-right',
  },
  {
    value: 'Total Projected Amount',
    className: 'text-align-right',
  },
  {
    value: 'Paid To Date',
  },
  {
    value: 'Current Amount Due',
    className: 'text-align-right',
  },
  {
    value: 'Remaining %',
    className: 'text-align-right',
  },
];

const changeOrdersColumns: Column[] = [
  {
    value: 'ID',
  },
  {
    value: 'Title',
  },
  {
    value: 'Total Amount',
    className: 'text-align-right',
  },
  {
    value: 'Status',
  },
  {
    value: 'Date',
  },
];

const emptyContractLineItemState: ContractLineItem[] = [
  {
    costCode: '',
    total_amount: '',
    description: '',
    approved_changes: '',
    total_approved_amount: '',
    pending_changes: '',
    total_projected_amount: '',
    paid_to_date: '',
    current_amount_due: '',
    remaining_percentage: '',
    created_on_utc: getCurrentUTCDateTime(),
  },
];

const defaultContractModalStatus = 'Draft';

interface Props {
  appContext: AppContext;
}

const ContractPage: React.FC<Props> = ({ appContext }) => {
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    null
  );
  const [isContractModalVisible, setIsContractModalVisible] =
    useState<boolean>(false);
  const [isLineItemsModalVisible, setIsLineItemsModalVisible] =
    useState<boolean>(false);
  const [sovTableRows, setSovTableRows] = useState<Row[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [contractModalVendorValue, setContractModalVendorValue] =
    useState<string>('');
  const [contractModalStatusValue, setContractModalStatusValue] =
    useState<string>(defaultContractModalStatus);
  const [contractModalApproversValue, setContractModalApproversValue] =
    useState<string[]>([]);
  const [contractModalContractID, setContractModalContractID] =
    useState<string>('');
  const [contractModalContractTitle, setContractModalContractTitle] =
    useState<string>('');
  const [
    contractModalContractDescription,
    setContractModalContractDescription,
  ] = useState<string>('');
  const [
    contractModalMultiselectActiveOptions,
    setContractModalMultiselectActiveOptions,
  ] = useState<OptionType[]>([]);
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;
  const contractsMap: filtersMap = formatContractFilters(appContext.contracts);
  // LineItems modal
  const [lineItemsModalNewLineItems, setLineItemsModalNewLineItems] = useState<
    ContractLineItem[]
  >(emptyContractLineItemState);

  const { id } = useParams();

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const handleViewTabClicked = (newView: number) => {
    // handle tab change here
  };

  const handleCreateContractClicked = () => {
    setIsContractModalVisible(true);
  };

  const handleContractModalClose = () => {
    setIsContractModalVisible(false);
    setIsLineItemsModalVisible(false);

    // Wait for fade out animation to play
    setTimeout(() => {
      setLineItemsModalNewLineItems(emptyContractLineItemState);
      setSelectedContract(null);
      clearContractModalState();
    }, 200);
  };

  const handleCloseLineItemsModal = () => {
    setIsLineItemsModalVisible(false);
  };

  const handleApproverOptionValueClicked = (newValues: string[]) => {
    setContractModalApproversValue(newValues);
  };

  const handleUploadAttachment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const uploadResponse = await postContractAttachment(file);
    const uploadUrl = uploadResponse.upload_url;
    const closeUploadUrl = getAPIBase() + 'upload/process';
    const uploadId = uploadResponse.id;

    // Upload to bucket
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file?.type,
      },
    });

    // Close connection to bucket
    const closeUrlRequest = {
      ...standardRequestObject,
      method: 'PUT',
      body: JSON.stringify({
        upload_id: uploadId,
      }),
    };
    const formattedCloseRequest = new Request(closeUploadUrl, closeUrlRequest);
    const res = await fetch(formattedCloseRequest);
    const data = await res.json();

    const newAttachmentsState = [...attachments];
    const newAttachment: Attachment = {
      id: uploadId,
      title: file.name,
      size: file.size,
      download_url: data.download_url,
    };
    newAttachmentsState.push(newAttachment);

    setAttachments(newAttachmentsState);
  };

  const handleDeleteAttachmentClicked = (id: string) => {
    const newAttachmentsState: Attachment[] = attachments.filter(
      (attachment: Attachment) => attachment.id !== id
    );
    setAttachments(newAttachmentsState);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContractModalContractDescription(event.target.value);
  };

  const handleAddAnotherLineItemClicked = () => {
    if (lineItemsModalNewLineItems.length >= 5) {
      showErrorMessageToast('Can add max of 5 line items at once');
      return;
    }
    const newState = [
      ...lineItemsModalNewLineItems,
      emptyContractLineItemState[0],
    ];
    setLineItemsModalNewLineItems(newState);
  };

  const handleAddLineItemsSubmit = async () => {
    const formattedLineItems: Row[] =
      formatNewContractLineItemsScheduleOfValuesToTableData(
        lineItemsModalNewLineItems,
        contractModalCostCodeOptions,
        appContext.changeOrders,
        appContext.invoices
      );

    const duplicateRows = findDuplicateRows(sovTableRows, formattedLineItems);

    if (duplicateRows.length > 0) {
      showErrorMessageToast('Duplicate cost code(s) found');
      return;
    }

    const newState: Row[] = [...sovTableRows, ...formattedLineItems];
    const sortedSOVRows = sortRowsByCostCode(newState);
    setSovTableRows(sortedSOVRows);

    setIsLineItemsModalVisible(false);
    setLineItemsModalNewLineItems(emptyContractLineItemState);
  };

  const handleSubmitContract = async () => {
    const formattedAttachmentIds = attachments.map(
      (attachment: Attachment) => attachment.id
    );
    const originalContractFromBackend = appContext.contracts.find(
      (contract: Contract) => contract.id === selectedContract?.id
    );
    const formattedForBackendLineItems = formatContractLineItemsForBackend(
      sovTableRows,
      originalContractFromBackend?.line_items ?? []
    );

    // Save existing if we're editing an existing contract
    if (selectedContract) {
      const updatedContract = await putContract(
        {
          project_id: appContext.currentProject.id,
          title: contractModalContractTitle,
          status: contractModalStatusValue,
          number: contractModalContractID,
          description: contractModalContractDescription,
          vendor_id: contractModalVendorValue,
          approver_user_ids: contractModalApproversValue,
          attachment_upload_ids: formattedAttachmentIds,
          line_item_json: formattedForBackendLineItems,
        },
        selectedContract.id
      );

      if (updatedContract.error_message) {
        showErrorMessageToast(updatedContract.error_message);
      } else {
        const newContractsState: Contract[] = appContext.contracts.map(
          (contract: Contract) =>
            contract.id === updatedContract.id ? updatedContract : contract
        );
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        showSuccessToastMessage('Successfully updated contract');
        handleContractModalClose();
      }
    }
    // Otherwise create a new contract
    else {
      const newCreatedContract = await postNewContract({
        project_id: appContext.currentProject.id,
        title: contractModalContractTitle,
        status: contractModalStatusValue,
        number: contractModalContractID,
        description: contractModalContractDescription,
        vendor_id: contractModalVendorValue,
        approver_user_ids: contractModalApproversValue,
        attachment_upload_ids: formattedAttachmentIds,
        line_item_json: formattedForBackendLineItems,
      });
      if (newCreatedContract.error_message) {
        showErrorMessageToast(newCreatedContract.error_message);
      } else {
        const newContractsState = [...appContext.contracts, newCreatedContract];
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        showSuccessToastMessage('Successfully created new contract');
        handleContractModalClose();
      }
    }
  };

  const handleApproveContract = async () => {
    if (!selectedContract) return;
    const contractApproval = await postContractApproval(selectedContract.id);

    if (contractApproval.error_message) {
      showErrorMessageToast(contractApproval.error_message);
    } else {
      const newApproval = contractApproval.is_approved;
      const newStatus = contractApproval.status;
      showSuccessToastMessage(
        `Successfully ${newApproval === true ? '' : 'un'}approved contract`
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();

      const currentContractApprovers = selectedContract.approvers;
      const updatedContractApprovers = currentContractApprovers.map((appr) => {
        if (appr.id == appContext.currentUser.id) {
          appr.is_approved = newApproval;
        }
        return appr;
      });
      setSelectedContract({
        ...selectedContract,
        status: newStatus,
        approvers: updatedContractApprovers,
      });

      setContractModalStatusValue(newStatus);
      setContractModalMultiselectActiveOptions(
        contractModalMultiselectActiveOptions.map((option: OptionType) => {
          if (option.value == appContext.currentUser.id) {
            option.isComplete = newApproval;
          }
          return option;
        })
      );
    }
  };

  const clearContractModalState = () => {
    setAttachments([]);
    setContractModalVendorValue('');
    setContractModalApproversValue([]);
    setContractModalStatusValue(defaultContractModalStatus);
    setContractModalContractID('');
    setContractModalContractTitle('');
    setContractModalContractDescription('');
    setContractModalMultiselectActiveOptions([]);
    setSovTableRows([]);
  };

  const handleSOVTableCellChanged = (
    newValue: string,
    rowIndex: number,
    columnIndex: number
  ) => {
    const newSOVTableState: Row[] = [...sovTableRows];
    newSOVTableState[rowIndex].cells[columnIndex].value = newValue;
    // TODO: Make this not dependent on weird column math
    // Need to add 1 to the column index bc there's a blank column for the actions column
    const columnName = sovEditableColumns[columnIndex + 1].value;
    // Switching the cost code should automatically update the value of description
    // e.g. choosing a different code (0.002) from the dropdown of cost codes
    // should automatically swap the description (Electrical stuff)
    if (columnName === 'Cost Code') {
      const newDescriptionValue = getEntireCostCodeObjectByCostCodeId(
        newValue,
        contractModalCostCodeOptions
      )?.description;

      // The index "1" here is what column number description is in
      newSOVTableState[rowIndex].cells[1].value = newDescriptionValue ?? '';
    }

    // Any time amount is updated, the total balance to finish should be the same
    if (columnName === 'Amount') {
      newSOVTableState[rowIndex].cells[8].value = newValue;
    }

    const sortedSOVRows = sortRowsByCostCode(newSOVTableState);

    setSovTableRows(sortedSOVRows);
  };

  const handleRowClicked = (contractID: string) => {
    const newSelectedContract = appContext.contracts.find(
      (contract: Contract) => contract.id === contractID
    );

    if (!newSelectedContract) {
      console.warn(`Unable to find contract with ID: ${contractID}`);
      return;
    }

    setSelectedContract(newSelectedContract);
    setContractModalContractID(newSelectedContract.number);
    setContractModalContractDescription(newSelectedContract.description ?? '');
    setContractModalContractTitle(newSelectedContract.title);
    setContractModalVendorValue(newSelectedContract.vendor_id ?? '');
    setContractModalStatusValue(newSelectedContract.status);

    const formattedContractModalMultiselectActiveOptions =
      newSelectedContract.approvers.map((approver: User) => {
        return {
          label: `${approver.first_name} ${approver.last_name}`,
          value: approver.id,
          isComplete: approver.is_approved === true,
        };
      });

    setContractModalMultiselectActiveOptions(
      formattedContractModalMultiselectActiveOptions
    );

    const formattedAttachments = newSelectedContract.attachments.map(
      (attachment: any) => ({
        id: attachment.id,
        title: attachment.upload.filename,
        // TODO: Have backend send an interger for filesize
        size: null,
        download_url: attachment.upload.download_url,
      })
    );
    setAttachments(formattedAttachments);

    const formattedLineItemsForSOVTable: Row[] =
      formatContractLineItemsForEditableTable(
        newSelectedContract.line_items,
        contractModalCostCodeOptions
      );

    const sortedSovTableRows = sortRowsByCostCode(
      formattedLineItemsForSOVTable
    );
    setSovTableRows(sortedSovTableRows);

    setIsContractModalVisible(true);
  };

  const handleNewLineItemChanged = (
    newValue: string,
    lineItemKey: keyof ContractLineItem,
    index: number
  ) => {
    const newLineItem = { ...lineItemsModalNewLineItems[index] };
    if (lineItemKey === 'costCode') {
      newLineItem.description = getCostCodeDescriptionByCode(
        contractModalCostCodeOptions,
        newValue
      );
    }

    newLineItem[lineItemKey] = newValue;
    const newLineItemState = [...lineItemsModalNewLineItems];
    newLineItemState[index] = newLineItem;
    setLineItemsModalNewLineItems(newLineItemState);
  };

  const handleContractSOVDeleteLineItem = (
    event: React.MouseEvent,
    row: Row
  ) => {
    const newContractSOVLineItemsState = sovTableRows.filter(
      (sovTableRow: any) => sovTableRow.id !== row.id
    );

    const sortedSOVRows = sortRowsByCostCode(newContractSOVLineItemsState);

    setSovTableRows(sortedSOVRows);
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  useEffect(() => {
    // Auto select if there's an ID in the URL
    if (!appContext.contracts || appContext.contracts.length === 0) return;
    if (id) {
      const foundContract = appContext.contracts.find((contract: Contract) => {
        return contract.id === id;
      });
      if (foundContract) {
        handleRowClicked(foundContract.id);
      } else {
        console.warn(`No contract found with id: ${id}`);
      }
    }
  }, [appContext.contracts, id]);

  useEffect(() => {
    // If the user came from my inbox, update browser history so back button navigates back to it
    if (searchParams.get('from') === 'inbox') {
      window.history.pushState(null, '', '/inbox');
      window.history.pushState(null, '', location.pathname);
    }
  }, []);

  const formattedContractTableData = sortContractTableRows(
    applyContractFilters(
      formatContractsToTableRows(appContext.contracts),
      searchboxValue,
      activeFilters
    )
  );

  const formattedTableData = mapContractToTableData(appContext.contracts);
  const tabOptions = ['All contracts'];
  const hasAttachments = attachments.length > 0;
  const contractModalVendorOptions = appContext.currentProject.vendors.filter(
    (vendor: Vendor) => vendor.is_deactivated === false
  );

  const contractModalCostCodeOptions = sortCostCodesByCode(
    appContext.currentProject.cost_codes
  );
  const contractModalUserOptions = appContext.currentProject.users.map(
    (contractUser: User) => {
      return {
        value: contractUser.id,
        label: `${contractUser.first_name} ${contractUser.last_name}`,
        isComplete: selectedContract
          ? selectedContract.approvers?.find(
              (appr) => appr.id == contractUser.id
            )?.is_approved === true
          : false,
      };
    }
  );
  const formattedForEditableTableChangeOrders = selectedContract
    ? sortContractChangeOrders(
        formatContractChangeOrdersForEditableTable(
          selectedContract?.change_orders
        )
      )
    : [];

  const formattedForEditableTableInvoices = selectedContract
    ? sortContractInvoices(
        formatContractInvoicesForEditableTable(selectedContract?.invoices)
      )
    : [];

  window.document.title = 'BidSight – Contracts';
  return (
    <section className="ContractPage">
      <div>
        <div className="header-button-container">
          <div className="primary-header-subheader-container">
            <h4 className="main-page-header">Contracts</h4>
            <h5>Create and view your contracts here</h5>
          </div>
          <div>
            <span className="edit-button-container">
              {/* <Button
              label={'Upload contract'}
              onClick={() => console.log('upload contract clicked')}
              variant={ButtonVariant.GrayThin}
            /> */}
            </span>
            {!appContext.isRefetching && appContext.contracts.length == 0 ? (
              <div /> // wierd bug where "isRefetching || contracts.length == 0" condition isn't trigger a render
            ) : (
              <Button
                label={'Create contract'}
                onClick={handleCreateContractClicked}
                variant={ButtonVariant.PrimaryThin}
                isDisabled={appContext.isRefetching}
              />
            )}
          </div>
        </div>
        <div className="tab-bar-container">
          <TabBar
            tabOptions={tabOptions}
            activeTabOption={tabOptions[0]}
            onTabClick={handleViewTabClicked}
          />
        </div>
        <div className="search-filter-display-container">
          <div className="search-fitler-container">
            <div className="search-container">
              <TextInput
                placeholder="Search"
                icon={<MagnifyingGlassIcon />}
                onChange={(newValue: string) => setSearchboxValue(newValue)}
              />
            </div>
            <span className="filter-popunder-container">
              <span className="filter-button-container">
                <IconButton
                  icon={<FilterIcon />}
                  label={'Filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() =>
                    setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                  }
                />
                <PopUnder
                  isOpen={isFilterPopUnderVisible}
                  onClose={() => setIsFilterPopUnderVisible(false)}
                  optionsMap={contractsMap}
                  handleSecondOptionSelected={handleFilterSelected}
                  activeFilters={activeFilters}
                />
              </span>
              {hasActiveFilters && (
                <span className="clear-filters-button-container">
                  <IconButton
                    icon={<CloseIcon />}
                    label={'Clear filters'}
                    variant={IconButtonVariants.dotted}
                    onClick={() => setActiveFilters([])}
                  />
                </span>
              )}
            </span>
          </div>
          <IconButton
            icon={<ExportIcon />}
            label={tableIsExporting ? 'Exporting...' : 'Export'}
            variant={IconButtonVariants.dotted}
            onClick={() => setTableIsExporting(true)}
          />
        </div>
        <div className="active-filters-container">
          {activeFilters.map((activeFilter) => (
            <FilterButton
              key={activeFilter.label}
              filter={activeFilter}
              onClick={handleFilterButtonRemoved}
            />
          ))}
        </div>
      </div>
      <div className="bottom">
        {appContext.isRefetching ? (
          <EditableTableLoadingSkeleton />
        ) : formattedTableData.length > 0 &&
          formattedContractTableData.length > 0 ? (
          <div className="table-container">
            <EditableTableV2
              rows={formattedContractTableData}
              columns={contractTableColumns}
              isReadOnly
              onTablerowClicked={(rowIndex: number, row: Row) =>
                handleRowClicked(row.id ?? '')
              }
              tableName={`${appContext.currentProject.name} Contracts - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
            />
          </div>
        ) : formattedTableData.length > 0 &&
          formattedContractTableData.length === 0 ? (
          <div>No contracts found</div>
        ) : (
          <div className="no-contracts-container">
            <FileIconWithCircle />
            <p className="no-contracts-added">No contracts</p>
            <p>Create a contract to get started</p>
            <div className="no-contracts-button-container">
              <Button
                label="Create contract"
                onClick={handleCreateContractClicked}
                variant={ButtonVariant.PrimaryThin}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isContractModalVisible}
        primaryHeader={`${selectedContract ? 'Edit' : 'Create'} a contract`}
        onClose={handleContractModalClose}
        size={ModalSizes.fullScreen}
        hideXButton={true}
        hideButtons={true}
      >
        <div className="modal-contract-content">
          <div className="top-right-buttons-container">
            <Button
              label="Cancel"
              variant={ButtonVariant.GrayThin}
              onClick={handleContractModalClose}
            />
            {!!selectedContract?.approvers?.find(
              (appr) => appr.id == appContext.currentUser.id
            ) &&
              (() => {
                const approval = selectedContract.approvers.find(
                  (appr) => appr.id == appContext.currentUser.id
                )!;
                return (
                  <span className="margin-left">
                    <Button
                      label={`${
                        approval.is_approved === true ? 'Unapprove' : 'Approve'
                      }`}
                      variant={ButtonVariant.SecondaryThin}
                      onClick={handleApproveContract}
                    />
                  </span>
                );
              })()}
            <span className="margin-left">
              <Button
                label={`${selectedContract ? 'Save' : 'Create'}`}
                variant={ButtonVariant.PrimaryThin}
                onClick={handleSubmitContract}
              />
            </span>
          </div>
          <div className="margin-bottom">
            <Card>
              <div className="card-header">
                <ChevronDownIcon className="chevron-icon" />
                <span className="label-container">
                  <h6>Contract Details</h6>
                  <p>Input contract details here</p>
                </span>
              </div>
              <div className="contract-inputs-container">
                <div className="left-half">
                  <div className="row">
                    <span className="label-input-container">
                      <label htmlFor="contract-id-input">Contract ID</label>
                      <div className="text-input-container">
                        <TextInput
                          id="contract-id-input"
                          placeholder="Contract ID"
                          value={contractModalContractID}
                          onChange={(newValue: string) =>
                            setContractModalContractID(newValue)
                          }
                        />
                      </div>
                    </span>
                    <span className="label-input-container">
                      <label htmlFor="contract-title-input">
                        Contract Title
                      </label>
                      <div className="text-input-container">
                        <TextInput
                          id="contract-title-input"
                          placeholder="Contract title"
                          value={contractModalContractTitle}
                          onChange={(newValue: string) =>
                            setContractModalContractTitle(newValue)
                          }
                        />
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <span className="label-input-container">
                      <label htmlFor="contract-vendor-input">Vendor</label>
                      <div className="text-input-container">
                        <Select
                          id="contract-vendor-input"
                          onChange={(newValue: string) =>
                            setContractModalVendorValue(newValue)
                          }
                          value={contractModalVendorValue}
                        >
                          {contractModalVendorOptions.map(
                            (vendorOption: Vendor) => {
                              return (
                                <option
                                  key={vendorOption.id}
                                  value={vendorOption.id}
                                >
                                  {vendorOption.name}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </div>
                    </span>
                    <span className="label-input-container">
                      <label htmlFor="contract-status-input">Status</label>
                      <div className="text-input-container">
                        <Select
                          id="contract-status-input"
                          onChange={(newValue: string) =>
                            setContractModalStatusValue(newValue)
                          }
                          value={contractModalStatusValue}
                        >
                          <option>Draft</option>
                          <option>In Review</option>
                          <option>Approved</option>
                          <option>Complete</option>
                          <option>Terminated</option>
                        </Select>
                      </div>
                    </span>
                  </div>
                  <div className="row">
                    <div className="label-input-container full-width">
                      <label htmlFor="contract-status-input">Approvers</label>
                      <div className="text-input-container">
                        <MultiSelect
                          id="contract-status-input"
                          options={contractModalUserOptions}
                          selectedOptions={
                            contractModalMultiselectActiveOptions
                          }
                          setSelectOptions={(newOptions: OptionType[]) =>
                            setContractModalMultiselectActiveOptions(newOptions)
                          }
                          onChange={(newValue: string[]) =>
                            handleApproverOptionValueClicked(newValue)
                          }
                        />
                      </div>
                      {contractModalMultiselectActiveOptions.length > 0 && (
                        <span className="approval-status">
                          {`${
                            contractModalMultiselectActiveOptions.filter(
                              (opt) => opt.isComplete === true
                            ).length
                          }/${
                            contractModalMultiselectActiveOptions.length
                          } approvers have approved the contract`}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="label-input-container full-width">
                      <label htmlFor="contract-description-input">
                        Description
                      </label>
                      <div className="text-area-container">
                        <textarea
                          placeholder="Enter a description..."
                          onChange={handleDescriptionChange}
                          value={contractModalContractDescription}
                          id="contract-description-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-half">
                  <div className="area-header">Attachments</div>
                  <div className="drag-and-drop-area">
                    {hasAttachments ? (
                      <>
                        {attachments.map((attachment: Attachment) => {
                          return (
                            <div
                              className="uploaded-attachment"
                              key={attachment.id}
                            >
                              <div>
                                <a
                                  href={
                                    attachment.download_url ||
                                    'https://www.bidsight.io/'
                                  }
                                  target="_blank"
                                  className="attachment-link"
                                >
                                  {attachment.title}
                                </a>
                              </div>
                              <button
                                onClick={() =>
                                  handleDeleteAttachmentClicked(attachment.id)
                                }
                              >
                                <TrashCanIcon className="trash-can-icon" />
                              </button>
                            </div>
                          );
                        })}
                        <div className="upload-container">
                          <label htmlFor="file-upload" className="upload-label">
                            <span className="click-text">Attach more</span>
                          </label>
                          <div>
                            PDF only {'('}max 5gb{')'}
                          </div>
                          <input
                            type="file"
                            id="file-upload"
                            accept=".pdf"
                            className="file-input"
                            max={5000000}
                            onChange={handleUploadAttachment}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="empty-attachments-container">
                        <IconButton icon={<UploadIcon />} />
                        <div className="upload-container">
                          <label htmlFor="file-upload" className="upload-label">
                            <span className="click-text">Click to upload</span>
                          </label>
                          <div>
                            PDF only {'('}max 5gb{')'}
                          </div>
                          <input
                            type="file"
                            id="file-upload"
                            accept=".pdf"
                            className="file-input"
                            max={5000000}
                            onChange={handleUploadAttachment}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="margin-bottom">
            <Card>
              <div className="card-header margin-bottom">
                <ChevronDownIcon className="chevron-icon" />
                <span className="label-container">
                  <h6>Schedule of values</h6>
                  <p>Add, remove or edit schedule of values for the contract</p>
                </span>
              </div>
              <div className="auto-overflow-x">
                {sovTableRows.length > 0 && (
                  <EditableTableV2
                    columns={sovEditableColumns}
                    rows={sovTableRows}
                    onTableCellChanged={handleSOVTableCellChanged}
                    onTableRowDeleteClicked={handleContractSOVDeleteLineItem}
                    showSummationRow
                    useFixedPositionOnCostCodeSelector
                  />
                )}
              </div>
              <div className="add-line-items-button-container">
                <IconButton
                  label="Add line items"
                  icon={<PlusIcon />}
                  onClick={() => setIsLineItemsModalVisible(true)}
                />
              </div>
            </Card>
          </div>
          <div className="margin-bottom">
            <Card>
              <div className="card-header">
                <ChevronDownIcon className="chevron-icon" />
                <span className="label-container">
                  <h6>Change Orders</h6>
                  <p>All change orders associated with this contract</p>
                </span>
              </div>
              <div className="change-order-table-container auto-overflow-x ">
                <EditableTableV2
                  isReadOnly
                  rows={formattedForEditableTableChangeOrders}
                  columns={changeOrdersColumns}
                />
              </div>
            </Card>
          </div>
          <div className="margin-bottom">
            <Card>
              <div className="card-header">
                <ChevronDownIcon className="chevron-icon" />
                <span className="label-container">
                  <h6>Invoices</h6>
                  <p>All invoices associated with this contract</p>
                </span>
              </div>
              <div className="change-order-table-container auto-overflow-x">
                <EditableTableV2
                  isReadOnly
                  rows={formattedForEditableTableInvoices}
                  columns={changeOrdersColumns}
                />
              </div>
            </Card>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isLineItemsModalVisible}
        onClose={handleCloseLineItemsModal}
        size={ModalSizes.mediumLarge}
        primaryButtonLabel="Add line items"
        primaryHeader="Add SOV line items"
        onSecondaryButtonClicked={handleCloseLineItemsModal}
        onPrimaryButtonClicked={handleAddLineItemsSubmit}
        disableScrolling
      >
        <div className="line-items-modal-body-container">
          {lineItemsModalNewLineItems.map(
            (lineItemsModalNewLineItem: ContractLineItem, i: number) => {
              const entireCostCodeObject = getEntireCostCodeObjectByCostCodeId(
                lineItemsModalNewLineItem.costCode,
                contractModalCostCodeOptions
              );

              const isDuplicate = sovTableRows.some(
                (row: Row) => row.cells[0].value === entireCostCodeObject?.id
              );

              return (
                <div
                  key={i}
                  className={`line-items-modal-inputs ${
                    isDuplicate ? 'duplicate-row' : ''
                  }`}
                >
                  <span className="label-input-container">
                    <label htmlFor="contract-line-item-cost-code-input">
                      Cost Code
                    </label>
                    <div className="text-input-container">
                      <CostCodeSelectorInput
                        openFromBottom
                        onlyShowAvailableCostCodes
                        all_cost_codes={contractModalCostCodeOptions}
                        usedCostCodes={convertBudgetLineItemsIntoCostCodes(
                          appContext.budget
                        )}
                        value={entireCostCodeObject}
                        onCostCodeSelect={(newCostCodeValue: CostCode) =>
                          handleNewLineItemChanged(
                            newCostCodeValue.id,
                            'costCode',
                            i
                          )
                        }
                      />
                    </div>
                  </span>
                  <span className="label-input-container">
                    <label htmlFor="contract-line-item-description-input">
                      Description
                    </label>
                    <div className="text-input-container description">
                      {getCostCodeDescriptionByCode(
                        contractModalCostCodeOptions,
                        lineItemsModalNewLineItem.costCode
                      )}
                    </div>
                  </span>
                  <span className="label-input-container">
                    <label htmlFor="contract-line-item-amount-input">
                      Amount
                    </label>
                    <div className="text-input-container">
                      <CurrencyInput
                        inputprops={{
                          id: 'contract-line-item-amount-input',
                          placeholder: 'Amount',
                          value: lineItemsModalNewLineItem.total_amount,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleNewLineItemChanged(
                              event.target.value,
                              'total_amount',
                              i
                            );
                          },
                        }}
                      />
                    </div>
                  </span>
                </div>
              );
            }
          )}

          <span className="add-another-button-container">
            <IconButton
              label="Add another"
              icon={<PlusIcon />}
              onClick={handleAddAnotherLineItemClicked}
            />
          </span>
        </div>
      </Modal>
    </section>
  );
};

export default ContractPage;
