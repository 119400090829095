import { ChangeOrder } from '../../types/changeOrderTypes';

export function formatChangeOrderFilters(changeOrders: ChangeOrder[]): {
  ID: string[];
  Title: string[];
  Vendor: string[];
  Priority: string[];
  Status: string[];
  Contract: string[];
} {
  const idSet: Set<string> = new Set();
  const titleSet: Set<string> = new Set();
  const vendorSet: Set<string> = new Set();
  const prioritySet: Set<string> = new Set();
  const statusSet: Set<string> = new Set();
  const contractSet: Set<string> = new Set();

  changeOrders.forEach((changeOrder: ChangeOrder) => {
    let id = changeOrder.number;
    if (!id) {
      id = 'None';
    }
    idSet.add(id);

    let title = changeOrder.title;
    if (!title) {
      title = 'None';
    }
    titleSet.add(title);

    let vendor = changeOrder.vendor.name;
    if (!vendor) {
      vendor = 'None';
    }
    vendorSet.add(vendor);

    let priority = changeOrder.priority;
    if (!priority) {
      priority = 'None';
    }
    prioritySet.add(priority);

    const status = changeOrder.status;
    statusSet.add(status);

    let contract = changeOrder.contract_title;
    if (!contract) {
      contract = 'None';
    }
    contractSet.add(contract);
  });

  const idArray: string[] = Array.from(idSet);
  const titleArray: string[] = Array.from(titleSet);
  const vendorArray: string[] = Array.from(vendorSet);
  const priorityArray: string[] = Array.from(prioritySet);
  const statusArray: string[] = Array.from(statusSet);
  const contractArray: string[] = Array.from(contractSet);

  return {
    ID: idArray,
    Title: titleArray,
    Vendor: vendorArray,
    Priority: priorityArray,
    Status: statusArray,
    Contract: contractArray,
  };
}
