import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { ProjectFundingSource, ProjectFundingSourceExclusions } from '../../types/projectTypes';
import { fundingSourceRoute } from './postNewFundingSource';
import { formatDateToCustomString } from '../../utils/formatDateForBackend';
import { removeEverythingBesidesNumbersAndDecimalsFromString } from '../../utils/removeEverythingBesidesNumbersAndDecimalsFromString';

export const putFundingSource = async (fundingSource: ProjectFundingSource) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + fundingSourceRoute;

  const req = {
    ...standardRequestObject,
    method: 'PUT',
    body: JSON.stringify({
      ...fundingSource,
      cost_code_exclusions: fundingSource.exclusions
        .map((exclusion: ProjectFundingSourceExclusions) => exclusion.cost_code_id)
        .join(','),
      funding_source_type: fundingSource.type,
      order_index: fundingSource.payment_order_index,
      maturity_date_utc: formatDateToCustomString(
        new Date(fundingSource.maturity_date_utc ?? '')
      ),
      close_date_utc: formatDateToCustomString(
        new Date(fundingSource.close_date_utc ?? '')
      ),
      original_amount: removeEverythingBesidesNumbersAndDecimalsFromString(
        fundingSource.original_amount.toString()
      ),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
