import {
  CellType,
  Row,
  RowType,
} from '../../components/EditableTable/EditableTableV2';
import { Budget, BudgetLineItem } from '../../types/budgetTypes';
import { calculateApprovedAmount } from './calculations/calculateApprovedAmount';
import { calculateApprovedCOs } from './calculations/calculateApprovedCOs';
import { calculateBoughtScope } from './calculations/calculateBoughtScope';
import { calculateCurrentDraw } from './calculations/calculateCurrentDraw';
import { calculateModificationAmount } from './calculations/calculateModificationAmount';
import { calculatePendingChanges } from './calculations/calculatePendingChanges';
import { calculateProjectedBudget } from './calculations/calculateProjectedBudget';
import { calculateRemainingApprovedSpend } from './calculations/calculateRemainingApprovedSpend';
import { calculateRemainingProjectedSpend } from './calculations/calculateRemainingProjectedSpend';
import { calculateRequestedToDate } from './calculations/calculateRequestedToDate';
import { calculateTotalAmount } from './calculations/calculateTotalAmount';
import { calculateUnboughtScope } from './calculations/calculateUnboughtScope';
import { calculateVarianceToApproved } from './calculations/calculateVarianceToApproved';
import { calculateVarianceToProjected } from './calculations/calculateVarianceToProjected';

export const formatFinalizedBudgetRow = (
  budgetLineItem: BudgetLineItem,
  depth: number,
  isLineItemNotALink?: boolean
): Row => {
  const row: Row = {
    cells: [],
    type: depth === 0 ? RowType.parent : RowType.child,
    id: budgetLineItem.id,
    rowData: budgetLineItem,
    children: [],
    indent: depth,
  };

  // Line Item
  row.cells.push({
    value: `${budgetLineItem?.cost_code?.code} - ${budgetLineItem?.cost_code?.description}`,
    type: CellType.costCodeDropDown,
    isCellDisabled: !budgetLineItem.isNewlyCreated,
    columnDisplayName: 'Cost Code',
  });

  // Original Amount
  row.cells.push({
    value: calculateTotalAmount(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    columnDisplayName: 'Original Amount',
    isCellDisabled: true,
  });

  // Modification Amount
  row.cells.push({
    value: calculateModificationAmount(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Modification Amount',
  });

  // Approved CO's
  row.cells.push({
    value: calculateApprovedCOs(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: "Approved CO's",
  });

  // Approved amount
  row.cells.push({
    value: calculateApprovedAmount(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Approved Amount',
  });

  // Pending changes
  row.cells.push({
    value: calculatePendingChanges(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Pending Changes',
  });

  // Projected Budget
  row.cells.push({
    value: calculateProjectedBudget(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Projected Budget',
  });

  // Variance to Approved
  row.cells.push({
    value: calculateVarianceToApproved(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Variance to Approved',
  });

  // Variance to Projected
  row.cells.push({
    value: calculateVarianceToProjected(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Variance to Projected',
  });

  // Requested to Date
  row.cells.push({
    value: calculateRequestedToDate(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Requested to Date',
  });

  // Current Draw
  row.cells.push({
    value: calculateCurrentDraw(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Current Draw',
  });

  // Remaining Approved Spend
  row.cells.push({
    value: calculateRemainingApprovedSpend(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Remaining Approved Spend',
  });

  // Remaining Projected Spend
  row.cells.push({
    value: calculateRemainingProjectedSpend(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Remaining Projected Spend',
  });

  // Vendors
  row.cells.push({
    value: budgetLineItem.buyout_vendor.join(', '),
    type: CellType.text,
    isCellDisabled: true,
    columnDisplayName: 'Vendors',
  });

  // Bought Scope
  row.cells.push({
    value: calculateBoughtScope(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Bought Scope',
  });

  // Unbought Scope
  row.cells.push({
    value: calculateUnboughtScope(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Unbought Scope',
  });

  if (budgetLineItem.children) {
    budgetLineItem.children.forEach((childBudgetLineItem) => {
      const childRow = formatFinalizedBudgetRow(childBudgetLineItem, depth + 1);
      if (row.children) {
        row.children.push(childRow);
      }
    });
  }

  return row;
};

export const formatBudgetToEditableTableRows = (
  budget: Budget | null
): Row[] => {
  const result: Row[] = [];

  if (!budget) return result;

  budget.line_items.forEach((budgetLineItem) => {
    const parentRow = formatFinalizedBudgetRow(budgetLineItem, 0);
    result.push(parentRow);
  });

  // Sort by cost code low to high
  result.sort(
    (a, b) =>
      parseInt(a.rowData.cost_code.code, 10) -
      parseInt(b.rowData.cost_code.code, 10)
  );

  return result;
};
