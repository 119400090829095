import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import './BudgetPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TabBar from '../TabBar/TabBar';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import TextInput from '../TextInput/TextInput';
import { BsFilter as FilterIcon } from 'react-icons/bs';
import Modal, { ModalSizes } from '../Modal/Modal';
import { BudgetLineItem, BudgetLineItemEntity } from '../../types/budgetTypes';
import EditableTableV2, {
  Column,
  Row,
  RowType,
} from '../EditableTable/EditableTableV2';
import { formatBudgetToEditableTableRows } from '../../utils/budget/formatBudgetToEditableTableRows';
import { GoPlus as PlusIcon } from 'react-icons/go';
import cloneDeep from 'lodash/cloneDeep';
import { postLineItems } from '../../services/budget/postLineItem';
import { formatBudgetLineItemsForBackend } from '../../utils/budget/formatBudgetLineItemsForBackend';
import { toast } from 'react-toastify';
import { putFinalizedBudget } from '../../services/budget/putFinalizedBudget';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import { formatNonFinalizedBudgetToEditableTable } from '../../utils/budget/formatNonFinalizedBudgetToEditableTable';
import { useDebounce } from 'usehooks-ts';
import { putBudgetLineItems } from '../../services/budget/putBudgetLineItems';
import { getValueOfBudgetLineItemFromID } from '../../utils/budget/getValueOfBudgetLineItemFromID';
import { formatBudgetModificationsToLineItemsForBackend } from '../../utils/budget/formatBudgetModificationsToLineItemsForBackend';
import { postLineItemModifications } from '../../services/budget/postLineItemModifications';
import BudgetBlade from '../BudgetBlade/BudgetBlade';
import { getBudgetLineItemEntity } from '../../services/budget/getBudgetLineItemEntity';
import { CostCode } from '../../types/sharedTypes';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../../utils/currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { formatToUSD } from '../../utils/currency/formatToUSD';
import { isLastCharADecimal } from '../../utils/misc/isLastCharADecimal';
import { formatNumberWithCommas } from '../../utils/currency/formatNumberWithCommas';
import { AppContext } from '../../types/appContextTypes';
import { removeBudgetLineItemById } from '../../utils/budget/removeBudgetLineItemById';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import { formatBudgetFilters } from '../../utils/budget/formatBudgetFilters';
import { applyBudgetFilters } from '../../utils/budget/applyBudgetFilters';
import CostCodeSelectorInput from '../CostCodeSelectorInput/CostCodeSelectorInput';
import { BiExport as ExportIcon } from 'react-icons/bi';
import { sortCostCodesByCode } from '../../utils/costCodes/sortCostCodesByCode';
import { v4 as uuidv4 } from 'uuid';
import { convertBudgetLineItemsIntoCostCodes } from '../../utils/costCodes/convertBudgetLineItemsIntoCostCodes';
import { getBudgetLineItemByID } from '../../utils/budget/getBudgetLineItemByID';
import { doesDecimalEndWithZero } from '../../utils/misc/doesDecimalEndWithZero';
import { getCostCodeParentByChildID } from '../../utils/costCodes/getCostCodeParentByChildID';
import { findAndReplaceBudgetLineItem } from '../../utils/budget/findAndReplaceBudgetLineItem';
import { createMockBudgetLineItem } from '../../utils/budget/createMockBudgetLineItem';
import { addNewLineItemToChildren } from '../../utils/budget/addNewLineItemToChildren';
import { createPathToOrphanBudgetLineItem } from '../../utils/budget/createPathToOrphanBudgetLineItem';
import { createHeirarchyOfBudgetLineItemsFromFlatList } from '../../utils/budget/createHeirarchyOfBudgetLineItemsFromFlatList';
import { getMissingPathToOrphanBudetLineItem } from '../../utils/budget/getMissingPathToOrphanBudetLineItem';
import { getBudgetLineItemByCostCodeID } from '../../utils/budget/getBudgetLineItemByCostCodeID';
import { doesParentBudgetLineItemHaveNewlyCreatedChild } from '../../utils/budget/doesParentBudgetLineItemHaveNewlyCreatedChild';
import { findCostCodeDifference } from '../../utils/costCodes/findCostCodeDifference';
import { filterBudgetLineItemsForOutOfPlaceCostCodes } from '../../utils/budget/filterBudgetLineItemsForOutOfPlaceCostCodes';
import { doBudgetLineItemsHaveANullCostCode } from '../../utils/budget/doBudgetLineItemsHaveANullCostCode';
import { sortBudgetLineItemsByCostCodeCode } from '../../utils/budget/sortBudgetLineItemsByCostCodeCode';
import { removeDuplicateChildren } from '../../utils/budget/removeDuplicateChildren';
import { lineItemToAddHasDuplicateCostCodes } from '../../utils/budget/lineItemToAddHasDuplicateCostCodes';
import { findPathToChildCostCode } from '../../utils/costCodes/findPathToCostCodeChild';
import { modificationToLineItemHasDuplicate } from '../../utils/budget/modificationToLineItemHasDuplicate';

export const budgetColumns: Column[] = [
  {
    value: 'Line Item',
    className: 'budget-cost-code-column',
  },
  {
    value: 'Original Amount',
    className: 'text-align-right',
  },
  {
    value: 'Modification Amount',
    className: 'text-align-right',
  },
  {
    value: "Approved CO's",
    className: 'text-align-right',
  },
  {
    value: 'Approved Amount',
    className: 'text-align-right',
  },
  {
    value: 'Pending Changes',
    className: 'text-align-right',
  },
  {
    value: 'Projected Budget',
    className: 'text-align-right',
  },
  {
    value: 'Variance to Approved',
    className: 'text-align-right',
  },
  {
    value: 'Variance to Projected',
    className: 'text-align-right',
  },
  {
    value: 'Requested to Date',
    className: 'text-align-right',
  },
  {
    value: 'Current Draw',
    className: 'text-align-right',
  },
  {
    value: 'Remaining Approved Spend',
    className: 'text-align-right',
  },
  {
    value: 'Remaining Projected Spend',
    className: 'text-align-right',
  },
  {
    value: 'Vendors',
    className: 'text-align-right',
  },
  {
    value: 'Bought Scope',
    className: 'text-align-right',
  },
  {
    value: 'Unbought Scope',
    className: 'text-align-right',
  },
];

export const notFinalizedBudgetColumns: Column[] = [
  {
    value: 'Line Item',
    className: 'budget-cost-code-column',
  },
  {
    value: 'Total Amount',
    className: 'text-align-right',
  },
  {
    value: 'Requested to Date',
    className: 'text-align-right',
  },
  {
    value: 'Current Draw',
    className: 'text-align-right',
  },
  {
    value: 'Remaining to Spend',
    className: 'text-align-right',
  },
  {
    value: 'Committed Costs',
    className: 'text-align-right',
  },
];

const tabOptions = ['Detailed Budget']; // 'Scenario Modeling', 'Snapshots'

export interface LineItemToAdd {
  cost_code: CostCode | null;
  amount: string;
}

export interface ModificationToLineItem {
  cost_code: CostCode | null;
  modification_amount: string;
  approved_budget_amount: string;
  new_approved_budget_amount: string;
  line_item_id: string;
}

const getEmptyLineItemToAdd = (): LineItemToAdd => ({
  cost_code: null,
  amount: '',
});

const getEmptyModificationToLineItem = (): ModificationToLineItem => ({
  cost_code: null,
  modification_amount: '',
  approved_budget_amount: '',
  new_approved_budget_amount: '',
  line_item_id: '',
});

interface Props {
  appContext: AppContext;
}

const BudgetPage: React.FC<Props> = memo(({ appContext }: Props) => {
  const [activeTabindex, setActiveTabIndex] = useState<number>(0);
  const [isExportSnapshotModalOpen, setIsExportSnapshotModalOpen] =
    useState<boolean>(false);
  const [
    isConfirmRemoveLineItemModalVisible,
    setIsConfirmRemoveLineItemModalVisible,
  ] = useState<boolean>(false);
  const [
    isConfirmFinalizeBudgetModalVisible,
    setIsConfirmFinalizeBudgetModalVisible,
  ] = useState<boolean>(false);
  const [isAddLineItemModalOpen, setIsAddLineItemModalOpen] =
    useState<boolean>(false);
  const [isModifyLineItemModalVisible, setIsModifyLineItemModalVisible] =
    useState<boolean>(false);
  const [lineItemsToAdd, setLineItemsToAdd] = useState<LineItemToAdd[]>([
    getEmptyLineItemToAdd(),
  ]);
  const [modificationsToLineItems, setModificationsToLineItems] = useState<
    ModificationToLineItem[]
  >([getEmptyModificationToLineItem()]);
  const [budgetLineItems, setBudgetLineItems] = useState<
    BudgetLineItem[] | undefined
  >();
  const debouncedBudgetLineItems = useDebounce<BudgetLineItem[] | undefined>(
    budgetLineItems,
    200
  );
  const [budgetIsInitial, setBudgetIsInitial] = useState<boolean>(true);
  const [isBladeOpen, setIsBladeOpen] = useState<boolean>(false);
  const [selectedBudgetLineItem, setSelectedBudgetLineItem] =
    useState<BudgetLineItem | null>(null);
  const [budgetLineItemEntity, setBudgetLineItemEntity] =
    useState<BudgetLineItemEntity | null>(null);
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;
  const [
    isSaveModificationsButtonDisabled,
    setIsSaveModificationsButtonDisabled,
  ] = useState(false);

  const budgetMap: filtersMap = formatBudgetFilters(
    appContext.budget,
    appContext.currentProject.budget_is_finalized
  );

  const sortedCostCodes = sortCostCodesByCode(
    appContext.currentProject.cost_codes
  );

  const usedCostCodes = convertBudgetLineItemsIntoCostCodes(
    budgetLineItems ?? []
  );

  useEffect(() => {
    setBudgetLineItems(appContext.budget);
  }, []);

  useEffect(() => {
    if (!selectedBudgetLineItem) return;
    const getData = async () => {
      const budgetLineItemEntitiesData = await getBudgetLineItemEntity(
        selectedBudgetLineItem.id
      );
      setBudgetLineItemEntity(budgetLineItemEntitiesData);
    };
    getData();
  }, [selectedBudgetLineItem]);

  // Keept the line items in a non-finalized budget updated as you
  // edit them in line
  useEffect(() => {
    if (
      !budgetLineItems ||
      !debouncedBudgetLineItems ||
      doBudgetLineItemsHaveANullCostCode(debouncedBudgetLineItems)
    )
      return;
    if (budgetIsInitial === true) {
      // This function will always fire at page load when debouncedBudgetLineItems is initially
      // defined. Catch that case here and prevent sending out a PUT with the initial unmodified
      // line item data.
      setBudgetIsInitial(false);
      return;
    }

    const updateLineItems = async () => {
      const clonedLineItems = cloneDeep(budgetLineItems);
      await putBudgetLineItems(appContext.currentProject.id, clonedLineItems);
      appContext.refetchProjectData(true);
    };
    updateLineItems();
  }, [debouncedBudgetLineItems]);

  const handleRowSelected = useCallback(
    (rowIndex: number, row: Row) => {
      setSelectedBudgetLineItem(row.rowData ?? null);
    },
    [selectedBudgetLineItem]
  );

  const handleViewTabClicked = (newView: number) => {
    // TODO: Make this actually do something
    return;
    // setActiveTabIndex(newView);
  };

  const handleExportBudgetClicked = () => {
    alert('export budget clicked');
  };

  const confirmRemoveLineItem = () => {
    alert('confirmRemoveLineItem clicked');
  };

  const handleImportBudgetClicked = () => {
    alert('handleImportBudgetClicked clicked');
  };

  const handleAddLineItemButtonClicked = () => {
    setIsAddLineItemModalOpen(true);
    clearAddLineItemModalState();
  };

  const clearAddLineItemModalState = () => {
    setLineItemsToAdd([getEmptyLineItemToAdd()]);
  };

  const handleModifyBudgetButtonClicked = () => {
    setTimeout(() => {
      setIsModifyLineItemModalVisible(true);
    }, 50);
  };

  const confirmFinalizeBudget = async () => {
    const finalizedBudget = await putFinalizedBudget(
      appContext.currentProject.id
    );

    if (finalizedBudget.error_message) {
      showErrorMessageToast(finalizedBudget.error_message);
    } else {
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();
      showSuccessToastMessage('Budget has been successfully finalized');
      setIsConfirmFinalizeBudgetModalVisible(false);
    }
  };

  const updateTotalAmountById = (
    items: BudgetLineItem[],
    idToUpdate: string,
    newTotalAmount: number
  ): BudgetLineItem[] => {
    return items.map((item) => {
      if (item.id === idToUpdate) {
        // If the item ID matches, update its total_amount
        return {
          ...item,
          total_amount: newTotalAmount,
          unit_cost: newTotalAmount,
        };
      } else if (item.children.length > 0) {
        // If the item has children, recursively update them
        return {
          ...item,
          children: updateTotalAmountById(
            item.children,
            idToUpdate,
            newTotalAmount
          ),
        };
      }
      // If the item doesn't match and has no children, return it as is
      return item;
    });
  };

  const handleAddNewRowClicked = useCallback(
    (row: Row) => {
      const entireParentCostCodeObject = getCostCodeParentByChildID(
        sortedCostCodes,
        row.rowData.cost_code
      );

      const usedCostCodeParent = getCostCodeParentByChildID(
        usedCostCodes,
        row.rowData.cost_code
      );

      const parentBudgetLineItem = getBudgetLineItemByCostCodeID(
        budgetLineItems ?? [],
        entireParentCostCodeObject?.id ?? ''
      );

      if (
        !parentBudgetLineItem ||
        !entireParentCostCodeObject ||
        !usedCostCodeParent
      ) {
        return console.warn(
          'Unable to find parent budget line item, parent cost code or used parent cost code'
        );
      }

      const differenceInChildren = findCostCodeDifference(
        usedCostCodeParent,
        entireParentCostCodeObject
      );

      // Don't allow user to create new rows if a new row already exists or if we've used
      // all cost codes options for that family
      if (
        doesParentBudgetLineItemHaveNewlyCreatedChild(parentBudgetLineItem) ||
        differenceInChildren.length === 0
      ) {
        return;
      }

      const newBudgetLineItem = createMockBudgetLineItem(
        entireParentCostCodeObject ?? row.rowData.cost_code,
        uuidv4(),
        true,
        appContext.currentProject.id,
        false,
        row.type === RowType.parent && row.rowData.children.length === 0
          ? row.rowData.total_amount
          : 0
      );

      const budgetLineItemsCopy = cloneDeep(budgetLineItems);

      addNewLineItemToChildren(
        budgetLineItemsCopy,
        newBudgetLineItem,
        entireParentCostCodeObject?.id ?? ''
      );
      setBudgetLineItems(budgetLineItemsCopy);
    },
    [sortedCostCodes, budgetLineItems, setBudgetLineItems]
  );

  const handleNonFinalizedTableCellChanged = useCallback(
    (
      newValue: string,
      rowIndex: number,
      columnIndex: number,
      columnName?: string,
      row?: Row,
      parentRowIndex?: number
    ) => {
      if (!budgetLineItems) return;
      const newValueWithoutChars =
        removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue);

      if (doesDecimalEndWithZero(newValue)) {
        return;
      } else {
        const updatedBudetLineItems = updateTotalAmountById(
          budgetLineItems,
          row?.id ?? '',
          parseFloat(newValueWithoutChars)
        );

        setBudgetLineItems(updatedBudetLineItems);
      }
    },
    [
      budgetLineItems,
      removeCharactersExceptForPeriodAndNumbersFromFloatString,
      doesDecimalEndWithZero,
      updateTotalAmountById,
      setBudgetLineItems,
    ]
  );

  const handleFinalizeBudgetClicked = () => {
    setIsConfirmFinalizeBudgetModalVisible(true);
  };

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const handleSubmitAddLineItem = async () => {
    const containsNullCostCode = lineItemsToAdd.some(
      (lineItemToAdd: LineItemToAdd) => lineItemToAdd.cost_code === null
    );

    if (containsNullCostCode) {
      return showErrorMessageToast('Unselected cost code(s)');
    }

    const hasDuplicateCostCodes =
      lineItemToAddHasDuplicateCostCodes(lineItemsToAdd);

    if (hasDuplicateCostCodes) {
      return showErrorMessageToast(
        'Cannot add two or more of the same cost code'
      );
    }

    const budgetLineItemsToAdd = lineItemsToAdd.map(
      (lineItemToAdd: LineItemToAdd) => {
        const parentCostCode = getCostCodeParentByChildID(
          sortedCostCodes,
          lineItemToAdd.cost_code
        );

        if (!parentCostCode) {
          return console.warn(
            'Parent cost code not found for line item: ',
            lineItemToAdd
          );
        }
        if (!lineItemToAdd.cost_code) {
          return console.warn(
            'Cost code not found for line item: ',
            lineItemToAdd
          );
        }
        return createMockBudgetLineItem(
          parentCostCode,
          uuidv4(),
          false,
          appContext.currentProject.id,
          false,
          parseFloat(
            removeCharactersExceptForPeriodAndNumbersFromFloatString(
              lineItemToAdd.amount
            )
          ),
          lineItemToAdd.cost_code
        );
      }
    );

    let updatedLineItems = cloneDeep(budgetLineItems);

    budgetLineItemsToAdd.forEach((newBudgetLineItem: BudgetLineItem | void) => {
      if (!newBudgetLineItem) {
        return console.error('No newBudgetLineItem found');
      }

      const isHighestLevelCostCode =
        !newBudgetLineItem?.cost_code?.code.includes('.');

      if (!newBudgetLineItem.parentCostCode) {
        return console.error('No parent cost code found');
      }

      // Creating all children
      if (!isHighestLevelCostCode) {
        const pathToCostCodeChild = findPathToChildCostCode(
          newBudgetLineItem.parentCostCode,
          newBudgetLineItem?.cost_code?.id ?? ''
        );
        pathToCostCodeChild.forEach((costCode: CostCode, index: number) => {
          const foundBudgetLineItem = getBudgetLineItemByCostCodeID(
            updatedLineItems,
            costCode.id ?? ''
          );
          const doesRootParentExist = Boolean(
            getBudgetLineItemByCostCodeID(
              updatedLineItems,
              pathToCostCodeChild[0].id ?? ''
            )
          );

          if (!doesRootParentExist) {
            const rootParent = getCostCodeParentByChildID(
              sortedCostCodes,
              newBudgetLineItem?.cost_code
            );

            const newLineItem = createMockBudgetLineItem(
              null,
              uuidv4(),
              false,
              appContext.currentProject.id,
              false,
              parseFloat(
                removeCharactersExceptForPeriodAndNumbersFromFloatString(
                  newBudgetLineItem.total_amount.toString()
                )
              ),
              rootParent
            );
            updatedLineItems?.push(newLineItem);
          }

          // Go to this budget line item's parent and add this to its children
          if (!foundBudgetLineItem) {
            const parentCostCode = pathToCostCodeChild[index - 1];
            if (!parentCostCode) {
              return console.error('No parent cost code found');
            }
            const foundParentBudgetLineItem = getBudgetLineItemByCostCodeID(
              updatedLineItems,
              parentCostCode.id
            );
            if (!foundParentBudgetLineItem) {
              return console.error('No parent budget line item found');
            }

            const newLineItem = createMockBudgetLineItem(
              parentCostCode,
              uuidv4(),
              false,
              appContext.currentProject.id,
              false,
              parseFloat(
                removeCharactersExceptForPeriodAndNumbersFromFloatString(
                  newBudgetLineItem.total_amount.toString()
                )
              ),
              costCode
            );

            foundParentBudgetLineItem.children = [
              ...foundParentBudgetLineItem.children,
              newLineItem,
            ];

            updatedLineItems = findAndReplaceBudgetLineItem(
              updatedLineItems ?? [],
              foundParentBudgetLineItem
            );
          }
        });
      }
      // Create ONLY the root parent if that's what you selected
      else {
        const newLineItem = createMockBudgetLineItem(
          null,
          uuidv4(),
          false,
          appContext.currentProject.id,
          false,
          parseFloat(
            removeCharactersExceptForPeriodAndNumbersFromFloatString(
              newBudgetLineItem.total_amount.toString()
            )
          ),
          newBudgetLineItem?.cost_code
        );
        updatedLineItems?.push(newLineItem);
      }
    });

    if (!updatedLineItems) {
      return console.error('No updated line items');
    }
    updatedLineItems =
      filterBudgetLineItemsForOutOfPlaceCostCodes(updatedLineItems);

    const deDeupedLineItems = removeDuplicateChildren(updatedLineItems);
    const sortedLineItems =
      sortBudgetLineItemsByCostCodeCode(deDeupedLineItems);

    clearAddLineItemModalState();
    setIsAddLineItemModalOpen(false);
    showSuccessToastMessage('Successfully added new line item(s)');
    setBudgetLineItems(sortedLineItems);
    appContext.refetchProjectData(true);
  };

  const handleAddNewLineItemInLine = useCallback(
    async (selectedCostCode: CostCode, row: Row) => {
      const newBudgetLineItem = getBudgetLineItemByID(
        row.id ?? '',
        budgetLineItems ?? []
      );

      if (!newBudgetLineItem) {
        return console.warn(
          `No budget line item found in state with ID: ${row.id}`
        );
      }

      const entireParentCostCodeObject = getCostCodeParentByChildID(
        sortedCostCodes,
        selectedCostCode
      );

      if (!entireParentCostCodeObject) {
        return console.warn('No cost code parent found');
      }

      const budgetLineItemToAdd = createMockBudgetLineItem(
        entireParentCostCodeObject,
        row.id,
        false,
        appContext.currentProject.id,
        false,
        row.rowData.total_amount
      );

      budgetLineItemToAdd.cost_code = selectedCostCode;
      budgetLineItemToAdd.cost_code_id = selectedCostCode.id;
      budgetLineItemToAdd.id = row.id ?? 'abc-123';

      let updatedBudgetLineItems = findAndReplaceBudgetLineItem(
        budgetLineItems ?? [],
        budgetLineItemToAdd
      );

      const pathToNewBudgetLineItem = createPathToOrphanBudgetLineItem(
        updatedBudgetLineItems ?? [],
        budgetLineItemToAdd
      );

      const missingPathToBudgetLineItem = getMissingPathToOrphanBudetLineItem(
        updatedBudgetLineItems ?? [],
        budgetLineItemToAdd
      );

      const lineItemsToCreate = [
        ...missingPathToBudgetLineItem,
        newBudgetLineItem,
      ];
      lineItemsToCreate[lineItemsToCreate.length - 1].cost_code =
        selectedCostCode;

      createHeirarchyOfBudgetLineItemsFromFlatList(pathToNewBudgetLineItem);

      updatedBudgetLineItems = filterBudgetLineItemsForOutOfPlaceCostCodes(
        updatedBudgetLineItems
      );

      const deDeupedLineItems = removeDuplicateChildren(updatedBudgetLineItems);

      const sortedLineItems =
        sortBudgetLineItemsByCostCodeCode(deDeupedLineItems);

      setBudgetLineItems(sortedLineItems);
      appContext.refetchProjectData(true);
    },
    [budgetLineItems, setBudgetLineItems]
  );

  const handleAddAnotherClicked = () => {
    if (lineItemsToAdd.length >= 5) {
      showErrorMessageToast('Max of 5 line items editable at once');
      return;
    }

    const deepCopy: LineItemToAdd[] = cloneDeep(lineItemsToAdd);
    deepCopy.push(getEmptyLineItemToAdd());
    setLineItemsToAdd(deepCopy);
  };

  const handleModifyAnotherLineItemClicked = () => {
    if (modificationsToLineItems.length >= 5) {
      showErrorMessageToast('Max of 5 line items editable at once');
      return;
    }

    const deepCopy: ModificationToLineItem[] = cloneDeep(
      modificationsToLineItems
    );
    deepCopy.push(getEmptyModificationToLineItem());
    setModificationsToLineItems(deepCopy);
  };

  const handleLineItemToAddChanged = (
    newValue: string | CostCode,
    keyToChange: string,
    index: number
  ) => {
    const newLineItemToAddState: any = [...lineItemsToAdd];
    newLineItemToAddState[index][keyToChange] = newValue;
    setLineItemsToAdd(newLineItemToAddState);
  };

  const handleModificationToLineItemChanged = (
    newValue: any,
    keyToChange: string,
    index: number
  ) => {
    const newModificationToLineItemsState: any = [...modificationsToLineItems];
    newModificationToLineItemsState[index][keyToChange] = newValue;

    // When cost_code changes - find the approved budget ammount field
    // Also update the line_item_id field
    if (keyToChange === 'cost_code') {
      const costCodeValue = getValueOfBudgetLineItemFromID(
        budgetLineItems,
        newValue.id
      );

      if (!costCodeValue.value) {
        newModificationToLineItemsState[index]['approved_budget_amount'] = '0';
      } else {
        newModificationToLineItemsState[index]['approved_budget_amount'] =
          costCodeValue.value;
      }
      newModificationToLineItemsState[index]['line_item_id'] =
        costCodeValue.line_item_id;
    }

    // Keep new_approved_budget_amount updated based on what gets modification amount
    if (keyToChange === 'modification_amount' && typeof newValue === 'string') {
      if (isLastCharADecimal(newValue)) return;

      let newApprovedBudgetAmount: number | string = 0;

      const parsedNewValueAmount =
        removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue);

      const newValueAmount: number = parseFloat(parsedNewValueAmount);

      const approvedBudetAmount: number = parseFloat(
        newModificationToLineItemsState[index]['approved_budget_amount']
      );
      newApprovedBudgetAmount = approvedBudetAmount + newValueAmount;

      if (Number.isNaN(newApprovedBudgetAmount)) {
        newApprovedBudgetAmount = '0';
      } else {
        newModificationToLineItemsState[index][
          'new_approved_budget_amount'
        ] = `$${formatNumberWithCommas(newApprovedBudgetAmount)}`;
      }
    }
    setModificationsToLineItems(newModificationToLineItemsState);
  };

  const handleCloseModifyLineItemsModal = () => {
    setIsModifyLineItemModalVisible(false);
    setTimeout(() => {
      setModificationsToLineItems([getEmptyModificationToLineItem()]);
    }, 200);
  };

  const handleSubmitModifiedLineItems = async () => {
    // Don't allow user to accidentally submit more than one network request
    setIsSaveModificationsButtonDisabled(true);
    const containsNullCostCode = modificationsToLineItems.some(
      (modificationsToLineItems: ModificationToLineItem) =>
        modificationsToLineItems.cost_code === null
    );
    if (containsNullCostCode) {
      setIsSaveModificationsButtonDisabled(false);
      return showErrorMessageToast('Unselected cost code(s)');
    }

    const hasDuplicateCostCodes = modificationToLineItemHasDuplicate(
      modificationsToLineItems
    );

    if (hasDuplicateCostCodes) {
      setIsSaveModificationsButtonDisabled(false);
      return showErrorMessageToast(
        'Cannot modify two or more of the same cost code'
      );
    }

    const formattedModifiedLineItems =
      formatBudgetModificationsToLineItemsForBackend(modificationsToLineItems);

    const updatedLineItems = await postLineItemModifications(
      formattedModifiedLineItems,
      appContext.currentProject.id
    );

    if (updatedLineItems.error_message) {
      showErrorMessageToast(updatedLineItems.error_message);
    } else {
      setBudgetLineItems(updatedLineItems);
      showSuccessToastMessage('Successfully modified line item(s)');
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();
      setIsModifyLineItemModalVisible(false);
      setModificationsToLineItems([getEmptyModificationToLineItem()]);
    }

    setIsSaveModificationsButtonDisabled(false);
  };

  const handleBladeClose = () => {
    setIsBladeOpen(false);
    setBudgetLineItemEntity(null);
    setSelectedBudgetLineItem(null);
  };

  const handleBladeOpen = useCallback(() => {
    setIsBladeOpen(true);
  }, []);

  const handleNonFinalizedBudgetRowDeleted = (id?: string) => {
    if (!id || !budgetLineItems) return;
    const updateLineItems = async () => {
      const lineItemsWithDeletion = removeBudgetLineItemById(
        id,
        cloneDeep(budgetLineItems)
      );
      const updatedBudget = await putBudgetLineItems(
        appContext.currentProject.id,
        lineItemsWithDeletion
      );
      if (updatedBudget.error_message) {
        showErrorMessageToast(updatedBudget.error_message);
      } else {
        appContext.refetchProjectData(true);
        setBudgetLineItems(updatedBudget);
        setIsBladeOpen(false);
      }
    };
    updateLineItems();
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const formattedBudgetTableRows = useMemo(() => {
    if (appContext.currentProject.budget_is_finalized) {
      return applyBudgetFilters(
        formatBudgetToEditableTableRows({
          line_items: budgetLineItems || [],
          is_finalized: appContext.currentProject.budget_is_finalized,
        }),
        searchboxValue,
        activeFilters
      );
    } else {
      return applyBudgetFilters(
        formatNonFinalizedBudgetToEditableTable(
          {
            line_items: budgetLineItems || [],
            is_finalized: appContext.currentProject.budget_is_finalized,
          },
          sortedCostCodes,
          usedCostCodes
        ),
        searchboxValue,
        activeFilters
      );
    }
  }, [
    appContext.currentProject.budget_is_finalized,
    budgetLineItems,
    searchboxValue,
    activeFilters,
    sortedCostCodes,
    usedCostCodes,
  ]);

  window.document.title = 'BidSight – Budget';
  return (
    <section className="BudgetPage">
      <div>
        <div className="header-button-container">
          <div className="primary-header-subheader-container">
            <h4 className="main-page-header">Budget</h4>
            <h5>Create and manage your budget</h5>
          </div>
          <div className="header-action-button-containers">
            {!appContext.currentProject.budget_is_finalized &&
              !appContext.isRefetching &&
              formattedBudgetTableRows.length > 0 && (
                <div className="standard-button-container">
                  <Button
                    label="Finalize budget"
                    onClick={handleFinalizeBudgetClicked}
                    variant={ButtonVariant.GrayThin}
                  />
                </div>
              )}
            {/* TODO: Add these back in */}
            {/* <div className="standard-button-container">
            <Button
              label="Export"
              onClick={() => console.log('Export button clicked')}
              variant={ButtonVariant.GrayThin}
            />
          </div>
          <div className="standard-button-container">
            <Button
              label="Take snapshot"
              onClick={() => console.log('Take snapshot button clicked')}
              variant={ButtonVariant.GrayThin}
            />
          </div> */}
            {appContext.currentProject.budget_is_finalized ? (
              <Button
                label="Modify budget"
                onClick={handleModifyBudgetButtonClicked}
                variant={ButtonVariant.PrimaryThin}
                isDisabled={appContext.isRefetching}
              />
            ) : formattedBudgetTableRows.length == 0 ? (
              <div /> // wierd bug where "isRefetching || formattedBudgetTableRows.length == 0" condition isn't trigger a render
            ) : (
              <Button
                label="Add line item"
                onClick={handleAddLineItemButtonClicked}
                variant={ButtonVariant.PrimaryThin}
                isDisabled={appContext.isRefetching}
              />
            )}
          </div>
        </div>
        <div className="tab-bar-container">
          <TabBar
            tabOptions={tabOptions}
            activeTabOption={tabOptions[activeTabindex]}
            onTabClick={handleViewTabClicked}
          />
        </div>
        <div className="search-filter-display-container">
          <div className="search-fitler-container">
            <div className="search-container">
              <TextInput
                placeholder="Search"
                icon={<MagnifyingGlassIcon />}
                onChange={(newValue: string) => setSearchboxValue(newValue)}
              />
            </div>
            <span className="filter-popunder-container">
              <span className="filter-button-container">
                <IconButton
                  icon={<FilterIcon />}
                  label={'Filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() =>
                    setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                  }
                />
                <PopUnder
                  isOpen={isFilterPopUnderVisible}
                  onClose={() => setIsFilterPopUnderVisible(false)}
                  optionsMap={budgetMap}
                  handleSecondOptionSelected={handleFilterSelected}
                  activeFilters={activeFilters}
                />
              </span>
              {hasActiveFilters && (
                <span className="clear-filters-button-container">
                  <IconButton
                    icon={<CloseIcon />}
                    label={'Clear filters'}
                    variant={IconButtonVariants.dotted}
                    onClick={() => setActiveFilters([])}
                  />
                </span>
              )}
            </span>
          </div>
          <IconButton
            icon={<ExportIcon />}
            label={tableIsExporting ? 'Exporting...' : 'Export'}
            variant={IconButtonVariants.dotted}
            onClick={() => setTableIsExporting(true)}
          />
        </div>
        <div className="active-filters-container">
          {activeFilters.map((activeFilter) => (
            <FilterButton
              key={activeFilter.label}
              filter={activeFilter}
              onClick={handleFilterButtonRemoved}
            />
          ))}
        </div>
      </div>
      <div className="bottom">
        {appContext.isRefetching ? (
          <EditableTableLoadingSkeleton />
        ) : appContext.currentProject.budget_is_finalized &&
          formattedBudgetTableRows.length > 0 ? (
          <div className="table-container">
            <EditableTableV2
              isReadOnly
              rows={formattedBudgetTableRows}
              columns={budgetColumns}
              onTablerowClicked={(rowIndex: number, row: Row) =>
                handleRowSelected(rowIndex, row)
              }
              onTableCellClicked={handleBladeOpen}
              tableName={`${appContext.currentProject.name} Budget - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
              showSummationRow
              handleAddNewLineItemInLine={handleAddNewLineItemInLine}
            />
          </div>
        ) : budgetLineItems?.length === 0 ? (
          <div className="no-line-items-container">
            <FileIconWithCircle />
            <p className="no-items-added">Budget is empty</p>
            <p>Add line items or import a budget to get started</p>
            <div className="no-line-items-button-container">
              {/* TODO: Add importing budgets */}
              {/* <Button
              label="Import budget"
              onClick={handleImportBudgetClicked}
              variant={ButtonVariant.GrayThin}
            /> */}
              <Button
                label="Add line item"
                onClick={handleAddLineItemButtonClicked}
                variant={ButtonVariant.PrimaryThin}
              />
            </div>
          </div>
        ) : formattedBudgetTableRows.length === 0 ? (
          <div>No budget line items found</div>
        ) : (
          <div className="table-container">
            <EditableTableV2
              rows={formattedBudgetTableRows}
              columns={notFinalizedBudgetColumns}
              onTablerowClicked={(rowIndex: number, row: Row) =>
                handleRowSelected(rowIndex, row)
              }
              onTableCellChanged={handleNonFinalizedTableCellChanged}
              onTableCellClicked={handleBladeOpen}
              tableName={`${appContext.currentProject.name} Budget - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
              showSummationRow
              handleAddNewLineItemInLine={handleAddNewLineItemInLine}
              onAddRowClicked={handleAddNewRowClicked}
            />
          </div>
        )}
      </div>
      <Modal
        isOpen={isExportSnapshotModalOpen}
        primaryHeader="Export snapshot"
        secondaryHeader="Export your budget snapshot as a xisx file"
        onSecondaryButtonClicked={() => setIsExportSnapshotModalOpen(false)}
        onClose={() => setIsExportSnapshotModalOpen(false)}
        onPrimaryButtonClicked={handleExportBudgetClicked}
        primaryButtonLabel="Export budget"
      ></Modal>
      <Modal
        isOpen={isConfirmRemoveLineItemModalVisible}
        primaryHeader="Remove line item"
        secondaryHeader="Are you sure you want to remove this line item?"
        onSecondaryButtonClicked={() =>
          setIsConfirmRemoveLineItemModalVisible(false)
        }
        onClose={() => setIsConfirmRemoveLineItemModalVisible(false)}
        onPrimaryButtonClicked={confirmRemoveLineItem}
        primaryButtonType={ButtonVariant.ErrorThin}
        secondaryButtonType={ButtonVariant.GrayThin}
        primaryButtonLabel="Remove line item"
      ></Modal>
      <Modal
        isOpen={isAddLineItemModalOpen}
        primaryHeader="Add line items"
        secondaryHeader="Select a cost code and add an amount"
        onSecondaryButtonClicked={() => setIsAddLineItemModalOpen(false)}
        onClose={() => setIsAddLineItemModalOpen(false)}
        onPrimaryButtonClicked={handleSubmitAddLineItem}
        primaryButtonLabel="Add Line item"
        secondaryButtonType={ButtonVariant.GrayThin}
        primaryButtonType={ButtonVariant.PrimaryThin}
        disableScrolling
      >
        <div className="add-line-items-modal-content">
          {lineItemsToAdd.map(
            (currentLineItemToAdd: LineItemToAdd, index: number) => {
              return (
                <>
                  <div className="label-input-container">
                    <label htmlFor="cost-code-selector">Cost Code</label>
                    <div>
                      <CostCodeSelectorInput
                        openFromBottom
                        all_cost_codes={sortedCostCodes}
                        value={currentLineItemToAdd.cost_code}
                        onCostCodeSelect={(costCodeChild: CostCode) =>
                          handleLineItemToAddChanged(
                            costCodeChild,
                            'cost_code',
                            index
                          )
                        }
                        isFullWidth
                        usedCostCodes={usedCostCodes}
                        onlyShowAvailableCostCodes
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="amount-input">Amount</label>
                    <div>
                      <CurrencyInput
                        inputprops={{
                          id: 'amount-input',
                          placeholder: 'Amount',
                          value: currentLineItemToAdd.amount,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleLineItemToAddChanged(
                              event.target.value,
                              'amount',
                              index
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            }
          )}
        </div>
        <IconButton
          icon={<PlusIcon />}
          label="Add another"
          onClick={handleAddAnotherClicked}
        />
      </Modal>
      <Modal
        isOpen={isConfirmFinalizeBudgetModalVisible}
        primaryHeader="Finalize budget"
        secondaryHeader="Finalizing a budget will lock in the current budget as the project's original budget. This action cannot be undone."
        onSecondaryButtonClicked={() =>
          setIsConfirmFinalizeBudgetModalVisible(false)
        }
        onClose={() => setIsConfirmFinalizeBudgetModalVisible(false)}
        onPrimaryButtonClicked={confirmFinalizeBudget}
        primaryButtonLabel="Finalize budget"
        secondaryButtonType={ButtonVariant.GrayThin}
        primaryButtonType={ButtonVariant.PrimaryThin}
      ></Modal>
      <Modal
        isOpen={isModifyLineItemModalVisible}
        primaryHeader="Modify line item"
        secondaryHeader="Select the line item you want to modify or add a new line item"
        onSecondaryButtonClicked={handleCloseModifyLineItemsModal}
        onClose={handleCloseModifyLineItemsModal}
        onPrimaryButtonClicked={handleSubmitModifiedLineItems}
        primaryButtonLabel="Save modifications"
        secondaryButtonType={ButtonVariant.GrayThin}
        primaryButtonType={ButtonVariant.PrimaryThin}
        size={ModalSizes.large}
        disableScrolling
        isPrimaryButtonDisabled={isSaveModificationsButtonDisabled}
      >
        <div className="modify-line-item-modal">
          {modificationsToLineItems.map(
            (modificationToLineItem: ModificationToLineItem, index: number) => {
              return (
                <div
                  className="modify-line-item-modal-list-item"
                  key={modificationToLineItem.line_item_id}
                >
                  <div className="label-input-container">
                    <label htmlFor="cost-code-selector">Cost Code</label>
                    <div>
                      <CostCodeSelectorInput
                        openFromBottom
                        all_cost_codes={usedCostCodes}
                        value={modificationToLineItem.cost_code}
                        onCostCodeSelect={(costCodeChild: CostCode) =>
                          handleModificationToLineItemChanged(
                            costCodeChild,
                            'cost_code',
                            index
                          )
                        }
                        isFullWidth
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="approved-budget-amount-input">
                      Approved Budget Amount
                    </label>
                    <div>
                      <CurrencyInput
                        inputprops={{
                          id: 'approved-budget-amount-input',
                          placeholder: 'Approved Budget Amount',
                          disabled: true,
                          value: formatToUSD(
                            modificationToLineItem.approved_budget_amount
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="modification-amount-input">
                      Modification Amount
                    </label>
                    <div>
                      <CurrencyInput
                        inputprops={{
                          id: 'modification-amount-input',
                          placeholder: 'Modification amount',
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleModificationToLineItemChanged(
                              event.target.value,
                              'modification_amount',
                              index
                            ),
                          value: modificationToLineItem.modification_amount,
                        }}
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label htmlFor="new-approved-budget-input">
                      New Approved Budget
                    </label>
                    <div>
                      <CurrencyInput
                        inputprops={{
                          id: 'new-approved-budget-input',
                          placeholder: 'New Approved Budget',
                          disabled: true,
                          value:
                            modificationToLineItem.new_approved_budget_amount,
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
          <IconButton
            label="Modify another"
            icon={<PlusIcon />}
            onClick={handleModifyAnotherLineItemClicked}
          />
        </div>
      </Modal>
      <BudgetBlade
        isOpen={isBladeOpen}
        onClose={handleBladeClose}
        budgetLineItemEntity={budgetLineItemEntity}
        budgetIsFinalized={appContext.currentProject.budget_is_finalized}
        selectedBudgetLineItem={selectedBudgetLineItem}
        onDeleteClicked={handleNonFinalizedBudgetRowDeleted}
      />
    </section>
  );
});

BudgetPage.displayName = 'BudgetPage';

export default BudgetPage;
