import { CostCode } from '../types/sharedTypes';

export const getEntireCostCodeObjectByCostCodeId = (
  costCodeID: string,
  allCostCodes: CostCode[]
) => {
  const findCostCodeObject = (codes: CostCode[]): CostCode | null => {
    for (const costCode of codes) {
      if (costCode.id === costCodeID) {
        return costCode;
      }
      if (costCode.children.length > 0) {
        const childCostCode = findCostCodeObject(costCode.children);
        if (childCostCode) {
          return childCostCode;
        }
      }
    }
    return null;
  };

  const matchingCostCode = findCostCodeObject(allCostCodes);
  return matchingCostCode || null;
};
