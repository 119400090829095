import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import { Notification } from '../../types/notificationTypes';
import { formatNumberWithCommas } from '../currency/formatNumberWithCommas';

export const formatNotificationsForEditableTable = (
  notifications: Notification[]
): Row[] => {
  const result: Row[] = [];

  if (!notifications || notifications.length === 0) return result;

  for (const notification of notifications) {
    const currentRow: Row = {
      cells: [],
      rowData: notification,
    };
    // Entity name
    currentRow.cells.push({
      value: notification.entity_name,
      className: 'description-cell',
      id: 'description-cell',
      type: CellType.text,
    });

    // Entity type
    currentRow.cells.push({
      value: notification.entity_type,
      type: CellType.status,
    });

    // Action requested
    currentRow.cells.push({
      value: notification.action_requested,
      type: CellType.text,
    });

    // Vendor
    currentRow.cells.push({
      value: notification.vendor_name ?? 'N/A',
      type: CellType.text,
    });

    // Current status
    currentRow.cells.push({
      value: notification.status,
      type: CellType.status,
    });

    // Amount
    currentRow.cells.push({
      value: '$' + formatNumberWithCommas(notification.total_amount),
      type: CellType.text,
    });

    result.push(currentRow);
  }

  return result;
};
