import { ModificationToLineItem } from '../../components/BudgetPage/BudgetPage';
import { FormattedForBackendLineItemModification } from '../../services/budget/postLineItemModifications';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';

export const formatBudgetModificationsToLineItemsForBackend = (
  lineItemModifications: ModificationToLineItem[]
): FormattedForBackendLineItemModification[] => {
  const result: FormattedForBackendLineItemModification[] = [];

  for (let i = 0; i < lineItemModifications.length; i++) {
    const currentLineItemModification: ModificationToLineItem =
      lineItemModifications[i];
    result.push({
      amount: removeCharactersExceptForPeriodAndNumbersFromFloatString(
        currentLineItemModification.modification_amount
      ),
      line_item_id: currentLineItemModification?.line_item_id
        ? currentLineItemModification?.line_item_id
        : '',
      cost_code_id: currentLineItemModification?.cost_code?.id
        ? currentLineItemModification.cost_code.id
        : '',
    });
  }

  return result;
};
