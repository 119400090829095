import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { drawPackageRoute } from './getDrawPackages';
import { FormattedForBackendDrawPackage } from '../../types/drawPackageTypes';

export const postDrawPackage = async (
  formattedForBackendDrawPackage: FormattedForBackendDrawPackage
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + drawPackageRoute;

  const formattedApprovedUserIds =
    formattedForBackendDrawPackage.approver_user_ids.length === 0
      ? ''
      : formattedForBackendDrawPackage.approver_user_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'POST',
    body: JSON.stringify({
      ...formattedForBackendDrawPackage,
      approver_user_ids: formattedApprovedUserIds,
      funding_source_json: JSON.stringify(
        formattedForBackendDrawPackage.funding_source_json
      ),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
