import { FormattedForBackendContractLineItem } from '../../types/contractTypes';
import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { contractRoute } from './getContract';

interface FormattedForPutToBackendContract {
  project_id: string;
  title: string;
  status: string;
  number: string;
  description: string;
  vendor_id: string;
  approver_user_ids: string[];
  attachment_upload_ids: string[];
  line_item_json: FormattedForBackendContractLineItem[];
}

export const putContract = async (
  formattedForBackendContract: FormattedForPutToBackendContract,
  contractId: string
) => {
  const apiBase = getAPIBase();
  const endpoint = apiBase + contractRoute;

  const formattedApprovedUserIds =
    formattedForBackendContract.approver_user_ids.length === 0
      ? ''
      : formattedForBackendContract.approver_user_ids.join(',');

  const formattedAttachmentIds =
    formattedForBackendContract.attachment_upload_ids.length === 0
      ? ''
      : formattedForBackendContract.attachment_upload_ids.join(',');

  const req = {
    ...standardRequestObject,
    method: 'PUT',
    body: JSON.stringify({
      ...formattedForBackendContract,
      approver_user_ids: formattedApprovedUserIds,
      attachment_upload_ids: formattedAttachmentIds,
      contract_id: contractId,
      line_item_json: JSON.stringify(
        formattedForBackendContract.line_item_json
      ),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
