import { BudgetLineItem } from '../../types/budgetTypes';
import {
  getAPIBase,
  standardErrorHandler,
  standardRequestObject,
} from '../shared';
import { budgetRoute } from './getBudgets';

export const putBudgetLineItems = async (
  projectId: string,
  lineItems?: BudgetLineItem[]
) => {
  if (!lineItems || !projectId) {
    console.warn('Need project id and line items');
    return;
  }
  const apiBase = getAPIBase();
  const endpoint = apiBase + budgetRoute + '/line' + `?project_id=${projectId}`;


  const req = {
    ...standardRequestObject,
    method: 'PUT',
    body: JSON.stringify({
      line_item_json: JSON.stringify(lineItems),
    }),
  };

  try {
    const request = new Request(endpoint, req);
    const res = await fetch(request);
    const data = await res.json();
    return data;
  } catch (error: any) {
    standardErrorHandler(error);
  }
};
