import { ProjectTeamMember } from '../types/projectTypes';

export const applyGroupAndOrderFilters = (
  teamMembers: ProjectTeamMember[],
  groupBy: string,
  showDeactivated: boolean
) => {
  const filtersToTeamMemberDataValueMap: {
    [key: string]: keyof ProjectTeamMember;
  } = {
    Title: 'organization_title',
    Projects: 'project_count',
    Team: 'teams',
  };

  const groupKey = filtersToTeamMemberDataValueMap[groupBy];
  const groupedMembers: { [key: string]: ProjectTeamMember[] } = {};

  for (let i = 0; i < teamMembers.length; i++) {
    const currentTeamMember = teamMembers[i];
    const groupValue = currentTeamMember[groupKey];

    // Check if the team member is deactivated and showDeactivated is false
    if (!showDeactivated && currentTeamMember.is_deactivated) {
      continue; // Skip this team member
    }

    if (groupKey === 'teams') {
      const firstTeam =
        Array.isArray(groupValue) && groupValue.length > 0 ? groupValue[0] : '';
      if (!groupedMembers[firstTeam]) {
        groupedMembers[firstTeam] = [];
      }
      groupedMembers[firstTeam].push(currentTeamMember);
    } else {
      if (!groupedMembers[groupValue as string]) {
        groupedMembers[groupValue as string] = [];
      }
      groupedMembers[groupValue as string].push(currentTeamMember);
    }
  }

  const result: ProjectTeamMember[] = [];

  for (const groupValue in groupedMembers) {
    const groupMembers = groupedMembers[groupValue];
    result.push(...groupMembers);
  }

  const sortedResult = result.sort((a, b) => {
    const nameA = a.first_name;
    const nameB = b.first_name;

    return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
  });

  return sortedResult;
};
