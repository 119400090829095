import {
    getAPIBase,
    standardErrorHandler,
    standardRequestObject,
} from '../shared';

const route = 'invite/accept';

export const postAcceptInvite = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    inviteCode: string
) => {
    const apiBase = getAPIBase();
    const endpoint = apiBase + route;

    const req = {
        ...standardRequestObject,
        method: 'POST',
        body: JSON.stringify({
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            invite_code: inviteCode
        }),
    };

    try {
        const request = new Request(endpoint, req);
        const res = await fetch(request);
        const data = await res.json();
        return data;
    } catch (error: any) {
        standardErrorHandler(error);
    }
};
