import { CellType, Row } from '../components/EditableTable/EditableTableV2';
import { Invoice } from '../types/contractTypes';
import { formatDateMMDDYYYY } from './formatDateMMDDYYYY';

export const formatContractInvoicesForEditableTable = (
  invoices: Invoice[]
): Row[] => {
  const result: Row[] = [];

  if (!invoices || invoices.length === 0) return result;

  for (let i = 0; i < invoices.length; i++) {
    const currentInvoice = invoices[i];
    const currentRow: Row = {
      cells: [],
      rowData: currentInvoice,
      id: currentInvoice.id,
    };

    // ID
    currentRow.cells.push({
      value: currentInvoice.number_from_vendor ?? '-',
      type: CellType.number,
    });

    // Title
    currentRow.cells.push({
      value: currentInvoice.name,
      type: CellType.text,
    });

    // Total amount
    currentRow.cells.push({
      value: currentInvoice.total_amount.toFixed(2),
      type: CellType.dollars,
    });

    // Status
    currentRow.cells.push({
      value: currentInvoice.status,
      type: CellType.status,
    });

    // Date
    currentRow.cells.push({
      value: formatDateMMDDYYYY(currentInvoice.date_utc),
      type: CellType.text,
    });

    result.push(currentRow);
  }

  return result;
};
