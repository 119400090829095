import React, { ReactNode } from 'react';
import './Blade.scss';

interface Props {
  children?: ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const Blade: React.FC<Props> = ({ children, onClose, isOpen }) => {
  return (
    <div className="Blade">
      <div
        onClick={onClose}
        className={`background ${isOpen ? 'open-budget-blade-background' : ''}`}
      />
      <aside className={`blade-content ${isOpen ? 'open-budget-blade' : ''}`}>
        <button onClick={onClose} className="close-button">
          X
        </button>
        {children}
      </aside>
    </div>
  );
};

export default Blade;
