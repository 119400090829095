import { CellType, Row } from '../../components/EditableTable/EditableTableV2';
import {
  BudgetLineItemEntity,
  BudgetLineItemEntityContract,
  BudgetLineItemEntityInvoice,
  BudgetLineItemEntityChangeOrder,
} from '../../types/budgetTypes';

export const formatBudgetLineItemEntityToEditableTableRows = (
  budgetLineItemEntity: BudgetLineItemEntity | null
): Row[] => {
  const result: Row[] = [];
  if (!budgetLineItemEntity) return result;

  Object.entries(budgetLineItemEntity).forEach(
    ([key, value]: [
      string,
      (
        | BudgetLineItemEntityContract[]
        | BudgetLineItemEntityInvoice[]
        | BudgetLineItemEntityChangeOrder[]
      ),
    ]) => {
      // Check if value is an array before using forEach
      if (Array.isArray(value)) {
        value.forEach(
          (
            budgetLineItemEntity:
              | BudgetLineItemEntityContract
              | BudgetLineItemEntityInvoice
              | BudgetLineItemEntityChangeOrder
          ) => {
            const currentRowValue: Row = {
              cells: [],
              id: budgetLineItemEntity.id,
              rowData: { ...budgetLineItemEntity, key: key },
            };
            const name =
              key === 'invoices'
                ? budgetLineItemEntity.name ?? '-'
                : budgetLineItemEntity.title ?? '-';

            // Name
            currentRowValue.cells.push({
              value: name,
              type: CellType.text,
            });

            // Amount
            currentRowValue.cells.push({
              value: budgetLineItemEntity.amount.toString(),
              type: CellType.dollars,
            });

            // Status
            currentRowValue.cells.push({
              value: budgetLineItemEntity.status,
              type: CellType.status,
            });

            result.push(currentRowValue);
          }
        );
      }
    }
  );

  return result;
};
