import { CostCode } from '../types/sharedTypes';

export const getCostCodeByCode = (
  costCodes: CostCode[],
  targetCode: string
): CostCode | null => {
  let result: CostCode | null = null;

  const findCostCode = (codes: CostCode[]): void => {
    for (const code of codes) {
      if (code.code === targetCode) {
        result = code;
        // If the target code is found, no need to continue searching
        return;
      }

      // Recursively search in the children of the current cost code
      findCostCode(code.children);

      // If the result has been found, break out of the loop
      if (result !== null) {
        break;
      }
    }
  };

  // Start the search from the top-level cost codes
  findCostCode(costCodes);

  return result;
};
