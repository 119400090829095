import { ContractLineItem } from '../components/ContractPage/ContractPage';
import { getCostCodeDescriptionByCode } from './getCostCodeDescriptionByCode';
import { CostCode } from '../types/sharedTypes';
import { getCostCodeCodeById } from './getCostCodeCodeById';
import {
  Row,
  CellType,
  DropdownOption,
} from '../components/EditableTable/EditableTableV2';
import { getEntireCostCodeObjectByCostCodeId } from './getEntireCostCodeObjectByCostCodeId';
import { ChangeOrder, ChangeOrderLineItem } from '../types/changeOrderTypes';
import { Invoice, InvoiceLineItem } from '../types/invoiceTypes';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from './currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUTCDateTime } from './misc/getCurrentUTCDateTime';

export const fallbackCostCode: CostCode = {
  code: '12.345',
  description: 'Fallback costcode',
  id: 'aaaaaaa-bbbb-ccccc-ddd-eeeeeeeeeee',
  organization_id: null,
  project_id: null,
  type: 'Testing',
  is_parent: false,
  children: [],
};

export const formatNewContractLineItemsScheduleOfValuesToTableData = (
  newContractLineItems: ContractLineItem[],
  costCodes: CostCode[],
  changeOrders: ChangeOrder[],
  invoices: Invoice[]
): Row[] => {
  const result: Row[] = [];
  newContractLineItems.forEach((contractLineItem: ContractLineItem) => {
    const currentRow: Row = {
      cells: [],
      id: uuidv4(),
      rowData: { created_on_utc: getCurrentUTCDateTime() },
    };

    let costCodeValue = getEntireCostCodeObjectByCostCodeId(
      contractLineItem.costCode,
      costCodes
    );
    if (!costCodeValue) costCodeValue = fallbackCostCode;

    // 'Cost Code'
    currentRow.cells.push({
      value: costCodeValue.id,
      type: CellType.dropdown,
      dropdownOptions: costCodes,
    });

    // 'Quoted Line Item'
    currentRow.cells.push({
      value: contractLineItem.quoted_line_item ?? '',
      type: CellType.editableText,
    });

    // 'Original Amount'
    currentRow.cells.push({
      value: removeCharactersExceptForPeriodAndNumbersFromFloatString(
        contractLineItem.total_amount
      ),
      type: CellType.dollars,
    });

    // 'Approved COs'
    const approvedChangeOrderLineItems = [];
    for (const changeOrder of changeOrders) {
      if (changeOrder.status !== 'Approved') continue;
      for (const changeOrderLineItem of changeOrder.line_items) {
        if (changeOrderLineItem.cost_code_id === costCodeValue.id) {
          approvedChangeOrderLineItems.push(changeOrderLineItem);
        }
      }
    }
    const approveCOsAmount = approvedChangeOrderLineItems.reduce(
      (acc: number, changeOrderLineItem: ChangeOrderLineItem) => {
        return acc + changeOrderLineItem.budgeted_amount;
      },
      0.0
    );
    currentRow.cells.push({
      value: approveCOsAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Total Approved Amount'
    const approvedAmount =
      approveCOsAmount +
      parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          contractLineItem.total_amount
        )
      );
    currentRow.cells.push({
      value: approvedAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Pending COs'
    const pendingChangeOrderLineItems = [];
    for (const changeOrder of changeOrders) {
      if (changeOrder.status !== 'In Review') continue;
      for (const changeOrderLineItem of changeOrder.line_items) {
        if (changeOrderLineItem.cost_code_id === costCodeValue.id) {
          pendingChangeOrderLineItems.push(changeOrderLineItem);
        }
      }
    }
    const pendingCOsAmount = pendingChangeOrderLineItems.reduce(
      (acc: number, changeOrderLineItem: ChangeOrderLineItem) => {
        return acc + +changeOrderLineItem.budgeted_amount;
      },
      0.0
    );
    currentRow.cells.push({
      value: pendingCOsAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Total Projected Amount'
    const projectedAmount = approvedAmount + pendingCOsAmount;
    currentRow.cells.push({
      value: projectedAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Paid To Date'
    const paidInvoiceLineItems = [];
    for (const invoice of invoices) {
      if (invoice.status !== 'Paid') continue;
      for (const invoiceLineItem of invoice.line_items) {
        if (invoiceLineItem.cost_code_id === costCodeValue.id) {
          paidInvoiceLineItems.push(invoiceLineItem);
        }
      }
    }
    const paidInvoiceAmount = paidInvoiceLineItems.reduce(
      (acc: number, invoiceLineItem: InvoiceLineItem) => {
        return acc + invoiceLineItem.current_billing;
      },
      0.0
    );
    currentRow.cells.push({
      value: paidInvoiceAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Current Amount Due'
    const unpaidInvoiceLineItems = [];
    for (const invoice of invoices) {
      if (
        !['Draft', 'Reviewed', 'Approved', 'Sent to ERP'].includes(
          invoice.status
        )
      )
        continue;
      for (const invoiceLineItem of invoice.line_items) {
        if (invoiceLineItem.cost_code_id === costCodeValue.id) {
          unpaidInvoiceLineItems.push(invoiceLineItem);
        }
      }
    }
    const unpaidInvoiceAmount = unpaidInvoiceLineItems.reduce(
      (acc: number, invoiceLineItem: InvoiceLineItem) => {
        return acc + invoiceLineItem.current_billing;
      },
      0.0
    );
    currentRow.cells.push({
      value: unpaidInvoiceAmount.toFixed(2),
      type: CellType.dollars,
      isCellDisabled: true,
      className: 'text-align-right',
    });

    // 'Remaining %'
    let remainingPercentage =
      ((projectedAmount - paidInvoiceAmount) / projectedAmount) * 100;
    if (remainingPercentage < 0) remainingPercentage = 0;
    currentRow.cells.push({
      value: remainingPercentage.toFixed(2),
      type: CellType.percentage,
      isCellDisabled: true,
    });

    result.push(currentRow);
  });

  return result;
};
