import { CostCode } from '../../types/sharedTypes';

export const findCostCodeDifference = (
  costCode1: CostCode | null,
  costCode2: CostCode | null
): CostCode[] => {
  const result: CostCode[] = [];
  if (!costCode1 || !costCode2) return result;

  const traverseAndCompare = (
    currentCostCode1: CostCode,
    currentCostCode2: CostCode
  ): void => {
    const code1Set = new Set(currentCostCode1.children.map((c) => c.code));

    currentCostCode2.children.forEach((child2) => {
      if (!code1Set.has(child2.code)) {
        result.push(child2);
      }
    });

    currentCostCode1.children.forEach((child1) => {
      const matchingChild2 = currentCostCode2.children.find(
        (c) => c.code === child1.code
      );

      if (matchingChild2) {
        traverseAndCompare(child1, matchingChild2);
      } else {
        result.push(child1);
      }
    });
  };

  traverseAndCompare(costCode1, costCode2);

  return result.flatMap((item) => [...item.children, item]);
};
