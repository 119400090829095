export interface CostCode {
  children: CostCode[];
  code: string;
  id: string;
  is_parent: boolean;
  description: string;
  organization_id: string | null;
  project_id: string | null;
  type: string;
}

export interface Attachment {
  change_order_id: string;
  id: string;
  is_unattached: boolean;
  upload: {
    content_type: string;
    download_url: string;
    download_url_expiration_utc: string;
    filename: string;
    id: string;
    is_processed: boolean;
    type: string;
    uploaded_by_user_id: string;
  };
  upload_id: string;
}

export interface Vendor {
  id: string;
  name: string;
  description?: string;
  point_of_contact: string;
  vendor_number?: string;
  tax_id?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  is_deactivated: boolean;
}

export enum Statuses {
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Funded' = 'Funded',
  'Approved' = 'Approved',
  'Sent' = 'Sent',
  'Paid' = 'Paid',
  'Complete' = 'Complete',
  'Pending' = 'Pending',
  'Reviewed' = 'Reviewed',
  'Medium' = 'Medium',
  'Draft' = 'Draft',
  'Low' = 'Low',
  'Archived' = 'Archived',
  'New' = 'New',
  'Rejected' = 'Rejected',
  'Terminated' = 'Terminated',
  'Deactivated' = 'Deactivated',
  'High' = 'High',
  'Urgent' = 'Urgent',
  'In Review' = 'In Review',
}

export interface FilterItem {
  label: string;
  value: string;
}
