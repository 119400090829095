import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { PiWarningCircle as WarningIcon } from 'react-icons/pi';
import { FiCheck as CheckIcon } from 'react-icons/fi';
import './MultiSelect.scss';

export interface OptionType {
  value: string;
  label: string;
  isComplete: boolean;
}

export interface VendorFormattedForSelect {
  value: string;
  label: string;
  vendor_id: string | null;
}

interface Props {
  id?: string;
  options: OptionType[];
  onChange?: (newOptions: string[]) => void;
  selectedOptions: OptionType[];
  setSelectOptions: (newSelectedOptions: OptionType[]) => void;
}

const { MultiValue } = components;
const MultiValueIcon = (props: any) => {
  return (
    <MultiValue className="multi-value-container" {...props}>
      {props.data.isComplete ? (
        <CheckIcon size={15} className="status-icon" />
      ) : (
        <WarningIcon size={15} className="status-icon" />
      )}
      {props.data.label}
    </MultiValue>
  );
};

const MultiSelect: React.FC<Props> = ({
  id,
  options,
  onChange,
  selectedOptions,
  setSelectOptions,
}) => {
  const handleChange = (selected: any) => {
    setSelectOptions(selected);
    if (onChange) {
      const onlySelectedValues: string[] = selected.map(
        (selectedItem: OptionType) => selectedItem.value
      );
      onChange(onlySelectedValues);
    }
  };

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      id={id}
      components={{ MultiValue: MultiValueIcon }}
      styles={{
        multiValue: (baseStyles, state) => {
          return {
            ...baseStyles,
            backgroundColor: state.data.isComplete ? '#ECFDF3' : '#FFFAEB',
            color: state.data.isComplete ? '#067647' : '#B54708',
            border: `1px solid ${
              state.data.isComplete ? '#ABEFC6' : '#FEDF89'
            }`,
            borderRadius: '4px',
          };
        },
        multiValueLabel: (baseStyles, state) => {
          return {
            ...baseStyles,
            color: state.data.isComplete ? '#067647' : '#B54708',
          };
        },
        multiValueRemove: (baseStyles, state) => {
          return {
            ...baseStyles,
            height: '1.2rem',
            marginRight: '2px',
          };
        },
      }}
    />
  );
};

export default MultiSelect;
