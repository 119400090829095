import React, { useState, useEffect } from 'react';
import './UsersPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import formatTeamFilters from '../../utils/formatTeamFilters';
import { applyTeamFilters } from '../../utils/applyTeamFilter';
import DisplayPopUnder from '../DisplayPopUnder/DisplayPopUnder';
import { applyGroupAndOrderFilters } from '../../utils/applyGroupAndOrderFilters';
import Modal from '../Modal/Modal';
import { inviteTeamMember } from '../../services/team/inviteTeamMember';
import { isCommaSeparated } from '../../utils/isCommaSeparated';
import { toast } from 'react-toastify';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import { AppContext } from '../../types/appContextTypes';
import { FilterItem } from '../../types/sharedTypes';
import { BsFilter as FilterIcon } from 'react-icons/bs';
import TabBar from '../TabBar/TabBar';
import EditableTableV2, { Column } from '../EditableTable/EditableTableV2';
import { formatUsersForEditableTable } from '../../utils/users/formatUsersForEditableTable';
import { BiExport as ExportIcon } from 'react-icons/bi';

export enum TeamTypes {
  Internal = 'internal',
  External = 'external',
}

interface Props {
  appContext: AppContext;
}

const tabOptions = ['Internal', 'External'];

const userTableColumns: Column[] = [
  {
    value: 'Name',
  },
  {
    value: 'Title',
  },
  {
    value: 'Email',
  },
  {
    value: 'Teams',
  },
  {
    value: 'Status',
  },
];

const UsersPage: React.FC<Props> = ({ appContext }) => {
  const [activeTabindex, setActiveTabIndex] = useState<number>(0);
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [isInviteUserModalVisible, setIsInviteUserModalVisible] =
    useState<boolean>(false);
  const [isDisplaysPopUnderVisible, setIsDisplaysPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamTypes>(
    TeamTypes.Internal
  );
  const [search, setSearch] = useState<string>('');
  const [isShowDeactivatedUsersToggleOn, setIsShowDeactivatedUsersToggleOn] =
    useState<boolean>(true);
  const [selectedGroupBy, setSelectedGroupBy] = useState<string>('None');
  const [selectedOrderBy, setSelectedOrderBy] =
    useState<string>('orderByOptions');
  const [emailsToInvite, setEmailsToInvite] = useState<string>('');
  const [emailsToInviteError, setEmailsToInviteError] = useState<string>('');
  const [role, setRole] = useState<string>('Member');
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);

  const hasActiveFilters = activeFilters.length > 0;

  const showSuccessInviteUsersToast = () =>
    toast('Successfully invited users!', {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const closeFilterPopUnder = () => {
    setIsFilterPopUnderVisible(false);
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleClearFiltersButtonClicked = () => {
    setActiveFilters([]);
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const handleViewTabClicked = (newView: number) => {
    setActiveTabIndex(newView);
    const newSelectedTeam = tabOptions[newView];
    setSelectedTeam(newSelectedTeam.toLowerCase() as TeamTypes);
  };

  const handleResetClicked = () => {
    setSelectedGroupBy('None');
    setSelectedOrderBy('None');
  };

  const handleInviteUsersSubmit = () => {
    if (emailsToInvite.length === 0) return;
    const isStringCommaSeparated = isCommaSeparated(emailsToInvite);
    if (!isStringCommaSeparated) {
      setEmailsToInviteError('Emails must be comma separated');
      return;
    } else {
      setEmailsToInviteError('');
      (async () => {
        const response = await inviteTeamMember(
          appContext.currentProject.id,
          emailsToInvite,
          role
        );
        if (response?.error_message) {
          showErrorMessageToast(response?.error_message);
        } else {
          showSuccessInviteUsersToast();
          setIsInviteUserModalVisible(false);
        }
      })();
    }
  };

  const handleCancelInviteUsersModalClicked = () => {
    setIsInviteUserModalVisible(false);
    setEmailsToInviteError('');
  };

  const teamFiltersMap: filtersMap = formatTeamFilters(
    appContext.projectTeam[selectedTeam]
  );
  const filteredTeamMembers = applyTeamFilters(
    activeFilters,
    appContext.projectTeam[selectedTeam],
    search
  );
  const groupAndOrderedTeammates = applyGroupAndOrderFilters(
    filteredTeamMembers,
    selectedGroupBy,
    isShowDeactivatedUsersToggleOn
  );

  const userTableRows = formatUsersForEditableTable(groupAndOrderedTeammates);

  const groupByOptions = ['None', 'Title', 'Projects', 'Team'];
  const orderByOptions = ['None', 'Test 1', 'Test 2', 'Test 3'];

  window.document.title = 'BidSight – Users';
  return (
    <section className="UsersPage">
      <div className="header-button-container">
        <div className="primary-header-subheader-container">
          <h4 className="main-page-header">Project users</h4>
          <h5>View and invite team members here</h5>
        </div>
        <Button
          variant={ButtonVariant.PrimaryThin}
          label="Invite users"
          onClick={() => setIsInviteUserModalVisible(true)}
        />
      </div>
      <div className="tab-bar-container">
        <TabBar
          tabOptions={tabOptions}
          activeTabOption={tabOptions[activeTabindex]}
          onTabClick={handleViewTabClicked}
        />
      </div>
      <div className="search-filters-container">
        <span className="search-input-container">
          <TextInput
            placeholder="Search"
            icon={<MagnifyingGlassIcon />}
            onChange={setSearch}
          />
        </span>
        {filteredTeamMembers.length > 0 && (
          <span className="filter-popunder-container">
            <IconButton
              icon={<FilterIcon />}
              label={'Filters'}
              variant={IconButtonVariants.dotted}
              onClick={() =>
                setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
              }
            />
            <PopUnder
              isOpen={isFilterPopUnderVisible}
              onClose={closeFilterPopUnder}
              optionsMap={teamFiltersMap}
              handleSecondOptionSelected={handleFilterSelected}
              activeFilters={activeFilters}
            />
          </span>
        )}
        {hasActiveFilters && (
          <span className="clear-filters-button-container">
            <IconButton
              icon={<CloseIcon />}
              label={'Clear filters'}
              variant={IconButtonVariants.dotted}
              onClick={handleClearFiltersButtonClicked}
            />
          </span>
        )}
        <div className="export-container">
          <IconButton
            icon={<ExportIcon />}
            label={tableIsExporting ? 'Exporting...' : 'Export'}
            variant={IconButtonVariants.dotted}
            onClick={() => setTableIsExporting(true)}
          />
        </div>
      </div>
      <div className="active-statuses-container">
        {activeFilters.map((activeFilter) => (
          <FilterButton
            key={activeFilter.label}
            filter={activeFilter}
            onClick={handleFilterButtonRemoved}
          />
        ))}
      </div>
      <div className="bottom">
        <div className="table-container">
          {appContext.isRefetching ? (
            <EditableTableLoadingSkeleton />
          ) : userTableRows.length > 0 ? (
            <EditableTableV2
              rows={userTableRows}
              columns={userTableColumns}
              tableName={`${appContext.currentProject.name} Users - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
              isReadOnly
            />
          ) : (
            <div>No users found</div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isInviteUserModalVisible}
        onClose={handleCancelInviteUsersModalClicked}
        onPrimaryButtonClicked={handleInviteUsersSubmit}
        onSecondaryButtonClicked={handleCancelInviteUsersModalClicked}
        primaryButtonLabel="Send invites"
        primaryHeader="Invite users"
        secondaryHeader="Invite users by adding their email and selecting a role"
      >
        <label className="modal-input-label" htmlFor="email-input">
          Email
        </label>
        <div className="input-container">
          <TextInput
            id="email-input"
            placeholder="email@example.com, email2@example.com"
            onChange={setEmailsToInvite}
            errorMessage={emailsToInviteError}
          />
        </div>
        <div className="label-input-container">
          <label className="modal-input-label" htmlFor="role-input">
            Invite as
          </label>
        </div>
        <select
          className="role-select"
          id="role-input"
          value={role}
          onChange={(event) => setRole(event.target.value)}
        >
          <option>Member</option>
          <option>Admin</option>
        </select>
      </Modal>
    </section>
  );
};

export default UsersPage;
