import React, { useState, useEffect, useRef, useCallback } from 'react';
import './InvoicesPage.scss';
import Button, { ButtonVariant } from '../Button/Button';
import TabBar from '../TabBar/TabBar';
import IconButton, { IconButtonVariants } from '../IconButton/IconButton';
import TextInput from '../TextInput/TextInput';
import { HiAdjustmentsHorizontal as DisplayIcon } from 'react-icons/hi2';
import {
  AiOutlineSearch as MagnifyingGlassIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import { BsFilter as FilterIcon, BsUpload as UploadIcon } from 'react-icons/bs';
import { ImMagicWand as MagicWandIcon } from 'react-icons/im';
import Modal, { ModalSizes } from '../Modal/Modal';
import Card from '../Card/Card';
import Select from '../Select/Select';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { createTomorrowdate } from '../../utils/createTomorrowDate';
import PdfViewer from '../PDFVIewer/PDFViewer';
import MultiSelect, {
  OptionType,
  VendorFormattedForSelect,
} from '../MultiSelect/MultiSelect';
import {
  DrawPackage,
  Invoice,
  InvoiceLineItem,
  NewInvoiceLineItem,
  Upload,
} from '../../types/invoiceTypes';
import EditableTableV2, { Column, Row } from '../EditableTable/EditableTableV2';
import EditableTableLoadingSkeleton from '../EditableTable/EditableTableLoadingSkeleton';
import { formatInvoicesForEditableTable } from '../../utils/invoice/formatInvoicesForEditableTable';
import { Vendor, CostCode } from '../../types/sharedTypes';
import { User } from '../../types/userTypes';
import { Contract } from '../../types/contractTypes';
import { formatInvoiceLineItemsForEditableTable } from '../../utils/invoice/formatInvoiceLineItemsForEditableTable';
import { postInvoice } from '../../services/invoice/postInvoice';
import { formatDateToCustomString } from '../../utils/formatDateForBackend';
import { toast } from 'react-toastify';
import { postContractAttachment } from '../../services/contract/postContractAttachment';
import { getAPIBase, standardRequestObject } from '../../services/shared';
import axios from 'axios';
import { GoPlus as PlusIcon } from 'react-icons/go';
import { getCostCodeDescriptionByCode } from '../../utils/getCostCodeDescriptionByCode';
import { formatNewLineItemsIntoBackendLineItems } from '../../utils/invoice/formatNewLineItemsIntoBackendLineItems';
import { putInvoice } from '../../services/invoice/putInvoice';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { removeEverythingBesidesNumbersAndDecimalsFromString } from '../../utils/removeEverythingBesidesNumbersAndDecimalsFromString';
import { ReactComponent as FileIconWithCircle } from '../../assets/File-icon-with-circle.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import { getEntireCostCodeObjectByCostCodeId } from '../../utils/getEntireCostCodeObjectByCostCodeId';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { removeCharactersExceptForPeriodAndNumbersFromFloatString } from '../../utils/currency/removeCharactersExceptForPeriodAndNumbersFromFloatString';
import { getEntireCostCodeObjectByDescription } from '../../utils/getEntireCostCodeObjectByDescription';
import { isLastCharADecimal } from '../../utils/misc/isLastCharADecimal';
import { formatInvoiceLineItemsForBackend } from '../../utils/invoice/formatInvoiceLineItemsForBackend';
import { formatContractLineItemsIntoInvoiceLineItems } from '../../utils/invoice/formatContractLineItemsIntoInvoiceLineItems';
import { v4 as uuidv4 } from 'uuid';
import { sortInvoiceLineItems } from '../../utils/costCodes/sortInvoiceLineItemsByCostCodeCode';
import { AppContext } from '../../types/appContextTypes';
import { postInvoiceApproval } from '../../services/invoice/postInvoiceApproval';
import { postInvoiceReview } from '../../services/invoice/postInvoiceReview';
import { FilterItem } from '../../types/sharedTypes';
import PopUnder, { filtersMap } from '../PopUnder/PopUnder';
import FilterButton from '../FilterButton/FilterButton';
import { formatInvoiceFilters } from '../../utils/invoice/formatInvoiceFilters';
import { applyInvoiceFilters } from '../../utils/invoice/applyInvoiceFilters';
import { ReactComponent as LoadingSpinner } from '../../assets/loading-spinner.svg';
import { postExtractInvoicePage } from '../../services/invoice/postExtractInvoicePage';
import { sortInvoices } from '../../utils/invoice/sortInvoices';
import { getInitialInvoiceLineItemWithCalculations } from '../../utils/invoice/getInitialInvoiceLineItemWithCalculations';
import { BiExport as ExportIcon } from 'react-icons/bi';
import CostCodeSelectorInput from '../CostCodeSelectorInput/CostCodeSelectorInput';
import { sortCostCodesByCode } from '../../utils/costCodes/sortCostCodesByCode';
import { convertBudgetLineItemsIntoCostCodes } from '../../utils/costCodes/convertBudgetLineItemsIntoCostCodes';
import { postExportInvoicesForERP } from '../../services/invoice/postExportInvoicesForERP';

interface AttachmentFormattedForFrontEnd {
  id: string;
  size?: number | null;
  title: string;
  download_url: string;
}

const invoiceColumns: Column[] = [
  {
    value: 'ID',
  },
  {
    value: 'Invoice Name',
  },
  {
    value: 'Invoice Number',
  },
  {
    value: 'Vendor',
  },
  {
    value: 'Total Amount',
    className: 'text-align-right',
  },
  {
    value: 'Status',
  },
  {
    value: 'Invoice Date',
  },
  {
    value: 'Draw Package',
  },
  {
    value: 'Draw Status',
  },
  {
    value: 'Contract',
  },
  {
    value: 'Contract Status',
  },
];

const invoiceLineItemColumns: Column[] = [
  // Blank column for actions column
  {
    value: '',
    className: 'actions-column',
  },
  {
    value: 'Cost Code',
    dataKey: 'cost_code',
    className: 'cost-code-selector-column',
  },
  {
    value: 'Quoted Line Item',
    dataKey: 'quoted_line_item',
  },
  {
    value: 'Current Billing',
    className: 'text-align-right',
    dataKey: 'current_billing',
  },
  {
    value: 'Previously Paid',
    className: 'text-align-right',
    dataKey: 'previously_paid',
  },
  {
    value: 'Scheduled Value',
    className: 'text-align-right',
    dataKey: 'scheduled_value',
  },
  {
    value: 'From Previous Applications',
    className: 'text-align-right',
    dataKey: 'from_previous_applications',
  },
  {
    value: 'This Period',
    className: 'text-align-right',
    dataKey: 'this_period',
  },
  {
    value: 'Materials Presently Stored',
    className: 'text-align-right',
    dataKey: 'materials_presently_stored',
  },
  {
    value: 'Total Completed and Stored to Date',
    className: 'text-align-right',
    dataKey: 'total_completed_and_stored_to_date',
  },
  {
    value: '% Complete',
  },
  {
    value: 'Balance to Finish',
    className: 'text-align-right',
  },
  {
    value: 'Total retainage',
    className: 'text-align-right',
    dataKey: 'retainage',
  },
  {
    value: 'Current work and materials',
    className: 'text-align-right',
    dataKey: 'current_work_and_materials',
  },
  {
    value: 'Previous retainage',
    className: 'text-align-right',
    dataKey: 'previous_retainage',
  },
  {
    value: 'Current Retainage',
    className: 'text-align-right',
    dataKey: 'current_retainage',
  },
];

interface PageContentLineItem {
  amount?: string;
  description?: string;
  description_of_work?: string;
  retainage?: string;
  materials_presently_stored?: string;
  scheduled_value?: string;
  work_completed_this_period?: string;
  product_code?: string;
}

interface PageContent {
  amount_due: string;
  due_date: string;
  invoice_date: string;
  invoice_id: string;
  line_items: PageContentLineItem[];
  supplier_name: string;
  total_amount: string;
}

interface DownloadResponse {
  download_url: string;
  num_pages: number;
  page_content: PageContent;
}

interface WorkflowViewProps {
  selectedContract: string;
  selectedVendor: string;
  selectedDrawPackage: string;
  reviewersActiveOptions: OptionType[];
  approversActiveOptions: OptionType[];
  reviewersOptions: OptionType[];
  approversOptions: OptionType[];
  contractOptions: VendorFormattedForSelect[];
  drawPackageOptions: VendorFormattedForSelect[];
  handleSelectedContractChange: (newVendor: string) => void;
  handleSelectedDrawPackageChange: (newValue: string) => void;
  handleReviewerActiveOptionsChange: (newActiveOptions: string[]) => void;
  handleReviewerOptionsChange: (newActiveOptions: OptionType[]) => void;
  handleApproverActiveOptionsChange: (newActiveOptions: string[]) => void;
  handleApproverOptionsChange: (newActiveOptions: OptionType[]) => void;
}

const WorkFlowView: React.FC<WorkflowViewProps> = ({
  selectedContract,
  selectedVendor,
  selectedDrawPackage,
  reviewersActiveOptions,
  reviewersOptions,
  approversOptions,
  approversActiveOptions,
  contractOptions,
  drawPackageOptions,
  handleSelectedContractChange,
  handleSelectedDrawPackageChange,
  handleReviewerActiveOptionsChange,
  handleReviewerOptionsChange,
  handleApproverActiveOptionsChange,
  handleApproverOptionsChange,
}) => {
  return (
    <div className="WorkFlowView">
      <div className="label-input-container">
        <label htmlFor="selected-contract">Contract</label>
        <Select
          id="selected-contract"
          value={selectedContract}
          onChange={(newContract: string) =>
            handleSelectedContractChange(newContract)
          }
          emptyPlaceholder={
            contractOptions.length > 0
              ? 'No contracts for this vendor'
              : 'No contracts available'
          }
        >
          {contractOptions
            .filter((contract) => {
              return (
                !selectedVendor ||
                !contract.vendor_id ||
                selectedVendor == contract.vendor_id
              );
            })
            .map((vendor: VendorFormattedForSelect) => {
              return (
                <option key={vendor.vendor_id} value={vendor.value}>
                  {vendor.label}
                </option>
              );
            })}
        </Select>
      </div>
      <div className="label-input-container">
        <label htmlFor="reviewers">Reviewers</label>
        <MultiSelect
          id="reviewers"
          options={reviewersOptions}
          selectedOptions={reviewersActiveOptions}
          setSelectOptions={(newOptions: OptionType[]) =>
            handleReviewerOptionsChange(newOptions)
          }
          onChange={(newValue: string[]) =>
            handleReviewerActiveOptionsChange(newValue)
          }
        />
        {reviewersActiveOptions.length > 0 && (
          <span className="approval-status">
            {`${
              reviewersActiveOptions.filter((opt) => opt.isComplete === true)
                .length
            }/${
              reviewersActiveOptions.length
            } reviewers have reviewed the invoice`}
          </span>
        )}
      </div>
      <div className="label-input-container">
        <label htmlFor="approvers">Approvers</label>
        <MultiSelect
          id="approvers"
          options={approversOptions}
          selectedOptions={approversActiveOptions}
          setSelectOptions={(newOptions: OptionType[]) =>
            handleApproverOptionsChange(newOptions)
          }
          onChange={(newValue: string[]) =>
            handleApproverActiveOptionsChange(newValue)
          }
        />
        {approversActiveOptions.length > 0 && (
          <span className="approval-status">
            {`${
              approversActiveOptions.filter((opt) => opt.isComplete === true)
                .length
            }/${
              approversActiveOptions.length
            } approvers have approved the invoice`}
          </span>
        )}
      </div>
      <div className="label-input-container">
        <label htmlFor="draw-package">Draw Package</label>
        <Select
          id="draw-package"
          value={selectedDrawPackage}
          onChange={(newDrawPackage: string) =>
            handleSelectedDrawPackageChange(newDrawPackage)
          }
        >
          {drawPackageOptions.map((drawPackage: VendorFormattedForSelect) => {
            return (
              <option key={drawPackage.label} value={drawPackage.value}>
                {drawPackage.label}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

interface InvoiceDataViewProps {
  vendorID: string;
  invoiceNumber: string;
  totalAmount: string;
  invoiceID: string;
  description: string;
  selectedVendor: string;
  date: Date;
  minDate?: Date;
  status: string;
  vendorOptions: VendorFormattedForSelect[];
  handleSelectedVendorChange: (newVendor: string) => void;
  handleVendorIDChange: (newValue: string) => void;
  handleInvoiceNumberChange: (newValue: string) => void;
  handleInvoiceIDChange: (newValue: string) => void;
  handleTotalAmountChange: (newValue: string) => void;
  handleDateChange: (newValue: Date) => void;
  handleDescriptionChange: (newValue: string) => void;
  handleStatusChange: (newValue: string) => void;
}

const InvoiceDataView: React.FC<InvoiceDataViewProps> = ({
  vendorID,
  invoiceNumber,
  invoiceID,
  totalAmount,
  selectedVendor,
  date,
  description,
  vendorOptions,
  minDate,
  status,
  handleSelectedVendorChange,
  handleVendorIDChange,
  handleInvoiceNumberChange,
  handleInvoiceIDChange,
  handleTotalAmountChange,
  handleDateChange,
  handleDescriptionChange,
  handleStatusChange,
}) => {
  const statusOptions = [
    { label: 'Draft', value: 'Draft' },
    { label: 'In Review', value: 'In Review' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Sent to ERP', value: 'Sent to ERP' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Archived', value: 'Archived' },
  ];

  return (
    <div className="InvoiceDataView">
      <div className="label-input-container">
        <label htmlFor="invoice-status">Invoice Status</label>
        <Select
          value={status}
          onChange={(newValue: string) => handleStatusChange(newValue)}
        >
          {statusOptions.map((statusOption: any) => {
            return (
              <option key={statusOption.label} value={statusOption.value}>
                {statusOption.label}
              </option>
            );
          })}
        </Select>
      </div>
      <div className="label-input-container">
        <label htmlFor="vendor-select">Vendor</label>
        <Select
          id="vendor-select"
          value={selectedVendor}
          onChange={(newVendor: string) =>
            handleSelectedVendorChange(newVendor)
          }
        >
          {vendorOptions.map((vendor: VendorFormattedForSelect) => {
            return (
              <option key={vendor.vendor_id} value={vendor.value}>
                {vendor.label}
              </option>
            );
          })}
        </Select>
      </div>
      {/* <div className="label-input-container">
        <label htmlFor="vendor-id">Vendor ID</label>
        <TextInput
          id="vendor-id"
          placeholder="Vendor ID"
          value={vendorID}
          isDisabled
          onChange={(newValue: string) => handleVendorIDChange(newValue)}
        />
      </div> */}
      <div className="label-input-container">
        <label htmlFor="invoice-number">Invoice #</label>
        <TextInput
          id="invoice-number"
          placeholder="Invoice number"
          value={invoiceNumber}
          onChange={(newValue: string) => handleInvoiceNumberChange(newValue)}
        />
      </div>
      <div className="label-input-container">
        <label htmlFor="invoice-id">Invoice ID</label>
        <TextInput
          id="invoice-id"
          placeholder="Invoice ID"
          value={invoiceID}
          onChange={(newValue: string) => handleInvoiceIDChange(newValue)}
        />
      </div>
      <div className="label-input-container">
        <label htmlFor="invoice-date">Invoice Date</label>
        <CustomDatePicker
          selectedDate={date}
          handleDateChange={(newDate: Date) => handleDateChange(newDate)}
        />
      </div>
      <div className="label-input-container">
        <label htmlFor="total-amount">Total amount</label>
        <CurrencyInput
          inputprops={{
            id: 'total-amount',
            placeholder: 'Total amount',
            // Sometimes this input renders without a $ symbol.
            // forcing the input to always have one with this hack
            value: `$${totalAmount}`,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              handleTotalAmountChange(event.target.value);
            },
          }}
        />
      </div>
      <div className="label-input-container">
        <label htmlFor="description">Description</label>
        <div className="text-area-container">
          <textarea
            placeholder="Enter a description..."
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleDescriptionChange(e.target.value)
            }
            value={description}
            id="description"
          />
        </div>
      </div>
    </div>
  );
};

const tabOptions = ['All invoices'];

const modalTabBarOptions = ['Invoice Data', 'Workflows'];

const defaultInvoiceLineItem: NewInvoiceLineItem = {
  amount: '',
  cost_code: '',
  description: '',
};

const emptyLineItemState: NewInvoiceLineItem[] = [defaultInvoiceLineItem];

interface Props {
  appContext: AppContext;
}

const InvoicesPage: React.FC<Props> = ({ appContext }) => {
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isAddLineItemsModalVisible, setIsAddLineItemsModalVisible] =
    useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeModalTabIndex, setActiveModalTabIndex] = useState<number>(0);
  const [isInvoiceModalVisible, setIsInvoiceModalVisble] =
    useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [vendorID, setVendorID] = useState<string>('');
  const [invoiceID, setInvoiceID] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [name, setName] = useState<string>('New Invoice');
  const [date, setDate] = useState<Date>(createTomorrowdate());
  const [description, setDescription] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [attachments, setAttachments] = useState<
    AttachmentFormattedForFrontEnd[]
  >([]);
  const [upload, setUpload] = useState<Upload | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(-1);
  const [status, setStatus] = useState<string>('');
  const { id } = useParams();
  const [isFilterPopUnderVisible, setIsFilterPopUnderVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
  const [searchboxValue, setSearchboxValue] = useState<string>('');
  const [selectedExtractionPage, setSelectedExtractionPage] =
    useState<number>(1);
  const [isExtracting, setIsExtracting] = useState<boolean>(false);
  const [tableIsExporting, setTableIsExporting] = useState<boolean>(false);
  const hasActiveFilters = activeFilters.length > 0;
  const invoicesMap: filtersMap = formatInvoiceFilters(appContext.invoices);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  // Refs
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  // Workflows state
  const [selectedContract, setSelectedContract] = useState<string>('');
  const [selectedDrawPackage, setSelectedDrawPackage] = useState<string>('');
  const [reviewersActiveOptions, setReviewersActiveOptions] = useState<
    string[]
  >([]);
  const [reviewerValue, setReviewersValue] = useState<OptionType[]>([]);
  const [approverActiveOptions, setApproverActiveOptions] = useState<string[]>(
    []
  );
  const [approverValue, setApproversValue] = useState<OptionType[]>([]);

  // Add line items modal
  const [lineItemsModalNewLineItems, setLineItemsModalNewLineItems] =
    useState<NewInvoiceLineItem[]>(emptyLineItemState);
  const [lineItems, setLineItems] = useState<InvoiceLineItem[]>([]);

  const showErrorMessageToast = (errorMessage: string) =>
    toast(errorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'error',
    });

  const showSuccessToastMessage = (successMessage: string) =>
    toast(successMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      type: 'success',
    });

  const handleSelectedVendorChange = (newVendor: string) => {
    setSelectedVendor(newVendor);
  };

  const handleViewTabClicked = (newTabIndex: number) => {
    // TODO: Make this actually do something
    return;
    // setActiveTabIndex(newTabIndex);
  };

  const handleModalTabIndexChanged = (newTabIndex: number) => {
    setActiveModalTabIndex(newTabIndex);
  };

  const handleCreateInvoiceButtonClicked = () => {
    if (appContext.isRefetching) return;
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
    setIsInvoiceModalVisble(true);
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalVisble(false);
    resetModalState();
  };

  const handleSubmitInvoice = async () => {
    // Editing an existing invoice
    if (selectedInvoice) {
      const newInvoice = await putInvoice({
        project_id: appContext.currentProject?.id,
        invoice_id: selectedInvoice.id,
        name: name,
        status: status,
        date_utc: formatDateToCustomString(date),
        vendor_id: selectedVendor,
        contract_id: selectedContract,
        draw_package_id: selectedDrawPackage,
        number: invoiceID,
        number_from_vendor: invoiceNumber,
        upload_id: attachments[0]?.id ?? upload?.id ?? '',
        notes: description,
        reviewer_user_ids: reviewersActiveOptions,
        approver_user_ids: approverActiveOptions,
        line_item_json: JSON.stringify(
          formatInvoiceLineItemsForBackend(lineItems)
        ),
        total_amount:
          removeCharactersExceptForPeriodAndNumbersFromFloatString(totalAmount),
      });

      if (newInvoice.error_message) {
        showErrorMessageToast(newInvoice.error_message);
      } else {
        showSuccessToastMessage('Successfully updated invoice');

        const newInvoiceState: Invoice[] = appContext.invoices.map(
          (invoice: Invoice) =>
            invoice.id === newInvoice.id ? newInvoice : invoice
        );
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        resetModalState();
      }
    }
    // Post a new invoice
    else {
      const newInvoice = await postInvoice({
        project_id: appContext.currentProject.id,
        name: name,
        status: status ?? 'Draft',
        date_utc: formatDateToCustomString(date),
        vendor_id: selectedVendor,
        contract_id: selectedContract,
        draw_package_id: selectedDrawPackage,
        number: invoiceID,
        number_from_vendor: invoiceNumber,
        upload_id: attachments[0]?.id ?? '',
        notes: description,
        reviewer_user_ids: reviewersActiveOptions,
        approver_user_ids: approverActiveOptions,
        line_item_json: JSON.stringify(
          formatInvoiceLineItemsForBackend(lineItems)
        ),
        total_amount:
          removeCharactersExceptForPeriodAndNumbersFromFloatString(totalAmount),
      });

      if (newInvoice.error_message) {
        showErrorMessageToast(newInvoice.error_message);
      } else {
        showSuccessToastMessage('Successfully created new invoice');
        const newInvoiceState = [...appContext.invoices, newInvoice];
        // TODO-STATE: verify refetching like this works
        appContext.refetchProjectData();
        setIsInvoiceModalVisble(false);
        resetModalState();
      }
    }
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const handleUploadPDF = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setUploadProgress(0);
    handleUploadAttachment(file);
  };

  const handleUploadButtonClicked = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const handleAddLineItemsButtonClicked = () => {
    setIsAddLineItemsModalVisible(true);
  };

  const handleCloseAddLineItemsModal = () => {
    setIsAddLineItemsModalVisible(false);
    setLineItemsModalNewLineItems(emptyLineItemState);
  };

  const handleSelectedContractChanged = (newValue: string) => {
    setSelectedContract(newValue);

    const foundContract = appContext.contracts.find(
      (contract: Contract) => contract.id === newValue
    );

    if (!foundContract) {
      setLineItems(
        lineItems.map((lineItem) => {
          lineItem.quoted_line_item = '';
          return lineItem;
        })
      );
      console.warn(`No contract found with ID of: ${newValue}`);
      return;
    }
    const formattedLineItems = formatContractLineItemsIntoInvoiceLineItems(
      foundContract.line_items,
      appContext.invoices,
      selectedInvoice,
      newValue,
      lineItems
    );

    setLineItems(formattedLineItems);
  };

  const handleAddLineItemsSubmit = () => {
    const formattedLineItemsToAdd = formatNewLineItemsIntoBackendLineItems(
      selectedInvoice,
      lineItemsModalNewLineItems,
      allCostCodeOptions,
      appContext.contracts,
      appContext.invoices,
      selectedContract
    );

    const newLineItemsState: InvoiceLineItem[] = sortInvoiceLineItems([
      ...lineItems,
      ...formattedLineItemsToAdd,
    ]);

    setLineItems(newLineItemsState);

    setIsAddLineItemsModalVisible(false);
    setLineItemsModalNewLineItems(emptyLineItemState);
  };

  const handleAddAnotherLineItemClicked = () => {
    if (lineItemsModalNewLineItems.length >= 5) {
      showErrorMessageToast('Can add max of 5 line items at once');
      return;
    }
    const newState = [...lineItemsModalNewLineItems, emptyLineItemState[0]];
    setLineItemsModalNewLineItems(newState);
  };

  const handleTableRowClicked = useCallback(
    async (rowIndex: number, row: Row) => {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }

      const currentInvoice = appContext.invoices.find(
        (invoice: any) => invoice.id === row.id
      );

      if (!currentInvoice) {
        console.warn(`No invoice found in state with ID of ${row.id}`);
        return;
      }

      const formattedReviewerUserIds = currentInvoice?.reviewers
        ? currentInvoice?.reviewers.map((reviewer: User) => reviewer.id)
        : [];

      const formattedApproverUserIds = currentInvoice?.approvers
        ? currentInvoice?.approvers.map((approver: User) => approver.id)
        : [];

      const reviewersOptions: OptionType[] =
        currentInvoice?.reviewers.map((user: User) => {
          return {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
            isComplete: user.is_reviewed === true,
          };
        }) ?? [];
      const approversOptions: OptionType[] =
        currentInvoice?.approvers.map((user: User) => {
          return {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
            isComplete: user.is_approved === true,
          };
        }) ?? [];

      const sortedLineItems = sortInvoiceLineItems(currentInvoice?.line_items);

      const invoiceDate = currentInvoice?.date_utc
        ? new Date(currentInvoice.date_utc)
        : createTomorrowdate();

      setPdfUrl(currentInvoice?.upload?.download_url ?? '');
      setIsInvoiceModalVisble(true);
      setSelectedInvoice(currentInvoice ?? null);
      setSelectedContract(currentInvoice?.contract_id ?? '');
      setVendorID(currentInvoice?.vendor_id ?? '');
      setName(currentInvoice?.name ?? '');
      setSelectedVendor(currentInvoice?.vendor_id ?? '');
      setInvoiceNumber(currentInvoice?.number_from_vendor ?? '');
      setDate(invoiceDate);
      setInvoiceID(currentInvoice?.number ?? '');
      setTotalAmount(currentInvoice?.total_amount.toString() ?? '');
      setDescription(currentInvoice?.notes ?? '');
      setSelectedDrawPackage(currentInvoice?.draw_package_id ?? '');
      setReviewersActiveOptions(formattedReviewerUserIds);
      setApproverActiveOptions(formattedApproverUserIds);
      setReviewersValue(reviewersOptions);
      setApproversValue(approversOptions);
      setStatus(currentInvoice?.status ?? '');
      setLineItems(sortedLineItems);
      setUpload(currentInvoice?.upload ?? '');
      setSelectedExtractionPage(1);
      setIsExtracting(false);
    },
    [appContext.invoices]
  );

  // Try to auto load the invoice if there's an ID in the URL
  useEffect(() => {
    if (appContext.invoices?.length === 0) return;
    let indexOfInvoice = null;
    const invoiceSelectedByIDInURL = appContext.invoices.find(
      (invoice: Invoice, index: number) => {
        if (invoice.id === id) {
          // Set the indexOfInvoice when the invoice with the specified 'id' is found
          indexOfInvoice = index;
          return true;
        }
        return false;
      }
    );

    // Zero is falsey in JavaScript so make sure we check if the number is 0
    if (invoiceSelectedByIDInURL && (indexOfInvoice || indexOfInvoice === 0)) {
      setSelectedInvoice(invoiceSelectedByIDInURL);
      handleTableRowClicked(indexOfInvoice, { id } as Row);
    }
  }, []);

  useEffect(() => {
    // If the user came from my inbox, update browser history so back button navigates back to it
    if (searchParams.get('from') === 'inbox') {
      window.history.pushState(null, '', '/inbox');
      window.history.pushState(null, '', location.pathname);
    }
  }, []);

  const resetModalState = () => {
    setIsInvoiceModalVisble(false);

    // Wait for fade out animation to play on modal
    setTimeout(() => {
      setSelectedInvoice(null);
      setVendorID('');
      setStatus('');
      setName('New Invoice');
      setSelectedVendor('');
      setSelectedDrawPackage('');
      setSelectedContract('');
      setInvoiceNumber('');
      setInvoiceID('');
      setDate(createTomorrowdate());
      setTotalAmount('');
      setDescription('');
      setReviewersActiveOptions([]);
      setApproverActiveOptions([]);
      setPdfUrl('');
      setAttachments([]);
      setUpload(null);
      setLineItems([]);
      setLineItemsModalNewLineItems(emptyLineItemState);
      setReviewersValue([]);
      setApproversValue([]);
      setSelectedExtractionPage(1);
      setIsExtracting(false);
    }, 200);
  };

  const handleNewLineItemChanged = (
    newValue: string,
    lineItemKey: keyof NewInvoiceLineItem,
    index: number
  ) => {
    const newLineItem = { ...lineItemsModalNewLineItems[index] };
    if (lineItemKey === 'cost_code') {
      newLineItem.description = getCostCodeDescriptionByCode(
        allCostCodeOptions,
        newValue
      );
    }

    newLineItem[lineItemKey] = newValue;
    const newLineItemState = [...lineItemsModalNewLineItems];
    newLineItemState[index] = newLineItem;
    setLineItemsModalNewLineItems(newLineItemState);
  };

  const handleLineItemChanged = (
    newValue: string,
    rowIndex: number,
    columnIndex: number,
    columnName?: string,
    row?: Row
  ) => {
    const newLineItemsState: any[] = [...sortInvoiceLineItems(lineItems)];
    const targetColumnKey = invoiceLineItemColumns[columnIndex + 1].dataKey;
    const targetLineItemToChange = lineItems.find(
      (lineItem: any) => lineItem.id === row?.id
    );

    if (!targetLineItemToChange) {
      console.warn(`Unable to find line item with ID: ${row?.id}`);
      return;
    }

    // Standard case for changing input value
    if (targetColumnKey && targetLineItemToChange) {
      if (
        targetColumnKey === 'cost_code' ||
        targetColumnKey === 'quoted_line_item'
      ) {
        (targetLineItemToChange as any)[targetColumnKey] = newValue;
      } else {
        (targetLineItemToChange as any)[targetColumnKey] = parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue)
        );
      }
    }

    // Don't do any math if the last char is a decimal becaues when you parse
    // "11." you'll always lose the decimal. You need to preserve the decimal
    // until there's one character after
    if (isLastCharADecimal(newValue)) return;

    // This Period or Materials Presently Stored should overwrite the value
    // of: Current Billing & Current work and materials
    if (
      targetColumnKey === 'this_period' ||
      targetColumnKey === 'materials_presently_stored'
    ) {
      const valueToAdd =
        targetColumnKey === 'this_period'
          ? targetLineItemToChange?.materials_presently_stored?.toString() ??
            '0'
          : targetLineItemToChange?.this_period?.toString() ?? '0';

      const newCurrentWorkAndMaterialsValue =
        parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue)
        ) +
        parseFloat(
          removeCharactersExceptForPeriodAndNumbersFromFloatString(valueToAdd)
        );

      const currentRetainageValue = parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          targetLineItemToChange.current_retainage.toString()
        )
      );

      const newCurrentBillingValue =
        newCurrentWorkAndMaterialsValue - currentRetainageValue;

      targetLineItemToChange.current_billing = newCurrentBillingValue;
      targetLineItemToChange.current_work_and_materials =
        newCurrentWorkAndMaterialsValue;
      targetLineItemToChange.total_completed_and_stored_to_date =
        targetLineItemToChange.from_previous_applications +
        (targetLineItemToChange.this_period ?? 0.0) +
        (targetLineItemToChange.materials_presently_stored ?? 0.0);
    }
    // Updates to Total Retainage should update the Current Retainage column and then
    // trigger an update to Current billing
    if (targetColumnKey === 'retainage') {
      const totalRetainageValue = parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(newValue)
      );
      const prevRetainage = parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          targetLineItemToChange?.previous_retainage?.toString() ?? '0'
        )
      );

      const newCurrentRetainageValue = totalRetainageValue - prevRetainage;

      const currentWorkAndMaterialsValue = parseFloat(
        removeCharactersExceptForPeriodAndNumbersFromFloatString(
          targetLineItemToChange?.current_work_and_materials?.toString() ?? '0'
        )
      );

      const newCurrentBillingValue =
        currentWorkAndMaterialsValue - newCurrentRetainageValue;

      targetLineItemToChange.current_retainage = newCurrentRetainageValue;
      targetLineItemToChange.current_billing = newCurrentBillingValue;
    }

    if (targetColumnKey === 'cost_code') {
      const newCostCode = getEntireCostCodeObjectByCostCodeId(
        newValue,
        allCostCodeOptions
      )!;

      const scheduledValue = selectedContract
        ? appContext.contracts
            .find((contract) => contract.id == selectedContract)
            ?.line_items.find(
              (cli) => cli.cost_code_id == targetLineItemToChange?.cost_code_id
            )?.total_amount ?? 0.0
        : 0.0;

      const newInvoiceLineItemCalcs = getInitialInvoiceLineItemWithCalculations(
        selectedInvoice,
        selectedContract === '' ? null : selectedContract,
        newCostCode,
        appContext.invoices,
        targetLineItemToChange.current_billing,
        scheduledValue
      );

      targetLineItemToChange.cost_code_id = newValue;
      targetLineItemToChange.cost_code = newCostCode;
      targetLineItemToChange.previous_retainage =
        newInvoiceLineItemCalcs.previous_retainage;
      targetLineItemToChange.current_retainage =
        targetLineItemToChange.retainage -
        (newInvoiceLineItemCalcs.previous_retainage ?? 0.0);
      targetLineItemToChange.from_previous_applications =
        newInvoiceLineItemCalcs.from_previous_applications;
      targetLineItemToChange.previously_paid =
        newInvoiceLineItemCalcs.previously_paid;
      targetLineItemToChange.total_completed_and_stored_to_date =
        newInvoiceLineItemCalcs.total_completed_and_stored_to_date;
      targetLineItemToChange.scheduled_value = scheduledValue;
      targetLineItemToChange.current_work_and_materials =
        newInvoiceLineItemCalcs.current_work_and_materials;
    }

    const indexOfDesiredRow = newLineItemsState.indexOf(
      (currentRow: Row) => currentRow.id === row?.id
    );

    newLineItemsState[indexOfDesiredRow] = targetLineItemToChange;
    setLineItems(newLineItemsState);
  };

  const handleUploadAttachment = async (file: File) => {
    const newUpload = await postContractAttachment(file, 'Invoices');
    const uploadUrl = newUpload.upload_url;
    const closeUploadUrl = getAPIBase() + 'upload/process';
    setUpload(newUpload);

    // Upload to bucket
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file?.type,
      },
      onUploadProgress: (event) => {
        const progress = event?.progress ? event.progress : 0;
        setUploadProgress(Math.round(progress * 100));
      },
    });

    // Close connection to bucket
    const closeUrlRequest = {
      ...standardRequestObject,
      method: 'PUT',
      body: JSON.stringify({
        upload_id: newUpload.id,
      }),
    };
    const formattedCloseRequest = new Request(closeUploadUrl, closeUrlRequest);
    const res = await fetch(formattedCloseRequest);
    const data: DownloadResponse = await res.json();

    // Abort if user closed the modal mid-upload
    if (!isInvoiceModalVisible) return;

    // Store the download URL returned by the request
    const downloadUrl = data.download_url;
    setPdfUrl(downloadUrl);
    setUploadProgress(-1);

    // Try to autofill the inputs by parsing the page content
    const pageContent = data.page_content;
    const numPages = data.num_pages;
    const uploadWithPageCount = { ...newUpload, num_pages: numPages };
    setUpload(uploadWithPageCount);
    handleExtractedPageContent(pageContent);

    const newAttachmentsState = [];
    const newAttachment: AttachmentFormattedForFrontEnd = {
      id: newUpload.id,
      title: file.name,
      size: file.size,
      download_url: data.download_url,
    };
    newAttachmentsState.push(newAttachment);

    setAttachments(newAttachmentsState);

    // Update page count of selected invoice for extraction dropdown
    if (!selectedInvoice) return;
    const newSelectedInvoice = { ...selectedInvoice };
    newSelectedInvoice['upload'] = {
      ...selectedInvoice?.upload,
      num_pages: numPages,
    };
    setSelectedInvoice(newSelectedInvoice);
  };

  const handleExtractedPageContent = (pageContent: PageContent): number => {
    if (pageContent.amount_due && !totalAmount) {
      setTotalAmount(
        removeEverythingBesidesNumbersAndDecimalsFromString(
          pageContent.amount_due
        )
      );
    }
    if (pageContent.invoice_date && date !== createTomorrowdate()) {
      setDate(new Date(pageContent.invoice_date));
    }
    if (pageContent.invoice_id && !invoiceNumber) {
      setInvoiceNumber(pageContent.invoice_id);
    }

    if (pageContent.supplier_name && !selectedVendor) {
      const vendorId = appContext.currentProject?.vendors.find(
        (vendor) => vendor.name == pageContent.supplier_name
      )?.id;
      if (vendorId) {
        setSelectedVendor(vendorId);
      }
    }

    // Try to autofill the line items if they exist on the page content
    // and if the user hasn't already added some line items
    let updatedLineItems = [...lineItems];
    let newLineItemsCount = 0;
    if (pageContent.line_items) {
      // We need to get the cost_code objects for each validParsedLineItem
      // based on its description or description of work values
      // if we don't have a cost code with this description then we will
      // return "null" for that cost code
      const parsedLineItems = pageContent.line_items.map((parsedLineItem) => {
        const cost_code = parsedLineItem.description
          ? getEntireCostCodeObjectByDescription(
              appContext.currentProject?.cost_codes ?? [],
              parsedLineItem.description
            )
          : parsedLineItem.description_of_work
          ? getEntireCostCodeObjectByDescription(
              appContext.currentProject?.cost_codes ?? [],
              parsedLineItem.description_of_work
            )
          : null;

        // If a cost code can't be found from the parsed description alone, attempt to match the
        // description to quoted line item strings.
        let costCodeFromQuotedLineItem: CostCode | null = null;
        if (!cost_code) {
          if (selectedContract) {
            // If a contract has been selected, only match against quoted line items on that
            // contract.
            appContext.contracts
              .filter((contract) =>
                selectedContract ? contract.id === selectedContract : true
              )
              ?.map((contract) => {
                contract.line_items
                  .filter(
                    (contractLineItem) => !!contractLineItem.quoted_line_item
                  )
                  .map((contractLineItem) => {
                    if (
                      contractLineItem.quoted_line_item ==
                        parsedLineItem.description ||
                      contractLineItem.quoted_line_item ==
                        parsedLineItem.description_of_work
                    ) {
                      costCodeFromQuotedLineItem = contractLineItem.cost_code;
                    }
                  });
              });
          } else {
            // If no contract has been selected, search all invoices for a matching quoted line item
            // string.
            appContext.invoices.map((invoice) =>
              invoice.line_items
                .filter((invoiceLineItem) => !!invoiceLineItem.quoted_line_item)
                .map((invoiceLineitem) => {
                  if (
                    (invoiceLineitem.quoted_line_item ==
                      parsedLineItem.description ||
                      invoiceLineitem.quoted_line_item ==
                        parsedLineItem.description_of_work) &&
                    !!invoiceLineitem.cost_code
                  ) {
                    costCodeFromQuotedLineItem = invoiceLineitem.cost_code;
                  }
                })
            );
          }
        }

        return {
          ...parsedLineItem,
          cost_code: cost_code ?? costCodeFromQuotedLineItem,
        };
      });

      // Now take these and make them into rows that can be rendered in a table
      parsedLineItems.forEach((parsedLineItem) => {
        // Convert all dollar values to floats, or 0 if they can't be converted
        let convertedAmount = parseFloat(
          removeEverythingBesidesNumbersAndDecimalsFromString(
            parsedLineItem.amount
          )
        );
        let convertedRetainage = parseFloat(
          removeEverythingBesidesNumbersAndDecimalsFromString(
            parsedLineItem.retainage
          )
        );
        let convertedThisPeriod = parseFloat(
          removeEverythingBesidesNumbersAndDecimalsFromString(
            parsedLineItem.work_completed_this_period
          )
        );
        let convertedMaterialsPresentlyStored = parseFloat(
          removeEverythingBesidesNumbersAndDecimalsFromString(
            parsedLineItem.materials_presently_stored
          )
        );
        if (isNaN(convertedRetainage)) convertedRetainage = 0;
        if (isNaN(convertedThisPeriod)) convertedThisPeriod = 0;
        if (isNaN(convertedMaterialsPresentlyStored))
          convertedMaterialsPresentlyStored = 0;
        if (isNaN(convertedAmount)) convertedAmount = 0;

        const matchingContractLineItem = selectedContract
          ? appContext.contracts
              .find((contract) => contract.id == selectedContract)
              ?.line_items.find(
                (cli) => cli.cost_code_id == parsedLineItem?.cost_code?.id
              )
          : null;
        const scheduledValue = matchingContractLineItem?.total_amount ?? 0.0;
        const finalizedRowsCalc = parsedLineItem.cost_code
          ? getInitialInvoiceLineItemWithCalculations(
              selectedInvoice,
              selectedContract === '' ? null : selectedContract,
              parsedLineItem.cost_code,
              appContext.invoices,
              convertedAmount,
              scheduledValue
            )
          : null;
        const currentRetainage = finalizedRowsCalc?.previous_retainage
          ? convertedRetainage - finalizedRowsCalc.previous_retainage
          : convertedRetainage;

        const currentWorkAndMaterials =
          convertedThisPeriod + convertedMaterialsPresentlyStored;
        const currentBillingFromThisPeriod =
          currentWorkAndMaterials - currentRetainage;
        const currentBilling =
          convertedAmount === 0 && currentBillingFromThisPeriod !== 0
            ? currentBillingFromThisPeriod
            : convertedAmount;
        const quotedLineItem = matchingContractLineItem
          ? matchingContractLineItem.quoted_line_item
          : parsedLineItem.description ??
            parsedLineItem.description_of_work ??
            '';
        const finalizedRow: InvoiceLineItem = {
          cost_code_id: parsedLineItem.cost_code?.id ?? '',
          created_on_utc: new Date().toDateString(),
          current_billing: currentBilling,
          current_retainage: currentRetainage,
          current_work_and_materials: currentWorkAndMaterials,
          from_previous_applications:
            finalizedRowsCalc?.from_previous_applications ?? 0,
          is_deleted: false,
          is_pending: false,
          materials_presently_stored: convertedMaterialsPresentlyStored,
          previously_paid: finalizedRowsCalc?.previously_paid ?? 0,
          previous_retainage: finalizedRowsCalc?.previous_retainage ?? 0,
          quantity: null,
          retainage: convertedRetainage,
          scheduled_value: scheduledValue,
          this_period: convertedThisPeriod,
          total_completed_and_stored_to_date:
            finalizedRowsCalc?.total_completed_and_stored_to_date ?? 0,
          unit_cost: null,
          unit_of_measurement: '',
          quoted_line_item: quotedLineItem,
          id: uuidv4(),
        };

        if (parsedLineItem.cost_code) {
          finalizedRow['cost_code'] = parsedLineItem.cost_code;
        }

        // If line items already exist, attempt to match them with the parsed line item based on
        // cost code.
        if (lineItems.length > 0) {
          const matchingLineItem = updatedLineItems.find((lineItem) => {
            return (
              lineItem.cost_code_id === finalizedRow.cost_code_id &&
              lineItem.cost_code_id !== ''
            );
          });
          if (matchingLineItem) {
            // If a match was found, update the editable fields.
            updatedLineItems = updatedLineItems.map((lineItem) => {
              if (lineItem.id == matchingLineItem.id) {
                const updatedLineItem = { ...lineItem };
                updatedLineItem.current_retainage =
                  finalizedRow.current_retainage;
                updatedLineItem.current_billing = finalizedRow.current_billing;
                updatedLineItem.this_period = finalizedRow.this_period;
                updatedLineItem.materials_presently_stored =
                  finalizedRow.materials_presently_stored;
                updatedLineItem.current_work_and_materials =
                  finalizedRow.current_work_and_materials;
                updatedLineItem.retainage = finalizedRow.retainage;
                if (!updatedLineItem.quoted_line_item) {
                  updatedLineItem.quoted_line_item =
                    finalizedRow.quoted_line_item;
                }
                return updatedLineItem;
              }

              return lineItem;
            });
          } else if (!selectedContract) {
            // If no match was found, add the parsed line item if contract line items aren't already
            // present.
            newLineItemsCount += 1;
            updatedLineItems.push(finalizedRow);
          }
        } else {
          // If no line items already exist, add the parsed line item.
          newLineItemsCount += 1;
          updatedLineItems.push(finalizedRow);
        }
      });

      setLineItems(updatedLineItems);
    }

    return newLineItemsCount;
  };

  const handleSendToERPClicked = async () => {
    const exportResponse = await postExportInvoicesForERP(selectedInvoices);
    if (exportResponse?.error_message) {
      toast(exportResponse?.error_message, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'error',
      });
    } else {
      window.open(exportResponse.export_url, '_blank');
      toast('Successfully exported invoice for ERP', {
        position: toast.POSITION.BOTTOM_CENTER,
        type: 'success',
      });
      setSelectedInvoices([]);
      appContext.refetchProjectData();
    }
  };

  const handleInvoicesSOVTableRowDeleteClicked = (
    event: React.MouseEvent,
    row: Row
  ) => {
    const newLineItemsState = lineItems.filter(
      (lineItem: InvoiceLineItem) => lineItem.id !== row.id
    );
    setLineItems(newLineItemsState);
  };

  const approverOptions: OptionType[] = appContext.currentProject
    ? appContext.currentProject.users.map((user: User) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
          vendor_id: null,
          isComplete: selectedInvoice
            ? selectedInvoice.reviewers.find(
                (reviewer: User) => reviewer.id === user.id
              )?.is_approved === true
            : false,
        };
      })
    : [];

  const reviewerOptions: OptionType[] = appContext.currentProject
    ? appContext.currentProject.users.map((user: User) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
          vendor_id: null,
          isComplete: selectedInvoice
            ? selectedInvoice.reviewers.find(
                (reviewer: User) => reviewer.id === user.id
              )?.is_reviewed === true
            : false,
        };
      })
    : [];

  const vendorOptions: VendorFormattedForSelect[] = appContext.currentProject
    ? appContext.currentProject.vendors
        .filter((vendor: Vendor) => vendor.is_deactivated === false)
        .map((vendor: Vendor) => {
          return {
            value: vendor.id,
            label: vendor.name,
            vendor_id: null,
          };
        })
    : [];

  const contractOptions: VendorFormattedForSelect[] = appContext.contracts
    ? appContext.contracts.map((contract: any) => {
        return {
          value: contract.id,
          label: contract.title,
          vendor_id: contract.vendor_id,
        };
      })
    : [];

  const drawPackageOptions: VendorFormattedForSelect[] = appContext.drawPackages
    ? appContext.drawPackages
        .map((drawPackage: DrawPackage) => ({
          value: drawPackage.id,
          label: drawPackage.title,
          vendor_id: null,
        }))
        .sort((a, b) => {
          const dateA = new Date(
            appContext.drawPackages.find((pkg) => pkg.id === a.value)
              ?.date_utc || ''
          );
          const dateB = new Date(
            appContext.drawPackages.find((pkg) => pkg.id === b.value)
              ?.date_utc || ''
          );

          return dateB.getTime() - dateA.getTime();
        })
    : [];

  const modalTabContent = [
    <InvoiceDataView
      key="InvoiceDataView"
      status={status}
      selectedVendor={selectedVendor}
      vendorID={vendorID}
      invoiceNumber={invoiceNumber}
      totalAmount={totalAmount}
      invoiceID={invoiceID}
      date={date}
      description={description}
      handleSelectedVendorChange={handleSelectedVendorChange}
      handleVendorIDChange={(newValue: string) => setVendorID(newValue)}
      handleInvoiceNumberChange={(newValue: string) =>
        setInvoiceNumber(newValue)
      }
      handleInvoiceIDChange={(newValue: string) => setInvoiceID(newValue)}
      handleTotalAmountChange={(newValue: string) => setTotalAmount(newValue)}
      handleDescriptionChange={(newValue: string) => setDescription(newValue)}
      handleDateChange={(newDate: Date) => handleDateChange(newDate)}
      handleStatusChange={(newValue: string) => setStatus(newValue)}
      vendorOptions={vendorOptions}
    />,
    <WorkFlowView
      key="WorkFlowView"
      selectedContract={selectedContract}
      selectedVendor={selectedVendor}
      contractOptions={contractOptions}
      drawPackageOptions={drawPackageOptions}
      selectedDrawPackage={selectedDrawPackage}
      handleSelectedContractChange={(newValue: string) =>
        handleSelectedContractChanged(newValue)
      }
      handleSelectedDrawPackageChange={(newValue: string) =>
        setSelectedDrawPackage(newValue)
      }
      reviewersOptions={reviewerOptions}
      approversOptions={approverOptions}
      handleReviewerOptionsChange={(newValues: OptionType[]) =>
        setReviewersValue(newValues)
      }
      handleReviewerActiveOptionsChange={(newValues: string[]) =>
        setReviewersActiveOptions(newValues)
      }
      handleApproverOptionsChange={(newValues: OptionType[]) =>
        setApproversValue(newValues)
      }
      handleApproverActiveOptionsChange={(newValues: string[]) =>
        setApproverActiveOptions(newValues)
      }
      reviewersActiveOptions={reviewerValue}
      approversActiveOptions={approverValue}
    />,
  ];

  const handleReviewInvoice = async () => {
    if (!selectedInvoice) return;
    const invoiceReview = await postInvoiceReview(selectedInvoice.id);

    if (invoiceReview.error_message) {
      showErrorMessageToast(invoiceReview.error_message);
    } else {
      const newReview = invoiceReview.is_reviewed;
      const newStatus = invoiceReview.status;
      showSuccessToastMessage(
        `Successfully ${newReview === true ? '' : 'un'}reviewed invoice`
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();

      const currentInvoiceReviewers = selectedInvoice.reviewers;
      const updatedInvoiceReviewers = currentInvoiceReviewers.map((rev) => {
        if (rev.id == appContext.currentUser.id) {
          rev.is_reviewed = newReview;
        }
        return rev;
      });
      setSelectedInvoice({
        ...selectedInvoice,
        status: newStatus,
        reviewers: updatedInvoiceReviewers,
      });

      setStatus(newStatus);
      setReviewersValue(
        reviewerValue.map((option: OptionType) => {
          if (option.value == appContext.currentUser.id) {
            option.isComplete = newReview;
          }
          return option;
        })
      );
    }
  };

  const handleApproveInvoice = async () => {
    if (!selectedInvoice) return;
    const invoiceApproval = await postInvoiceApproval(selectedInvoice.id);

    if (invoiceApproval.error_message) {
      showErrorMessageToast(invoiceApproval.error_message);
    } else {
      const newApproval = invoiceApproval.is_approved;
      const newStatus = invoiceApproval.status;
      showSuccessToastMessage(
        `Successfully ${newApproval === true ? '' : 'un'}approved invoice`
      );
      // TODO-STATE: verify refetching like this works
      appContext.refetchProjectData();

      const currentInvoiceApprovers = selectedInvoice.approvers;
      const updatedInvoiceApprovers = currentInvoiceApprovers.map((appr) => {
        if (appr.id == appContext.currentUser.id) {
          appr.is_approved = newApproval;
        }
        return appr;
      });
      setSelectedInvoice({
        ...selectedInvoice,
        status: newStatus,
        approvers: updatedInvoiceApprovers,
      });

      setStatus(newStatus);
      setApproversValue(
        approverValue.map((option: OptionType) => {
          if (option.value == appContext.currentUser.id) {
            option.isComplete = newApproval;
          }
          return option;
        })
      );
    }
  };

  const handleCheckboxColumnSelected = () => {
    if (selectedInvoices.length < invoiceTableRows.length) {
      setSelectedInvoices(invoiceTableRows.map((row: Row) => row.id ?? ''));
    } else {
      setSelectedInvoices([]);
    }
  };

  const handleInvoiceChecked = (row: Row) => {
    const isSelected = selectedInvoices.includes(row.id ?? '');
    if (isSelected) {
      const filteredIDs = selectedInvoices.filter((id) => id !== row.id);
      setSelectedInvoices(filteredIDs);
    } else {
      setSelectedInvoices([...selectedInvoices, row.id ?? '']);
    }
  };

  const handleFilterSelected = (newFilter: FilterItem) => {
    const filterExists = activeFilters.some(
      (filter) =>
        filter.label === newFilter.label && filter.value === newFilter.value
    );

    if (filterExists) {
      const updatedFilters = activeFilters.filter(
        (filter) =>
          filter.label !== newFilter.label || filter.value !== newFilter.value
      );
      setActiveFilters(updatedFilters);
    } else {
      const newFilters = [...activeFilters, newFilter];
      setActiveFilters(newFilters);
    }
  };

  const handleFilterButtonRemoved = (filterToRemove: FilterItem) => {
    const newData = activeFilters.filter(
      (item) =>
        !(
          item.label === filterToRemove.label &&
          item.value === filterToRemove.value
        )
    );
    setActiveFilters(newData);
  };

  const extractFromPage = async (pageNum: number) => {
    if (isNaN(pageNum) || !upload) return;
    setIsExtracting(true);

    const extractedContent = await postExtractInvoicePage(upload.id, pageNum);

    if (extractedContent.error_message) {
      showErrorMessageToast(extractedContent.error_message);
    } else {
      const newLineItems = handleExtractedPageContent(extractedContent);

      let successMsg = `Successfully parsed page ${pageNum}`;
      if (newLineItems > 0)
        successMsg += ` and added ${newLineItems} new line item(s)`;
      successMsg += '.';
      showSuccessToastMessage(successMsg);
    }
    setIsExtracting(false);
  };

  const hasUploadedPDF = Boolean(pdfUrl);
  const invoiceTableRows =
    appContext.invoices.length > 0
      ? sortInvoices(
          applyInvoiceFilters(
            formatInvoicesForEditableTable(
              appContext.invoices,
              selectedInvoices
            ),
            searchboxValue,
            activeFilters
          )
        )
      : [];

  const allCostCodeOptions = appContext.currentProject
    ? sortCostCodesByCode(appContext.currentProject.cost_codes)
    : [];
  const invoiceLineItemsTableRows = formatInvoiceLineItemsForEditableTable(
    sortInvoiceLineItems(lineItems),
    allCostCodeOptions,
    !!selectedContract
  );

  window.document.title = 'BidSight – Invoices';
  return (
    <section className="InvoicesPage">
      <div>
        <div className="header-button-container">
          <div className="primary-header-subheader-container">
            <h4 className="main-page-header">Invoices</h4>
            <h5>{`Invoices sent to ${
              appContext.currentProject?.invoice_pipeline_email ?? 'N/A'
            } will appear here`}</h5>
          </div>
          <div>
            {selectedInvoices.length > 0 && !appContext.isRefetching && (
              <span className="send-to-erp-button-container">
                <Button
                  label="Send to ERP"
                  onClick={handleSendToERPClicked}
                  variant={ButtonVariant.GrayThin}
                />
              </span>
            )}
            {!appContext.isRefetching && invoiceTableRows.length == 0 ? (
              <div /> // wierd bug where "isRefetching || invoiceTableRows.length == 0" condition isn't trigger a render
            ) : (
              <Button
                label={'Create Invoice'}
                isDisabled={appContext.isRefetching}
                onClick={handleCreateInvoiceButtonClicked}
                variant={ButtonVariant.PrimaryThin}
              />
            )}
          </div>
        </div>
        <div className="tab-bar-container">
          <TabBar
            tabOptions={tabOptions}
            activeTabOption={tabOptions[activeTabIndex]}
            onTabClick={handleViewTabClicked}
          />
        </div>
        <div className="search-filter-display-container">
          <div className="search-fitler-container">
            <div className="search-container">
              <TextInput
                placeholder="Search"
                icon={<MagnifyingGlassIcon />}
                onChange={(newValue: string) => setSearchboxValue(newValue)}
              />
            </div>
            <span className="filter-popunder-container">
              <span className="filter-button-container">
                <IconButton
                  icon={<FilterIcon />}
                  label={'Filters'}
                  variant={IconButtonVariants.dotted}
                  onClick={() =>
                    setIsFilterPopUnderVisible(!isFilterPopUnderVisible)
                  }
                />
                <PopUnder
                  isOpen={isFilterPopUnderVisible}
                  onClose={() => setIsFilterPopUnderVisible(false)}
                  optionsMap={invoicesMap}
                  handleSecondOptionSelected={handleFilterSelected}
                  activeFilters={activeFilters}
                />
              </span>
              {hasActiveFilters && (
                <span className="clear-filters-button-container">
                  <IconButton
                    icon={<CloseIcon />}
                    label={'Clear filters'}
                    variant={IconButtonVariants.dotted}
                    onClick={() => setActiveFilters([])}
                  />
                </span>
              )}
            </span>
          </div>
          <span />
          <IconButton
            icon={<ExportIcon />}
            label={tableIsExporting ? 'Exporting...' : 'Export'}
            variant={IconButtonVariants.dotted}
            onClick={() => setTableIsExporting(true)}
          />
        </div>
        <div className="active-filters-container">
          {activeFilters.map((activeFilter) => (
            <FilterButton
              key={activeFilter.label}
              filter={activeFilter}
              onClick={handleFilterButtonRemoved}
            />
          ))}
        </div>
        {selectedInvoices.length > 0 && (
          <p className="selected-invoices-counter">
            {selectedInvoices.length} invoice
            {selectedInvoices.length === 1 ? '' : 's'} selected
          </p>
        )}
      </div>
      {appContext.isRefetching ? (
        <EditableTableLoadingSkeleton />
      ) : invoiceTableRows.length > 0 ? (
        <div className="bottom">
          <div className="invoice-table-container">
            <EditableTableV2
              isReadOnly
              columns={invoiceColumns}
              rows={invoiceTableRows}
              onTablerowClicked={handleTableRowClicked}
              tableName={`${appContext.currentProject.name} Invoices - BidSight`}
              isExporting={tableIsExporting}
              setIsExporting={setTableIsExporting}
              showCheckboxSelectColumn
              onCheckboxSelected={handleInvoiceChecked}
              onCheckboxColumnSelected={handleCheckboxColumnSelected}
              isCheckboxHeaderSelected={
                invoiceTableRows.length === selectedInvoices.length
              }
            />
          </div>
        </div>
      ) : appContext.invoices.length > 0 && invoiceTableRows.length === 0 ? (
        <div>No invoices found</div>
      ) : (
        <div className="no-invoices-container">
          <FileIconWithCircle />
          <p className="no-invoices-added">No invoices</p>
          <p>Create an invoice to get started</p>
          <div className="no-invoices-button-container">
            <Button
              label="Create invoice"
              onClick={handleCreateInvoiceButtonClicked}
              variant={ButtonVariant.PrimaryThin}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={isInvoiceModalVisible}
        onClose={handleCloseInvoiceModal}
        size={ModalSizes.fullScreen}
        hideXButton={true}
        hideButtons={true}
      >
        <div className="modal-header-container">
          <input
            type="text"
            value={name}
            ref={nameInputRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </div>
        <div className="modal-change-order-content">
          <div className="top-right-buttons-container">
            <Button
              label="Cancel"
              variant={ButtonVariant.GrayThin}
              onClick={handleCloseInvoiceModal}
            />
            {!!selectedInvoice?.reviewers?.find(
              (rev) => rev.id == appContext.currentUser.id
            ) &&
              (() => {
                const review = selectedInvoice.reviewers.find(
                  (rev) => rev.id == appContext.currentUser.id
                )!;
                return (
                  <span className="margin-left">
                    <Button
                      label={`${
                        review.is_reviewed === true
                          ? 'Mark as Unreviewed'
                          : 'Mark as Reviewed'
                      }`}
                      variant={ButtonVariant.SecondaryThin}
                      onClick={handleReviewInvoice}
                    />
                  </span>
                );
              })()}
            {!!selectedInvoice?.approvers?.find(
              (appr) => appr.id == appContext.currentUser.id
            ) &&
              (() => {
                const approval = selectedInvoice.approvers.find(
                  (appr) => appr.id == appContext.currentUser.id
                )!;
                return (
                  <span className="margin-left">
                    <Button
                      label={`${
                        approval.is_approved === true ? 'Unapprove' : 'Approve'
                      }`}
                      variant={ButtonVariant.SecondaryThin}
                      onClick={handleApproveInvoice}
                    />
                  </span>
                );
              })()}
            <span className="margin-left">
              <Button
                label={selectedInvoice ? 'Save' : 'Create invoice'}
                isDisabled={appContext.isRefetching}
                variant={ButtonVariant.PrimaryThin}
                onClick={handleSubmitInvoice}
              />
            </span>
          </div>
        </div>
        <div className="modal-primary-content-container">
          <div className="left">
            <Card isFullHeight>
              <div className="modal-tab-bar-container">
                <TabBar
                  tabOptions={modalTabBarOptions}
                  activeTabOption={modalTabBarOptions[activeModalTabIndex]}
                  onTabClick={handleModalTabIndexChanged}
                />
              </div>
              {modalTabContent[activeModalTabIndex]}
            </Card>
          </div>
          <div className="right">
            <Card isFullHeight disableScroll>
              {hasUploadedPDF ? (
                <PdfViewer
                  fileUrl={pdfUrl}
                  handleFileChange={(event) => {
                    setPdfUrl('');
                    handleUploadPDF(event);
                  }}
                />
              ) : (
                <div className="empty-attachments-container">
                  <IconButton
                    onClick={() =>
                      uploadProgress == -1 && handleUploadButtonClicked()
                    }
                    icon={<UploadIcon />}
                  />
                  {uploadProgress >= 0 ? (
                    uploadProgress == 100 ? (
                      <div className="upload-container">
                        <div>{`Processing invoice...`}</div>
                      </div>
                    ) : (
                      <div className="upload-container">
                        {`Upload in progress (${uploadProgress}%)`}
                      </div>
                    )
                  ) : (
                    <div className="upload-container">
                      <label htmlFor="file-upload" className="upload-label">
                        <span className="click-text">Click to upload</span>
                      </label>
                      <div>
                        PDF only {'('}max 5gb{')'}
                      </div>
                      <input
                        type="file"
                        id="file-upload"
                        accept=".pdf"
                        className="file-input"
                        max={5000000}
                        onChange={handleUploadPDF}
                        ref={fileUploadRef}
                      />
                    </div>
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
        <Card>
          <div className="line-items-header">
            <h6>Line items</h6>
            {!!upload && upload.num_pages >= 0 && (
              <div className="line-items-controls">
                <div className="line-items-select-container">
                  <Select
                    value={selectedExtractionPage.toString()}
                    onChange={(newValue: string) =>
                      setSelectedExtractionPage(parseInt(newValue))
                    }
                    isDisabled={isExtracting}
                  >
                    {Array.from(Array(upload.num_pages).keys()).map(
                      (page_num) => {
                        return (
                          <option
                            value={(page_num + 1).toString()}
                            key={page_num}
                          >{`Page ${page_num + 1}`}</option>
                        );
                      }
                    )}
                  </Select>
                </div>
                {isExtracting ? (
                  <LoadingSpinner className="loading-spinner-extraction" />
                ) : (
                  <MagicWandIcon
                    className="magin-wand-icon"
                    onClick={() => extractFromPage(selectedExtractionPage)}
                  />
                )}
              </div>
            )}
          </div>
          <div className="bottom">
            <div className="invoice-table-container">
              {invoiceLineItemsTableRows.length > 0 ? (
                <EditableTableV2
                  columns={invoiceLineItemColumns}
                  rows={invoiceLineItemsTableRows}
                  onTableCellChanged={handleLineItemChanged}
                  onTableRowDeleteClicked={
                    handleInvoicesSOVTableRowDeleteClicked
                  }
                  isReadOnly={
                    status === 'Sent to ERP' ||
                    status === 'Paid' ||
                    status === 'Archived'
                  }
                  showSummationRow
                  useFixedPositionOnCostCodeSelector
                />
              ) : (
                <p>No line items for this invoice</p>
              )}
            </div>
          </div>
          <div className="add-line-items-button-container">
            <IconButton
              label="Add line items"
              icon={<PlusIcon />}
              onClick={handleAddLineItemsButtonClicked}
            />
          </div>
        </Card>
      </Modal>
      <Modal
        isOpen={isAddLineItemsModalVisible}
        onClose={handleCloseAddLineItemsModal}
        size={ModalSizes.medium}
        primaryButtonLabel="Add line items"
        primaryHeader="Add line items"
        onSecondaryButtonClicked={handleCloseAddLineItemsModal}
        onPrimaryButtonClicked={handleAddLineItemsSubmit}
        disableScrolling
      >
        <div className="line-items-modal-body-container">
          {lineItemsModalNewLineItems.map(
            (lineItemsModalNewLineItem: NewInvoiceLineItem, i: number) => {
              return (
                <div
                  className="line-items-modal-inputs"
                  id={`line-item-${i}`}
                  key={i}
                >
                  <span className="label-input-container">
                    <label htmlFor="contract-line-item-cost-code-input">
                      Cost Code
                    </label>
                    <div className="text-input-container">
                      <CostCodeSelectorInput
                        isFullWidth
                        openFromBottom
                        onlyShowAvailableCostCodes
                        all_cost_codes={allCostCodeOptions}
                        usedCostCodes={convertBudgetLineItemsIntoCostCodes(
                          appContext.budget
                        )}
                        value={getEntireCostCodeObjectByCostCodeId(
                          lineItemsModalNewLineItem.cost_code,
                          allCostCodeOptions
                        )}
                        onCostCodeSelect={(newSelectedCostCode: CostCode) =>
                          handleNewLineItemChanged(
                            newSelectedCostCode.id,
                            'cost_code',
                            i
                          )
                        }
                      />
                    </div>
                  </span>
                  <span className="label-input-container">
                    <label htmlFor="contract-line-item-amount-input">
                      Amount
                    </label>
                    <div className="text-input-container amount">
                      <CurrencyInput
                        inputprops={{
                          value: lineItemsModalNewLineItem.amount,
                          id: 'contract-line-item-amount-input',
                          placeholder: 'Amount',
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleNewLineItemChanged(
                              event.target.value,
                              'amount',
                              i
                            );
                          },
                        }}
                      />
                    </div>
                  </span>
                </div>
              );
            }
          )}
          <span className="add-another-button-container">
            <IconButton
              label="Add another"
              icon={<PlusIcon />}
              onClick={handleAddAnotherLineItemClicked}
            />
          </span>
        </div>
      </Modal>
    </section>
  );
};

export default InvoicesPage;
