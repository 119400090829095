import {
  CellType,
  Row,
  RowType,
} from '../../components/EditableTable/EditableTableV2';
import { Budget, BudgetLineItem } from '../../types/budgetTypes';
import { CostCode } from '../../types/sharedTypes';
import { findCostCodeDifference } from '../costCodes/findCostCodeDifference';
import { getCostCodeParentByChildID } from '../costCodes/getCostCodeParentByChildID';
import { calculateBoughtScope } from './calculations/calculateBoughtScope';
import { calculateCurrentDraw } from './calculations/calculateCurrentDraw';
import { calculateRequestedToDate } from './calculations/calculateRequestedToDate';
import { calculateTotalAmount } from './calculations/calculateTotalAmount';
import { doesParentBudgetLineItemHaveNewlyCreatedChild } from './doesParentBudgetLineItemHaveNewlyCreatedChild';
import { getBudgetLineItemByCostCodeID } from './getBudgetLineItemByCostCodeID';

export const formatNonFinalizedBudgetRow = (
  budgetLineItem: BudgetLineItem,
  depth: number,
  costCodes: CostCode[],
  usedCostCodes: CostCode[],
  allBudgetLineItems: BudgetLineItem[]
): Row => {
  const row: Row = {
    cells: [],
    type: budgetLineItem.isNewlyCreated
      ? RowType.new
      : depth === 0
      ? RowType.parent
      : RowType.child,
    id: budgetLineItem.id,
    rowData: budgetLineItem,
    children: [],
    indent: budgetLineItem.isNewlyCreated ? 0 : depth,
    isClickingDisabled: budgetLineItem.isDisabled ?? false,
  };

  const entireParentCostCodeObject = getCostCodeParentByChildID(
    costCodes,
    row.rowData.cost_code
  );

  if (!budgetLineItem.isNewlyCreated) {
    row.rowData.parentCostCode = entireParentCostCodeObject;
  }

  const usedCostCodeParent = getCostCodeParentByChildID(
    usedCostCodes,
    row.rowData.cost_code
  );

  const parentBudgetLineItem = getBudgetLineItemByCostCodeID(
    allBudgetLineItems ?? [],
    entireParentCostCodeObject?.id ?? ''
  );

  // Don't allow user to create new rows if a new row already exists or if we've used
  // all cost codes options for that family
  if (parentBudgetLineItem) {
    const differenceInChildren = findCostCodeDifference(
      usedCostCodeParent,
      entireParentCostCodeObject
    );
    row.isAddRowButtonDisabled =
      doesParentBudgetLineItemHaveNewlyCreatedChild(parentBudgetLineItem) ||
      differenceInChildren.length === 0;
  }

  // Line Item
  row.cells.push({
    value: `${budgetLineItem?.cost_code?.code} - ${budgetLineItem?.cost_code?.description}`,
    type: CellType.costCodeDropDown,
    isCellDisabled: !budgetLineItem.isNewlyCreated,
    columnDisplayName: 'Cost Code',
    dropdownOptions: costCodes,
    parentCostCode: budgetLineItem.parentCostCode,
    usedCostCodes: usedCostCodes,
  });

  // Total Amount
  row.cells.push({
    value: calculateTotalAmount(budgetLineItem).toString(),
    type: CellType.dollars,
    columnDisplayName: 'Total Amount',
    isCellDisabled: budgetLineItem.children.length > 0,
  });

  // Requested to Date
  row.cells.push({
    value: calculateRequestedToDate(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Requested to Date',
  });

  // Current Draw
  row.cells.push({
    value: calculateCurrentDraw(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Current Draw',
  });

  // Remaining to Spend
  const totalBudgetAmount = calculateTotalAmount(budgetLineItem);
  const requestedToDateAmount = calculateRequestedToDate(budgetLineItem);
  const currentDrawAmount = calculateCurrentDraw(budgetLineItem);
  const remainingToSpendValue =
    totalBudgetAmount - requestedToDateAmount - currentDrawAmount;

  row.cells.push({
    value: remainingToSpendValue.toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Remaining to Spend',
  });

  // Committed Costs
  row.cells.push({
    value: calculateBoughtScope(budgetLineItem).toFixed(2),
    type: CellType.dollars,
    isCellDisabled: true,
    columnDisplayName: 'Committed Costs',
  });

  if (budgetLineItem.children) {
    budgetLineItem.children.forEach((childBudgetLineItem) => {
      const childRow = formatNonFinalizedBudgetRow(
        childBudgetLineItem,
        depth + 1,
        costCodes,
        usedCostCodes,
        allBudgetLineItems
      );
      if (row.children) {
        row.children.push(childRow);
      }
    });
  }

  return row;
};

export const formatNonFinalizedBudgetToEditableTable = (
  budget: Budget | null,
  allCostCodes: CostCode[],
  usedCostCodes: CostCode[]
): Row[] => {
  const result: Row[] = [];

  if (!budget) return result;

  budget.line_items.forEach((budgetLineItem) => {
    const parentRow = formatNonFinalizedBudgetRow(
      budgetLineItem,
      0,
      allCostCodes,
      usedCostCodes,
      budget.line_items
    );
    result.push(parentRow);
  });

  // Sort by cost code low to high
  result.sort(
    (a, b) =>
      parseInt(a.rowData.cost_code.code, 10) -
      parseInt(b.rowData.cost_code.code, 10)
  );

  return result;
};
