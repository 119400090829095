import { Row } from '../../components/EditableTable/EditableTableV2';
import { FilterItem } from '../../types/sharedTypes';
import { filtersToNotificationDataValueMap } from './filtersToNotificationDataValueMap';

export const applyNotificationFilters = (
  rows: Row[],
  searchboxValue: string,
  activeFilters: FilterItem[]
): Row[] => {
  if (searchboxValue.length === 0 && activeFilters.length === 0) return rows;

  // First apply search
  const lowercaseSearchValue = searchboxValue.toLowerCase();
  const rowsWithSearchApplied = rows.filter(
    (row: Row) =>
      row.rowData.entity_name &&
      row.rowData.entity_name.toLowerCase().includes(lowercaseSearchValue)
  );

  if (activeFilters.length === 0) return rowsWithSearchApplied;

  // Then apply filters
  const rowsWithFiltersApplied: Row[] = [];
  rowsWithSearchApplied.forEach((row: Row) => {
    // All filters must apply to the row for it to be visible
    let shouldRowBeAdded = true;

    activeFilters.forEach((filter: FilterItem) => {
      const { label, value } = filter;
      const dataValueForFilter = filtersToNotificationDataValueMap[label];
      let contractValue = row.rowData[dataValueForFilter];

      if (label === 'Vendor') {
        contractValue = row.rowData?.vendor_name;
      }

      if (contractValue === null || contractValue === undefined) {
        contractValue = 'None';
      }

      // Row hasn't been added already && row's value matches filter
      if (
        !(
          String(contractValue) === value &&
          !rowsWithFiltersApplied.includes(row)
        )
      ) {
        shouldRowBeAdded = false;
      }
    });

    if (shouldRowBeAdded) rowsWithFiltersApplied.push(row);
  });

  return rowsWithFiltersApplied;
};
